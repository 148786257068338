<template lang="pug">
div
  .ActionRecibirEquipo
    //- BOTON Recibir Equipo
    button.change-button(
      :disabled="disabled || setDisabled",
      @click="RecibirEquipo"
    ) 
      span Recibir Equipo
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ActionRecibirEquipo',
  props: ['ot', 'disabled'],
  data() {
    return {
      setDisabled: false,
    };
  },
  methods: {
    ...mapActions('OTStore', ['recibirEquipo']),
    async RecibirEquipo() {
      const resp = await this.recibirEquipo(this.ot.ot);
      console.log(resp);
      if (resp && resp.status === 200) {
        this.setDisabled = true;
        this.$bvToast.toast('Se ha recibido el equipo correctamente', {
          variant: 'success',
        });
      } else {
        this.$bvToast.toast('Error al recibir el equipo', {
          variant: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
