import axios from '../../plugins/axios'

export const createEquipment = async ({ commit }, data) => {
  const apiAddress = 'api/equipo/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', { statusRequest: response })
      commit('SET_EQUIPMENT', { equipment: response.data })
    })
    .catch((error) => {
      commit('SET_STATUS', { statusRequest: error.response })
    })
}

export const editEquipment = async ({ commit }, data) => {
  const apiAddress = `api/equipo/${data.id}/`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', { statusRequest: response })
    })
    .catch((error) => {
      commit('SET_STATUS', { statusRequest: error.response })
    })
}

export const getEquipment = ({ commit }, equipmentId) => {
  const apiAddress = `api/equipo/${equipmentId}/`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => {
      commit('SET_EQUIPMENT', { equipment: response.data })
    })
    .catch((error) => console.log(error))
}

export const searchBySerial = ({}, { serial }) => {
  const apiAddress = `api/equipo/?query=${serial}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => response.data)
}

export const getModels = ({ commit }) => {
  const apiAddress = 'api/equipo/modelos/'
  return axios.get(apiAddress).then((res) => {
    commit('SET_MODELS', { models: res.data })
  })
}
export const createModel = ({}, data) => {
  const apiAddress = 'api/equipo/modelos/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config)
}

export const getBrands = ({ commit }) => {
  const apiAddress = 'api/equipo/marcas/'
  return axios
    .get(apiAddress)
    .then((res) => {
      commit('SET_BRANDS', { brands: res.data })
    })
    .catch((error) => error.response)
}

export const createBrand = ({}, data) => {
  const apiAddress = 'api/equipo/marcas/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}
