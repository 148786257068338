<template lang="pug">
Fragment
  .span-full
    div.head
      .d-flex
        b-icon.no-print.mr-2(icon="clipboard-data" variant="primary" font-scale="1.5")
        h4.mb-0.title Datos Provisorios
    hr
  div.details
    label.sub-title.is-primary R.U.T
    span.display-data {{ot.datos_provisorios.rut}}
  div.details
    label.input-label Tipo de Cliente
    span.display-data {{ot.datos_provisorios.tipo_cliente === 1 ? 'Persona' : 'Empresa'}}
  div.details
    label.input-label Nombre o Razón Social
    span.display-data
      | {{ot.datos_provisorios.tipo_cliente !== 1 ? ot.datos_provisorios.razon_social : `${ot.datos_provisorios.nombre} ${ot.datos_provisorios.apellido}` }}
  div(v-if="ot.datos_provisorios.tipo_cliente === 1").details
    label.input-label Email
    span.display-data
      | {{ot.datos_provisorios.email}}
  div(v-if="ot.datos_provisorios.tipo_cliente === 1").details
    label.input-label Contacto Telefono
    span.display-data {{ot.datos_provisorios.telefono}}
  div(v-if="ot.datos_provisorios.tipo_cliente !== 1").details
    label.input-label Sucursal
    span.display-data {{ot.datos_provisorios.nombre_sucursal || 'N/A'}}
  div(v-if="ot.datos_provisorios.tipo_cliente !== 1").details
    label.input-label Teléfono Sucursal
    span.display-data {{ot.datos_provisorios.telefono || 'N/A'}}
  div(v-if="ot.datos_provisorios.tipo_cliente !== 1").details
    label.input-label Correo Sucursal
    .d-flex.flex-column
      span {{ot.datos_provisorios.email || 'N/A'}}
  div.details
    label.input-label Región
    span.display-data {{ot.datos_provisorios.region}}
  div.details
    label.input-label Comuna
    span.display-data {{ot.datos_provisorios.comuna}}
  div.details
    label.input-label Dirección
    span.display-data {{ot.datos_provisorios.direccion}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ClientData',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'printNoDisplay']),
  },
  created() {
    if (!this.ot.cliente.persona && !this.ot.cliente.empresa) {
      this.$router.push(`/editar-orden/${this.ot.ot}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}
.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
}
.input-label {
  color: #74788d;
  font-weight: bold;
  font-size: 1em;
}
.display-data {
  font-size: 1em;
}
.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}
p {
  font-size: 15px;
}
.title2 {
  color: #253590;
  font-weight: bold;
  font-style: 15px;
}
</style>
