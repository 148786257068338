<template lang="pug">
Fragment
  .span-full
    div.head
      .d-flex
        b-icon.no-print.mr-2(icon="laptop" variant="primary" font-scale="1.5")
        h4.title.mb-0 Datos Equipo
      .no-print(v-if="!printNoDisplay" :style="printNoDisplay ? '': 'display:none;'")
        router-link(:to="`/editar-orden/${ot.ot}`")
          b-icon(icon="pencil")
    hr
  div.details
    label.sub-title N° de Serie
    .d-flex.justify-content-start
      span.display-data.mr-2 {{ot.equipo.serial_number}}
      CircularIcon(
        :number="ot.segundos_ingresos ? ot.segundos_ingresos.length : 0"
        :classCustom="'second-admission'"
        v-b-popover.hover.html="getData(ot.segundos_ingresos)"
        title="Otros ingresos"
      )
  div.details
      label.input-label Marca
      span.display-data {{ot.equipo.modelo_detail.marca.marca_name ? ot.equipo.modelo_detail.marca.marca_name : '---' }}
  div.details
      label.input-label Tipo de equipo
      span.display-data {{ot.equipo.modelo_detail.dispositive_type ? ot.equipo.modelo_detail.dispositive_type : '---'}}
  div.details
      label.input-label Modelo
      span.display-data {{ot.equipo.modelo_detail.model_name ? ot.equipo.modelo_detail.model_name : '---' }}
  div.details
      label.input-label Part Number
      span.display-data {{ot.equipo.modelo_detail.part_number ? ot.equipo.modelo_detail.part_number : '---' }}
  div.details
      label.input-label Incluye Cargador
      span.display-data {{ot.equipo.power_source? 'Si' : 'No'}}
  div.details
      label.input-label Disco duro
      span.display-data {{ot.equipo.size_hard_drive_detail ? ot.equipo.size_hard_drive_detail : '---'}}
  div.details(v-if="ot.tipo_servicio.id===1||ot.tipo_servicio.id===2||ot.tipo_servicio.id===3")
      label.input-label Fecha de Compra
      span.display-data {{ot.fecha_de_compra | beautyDate }}
  div.details(v-if="ot.tipo_servicio.id===1||ot.tipo_servicio.id===2||ot.tipo_servicio.id===3")
      label.input-label Sucursal
      span.display-data {{ ot.sucursal ? ot.sucursal.nombre : ot.tienda_empresa ? ot.tienda_empresa.razon_social : '---'}}
  div.details(v-if="ot.tipo_servicio.id===4")
      label.input-label Número de Presupuesto
      span.display-data {{ot.numero_cotizacion ? ot.numero_cotizacion : '---'}}
  div.details
      label.input-label RAM
      span.display-data {{ot.equipo.total_ram_memorie_detail ? ot.equipo.total_ram_memorie_detail : '---'}}
  div.details(v-if="ot.cliente.tipo_cliente==='Empresa'")
      label.input-label Codigo retail
      span.display-data {{ot.codigo_retail ? ot.codigo_retail : '---'}}
  div.details
      label.input-label Password
      span.display-data {{ot.equipo.password ? ot.equipo.password : '---'}}
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import CircularIcon from '@/components/UI/CircularIcon.vue'

export default {
  name: 'EquipmentData',
  components: {
    CircularIcon,
  },
  data() {
    return {
      editing: false,
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'printNoDisplay']),
  },
  methods: {
    getData(ordenes) {
      if (!ordenes) return []
      const urls = []
      // eslint-disable-next-line
      ordenes.map((o) => {
        urls.push(`<a href="/orden/${o.ot}" target="_blank"> ${o.ot} </a>`)
      })
      return urls.join(', ')
    },
  },
  filters: {
    beautyDate(date) {
      //eslint-disable-line
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}
.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
}
.input-label {
  color: #74788d;
  font-weight: bold;
  font-size: 1em;
  white-space: nowrap;
}
.display-data {
  color: #495057;
  font-size: 1em;
}
.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}
p {
  font-size: 15px;
}
.second-admission {
  background-color: #f1b44c;
  color: #495057;
  position: relative;
  bottom: 4px;
}
</style>
