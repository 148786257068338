<template lang="pug">
div.d-block(v-if="ot")
  p.font-select.text-center
  b-form.short-line-height
    b-row
      b-col
        b-form-group(label= "Nombre:" label-for="input-name")
          | {{ ot.cliente && ot.cliente.persona && ot.cliente.tipo_cliente === 'Persona' ? ot.cliente.persona.nombre : '--' }}
        b-form-group(label= "Rut:" label-for="input-rut")
          | {{ ot.cliente && ot.cliente.persona && ot.cliente.tipo_cliente === 'Persona' ? ot.cliente.persona.rut : '--' }}
        b-form-group(label= "Número de teléfono:" label-for="input-phone")
          | {{ ot.cliente && ot.cliente.persona && ot.cliente.tipo_cliente === 'Persona' ? ot.cliente.persona.telefono : '--'}}
        b-form-group(label= "Correos:" label-for="input-email")
          | {{ ot.cliente && ot.cliente.persona && ot.cliente.tipo_cliente === 'Persona' ? ot.cliente.persona.email : '--' }}
        b-form-group(label= "Ciudad:" label-for="input-city")
          | {{ ot.cliente && ot.cliente.persona && ot.cliente.tipo_cliente === 'Persona' ? ot.cliente.persona.comuna : '--' }}
      b-col
        b-form-group(label= "Modelo:" label-for="input-brand") {{ ot.equipo.modelo_detail.model_name }}
        b-form-group(label= "Numero de serie:" label-for="input-serialNumber") {{ot.equipo.serial_number }}
      b-col
        b-form-group(label= "Número de presupuesto:" label-for="input-brand") {{ quotation.numero_cotizacion ? quotation.numero_cotizacion : '-' }}
        b-form-group(
          label= "Lista de repuestos:"
          label-for="input-rma"
          )
          div(v-for="parte in quotation.partes_solicitadas") - {{ parte.part_number }}
        b-form-group(
          label= "Comentario rechazo:"
          label-for="input-brand"
          v-if="quotation.comentario_rechazo && quotation.comentario_rechazo.length > 0") {{ quotation.comentario_rechazo }}
        b-form-group(
          label= "Estado pago:"
          label-for="input-brand"
          v-if="quotation.estado_pago") {{ quotation.estado_pago }}
        b-form-group(
          label= "Archivo:"
          label-for="input-brand")
          a(v-if="quotation.pdf" :href="quotation.pdf" target="_blank") Descargar
          p(v-else) -
</template>
<script>

export default {
  props: ['ot', 'quotation'],
}
</script>
