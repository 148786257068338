<template lang="pug">
.circular(:class="classCustom" :style="getDisplay")
  span.span {{number}}
</template>

<script>
export default {
  props: ['number', 'classCustom'],
  computed: {
    // eslint-disable-next-line
    getDisplay() {
      if (this.number === 0) {
        return 'display: none;'
      }
    },
  },
}
</script>

<style lang="scss" soped>
.circular {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border-radius: 50%;
  cursor: default;

  &:hover {
    filter: brightness(1.1);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.4);
  }
}
.span {
  padding-bottom: 1px;
}
</style>
