<template lang="pug">
  .select-technician
    div
      //-BOTON INGRESAR A BODEGA
      //- Hace ingreso a bodega de una OT ingresada por un administrativo
      button.change-button(
        :disabled="disabled"
        size="sm"
        title="Ingresar a bodega"
        @click="crearMovimientoBodega(1, 1, 0, ot.recepcionista ? ot.recepcionista.id : null, user.id, 'Bodega')")
        | Ingresar a bodega
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'ActionPrimerIngresoABodega',
  props: ['ot', 'disabled'],

  data() {
    return {
      user: null,
      loading: false,
    }
  },
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
  },
  methods: {
    ...mapActions('Warehouse', ['saveMovement']),
    ...mapActions('OTStore', ['editOT', 'getOT']),
    async crearMovimientoBodega(
      t_movimiento,
      t_entrada,
      t_salida,
      entregado,
      recibido,
      ubicacion_ot
    ) {
      this.loading = true
      const data = {
        ot: this.ot.ot,
        tipo_movimiento: t_movimiento,
        tipo_entrada: t_entrada,
        tipo_salida: t_salida,
        entregado_por: entregado,
        recibido_por: recibido,
      }
      const resp = await this.saveMovement(data)
      if (resp.status === 200) {
        await this.editOT({ ot: this.ot.ot, ubicacion: ubicacion_ot })
        this.$bvToast.toast(
          `OT ingresada a las ${moment(resp.data.fecha_hora).format(
            'DD/MM/YYYY HH:mm'
          )}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
        await this.getOT(this.ot.ot)
      } else {
        this.$bvToast.toast(
          `Error al crear el movimiento de bodega: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
  },
}
</script>
