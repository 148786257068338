var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"sill-card"},[_c('div',{staticClass:"flex-row justify-content-between mb-3"},[_c('h5',{staticClass:"table-header"},[_vm._v("Detalle de Registro")]),_c('b-button',{staticClass:"d-flex align-items-center py-2 px-4",attrs:{"variant":"button"},on:{"click":_vm.exportToExcel}},[_c('b-icon',{attrs:{"icon":"file-earmark-arrow-down-fill","font-scale":"0.9"}}),_c('span',{staticClass:"ml-3"},[_vm._v("Exportar")])],1)],1),_c('b-table',{attrs:{"items":_vm.results,"fields":_vm.fields,"head-row-variant":"light-primary","tbody-class":"tabla-tbody","thead-class":"text-black tabla-thead","tbody-tr-class":"bg-white","responsive":"","show-empty":"","no-local-sorting":"","empty-text":"No hay Ordenes de Trabajo en este estado","busy":_vm.loading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('col'),_c('col'),_c('col'),_c('col'),(!_vm.hideOTColumn)?_c('col',{staticStyle:{"width":"1%","min-width":"160px"}}):_vm._e()]}},{key:"cell(created_at)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(value)))])]}},{key:"cell(usuario)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.first_name)+" "+_vm._s(value.last_name))])]}},{key:"cell(tiempo_desde_ultima)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('b-icon',{attrs:{"icon":_vm.timeIcons[item.color].icon,"variant":_vm.timeIcons[item.color].variant,"font-scale":"1.2"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("formatElapsedTimeInHours")(value)))])]}},{key:"cell(orden_trabajo)",fn:function(ref){
var value = ref.value;
return [_c('b-link',{staticClass:"order-link",attrs:{"to":("/orden/" + value)}},[_vm._v(_vm._s(value))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }