import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const OrdenDeTrabajos = {
  namespaced: true,
  state: {
    OTS: [],
    OT: {},
    OTFORM: {},
    // enteredOTs: [],
    // assignedOTs: [],
    // inRevisionOTs: [],
    // diagnosticatedOTs: [],
    // waitingPartsOTs: [],
    // inRepairingOTs: [],
    // waitingBudgetApprovedOTs: [],
    isSelected: false,
    currentOts: {},
    currentStates: [],
    lastQuery: '',
    isSearched: false,
    userStates: [],
    notificationsOTS: [],
    isSearching: false,
    defaultStates: false,
  },
  getters,
  mutations,
  actions,
}

export default OrdenDeTrabajos
