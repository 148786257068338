export default (date) => {
  // Cambiar fecha de YY/MM/DD a DD/MM/YY
  const fecha = new Date()
  const dateNew = date.split('-')
  const minutes = fecha.getMinutes()
  const hours = fecha.getHours()
  const day = dateNew[2]
  const month = dateNew[1]
  const year = dateNew[0]

  return `${year}-${month}-${day}T${hours}:${minutes}-03:00`
}
