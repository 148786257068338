import firebase from 'firebase/app'
import 'firebase/messaging'
import settings from '../../settings'

const onNotificationClick = async (event) => {
  console.log('[firebase] notificationclick', event.notification)

  const { action } = event.notification.actions[0]
  const url = event.notification?.click_action
  if (!url) {
    window.parent.focus()
    if (action === 'open_exceptions')
      window.location.href =`${settings.frontUrl}/excepciones`
    return
  }
  window.parent.open(url, '_blank')
}

export default async function initializeFirebase() {
  try {
    firebase.initializeApp(settings.firebaseConfig)
    const messaging = firebase.messaging()

    const vapidKey = settings.firebasePublicVapidKey
    const token = await messaging.getToken({ vapidKey })

    messaging.onMessage((payload) => {
      console.log('[firebase] Received message', payload)
      const { title, body, click_action: clickUrl } = payload.notification

      const options = {
        body,
        clickUrl,
        icon: '/firebase-logo.png',
      }

      const notification = new Notification(title, options)
      notification.onclick = () => onNotificationClick(payload)
    })

    return token
  } catch (err) {
    console.log('error initializing firebase', err)
  }
}
