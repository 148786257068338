<template lang="pug">
Fragment
  .span-full
    div.head.no-print
      .d-flex
        b-icon.no-print.mr-2(icon="clipboard" variant="primary" font-scale="1.5")
        h4.mb-0.title.no-print Ingreso del Equipo
      .no-print(v-if="!printNoDisplay" :style="printNoDisplay ? '' : 'display:none;'")
        router-link(:to="`/editar-orden/${ot.ot}`")
          b-icon(icon="pencil")
    hr.no-print
  div.details
      label.sub-title R.M.A
      VueBarcode(v-bind:value="ot.rma" width=1 height=30 textAlign="center" fontSize="15")
  div.details(v-if="ot.cliente.tipo_cliente==='Empresa'")
      label.input-label Guia de despacho de ingreso
      span.display-data {{ot.guia_despacho_ingreso}}
  div.details
      label.input-label Tipo de despacho
      span.display-data {{ tipoDespacho }}
  div.details
      label.input-label Fecha de ingreso
      span.display-data {{fechaIngreso | beautyDate}}
  div.details
      label.input-label Servicio Requerido
      span.display-data {{ot.tipo_servicio.nombre}}
  div.details
      label.input-label Otros accesorios
      span.display-data {{ot.otros_accesorios}}
  div.details
      label.input-label ¿Cliente exige cambio o 3x3?
      span.display-data {{ot.exige_cambio_3x3 ? 'Sí' : 'No'}}
  div.details.span-full-screen
      label.input-label Reporte de la falla
      span.display-data {{ot.reporte_falla_cliente}}
  div.details.span-full-screen
      label.input-label Observaciones
      span.display-data {{ot.observaciones}}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import VueBarcode from 'vue-barcode'
import { LABEL_TIPO_DESPACHO } from '@/utils/labels'

export default {
  name: 'EnteredEquipment',
  components: { VueBarcode },
  data() {
    return {
      editing: false,
      estados: [],
    }
  },
  created() {
    this.getOtHistory(this.ot.ot).then((res) => {
      this.estados = res.data
    })
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'printNoDisplay']),
    fechaIngreso() {
      const fecha = this.estados
        .filter((s) => s.estado.nombre === 'Ingresado')
        .map((f) => f.fecha_hora)
      return fecha.toLocaleString()
    },

    tipoDespacho() {
      return LABEL_TIPO_DESPACHO[this.ot?.despacho || this.ot?.sucursal?.despacho]
      // eslint-disable-next-line
      // if (this.ot.despacho || this.ot.sucursal?.despacho) {
      //   if (
      //     this.ot?.sucursal?.despacho === 'ENVIO' ||
      //     this.ot.despacho === 'ENVIO'
      //   ) {
      //     return 'Envio'
      //   }
      //   if (
      //     this.ot?.sucursal?.despacho === 'RETIRO' ||
      //     this.ot.despacho === 'RETIRO'
      //   ) {
      //     return 'Retiro por cliente'
      //   }
      //   if (
      //     this.ot?.sucursal?.despacho === 'COORD_CLIENTE' ||
      //     this.ot.despacho === 'COORD_CLIENTE'
      //   ) {
      //     return 'Coordinado por cliente'
      //   }
      // }
    },
  },
  methods: {
    ...mapActions('OT', ['getOtHistory']),
  },
  filters: {
    beautyDate(date) {
      //eslint-disable-line
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}
.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
}
.input-label {
  color: #74788d;
  font-weight: bold;
  font-size: 1em;
}
.display-data {
  color: #495057;
  font-size: 1em;
}
.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}
p {
  font-size: 15px;
}
.title2 {
  color: #253590;
  font-weight: bold;
  font-style: 15px;
}
</style>
