<template lang="pug">
div
  .d-flex
    b-icon.mr-2( icon="tools" variant="primary" font-scale="1.5" )
    span.title Historial Acciones
  hr
  ActionLogList(hideOTColumn).mb-3
  ActionLogPagination
</template>

<script>
import { mapActions, mapWritableState } from 'pinia'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'
import ActionLogList from '@/components/Reports/UserReport/ActionLogList.vue'
import ActionLogPagination from '@/components/Reports/UserReport/ActionLogPagination.vue'

export default {
  components: {
    ActionLogList,
    ActionLogPagination,
  },

  props: {
    otId: {
      type: String,
      default: null,
    },
    dateStart: {
      type: String,
      default: null,
    },
    dateEnd: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapWritableState(useUserReportStore, [
      'results',
      'currentTab',
      'filters',
    ]),
  },

  methods: {
    ...mapActions(useUserReportStore, ['getActionLogs']),
  },

  watch: {
    otId: {
      immediate: true,
      handler(value) {
        if (this.otId == null) return
        const dateStart = this.dateStart?.split('T')[0]
        const dateEnd = this.dateEnd?.split('T')[0]
        this.filters = {
          ...this.filters,
          ot: value,
          dateStart,
          dateEnd,
        }
        this.currentTab = 'Todos'
        this.getActionLogs()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
