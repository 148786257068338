<template lang="pug">
#ActionRevisar
  //- ACCION REVISAR
  div
    //- Botón acción
    button.change-button(
      @hide="hideModal()"
      @click="modal = true"
      ) Revisar
    //-Modal de revisión
    b-modal(v-model="modal" size="lg")
      template(#modal-title v-if="ot")
        span.header-modal.text-center Revisión Presupuesto {{ ot.ot }}
      slot
      template(#modal-footer='{ ok, cancel }')
        b-button(size='sm' variant='danger' @click='modal = false;') Cerrar
</template>
<script>
export default {
  props: ['ot', 'quotation'],
  data() {
    return {
      modal: false,
    }
  },
}
</script>
