<template lang="pug">
.d-flex.align-items-center.pl-4
  span.mr-5 {{ rut }}
  b-button(
    size="sm"
    :disabled="disabled"
    @click="$emit('clear-customer')"
  ) Cambiar Cliente
//- .show-rut
//-   .rut
//-     .text {{rut}}
//-   b-button.edit-button(
//-     size="sm"
//-     :disabled="disabled"
//-     @click="$emit('clear-customer')"
//-   ) Cambiar Cliente
</template>

<script>
export default {
  props: {
    rut: String,
    disabled: Boolean,
  },
}
</script>

<!-- TODO: simplify CSS -->
<style lang="scss" scoped>
.rut {
  width: 45%;
  margin-left: 30px;
}
</style>
