<template lang="pug">
  .select-technician
    div
      button.change-button(
        :disabled="disabled || loading"
        @click="devolverABodega()")
        | Devolver a bodega
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ActionDevolverBodega',
  props: ['ot', 'disabled'],

  data() {
    return {
      state: false,
      usuarios: [],
      loading: false,
    }
  },
  methods: {
    ...mapActions('OTStore', ['editOT', 'devolverBodega']),
    async devolverABodega() {
      this.loading = true
      const payload = {
        ot: this.ot.ot,
        user: this.user.user.id,
      }
      const resp = await this.devolverBodega(payload)
      if (resp.status === 200) {
        await this.editOT({
          ot: this.ot.ot,
          tecnico_asignado: this.ot.tecnico_asignado.id,
          ubicacion: 'Bodega',
        })
        this.$bvToast.toast(
          `OT entregada a bodega
        a las ${moment(resp.data.fecha_hora).format('DD/MM/YYYY HH:mm')}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
      } else {
        this.$bvToast.toast(
          `Error al crear el movimiento de recepción: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
}
</script>
