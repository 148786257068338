<template lang="pug">
.filters
    b-form-select.margin(
      v-if="userRole"
      v-model="filters.tecnico"
      :options="technicianOptions"
      :disabled="loading"
    )

    b-icon.margin(
      icon="x-circle"
      font-scale="1.5"
      variant="primary"
      style="cursor: pointer;"
      title="Eliminar filtros"
      v-if="filters.tecnico"
      @click="removeFilters"
    )

    DownloadOTS.mb-3.mb-lg-0(:otsFiltered="otsFiltered")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DownloadOTS from '@/components/UI/DownloadOTs.vue'
import { mapState, mapWritableState } from 'pinia'
import { useDashboardStore } from '../../pinia/views/useDashboardStore'

export default {
  name: 'Filters',
  props: ['otsFiltered'],
  components: { DownloadOTS },
  data() {
    return {
      userRole: null,
      serviceTypeOptions: [
        { value: null, text: 'Filtros por garantía' },
        { value: 1, text: 'Garantía' },
        // { value: 2, text: 'Garantía on Site 5D' },
        // { value: 3, text: 'Garantía on Site 7x24' },
        { value: 4, text: 'Presupuesto' },
      ],
    }
  },

  async created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.userRole = user.groups.find(
      (r) =>
        r.name === 'Gerencia' ||
        r.name === 'Administrativo' ||
        r.name === 'Supervisor'
    )
    await this.listTechnicians()
  },

  methods: {
    ...mapActions('TechniciansStore', ['listTechnicians']),
    ...mapActions('OT', ['getDashboard']),

    async removeFilters() {
      this.filters.tecnico = null
    },
  },

  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    ...mapWritableState(useDashboardStore, ['filters', 'loading']),
    ...mapState(useDashboardStore, ['queryParams']),

    technicianOptions() {
      const mappedTechnicians = this.technicians.filter((t => t.cantidad_ot > 0)).map((t) => {//eslint-disable-line
        return {
          value: t.tecnico.id,
          text: `${t.tecnico.first_name} ${t.tecnico.last_name} (${t.cantidad_ot}/${t.cantidad_maxima})`, //eslint-disable-line
        }
      }).sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase()) ? -1 : (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : 0);
      mappedTechnicians.unshift({
        value: null,
        text: 'Todos',
      })
      mappedTechnicians.unshift({
        value: null,
        text: 'Filtros por técnico',
        disabled: true,
      })
      return mappedTechnicians;
    },

    watchedFilters() {
      const f = this.filters
      return [f.tipoServicio, f.tipoCliente, f.tecnico]
    },
  },

  watch: {
    watchedFilters: {
      async handler() {
        this.loading = true
        await this.getDashboard(this.queryParams).then((r) => {
          this.$emit('filterData', r.data)
        })
        this.loading = false
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.margin {
  &:not(:last-child) {
    margin-right: 15px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}
.filters {
  display: flex;
  @include media-breakpoint-down(md) {
    display: block;
  }
}
</style>
