<template lang="pug">
ListOT
</template>

<script>
import ListOT from '../../components/OT/ListOT/ListOTIndex.vue'

export default {
  name: 'ListOTView',
  components: {
    ListOT,
  },
}
</script>
