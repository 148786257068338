export const OTS = (state) => state.OTS
export const enteredOTs = (state) => state.enteredOTs
export const assignedOTs = (state) => state.assignedOTs
export const inRevisionOTs = (state) => state.inRevisionOTs
export const diagnosticatedOTs = (state) => state.diagnosticatedOTs
export const waitingPartsOTs = (state) => state.waitingPartsOTs
export const inRepairingOTs = (state) => state.inRepairingOTs
export const waitingBudgetApprovedOTs = (state) =>
  state.waitingBudgetApprovedOTs
export const OT = (state) => state.OT
export const isSelected = (state) => state.isSelected
export const OTFORM = (state) => state.OTFORM
export const currentOts = (state) => state.currentOts
export const currentStates = (state) => state.currentStates
export const isSearched = (state) => state.isSearched
export const userStates = (state) => state.userStates
export const notificationsOTS = (state) => state.notificationsOTS
export const isSearching = (state) => state.isSearching
export const defaultStates = (state) => state.defaultStates
