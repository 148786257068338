<template lang="pug">
.select-technician
    div
      //- BOTON INFORME REVISADO (DAR SALIDA)
      //-Da salida a un equipo listo para entregar
      button.change-button(
        :disabled="disabled"
        title="Dar salida"
        @click="showModal(), getCourier(), getDistribuidores()")
        | {{ buttonLabel }}
      b-modal.hide-foooter(v-model="showModalDespacho" :title="`Ingresar datos de despacho`")
        b-form-group.input-label(label="Tipo de despacho" label-for="input-tipo-despacho", v-if="!requiereDistribuidor")
          b-form-select(
            id="input-tipo-despacho"
            v-model="tipo_despacho"
            :disabled="blockedDespacho"
            :options="tipoDespachoOptions"
            :state="$v.$dirty ? !$v.tipo_despacho.$invalid : null")
          .errors(v-if="$v.$dirty")
            b-form-invalid-feedback(:state="$v.tipo_despacho.required") Campo requerido
        b-form-group(label="N° de guía de despacho o N° de factura" label-for="input-guia-despacho" v-if="tipo_despacho")
          b-input(
            id="input-guia-despacho"
            v-model="numero_guia_despacho"
            :state="$v.$dirty ? !$v.numero_guia_despacho.$invalid : null")
          .errors(v-if="$v.$dirty")
            b-form-invalid-feedback(:state="$v.numero_guia_despacho.required") Campo requerido
        div(v-if="isTipoDespachoEnvio && !requiereDistribuidor")
          b-form-group(label="N° de courier" label-for="input-courier")
            b-input(
              v-model="numero_courrier"
              id="input-courier"
              :state="$v.$dirty ? !$v.numero_courrier.$invalid : null")
            .errors(v-if="$v.numero_courrier.$dirty")
              b-form-invalid-feedback(:state="$v.numero_courrier.required") Campo requerido
          b-form-group(label="Empresa de Courier" label-for="input-courier")
            b-form-select(
              id="input-courier"
              :options="optionsCourier"
              v-model="courier"
              :state="$v.$dirty ? !$v.numero_courrier.$invalid : null")
            .errors(v-if="$v.courier.$dirty")
              b-form-invalid-feedback(:state="$v.courier.required") Campo requerido
        div(v-if="requiereDistribuidor")
          b-form-group(label="Distribuidor" label-for="input-courier")
              b-form-select(
                id="input-courier"
                :options="optionsDistribuidores"
                v-model="distribuidor")
        template(#modal-footer='{ ok, cancel, hide }')
          b-button(size='sm' variant='success' @click='darSalida()' :disabled="disabledSave") Guardar
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ActionDarSalida',
  props: ['ot', 'disabled', 'toast'],

  validations: {
    tipo_despacho: {
      required: requiredIf(function fn() {
        return !this.requiereDistribuidor
      }),
    },
    numero_guia_despacho: { 
      required: requiredIf(function fn() {
        return this.tipo_despacho
      }),
     },
    numero_courrier: {
      required: requiredIf(function fn() {
        return this.isTipoDespachoEnvio && !this.requiereDistribuidor
      }),
    },
    courier: {
      required: requiredIf(function fn() {
        return this.isTipoDespachoEnvio && !this.requiereDistribuidor
      }),
    },
  },
  data() {
    return {
      courier: null,
      distribuidor: null,
      show: false,
      user: null,
      showModalDespacho: false,
      tipo_despacho: null,
      blockedDespacho: false,
      numero_guia_despacho: null,
      numero_courrier: null,
      searching: false,
      form: {
        rma: null,
        encargado: null,
      },
      usuarios: [],
      disabledSave: false,
    }
  },
  methods: {
    ...mapActions('Warehouse', ['changeWarehouseState']),
    ...mapActions('OTStore', [
      'entregaFinal',
      'getOT',
      'getCourier',
      'getDistribuidores',
    ]),
    ...mapActions('AuthenticationStore', ['getAllUsers']),

    hideModal() {
      this.state = null
      this.showModalDespacho = false
    },
    showModal() {
      if (!this.ot) return false
      const despachos = {
        RETIRO: 3,
        ENVIO: 4,
        COORD_CLIENTE: 7,
        null: null,
      }
      this.tipo_despacho =
        despachos[this.ot?.despacho || this.ot?.sucursal?.despacho]
      if (this.tipo_despacho === 7) this.blockedDespacho = true
      this.showModalDespacho = true
    },
    async darSalida() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.disabledSave = true
        const data = {
          ot: this.ot.ot,
          tipo_salida: this.tipo_despacho,
          entregado_por: this.user.id,
          numero_guia_despacho: this.numero_guia_despacho,
          numero_courrier: this.numero_courrier,
          courier: this.courier,
          distribuidor: this.distribuidor,
        }
        const resp = await this.entregaFinal(data)
        if (resp.status === 200) {
          this.toast(`OT cerrada (${resp.data})`, {
            variant: 'success',
          })
          this.hideModal()
          this.$emit('changed')
          this.$router.push(`/orden/${this.ot.ot}`);
        } else {
          this.toast(`Error al dar salida (${resp.data})`, {
            variant: 'danger',
          })
        }
        this.show = false
        this.disabledSave = false
      }
    },
  },
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
  },
  computed: {
    ...mapGetters('OTStore', ['couriers', 'distribuidores']),
    buttonLabel() {
      if (this.ot?.sucursal?.despacho === 'ENVIO' || this.ot.despacho === 'ENVIO') {
        return 'Enviar a cliente'
      }
      return `Dar salida  ${this.salidaAdministrativo ? ' Administrativo' : ''}`
    },
    salidaAdministrativo() {
      return (
        this.user.groups.map((g) => g.name).includes('Administrativo') &&
        this.ot.ubicacion === 'Recepción' &&
        this.ot.cliente?.tipo_cliente === 'Persona'
      )
    },
    tipoDespachoOptions() {
      return [
        { value: null, text: 'Seleccionar...' },
        { value: 3, text: 'Retiro por cliente' },
        { value: 4, text: 'Envío' },
        { value: 7, text: 'Coordinado por cliente' },
      ]
    },
    optionsCourier() {
      return this.couriers
        .map((c) => ({
          value: c.id,
          text: c.nombre,
        }))
        .concat([
          {
            value: null,
            text: 'Seleccione...',
          },
        ])
    },
    optionsDistribuidores() {
      return this.distribuidores
        .map((c) => ({
          value: c.id,
          text: c.nombre,
        }))
        .concat([
          {
            value: null,
            text: 'Seleccione...',
          },
        ])
    },
    requiereDistribuidor() {
      return this.ot.cambio_3x3
    },
    isTipoDespachoEnvio() {
      return this.tipo_despacho === 4 || this.tipo_despacho === 7
    },
  },
}
</script>

<style lang="scss" scoped>
// .change-button {
//   color: #fff;
//   background-color: #5B73E8;
//   border-color: #5B73E8;
//   width: 40px;
//   height: 40px;
//   border-radius: 5px;
//   font-weight: 600;
//   margin: 1px;
//   &:disabled {
//     background-color: #7d93ff;
//     border-color: #7d93ff;
//     cursor: not-allowed;
//   }
// }
// .button {
//   color: #fff;
//   background-color: #5B73E8;
//   border-color: #5B73E8;
//   width: auto;
//   height: 40px;
//   border-radius: 5px;
//   font-weight: 600;
//   font-size: 12px;
//   &:disabled {
//     background-color: #7d93ff;
//     border-color: #7d93ff;
//     cursor: not-allowed;
//   }
// }
</style>
