<template lang="pug">
Fragment
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Control de Inventario
  ListParts( @stop-loading="loading = false" )
</template>

<script>
import ListParts from '../../components/Inventory/List.vue'
import BackArrow from '../../components/UI/BackArrow.vue'

export default {
  name: 'Inventario',
  components: {
    ListParts,
    BackArrow,
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
