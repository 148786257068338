<template lang="pug">
b-modal(size="lg", v-model="show" :title="`Editar Excepcion para OT ${ot} - ${exception.tipo}`" hide-footer @hide="hideModal()")
    b-form.short-line-height(v-if="exception.orden")
      b-row
        b-col
            div
              b-row
                b-col
                  h6 Cliente
                  p {{ getCliente() }}
                b-col
                  h6 Fecha de compra
                  p {{ exception.orden.fecha_de_compra ? exception.orden.fecha_de_compra : '-' | moment('DD/MM/YYYY')}}
                b-col
                  h6 R.M.A
                  p {{ exception.orden.rma }}
              hr
              b-row
               b-col(v-if="exception.orden.diagnostico_detail && exception.orden.diagnostico_detail.informe && exception.orden.diagnostico_detail.informe.trim() !== ''")
                h6 Diagnóstico
                p {{  exception.orden.diagnostico_detail.informe }}
              b-row
               b-col(v-if="exception.orden.diagnostico_detail && exception.orden.diagnostico_detail.solucion && exception.orden.diagnostico_detail.solucion.trim() !== ''")
                h6 Solución
                p {{ exception.orden.diagnostico_detail.solucion }}
            div(v-if="exception")
              b-row
                b-col(v-if="form.tipo ==='Ampliación de garantía'")
                  div(v-if="exception.orden.cliente")
                    h6 Número de ingresos
                    p {{ exception.orden.segundos_ingresos ? exception.orden.segundos_ingresos.length : 0}}
                b-col(v-if="form.tipo === 'Aprobación de 3x3'")
                  h6 Creación OT
                  p {{ exception.orden.fecha_ingreso | formatedDate }}
                  h6 Sucursal
                  p {{ exception.orden.sucursal ? exception.orden.sucursal.nombre : exception.orden.tienda_empresa ? exception.orden.tienda_empresa.razon_social : '-' }}
                b-col(v-if="form.tipo === 'Aprobación de 3x3' || form.tipo ==='Ampliación de garantía' ")
                  h6 N° de serie
                  p {{ exception.orden.equipo ? exception.orden.equipo.serial_number : '-' }}
            div(v-if="exception && form.tipo ==='Cambio en espera de parte'")
              b-row
                //- b-col
                  //- b-button(variant="link" style="margin-left: -12px;" @click="download()") Descargar boleta
                b-col
                  h6 Fecha de llegada repuesto
                  p {{ exception.fecha_llegada_parte ? exception.fecha_llegada_parte : '-' | moment('DD/MM/YYYY') }}
                b-col
                  h6 Días
                  p {{ diffDays }}
              b-row
                b-col
                  h6 N° de serie
                  p {{ exception.orden.equipo ? exception.orden.equipo.serial_number : '-' }}
                b-col
                  h6 Repuesto solicitado
                  p {{ exception.repuesto_solicitado ? exception.repuesto_solicitado.part_number : '-' }}´
            div(v-if="exception && form.tipo ==='Cambio en espera de retiro'")
              b-row
                b-col
                  h6 Comentario cambio
                  p {{ exception.comentario_cambio ? exception.comentario_cambio : '-' }}
                b-col
                  h6 N° de serie
                  p {{ exception.orden.equipo ? exception.orden.equipo.serial_number : '-' }}
                b-col
                  h6 Nuevo RMA de cambio
                  p {{ exception.rma_cambio ? exception.rma_cambio : '-' }}
                  //- b-button(variant="link" style="margin-left: -12px;" @click="download()") Descargar boleta

      template(v-if="loadedOT")
        b-row
          b-col
            h6 Cliente solicita 3x3
            p {{exception.orden.exige_cambio_3x3 ? 'Sí' : 'No' }}
          b-col(cols='12' md='4' v-if="boletaUrl")
            h6.input-label Boleta:
            .flex-row.align-items-center.gap-1(v-if="boletaType === 'pdf'")
              b-icon.device-icon.h3(icon="file-pdf" variant="black")
              p {{ boletaName.split('/').pop() }}
            img.device-image(v-else :src="boletaUrl" alt="Imagen Boleta")
            b-button(variant="link" @click="download") Descargar boleta
        b-row
          b-col
            h6 Comentario Solicitud
            p {{form.comentario_solicitud}}
        b-row
          b-col(v-if="exception")
            b-form-group
              label.label-exception Comentario Respuesta
              b-form-textarea(
                v-model="form.comentario_respuesta"
              )
        b-row
          b-col.d-flex.flex-row.justify-content-end
            b-button.ml-2(size='sm' :disabled="loadingBtn"
              variant='success' @click='saveException()' v-if="!exception") Crear
            b-button.ml-2(size='sm' :disabled="loadingBtn"
              variant='success' @click='form.estado=1, saveException()'
              v-if="exception && (exception.estado === 'Aprobado' && usuarioGerencia && otNoCerrada)") Revertir 3x3
            b-button.ml-2(size='sm' :disabled="loadingBtn"
             variant='success' @click='form.estado=2, saveException()'
             v-if="exception && (exception.estado !== 'Aprobado' && usuarioGerencia && otNoCerrada)") Aprobar
            b-button.ml-2(size='sm' :disabled="loadingBtn"
             variant='danger' @click='form.estado=3, saveException()'
             v-if="exception && exception.estado !== 'Rechazado' && usuarioGerencia && otNoCerrada") Rechazar
            b-button.ml-2(size='sm' variant='muted' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'CreateEditExcepcion',
  props: ['orden_trabajo'],
  components: {
    VueTypeaheadBootstrap,
  },
  data() {
    return {
      orden: null,
      show: false,
      ot: this.orden_trabajo,
      user: null,
      loadingBtn: false,
      form: {
        estado: 1,
        orden_trabajo: null,
        comentario_solicitud: null,
        comentario_respuesta: null,
        tipo: null,
      },
      roles: null,
      loadedOT: false,
      estadoOptions: [
        { text: 'Solicitado', value: 1 },
        { text: 'Aprobado', value: 2 },
        { text: 'Rechazado', value: 3 },
      ],
      exceptionTypeOptions: [
        { text: 'Seleccione...', value: null },
        { text: 'Ampliación de garantía', value: 'Ampliación de garantía' },
        { text: 'Aprobación de 3x3', value: 'Aprobación de 3x3' },
        {
          text: 'Cambio en espera de parte',
          value: 'Cambio en espera de parte',
        },
        { text: 'Cambio en diagnóstico', value: 'Cambio en diagnóstico' },
        {
          text: 'Cambio en espera de retiro',
          value: 'Cambio en espera de retiro',
        },
      ],
      searching: false,
      query: '',
      OTs: [],
      boletaUrl: null,
      boletaType: null,
      boletaName: null,
    }
  },
  computed: {
    ...mapGetters('Exceptions', ['exception', 'showModal']),
    ...mapGetters('OT', ['OTS']),
    ...mapGetters('OTStore', ['files']),
    diffDays() {
      let dias = '-'
      if (this.exception.fecha_llegada_parte) {
        dias = this.$moment(this.exception.fecha_llegada_parte).diff(
          this.$moment(this.exception.orden.fecha_ingreso),
          'days'
        )
      }
      return dias
    },
    getBoleta() {
      let boleta = this.files.filter((b) => b.name.includes('boleta'))
      if (boleta.length > 0) {
        boleta = boleta[0] // eslint-disable-line
      } else {
        boleta = null
      }
      return boleta
    },
    usuarioGerencia() {
      return this.user.groups.filter((g) => g.name === 'Gerencia').length > 0
    },
    otNoCerrada() {
      return this.exception.orden.estado.estado.nombre !== 'Cerrado'
    }
  },
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
    this.roles = user.groups.map((t) => t.id)
    this.settingException()
  },
  methods: {
    ...mapActions('Exceptions', [
      'getExcepcion',
      'editExcepcion',
      'createExcepcion',
    ]),
    ...mapActions('OT', ['autocompleteOT']),
    ...mapMutations('OT', ['SET_OTS']),
    ...mapMutations('Exceptions', ['SET_SHOW_MODAL']),
    ...mapActions('OTStore', ['downloadFile', 'getFiles', 'getOT', 'getFileUrl']),
    onOtSelect(event) {
      this.form.orden_trabajo = event.OT
    },
    settingException() {
      if (this.exception) {
        const value = this.exception
        this.ot = value.orden_trabajo
        this.form.orden_trabajo = value.orden_trabajo
        this.form.estado = value.estados_detail
        this.form.comentario_solicitud = value.comentario_solicitud
        this.form.comentario_respuesta = value.comentario_respuesta
        this.form.tipo = value.tipo
      }
    },
    async saveException() {
      this.loadingBtn = true
      if (this.exception) {
        let canEdit = true
        if (this.form.estado === 1) {
          canEdit = false
          await this.$bvModal
            .msgBoxConfirm('¿Seguro que quieres revertir 3x3?', {
              title: 'Por favor Confirma',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'SÍ',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              this.$emit('save')
              canEdit = value
            })
            .catch(() => {})
        }
        if (canEdit) {
          this.form.respondido_por = this.user.id
          this.form.id = this.exception.id
          const response = await this.editExcepcion(this.form)
          if (response.status === 200) {
            if (
              this.form.estado === 3 &&
              this.form.tipo === 'Ampliación de garantía'
            ) {
              // Rechazado y ampliación de garantía
              this.$bvModal.msgBoxOk(
                'Se rechazó solicitud y OT se cambia a presupuesto',
                {
                  title: 'Solicitud de Excepción Rechazada',
                  size: 'md',
                  okVariant: 'success',
                  centered: true,
                  noCloseOnBackdrop: true,
                }
              )
            }
          }
          this.$emit('save')
          this.hideModal()
        }
      } else {
        this.form.solicitado_por = this.user.id
        await this.createExcepcion(this.form)
        this.$emit('save')
        this.hideModal()
      }
      this.loadingBtn = false
    },
    hideModal() {
      this.show = false
      this.SET_SHOW_MODAL(false)
      this.loadedOT = false
      this.SET_OTS({ OTS: null })
      this.form = {
        estado: 1,
        orden_trabajo: null,
        comentario_solicitud: null,
        comentario_respuesta: null,
      }
      this.ot = this.orden_trabajo
      this.$emit('changed')
    },
    selectingOT(selectOT) {
      this.form.orden_trabajo = selectOT.OT
    },
    getCliente() {
      let data = ''
      const { cliente } = this.exception.orden
      if (cliente.tipo_cliente === 'Empresa') {
        data = cliente.empresa.razon_social
      } else {
        data = `${cliente.persona.nombre ? cliente.persona.nombre : ''} ${
          cliente.persona.apellido ? cliente.persona.apellido : ''
        }`
      }
      return data
    },
    async setBoletaUrl() {
      await this.getFiles(this.exception.orden.ot)
      const item = this.files?.find((f) => f.type === 'BOLETA')
      if (!item) return null

      const payload = {
        fileName: item.name,
        fileType: item.file_type,
        fileId: item.file_id,
      }
      this.boletaUrl = await this.getFileUrl(payload)
      this.boletaType = item.file_type
      this.boletaName = item.name
    },
    async download() {
      await this.getFiles(this.exception.orden.ot)
      if (this.getBoleta) {
        const payload = {
          fileName: this.getBoleta.name,
          fileType: this.getBoleta.file_type,
          fileId: this.getBoleta.file_id,
        }
        await this.downloadFile(payload).catch((error) =>
          this.$bvToast.error(error)
        )
      } else {
        this.$bvToast.toast('La OT no tiene boleta almacenada', {
          variant: 'danger',
        })
      }
    },
  },
  watch: {
    orden_trabajo(value) {
      if (value) {
        this.form.orden_trabajo = value
        this.loadedOT = true
      }
    },
    exception(value) {
      if (value) {
        this.form.orden_trabajo = value.orden_trabajo
        this.form.estado = value.estados_detail
        this.form.comentario_solicitud = value.comentario_solicitud
        this.form.comentario_respuesta = value.comentario_respuesta
      }
    },
    showModal(value) {
      this.query = ''
      if (value) {
        this.show = true
        this.SET_SHOW_MODAL(false)
        if (this.orden_trabajo) {
          this.form.orden_trabajo = value
          this.loadedOT = true
        }
        this.settingException()
        if (!this.orden_trabajo) {
          this.SET_OTS({ OTS: null })
        }
      }
      this.setBoletaUrl()
    },
    'form.orden_trabajo': function fn(value) {
      if (value) {
        this.loadedOT = true
      }
    },
    async query(ot) {
      if (ot.length >= 2) {
        await this.autocompleteOT(ot)
        this.OTs = this.OTS.ordenes
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label-exception,
h6 {
  color: #74788d;
  font-weight: bold;
  font-size: 0.9rem;
}
::v-deep .form-group label {
  color: #74788d !important;
}
.row {
  margin-top: 10px;
}
.device-image {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
.device-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
</style>
