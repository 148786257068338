<template lang="pug">
  div
    .d-flex.justify-content-between
      .d-flex.justify-content-start
        b-icon.mr-2(icon="tools" variant="primary" font-scale="1.5")
        span.title Historial Excepciones
    hr
    b-table(:items="statesItems" :busy="loading" hover responsive)
      template(#table-busy)
            div(class="text-center text-danger my-2")
              b-spinner(class="align-middle")
    h4.text-center(v-if="statesItems.length === 0") No hay registros
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ExceptionsHistory',
  props: ['otId'],
  data() {
    return {
      exceptions: [],
      loading: false,
    }
  },
  async created() {
    this.loading = true
    await this.getOtExceptionsHistory(this.otId).then((res) => {
      this.exceptions = res.data
    })
    this.loading = false
  },
  methods: {
    ...mapActions('OT', ['getOtExceptionsHistory']),
  },
  computed: {
    statesItems() {
      return this.exceptions.map((e) => ({
        'Fecha de solicitud': new Date(e.fecha_hora_creacion).toLocaleString(),
        'Solicitado por':
          e.solicitado_por && e.solicitado_por.first_name
            ? `${e.solicitado_por.first_name} ${e.solicitado_por.last_name}`
            : '-',
        'Respondido por': `${
          e.respondido_por ? e.respondido_por.first_name : ''
        } ${e.respondido_por ? e.respondido_por.last_name : ''}`,
        estado: e.estado,
        tipo: e.tipo,
        'Comentario solicitud': e.comentario_solicitud
          ? e.comentario_solicitud
          : '',
        'Comentario respuesta': e.comentario_respuesta
          ? e.comentario_respuesta
          : '',
      }))
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
