<template lang="pug">
span#ChangeDelivery
  span.change-text(v-if="ot.despacho_solicitado && public") Cambio de Retiro Pendiente de Aprobación
  b-button(
    v-else-if="ot.cliente.tipo_cliente === 'Persona' && ot.cliente.persona || !public"
    @click="changeDeliveryModal()"
    variant="outline-button"
  ).font-weight-bold
    | Cambiar Tipo de Retiro
  b-modal.modal(v-model="deliveryModal" title="Cambiar Tipo de Retiro")
    .form-group
      label Tipo de Retiro
      b-form-select(
        v-model="form.typeDelivery"
        :options="deliveryOptions"
      )
    .form-group(v-if="form.typeDelivery == 'ENVIO'")
      label Dirección
      b-form-input(
        v-model="form.address"
        :state="$v.form.address.$dirty? !$v.form.address.$invalid : null"
        @input="$v.form.address.$touch()"
      )
        .errors(v-if="$v.form.address.$dirty")
          b-form-invalid-feedback(:state="$v.form.address.required")
              | Campo requerido
    .form-group(v-if="form.typeDelivery == 'ENVIO'")
      label Región
      b-form-select(
        v-model="form.region"
        :options="regionOptions"
        :state="$v.form.region.$dirty? !$v.form.region.$invalid : null"
        @input="$v.form.region.$touch()"
      )
        .errors(v-if="$v.form.region.$dirty")
          b-form-invalid-feedback(:state="$v.form.region.required")
              | Campo requerido
    .form-group(v-if="form.typeDelivery == 'ENVIO'")
      label Comuna
      b-form-select(
        v-model="form.comune"
        :options="comunaOptions"
        :state="$v.form.comune.$dirty? !$v.form.comune.$invalid : null"
        @input="$v.form.comune.$touch()"
      )
        .errors(v-if="$v.form.comune.$dirty")
          b-form-invalid-feedback(:state="$v.form.comune.required")
              | Campo requerido
    .form-group(v-if="public")
      label Correo electrónico
      b-form-input(
        v-model="form.email"
        :state="$v.form.email.$dirty? !$v.form.email.$invalid : null"
        @input="$v.form.email.$touch()"
      )
        .errors(v-if="$v.form.email.$dirty")
          b-form-invalid-feedback(:state="$v.form.email.required")
              | Campo requerido
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(@click="requestDelChange()" :disabled="loading") Aceptar
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['ot', 'public'],
  validations: {
    form: {
      address: { required: requiredIf(function () { return this.form.typeDelivery === 'ENVIO'; }) }, // eslint-disable-line
      region: { required: requiredIf(function () { return this.form.typeDelivery === 'ENVIO'; }) }, // eslint-disable-line
      comune: { required: requiredIf(function () { return this.form.typeDelivery === 'ENVIO'; }) }, // eslint-disable-line
      email: { required: requiredIf(function () {return this.public}) },
    },
  },
  data () {
    return {
      form: {
        typeDelivery: null,
        address: '',
        region: '',
        comune: '',
        email: '',
      },
      loading: false,
      deliveryModal: false,
      regionOptions: [],
      deliveryOptions: [
        { value: 'ENVIO', text: 'Envío' },
        { value: 'RETIRO', text: 'Retiro en Sucursal' },
      ],
    }
  },
  computed: {
    comunaOptions() {
      if (this.form.region) {
        const region = this.regiones.find((c) => c.region === this.form.region);
        if (region && region.comunas) {
          return region.comunas
            .map((c) => ({
              value: c,
              text: c,
            })).sort((a, b) => a.value.localeCompare(b.value));
        }
      }
      return [{ value: null, text: 'Selecciona una región' }];
    },
    ...mapGetters('ClientStore', ['regiones']),
  },
  methods: {
    ...mapActions('OTStore', ['editOT']),
    ...mapActions('ClientStore', ['changeOtDelivery', 'requestChangeDelivery']),
    changeDeliveryModal() {
      this.cleanModal();
      this.deliveryModal = true;
      this.form.typeDelivery = this.ot.despacho === 'ENVIO' ? 'RETIRO' : 'ENVIO';
      if (this.ot.cliente.persona && this.ot.cliente.tipo_cliente === 'Persona') {
        this.form.address = this.ot.cliente.persona.direccion
        this.form.region = this.ot.cliente.persona.region
        this.form.comune = this.ot.cliente.persona.comuna
      } else if (this.ot.cliente.empresa && this.ot.sucursal && this.ot.cliente.tipo_cliente === 'Empresa') {
        this.form.address = this.ot.sucursal.direccion
        this.form.region = this.ot.sucursal.region
        this.form.comune = this.ot.sucursal.comuna
      }
    },
    cleanModal() {
      this.form.typeDelivery = null;
      this.form.address = '';
      this.form.region = '';
      this.form.comune = '';
      this.form.email = '';
    },
    async requestDelChange() {
      this.loading = true;
      if (this.$v.$invalid) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        });
        this.$v.$touch();
      } else if (this.public) {
        const payload = {
          ot: this.ot.ot,
          tipo_retiro: this.form.typeDelivery,
          email: this.form.email,
          direccion_solicitada: this.form.address,
          comuna_solicitada: this.form.comune,
          region_solicitada: this.form.region,
        };
        const resp = await this.requestChangeDelivery(payload);
        if (resp && resp.status === 200) {
          this.$bvToast.toast(`${resp.data}`, {
            variant: 'success',
          });
          this.deliveryModal = false;
          this.ot.despacho_solicitado = this.ot.despacho === 'ENVIO' ? 'RETIRO' : 'ENVIO';
        } else {
          this.$bvToast.toast(`${resp.data}`, {
            variant: 'danger',
          });
        }
      } else {
        const payload = {
          ot: this.ot.ot,
          despacho: this.form.typeDelivery,
          direccion: this.form.address,
          comuna: this.form.comune,
          region: this.form.region,
        };
        const resp = await this.changeOtDelivery(payload);
        console.log(resp);
        if (resp && resp.status === 200) {
          this.$bvToast.toast(`${resp.data.message}`, {
            variant: 'success',
          });
          this.deliveryModal = false;
          this.ot.despacho_solicitado = this.ot.despacho === 'ENVIO' ? 'RETIRO' : 'ENVIO';
        } else {
          this.$bvToast.toast(`${resp.data}`, {
            variant: 'danger',
          });
        }
      }
      this.loading = false;
    },
  },
  created () {
    this.regionOptions = this.regiones
      .map((r) => ({
        value: r.region,
        text: r.region,
      }));
  }
}
</script>
