<template lang="pug">
#Dashboard.text-center
  .d-flex.justify-content-between.mb-2
    h4.title
      | Dashboard
      b-spinner.ml-3(v-if="loading" label="Cargando..." variant="primary")
    b-breadcrumb(:items="breadcrumbs")
  //- HeaderCards.mb-3
  MainTable
</template>

<script>
import firebase from "firebase/app";
import { mapState, mapWritableState } from 'pinia'
import { mapGetters, mapActions } from 'vuex'
import HeaderCards from '@/components/Dashboard/HeaderCards.vue'
import MainTable from '@/components/Dashboard/MainTable.vue'
import { useDashboardStore } from '../pinia/views/useDashboardStore'

export default {
  name: 'Dashboard',
  components: {
    HeaderCards,
    MainTable,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { path: '/' },
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    }
  },
  methods: {
    ...mapActions('Notifications', ['getNotificationsNew', 'setNewNotification']),
    async checkNotifications() {
      const bellicon = document.getElementsByClassName('bell-icon')

      const messaging = firebase.messaging()

      messaging.onMessage(async (payload) => {
        console.log('Message received. ', payload.notification)
        const notification = {
          title: payload.notification.title,
          body: payload.notification.body,
          action: payload.notification.actions[0].action
        }
        this.setNewNotification(notification)
        await this.getNotificationsNew(this.user.user.id)
        bellicon[0].classList.add('shake')
        setTimeout(() => {
          bellicon[0].classList.remove('shake')
        }, 1000)
      })
    },
  },
  async created() {
    this.filters.tecnico = null
    await this.checkNotifications()
  },
  computed: {
    ...mapState(useDashboardStore, ['loading']),
    ...mapWritableState(useDashboardStore, ['filters']),
    ...mapGetters('AuthenticationStore', ['user']),
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#Dashboard {
  @include media-breakpoint-up(lg) {
    max-width: 90%;
    margin: 0 auto;
  }
}
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
