<template lang="pug">
  div
    .d-flex.justify-content-between
      h4.title Diagnosticar ordenes de trabajo
      b-breadcrumb.breadcrumb(:items="breadcrumbs")
    AskPartsForm(:ot_id="this.$route.params.order_id" :data="this.$route.params.data")
</template>

<script>
import AskPartsForm from '@/components/AskParts/AskPartsForm.vue'

export default {
  name: 'AskParts',
  components: { AskPartsForm },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { path: '/' },
        },
        {
          text: 'Diagnosticar',
          to: { path: `/orden/${this.$route.params.order_id}` },
        },
        {
          text: `OT ${this.$route.params.order_id}`,
          active: true,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
  margin-bottom: 20px;
  font-weight: 600;
}
</style>
