<template lang="pug">
  NewOTIndex
</template>

<script>
import NewOTIndex from '../../components/OT/NewOT/NewOTIndex.vue'

export default {
  name: 'CreateOTView',
  components: { NewOTIndex },
}
</script>
