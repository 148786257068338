<template lang="pug">
#HeaderCards.header-cards
  b-card.summary-card.margin(@click="showDetails(totalOts, 'Todas')")
    .d-flex.justify-content-between
      .circle.total
        .content OT
      .data.d-flex.flex-column.justify-content-center
        span.quantity {{totalOts ? totalOts.length : 0}}
        span.label Total ordenes de trabajo
  b-card.summary-card.margin(@click="showDetails(nokbo, 'NOKBO')")
    .d-flex.justify-content-between
      .circle.nokbo
        .content NK
      .data.d-flex.flex-column.justify-content-center
        span.quantity {{nokbo ? nokbo.length : 0}}
        span.label OTs en NOKBO
  b-card.summary-card.margin(@click="showDetails(stop, 'STOP')")
    .d-flex.justify-content-between
      .circle.stop
        .content ST
      .data.d-flex.flex-column.justify-content-center
        span.quantity {{stop ? stop.length : 0}}
        span.label OTs en STOP
  b-card.summary-card.margin(@click="showDetails(allocated, 'ALLOCATED')")
    .d-flex.justify-content-between
      .circle.allocated
        .content AO
      .data.d-flex.flex-column.justify-content-center
        span.quantity {{allocated ? allocated.length : 0}}
        span.label OTs en ALLOCATED
</template>

<script>
export default {
  name: 'HeaderCards',
  data() {
    return {
      totalOts: null,
      nokbo: null,
      stop: null,
      allocated: null,
    }
  },
  mounted() {
    this.$root.$on('ots', (data) => {
      //eslint-disable-line
      this.totalOts = data
      this.nokbo = data ? data.filter((ot) => ot.estado.estado.fase === 2) : 0
      this.stop = data ? data.filter((ot) => ot.estado.estado.fase === 5) : 0
      this.allocated = data
        ? data.filter((ot) => ot.estado.estado.fase === 3)
        : 0
    })
  },
  methods: {
    showDetails(data, title) {
      if (data) {
        const estados = data.map((ot) => ot.estado.estado.nombre)
        const uniqueStates = [...new Set(estados)] // Elimina duplicados del array
        const params = { title, uniqueStates }
        this.$router.push({
          name: 'ResultadosFiltrosDashboard',
          query: params,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header-cards {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    .summary-card {
      width: calc(50% - 8px);
    }
  }
  @include media-breakpoint-down(sm) {
    align-items: center;
    flex-direction: column;
    .summary-card {
      width: 80%;
    }
  }
}
.margin:not(:last-child) {
  margin-right: 15px;
  @include media-breakpoint-down(lg) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.summary-card {
  height: 140px;
  width: 370px;
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  border-radius: 5px;
  @include media-breakpoint-down(lg) {
    height: 100px;
    width: 330px;
  }
  cursor: pointer;
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 95px;
    max-height: 95px;
    min-width: 95px;
    max-width: 95px;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      min-height: 65px;
      max-height: 65px;
      min-width: 65px;
      max-width: 65px;
    }
  }
  .total {
    background-color: #5b73e8;
  }
  .nokbo {
    background-color: #f1b44c;
  }
  .stop {
    background-color: #0096db;
  }
  .allocated {
    background-color: #34c38f;
  }
  .content {
    font-size: 36px;
    color: white;
    @include media-breakpoint-down(lg) {
      font-size: 25px;
    }
  }
  .quantity {
    text-align: right;
    font-size: 36px;
    color: #74788d;
    font-weight: bold;
    @include media-breakpoint-down(lg) {
      font-size: 25px;
    }
  }
  .label {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
    color: #495057;
    @include media-breakpoint-down(lg) {
      font-size: 12px;
    }
  }
}
</style>
