import axios from '../../plugins/axios'

export const listTechnicians = ({ commit }, { params } = {}) => {
  console.log('listTechnicians')
  const apiAddress = 'api/orden/tecnicos'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    params,
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_TECHNICIANS', { technicians: response.data })
  })
}
