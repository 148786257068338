import hasPermission from '@/utils/permissions'

const actions = [
  {
    name: 'Diagnosticar',
    roles: ['Supervisor', 'Técnico'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.diagnostico === null &&
      ot.rma !== null &&
      ot.ubicacion === 'Laboratorio',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'RevisarInforme',
    roles: ['Administrativo'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.diagnostico_detail &&
      ot.diagnostico_detail.revisado === false &&
      ot.estado?.estado?.orden >= 10 &&
      !ot.cambio_3x3,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'EnviarCorreoCambio',
    roles: ['Administrativo'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.mostrar_envio_correos &&
      ot.cambio_correo_enviado === null,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  // {
  //   name: 'CambioEsperaDeParte',
  //   roles: ['Administrativo'],
  //   show: (ot) => (
  //     ot.estado?.estado?.nombre !== 'Acreditación'
  //     && ot.estado?.estado?.nombre === 'Espera de partes'
  //   ),
  //   disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  // },
  {
    name: 'EsperaDeRetiro',
    roles: ['Administrativo'],
    show: (ot) => ot.estado?.estado?.nombre === 'A espera de retiro',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'EditRMA',
    roles: ['Administrativo', 'Supervisor'],
    show: (ot) => ot.cliente && ot.equipo && !ot.rma,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'SelectTechnician',
    roles: ['Supervisor'],
    show: (ot) => ot.rma && !ot.tecnico_asignado,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'PrimerIngresoABodega',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.ubicacion === 'Recepción' &&
      (ot.estado?.estado?.nombre === 'Ingresado' ||
        ot.estado?.estado?.nombre === 'Asignado'),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'EntregarTecnico',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.ubicacion === 'Bodega' &&
      ot.estado?.estado?.nombre === 'Asignado',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'IngresarABodegaRechazado',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.estado?.estado?.nombre === 'Rechazado' &&
      ot.ubicacion !== 'Bodega',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'IngresarABodegaPorEsperaDePartes',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ['Repuesto ordered', 'Espera de partes'].includes(
        ot.estado.estado.nombre
      ) &&
      ot.ubicacion !== 'Bodega',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'EntregarEquipoRepuestoLocalizado',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      [
        'Repuesto localizado',
        'Repuesto localizado solicitado',
        'Repuesto entregado',
        'En Reparación',
      ].includes(ot.estado.estado.nombre) &&
      ot.solicitud_partes.find(
        (p) => p.estado === 'Solicitado' || p.estado === 'Localizado'
      ),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'IngresarABodegaReparado',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ['Reparado', 'Revisión Informe', 'Informe Revisado'].includes(
        ot.estado.estado.nombre
      ) &&
      ot.ubicacion !== 'Bodega' &&
      ot.ubicacion !== 'Recepción',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'DarSalida',
    roles: ['Bodega', 'Administrativo'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      [
        'Reparado',
        'Revisión Informe',
        'Informe Revisado',
        'Rechazado',
      ].includes(ot.estado.estado.nombre) &&
      (ot.diagnostico_detail?.revisado || ot.cambio_3x3) &&
      ((ot.ubicacion === 'Bodega' && ot.cliente?.tipo_cliente === 'Empresa') ||
        (ot.ubicacion === 'Bodega' &&
          ot.cliente?.tipo_cliente === 'Persona' &&
          ot.despacho === 'ENVIO') ||
        (ot.ubicacion === 'Recepción' && ot.despacho !== 'ENVIO')),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  // {
  //   name: 'DesarmarEquipo',
  //   roles: ['Bodega'],
  //   show: (ot) => (
  //     ot.estado?.estado?.nombre !== 'Acreditación'
  //     && ot.cambio_correo_enviado
  //     && !ot.cambio_3x3
  //   ),
  //   disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  // },
  {
    name: 'Rechazar',
    roles: ['Supervisor', 'Técnico'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.estado?.estado &&
      ['En Evaluación', 'Espera de partes', 'En Reparación'].includes(
        ot.estado.estado.nombre
      ) &&
      ot.diagnostico_detail !== null,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'IngresarReparacion',
    roles: ['Supervisor', 'Técnico'],
    show: (ot) =>
      ot.estado?.estado &&
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      [
        'En Evaluación',
        'Espera de partes',
        'En Reparación',
        'Repuesto localizado solicitado',
      ].includes(ot.estado.estado.nombre) &&
      ot.diagnostico_detail !== null,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'SolicitarPartes',
    roles: ['Supervisor', 'Técnico'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      [
        'En Evaluación',
        'Espera de partes',
        'Repuesto localizado solicitado',
        'Repuestos entregados',
        'En Reparación',
      ].includes(ot.estado.estado.nombre),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'LlegadaDePartes',
    roles: ['Administrativo'],
    show: (ot) =>
      ot.estado?.estado &&
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot.estado?.estado?.nombre === 'Espera de partes',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'CompletPreIngreso',
    roles: ['Administrativo'],
    show: (ot) =>
      ot?.estado?.estado &&
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ['Pre ingreso', 'Pre ingreso regiones'].includes(ot.estado.estado.nombre),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'InternalQuotation',
    roles: ['Administrativo'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot?.tipo_servicio?.nombre === 'Presupuesto' &&
      ot?.estado?.estado &&
      ['Repuesto ordered', 'Espera de partes', 'En Reparación'].includes(
        ot.estado.estado.nombre
      ) &&
      ot.ubicacion !== 'Bodega',
    disabled: (ot) => ot.estado?.estado?.nombre === 'Cotización Solicitada',
  },
  {
    name: 'Reingreso',
    roles: ['Administrativo'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      [
        'Reparado',
        'Revisión Informe',
        'Informe Revisado',
        'Rechazado',
      ].includes(ot.estado.estado.nombre) &&
      ot.diagnostico_detail?.revisado &&
      (ot.ubicacion === 'Bodega' || ot.ubicacion === 'Recepción') &&
      !ot.cambio_3x3,
    disabled: (ot) => ot.estado?.estado?.nombre === 'Cotización Solicitada',
  },
  {
    name: 'EntregarRecepcion',
    roles: ['Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      (ot?.sucursal?.despacho?.toLowerCase().indexOf('retiro') > -1 ||
        ot.despacho?.toLowerCase().indexOf('retiro') > -1) &&
      [
        'Reparado',
        'Reparado 3x3',
        'Revisión Informe',
        'Informe Revisado',
        'Rechazado',
      ].includes(ot.estado.estado.nombre) &&
      ot.diagnostico_detail?.revisado &&
      ot.ubicacion === 'Bodega' &&
      ot.cliente?.tipo_cliente === 'Persona',
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'AddBoleta',
    roles: ['Administrativo', 'Supervisor', 'Técnico', 'Gerente', 'Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ot?.tipo_servicio?.nombre === 'Garantía' &&
      ot.cliente &&
      ot.equipo &&
      !ot.boleta &&
      (ot.ubicacion === 'Bodega' || ot.ubicacion === 'Recepción'),
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
  {
    name: 'Cambio3x3',
    roles: ['Administrativo', 'Bodega'],
    show: (ot) =>
      ot.estado?.estado?.nombre !== 'Acreditación' &&
      ['Reparado'].includes(ot.estado.estado.nombre) &&
      !ot.cambio_3x3,
    disabled: (ot) => ot.estado?.estado?.nombre === 'En Solicitud de Excepción',
  },
]

const getAction = (actionName) => actions.find((a) => a.name === actionName)
const hasRole = (user, roles) => hasPermission({ roles, user })

/* -------------------------------------- Métodos públicos -------------------------------------- */
const isDisabled = (actionName, ot) => getAction(actionName).disabled(ot)

const showButton = (actionName, ot, user) => {
  const action = getAction(actionName)
  if (!hasRole(user, action.roles)) return false

  return action.show(ot)
}

const hasActions = (ot, user) => {
  const actiones = actions.some(
    (action) => showButton(action.name, ot, user) && !action.disabled(ot)
  )
  return actiones
}

export { isDisabled, showButton, hasActions }
