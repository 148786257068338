import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const Notificaciones = {
  namespaced: true,
  state: {
    notificacions: [],
    notificacions_count: 0,
    notificacions_news: 0,
    notification: {}
  },
  getters,
  mutations,
  actions,
}

export default Notificaciones
