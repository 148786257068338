import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const PartsStore = {
  namespaced: true,
  state: {
    part: null,
  },
  getters,
  mutations,
  actions,
}

export default PartsStore
