<template>
  <b-alert
    :show="show"
    variant="success"
    style="z-index: 2000; max-width: 400px; position: fixed; top: 10px; right: 10px;"
    dismissible
    @dismissed="show = false"
  >
    <div>
      <h5>{{ title }}</h5>
      <h6>{{ body }}</h6>
      <div v-if="action.hasAction">
        <b-button @click="action.event" variant="link">{{ action.title }}</b-button>
      </div>
    </div>
  </b-alert>
</template>
<script>
export default {
  name: 'PushNotificationAlert',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      action: {
        event: '',
        title: '',
        hasAction: false,
      },
      body: '',
      title: ''
    }
  },
  methods: {
    getAction (action) {
      if (action === 'open_exceptions') {
        return {
          event: () => {
            if (this.$route.name !== 'Excepciones') {
              this.$router.push({ name: "Excepciones" })
            }
            this.show = false
          },
          title: 'Ver excepciones',
          hasAction: true,
        }
      }
      return {
        event: '',
        title: '',
        hasAction: false,
      }
    },
    showNotification(notification) {
      this.title = notification.title
      this.body = notification.body
      this.action = this.getAction(notification.action)
      setTimeout(() => {
        this.show = true
      }, 2000)
    },
  },
  watch: {
    notification: {
      handler() {
        this.showNotification(this.notification)
      },
      deep: true,
    },
  },
}
</script>
