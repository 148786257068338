<template lang="pug">
.main-wrapper
  .top-wrapper
    Navbar
    .error(v-if="!ot && statusRequest") Orden No encontrada
    .container.flex-col.gap-1(v-if="ot")
      .flex-row.gap-2
        .label-ot Seguimiento OT:
        .label-ot.text-button {{ot.ot}}
      b-row
        b-col(md="7" cols="12").mb-4.mb-md-0
          b-card.shadow-sm
            .card-contents.pb-4.pb-md-0
              h3.subtitle.mb-4.mb-md-2 Estado actual de la reparación

              .estado.mb-4
                .status-text.mb-2(v-if="ot.estado && ot.estado.estado" :style="getColor(ot.estado.estado)")
                  | {{ hasApproved3x3 ? 'Equipo con aprobación de cambio' : ot.estado.estado.nombre_cliente || ot.estado.estado.nombre }}
                .status-description(v-if="ot.estado && ot.estado.estado")
                  | {{ hasApproved3x3 ? '' : ot.estado.estado.descripcion_cliente }}

              b-progress.barra-progreso( :max="100" height="2rem" variant="button")
                b-progress-bar( :value="ot.estado.estado.porcentaje_cliente" )
                  div
                    span.h6 {{ hasApproved3x3 ? '100' : parseFloat(ot.estado.estado.porcentaje_cliente) }}
                    span.sign %

              h3.subtitle Diagnóstico
              .flex-col.mt-3(v-if="ot.diagnostico_detail")
                .flex-col
                  .label Informe
                  p {{ ot.diagnostico_detail.informe || '' }}
                .flex-col
                  .label Solución
                  p {{ ot.diagnostico_detail.solucion || '' }}
              .flex-col.align-items-center.text-center(v-else)
                b-img.blank-img.mb-4.mt-5.mt-md-3(:src="require('@/assets/img/diagnostic-blank.png')")
                span Tu equipo ha sido ingresado.
                span Tan pronto como tengamos su diagnostico podrás verlo en esta pantalla

        b-col(md="5" cols="12")
          b-card.shadow-sm
            .card-contents
              b-tabs(pills card justified lazy)
                b-tab(title="Datos Equipo")
                  TabDeviceData(:ot="ot")
                b-tab(title="Historial")
                  StateHistory(:states="stateHistory")

                b-tab(title="Informes")
                  h3.subtitle.mb-3 Informes
                  .flex-col.gap-4
                    .flex-row.align-items-center.justify-content-between(v-if="ot.diagnostico && ot.informe_cliente_pdf")
                      .label.text-button Informe de Ingreso
                      b-btn(variant="outline-button" @click="downloadReport(ot.ot)")
                        b-icon( icon="file-earmark-arrow-down-fill" )
                        strong.ml-2 Descargar PDF
                    .flex-row.align-items-center.justify-content-between(v-if="pdfIngreso")
                      .label.text-button Orden de ingreso
                      b-btn(variant="outline-button" @click="downloadEntryPDF")
                        b-icon( icon="file-earmark-arrow-down-fill" )
                        strong.ml-2 Descargar PDF

                b-tab(title="Adjuntos")
                  h3.subtitle.mb-2 Adjuntos
                  .thumbnails
                    .thumbnail-item(v-for="file,index in files" :key="file.file_id")
                      b-icon.h3(v-if="file.file_type === 'pdf'" icon="file-pdf" variant="white")
                      b-icon.h3(v-else-if="notAnImage(file.file_type)" icon="file-alt" variant="white")
                      b-img(v-else-if="fileBlobUrls[index]" :src="fileBlobUrls[index]" thumbnail)
                      b-icon.h3(v-else icon="images" variant="white")
                  .flex-row.justify-content-end.mt-3.mr-3
                    b-btn(variant="outline-button" @click="downloadAllImages" :disabled="downloadingAll") Descargar Todas

                //- Tab invisible para el layout
                b-tab(title="")

  .bottom-wrapper
    .container
      hr.w-100.m-0
      span.footer-text © {{ currentYear }} SILL | Servicio Técnico ASUS DELL Autorizado en Chile.
</template>

<script>
import moment from 'moment'
import JSZip from 'jszip'
import fileDownload from 'js-file-download'
import momentDurationFormatSetup from 'moment-duration-format'
import { mapGetters, mapActions } from 'vuex'
import Navbar from './Navbar/Navbar.vue'
import Status from '../Status.vue'
import TabDeviceData from './Subcomponents/TabDeviceData.vue'
import StateHistory from './Subcomponents/StateHistory.vue'

momentDurationFormatSetup(moment)

export default {
  name: 'ClientOT',

  components: {
    Navbar,
    Status,
    TabDeviceData,
    StateHistory,
  },
  data() {
    return {
      pdfIngreso: null,
      stateHistory: [],
      fileBlobUrls: [],
      downloadingAll: false,
      fields: [
        { key: 'number', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },
  async created() {
    const OT = parseInt(this.$route.params.otId, 10)
    const { rut } = this.$route.params
    const resp = await this.getPdfIngreso(OT)

    if (resp.status === 200) {
      // imprimir pdf de respuesta
      const file = new Blob([resp.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      this.pdfIngreso = fileURL
    }
    await this.getOTPublic({ otId: OT, rut })
    if (this.ot) {
      const res = await this.getOTHistoryPublic({ otId: OT, rut })
      if (res.status < 200 || res.status >= 300) {
        return this.$bvToast.toast('Error al cargar historial', {
          variant: 'danger',
        })
      }
      this.stateHistory = res.data

      await this.getFiles(this.ot.ot)
      this.files.forEach(async (item, idx) => {
        const payload = {
          fileName: item.name,
          fileType: item.file_type,
          fileId: item.file_id,
        }
        this.$set(this.fileBlobUrls, idx, await this.getFileUrl(payload))
      })
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'files', 'statusRequest']),
    currentYear() {
      return new Date().getFullYear()
    },
    hasApproved3x3() {
      return (
        this.ot &&
        this.ot.excepciones.filter(
          (e) => e.tipo === 'Aprobación de 3x3' && e.estado === 2
        ).length > 0
      )
    },
    fechaIngreso: ({ ot }) => moment(ot.fecha_ingreso).format('DD - MM - YYYY'),
    guiaIngreso: ({ ot }) => ot.guia_despacho_ingreso,
    tiempoIngreso: ({ ot }) =>
      moment
        .duration(ot.tiempo_estados.hora_total, 'second')
        .format('D[d] H[h]'),
    items() {
      const files =
        this.files.map((f, index) => ({
          number: index + 1,
          name: f.name ? f.name.split('/').pop() : '-',
          file: f,
        })) || []

      return files
    },
  },
  methods: {
    ...mapActions('OTStore', [
      'getOTPublic',
      'downloadReport',
      'getFiles',
      'downloadFile',
      'getPdfIngreso',
      'getOTHistoryPublic',
      'getFileUrl',
    ]),
    notAnImage(fileType) {
      return !['png', 'jpg', 'jpeg', 'gif'].includes(fileType)
    },
    downloadEntryPDF() {
      const link = document.createElement('a')
      link.href = this.pdfIngreso
      link.setAttribute(
        'download',
        `informe_ingreso_ot_${this.$route.params.otId}.pdf`
      )
      document.body.appendChild(link)
      link.click()
    },
    getColor(status) {
      if (status) {
        return `color: ${status.color_foreground}`
      }
    },

    async downloadItem(item) {
      const payload = {
        fileName: item.name,
        fileType: item.file.file_type,
        fileId: item.file.file_id,
      }
      try {
        this.downloadFile(payload)
      } catch (error) {
        this.$bvToast.toast(error, {
          variant: 'danger',
        })
      }
    },

    async downloadAllImages() {
      this.downloadingAll = true
      const zip = new JSZip()
      const folder = zip.folder(`Adjuntos_OT_${this.ot.ot}`)
      await Promise.all(
        this.fileBlobUrls.map(async (url, index) => {
          if (!url) return
          const blob = await fetch(url).then((r) => r.blob())
          const fileName = this.files[index].name.split('/').pop()
          folder.file(fileName, blob)
        })
      )
      zip.generateAsync({ type: 'blob' }).then((content) => {
        fileDownload(content, `Adjuntos_OT_${this.ot.ot}.zip`)
      })
      this.downloadingAll = false
    }
  },
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.bottom-wrapper .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  text-align: center;
  font-size: 22px;
  color: #d22020;
}

.top-wrapper {
  display: flex;
  flex-direction: column;
}

.top-wrapper .container {
  font-size: 16px;
  color: #525252;

  @media (min-width: 768px) {
    > .row {
      flex-grow: 1;
      > div {
        height: 100%;
        > .card {
          border-radius: 15px;
          // padding-bottom: 40px;
        }
      }
    }
  }
}

.card-contents {
  @media (min-width: 768px) {
    height: min(calc(100vh - 260px), 640px);
    overflow-y: auto;
  }
}

::v-deep .card-body {
  @media (max-width: 767px) {
    padding: 10px;
  }
}

.label-ot {
  font-size: 28px;
  font-weight: 700;
}

.status-text {
  font-weight: bold;
  font-size: 40px;
  color: #5b73e8 !important;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.status-description {
  text-align: center;
}

.blank-img {
  width: 260px;
  @media (max-width: 767px) {
    width: 190px;
  }
}

.footer-text {
  margin: 12px 0;
}

::v-deep .nav-pills .nav-link {
  border-radius: 8px;
}

::v-deep .nav-pills .nav-link:not(.active) {
  color: #525252;
  font-weight: bold;
}

::v-deep .nav-pills .nav-link.active {
  color: #fff;
  background-color: #5b73e8;
  font-weight: bold;
}

::v-deep .tabs > .card-header {
  padding: 5px;
  border-bottom: 0;
  background-color: #F8F9FE;
  @media (max-width: 767px) {
    background-color: transparent;
    overflow-x: auto;
    width: calc(100vw - 25px);
  }

  .card-header-pills {
    margin-left: 0;
    margin-right: 0;
    gap: 20px;
    scrollbar-width: thin;
    overflow-x: visible;

    >.nav-item {
      background-color: #F8F9FE;
      &:last-child {
        width: 0;
        margin-left: -25px;
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}

::v-deep .tab-content > .tab-pane {
  padding-left: 0;
  padding-right: 0;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(131px, 1fr));
  gap: 20px;
}

.thumbnail-item {
  width: 131px;
  height: 131px;
  border-radius: 10px;
  background-color: #CBCBCB;
  display: flex;
  justify-content: center;
  align-items: center;
}


::v-deep {
  .subtitle {
    color: #253590;
    font-weight: 700;
    font-size: 20px;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .label {
    color: #525252;
    font-weight: bold;
    font-size: 16px;
  }
}

/* ---------------------------------------------------------------------------------------------- */

::v-deep .form-group label {
  color: #253590;
  font-weight: bold;
}

.change-text {
  color: var(--info);
  font-style: italic;
}

#ot-number {
  color: #5b73e8;
  font-size: 22px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .orden,
  #ot-number {
    margin-left: 3rem;
  }
}
@media (max-width: 838px) {
  .orden {
    margin-top: 10px;
  }
}
.sub-title,
// .input-label {
//   width: 40%;
// }
.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 0.3rem;
}
.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}
// .input-label {
//   color: #74788d;
//   font-weight: bold;
//   font-size: 1em;
//   margin-bottom: 0.3rem;
// }
.OT-form-card {
  margin-bottom: 1rem;
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  border-radius: 0.5rem;
  padding: 1rem;
  justify-content: space-between;
  background-color: white;
}
@media (min-width: 768px) {
  // .container {
  //   min-height: 100vh;
  // }
  .container-dos {
    gap: 2rem;
    margin-bottom: 2rem;
    max-width: 100%;
    margin-right: 4rem;
    margin-left: 3rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(60px, auto);
  }
  .cliente {
    margin: auto;
    width: 100%;
    // grid-row: 1/3;
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .right-column {
    gap: 1rem;
    grid-column: 3/12;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: min-content;
  }
  @media (max-width: 1280px) {
    .cliente {
      grid-column: 1/4;
    }
    .right-column {
      grid-column: 4/12;
    }
  }
  .estado {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 100%;
    padding: 5px 20px;
    text-align: center;
  }
  .right-column {
    .bar {
      height: 3rem;
      box-shadow: 1px 5px 15px 3px rgba(204, 204, 204, 1);
      opacity: 1;
      border: none;
      margin-bottom: 1rem;
      color: #5b73e8;
      font-size: 1rem;
    }
    .status-description {
      color: var(--info);
      font-size: 16px;
    }
    .display-data {
      margin: auto;
      font-size: 1rem;
      font-weight: 600;
      color: #74788d;
      text-align: center;
    }
  }
  .card-diagnostico,
  .card-adjuntos {
    flex-grow: 1;
    .text {
      min-height: 3rem;
    }
  }
  .card-adjuntos {
    grid-column: 1/12;
  }
}

.barra-progreso {
  background-color: #e9ecef;
  border: none;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    margin-left: 10px;
    margin-right: 10px;
    .h6 {
      font-size: 12px;
    }
    .sign {
      font-size: 8px;
    }
  }
}

hr {
  margin: 0 0 1rem;
}
</style>
