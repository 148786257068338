<template lang="pug">
  .select-technician
    div
      //- BOTON ENTREGAR A RECEPCIÓN
      //- Da salida de bodega a recepción
      button.change-button(
        :disabled="disabled || loading"
        @click="entregarARecepcion()")
        | Entregar a recepción
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ActionEntregarRecepcion',
  props: ['ot', 'disabled'],

  data() {
    return {
      state: false,
      usuarios: [],
      loading: false,
    }
  },
  methods: {
    ...mapActions('OTStore', ['editOT', 'entregarRecepcion']),
    async entregarARecepcion() {
      this.loading = true
      const payload = {
        ot: this.ot.ot,
        user: this.user.user.id,
      }
      const resp = await this.entregarRecepcion(payload)
      if (resp.status === 200) {
        await this.editOT({
          ot: this.ot.ot,
          tecnico_asignado: this.ot.tecnico_asignado.id,
          ubicacion: 'Recepción',
        })
        this.$bvToast.toast(
          `OT entregada a recepción
        a las ${moment(resp.data.fecha_hora).format('DD/MM/YYYY HH:mm')}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
      } else {
        this.$bvToast.toast(
          `Error al crear el movimiento de bodega: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
}
</script>
