<template lang="pug">
#QuotationDetail(v-if="!loading")
  b-modal(v-model="showQuotationDetail" size="lg" centered :title="`Detalle de cotización OT ${ot.ot}`" ok-only)
    QuotationInfo(:ot="ot" :quotation="quotation")
</template>
<script>
import { mapActions } from 'vuex'
import QuotationInfo from './QuotationInfo.vue'

export default {
  components: {
    QuotationInfo,
  },
  data() {
    return {
      loading: false,
      ot: {},
      quotation: {},
      showQuotationDetail: false,
    }
  },
  methods: {
    ...mapActions('OT', ['getOtByID']),
    async fetchData(otId) {
      const response = await this.getOtByID(otId)
      this.ot = response.data
    },
    async open(quotation) {
      this.loading = true
      await this.fetchData(quotation.ot)
      this.quotation = quotation
      this.showQuotationDetail = true
      this.loading = false
    }
  },
}
</script>
