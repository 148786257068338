<template lang="pug">
  .sill-autocomplete
    autocomplete(
      :search="search"
      :debounceTime="200"
      :getResultValue="val => val[displayAttribute]"
      :placeholder="placeholder"
      @submit="onSubmit"
      )
      template(#result="{ result, props }")
        li(v-bind="props")
          span {{ result.part_number }}
          small(v-if="result.recomended" class="compatible-small") (Compatible)
    b-spinner.searching(v-if="searching" small)
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  name: 'SILLAutocomplete',
  props: [
    'search',
    'displayAttribute',
    'placeholder',
    'onSubmit',
    'searching',
    'setDefault',
  ],
  components: {
    Autocomplete,
  },
}
</script>

<style lang="scss">
@import '../../style/global.scss';
.sill-autocomplete {
  background: white;
  input {
    border: rgb(204, 204, 204) 1px solid;
    border-radius: 3px;
    padding: 5px 7px;
    // max-width: 330px;
    height: 37px;
    width: 100%;
  }
  .autocomplete-result-list {
    list-style-type: none !important;
    background-color: white;
    padding-left: 3px;
    margin-left: 0;
    border: #eaeaea 1px solid;
    max-width: 330px;
    li {
      list-style-type: none !important;
      margin: 5px 0;
      padding: 3px;
      cursor: pointer;
      &:hover {
        background-color: #eaeaea;
      }
    }
  }
}
.compatible-small {
  margin-left: 5px;
  font-size: 11px;
  color: $green;
}
</style>
