<template lang="pug">
b-form-group( label="Tipo Cliente" )
  b-form-radio-group(
    v-model="model"
    :value="1"
    :options="radioOptions"
    :disabled="disabled"
  ).radio-wrapper
</template>

<script>
import * as cts from '@/components/OT/constants'

export default {
  props: {
    value: Number,
    disabled: Boolean,
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.radioOptions = [
      { text: 'Persona', value: cts.CLIENTE_PERSONA },
      { text: 'Retail', value: cts.CLIENTE_EMPRESA },
    ]
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Separa tipo cliente en SM
.radio-wrapper {
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: space-between;

    &::v-deep .custom-control-inline {
      margin-right: 0;
    }
  }
}
</style>
