<template lang="pug">
b-tabs(pills card lazy)
  b-tab(
    v-for="(tab, index) in tabs"
    :title="`${tab.title || tab.states[0]}`"
    title-item-class="title-item"
    @click ="handleTabChange(tab)"
  )
    b-table(
      :fields="fields"
      :items="tableData"
      head-row-variant="light-primary"
      thead-class="text-black tabla-thead"
      tbody-class="tabla-tbody"
      tbody-tr-class="bg-white"
      responsive
      :busy="loading"
      show-empty
      empty-text="No hay cotizaciones para mostrar"
    )
      template(#cell(id)="{ item, value }")
        b-button.p-0.m-0(variant="text" size="sm" @click="handleView(item)")
          | {{ value }}
      template(#cell(created_at)="{ value }")
        span {{ value | formatedDate }}
      template(#cell(actions)="{ item }")
        b-button(
          v-if="item.state !== 'ANSWERED'"
          size="sm"
          variant="button"
          @click="handleAnswer(item)"
        ) Responder

  UploadQuoteModal(
    v-model="showUploadQuoteModal"
    :quote="selectedQuote"
    @updated="getExternalQuotes"
  )

  b-modal(v-model="showQuoteDetailModal" size="xl" ok-only)
    QuoteSummary(:quote="selectedQuote").mx-2
</template>

<script>
import { useExternalQuoteListStore } from '@/pinia/views/useExternalQuoteListStore'
import { mapActions, mapState, mapWritableState } from 'pinia'
import UploadQuoteModal from './UploadQuoteModal.vue'
import QuoteSummary from './QuoteSummary.vue'

export default {
  components: {
    UploadQuoteModal,
    QuoteSummary,
  },
  data() {
    return {
      loading: false,
      showUploadQuoteModal: false,
      showQuoteDetailModal: false,
      selectedQuote: null,
    }
  },
  computed: {
    ...mapWritableState(useExternalQuoteListStore, ['filters']),
    ...mapState(useExternalQuoteListStore, ['results']),
    tabs() {
      return [
        { title: 'Solicitado', states: ['REQUESTED'] },
        { title: 'Contestado', states: ['ANSWERED'] },
        { title: 'Todos', states: ['REQUESTED', 'ANSWERED'] },
      ]
    },
    fields() {
      return [
        { key: 'id', label: 'ID', stickyColumn: true },
        { key: 'created_at', label: 'Fecha' },
        { key: 'name', label: 'Cliente' },
        { key: 'rut', label: 'RUT' },
        { key: 'device_serial_number', label: 'N° Serie' },
        { key: 'actions', label: 'Acciones' },
      ]
    },
    tableData() {
      return this.results.map((o) => ({
        ...o,
        name: `${o.quote_client?.first_name} ${o.quote_client?.last_name}`,
        rut: o.quote_client?.rut,
      }))
    },
  },
  methods: {
    ...mapActions(useExternalQuoteListStore, ['getExternalQuotes']),
    handleTabChange(tab) {
      this.filters.states = tab.states
      this.filters.page = 1
      this.getExternalQuotes()
    },
    handleAnswer(item) {
      this.selectedQuote = item
      this.showUploadQuoteModal = true
    },
    handleView(item) {
      this.selectedQuote = item
      this.showQuoteDetailModal = true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

::v-deep .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8;
  font-weight: bold;
}
::v-deep .card-header {
  background-color: #ffffff;
  overflow-x: auto;

  ul {
    overflow-x: visible;
  }

  a {
    color: #495057;
    font-weight: bold;
    &:hover {
      color: #495057;
    }
  }
}
::v-deep table th {
  color: #495057;
}
a.router-link-active {
  color: #5b73e8;
}
.text-danger {
  color: #5b73e8 !important;
}
::v-deep .card-body {
  padding: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8 !important;
  font-weight: bold;
}

::v-deep .tabla-tbody td {
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

::v-deep .tabla-thead th {
  @include media-breakpoint-down(sm) {
    padding: 4px 10px 4px;
  }
}
</style>
