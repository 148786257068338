const mutations = {
  SET_EQUIPMENT(state, { equipment }) {
    state.equipment = equipment
  },
  SET_MODELS(state, { models }) {
    state.models = models
  },
  SET_MODEL(state, { model }) {
    state.model = model
  },
  SET_BRANDS(state, { brands }) {
    state.brands = brands
  },
  SET_STATUS(state, { statusRequest }) {
    state.statusRequest = statusRequest
  },
}
export default mutations
