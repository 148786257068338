<template lang="pug">
#ActionAddPaymentLink
  //- ACCION ADJUNTAR LINK DE PAGO
  div
    //- Botón acción
    button.change-button(
      @hide="hideModal()"
      @click="modal = true"
      ) Adjuntar link
    //-Modal de revisión
    b-modal(v-model="modal" size="lg")
      template(#modal-title v-if="ot")
        span.header-modal.text-center Adjuntar link de pago presupuesto {{ ot.ot }}
      slot
      hr
      label(for="paymentLink") Link de pago
      b-form-input(v-model="paymentLink" id="paymentLink")
      template(#modal-footer='{ ok, cancel }')
        b-button(size='sm' variant='success' @click="markPayment" :disabled="paymentLink === ''") Confirmar
        b-button(size='sm' variant='danger' @click='modal = false;') Cancelar
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['ot', 'quotation'],
  data() {
    return {
      modal: false,
      paymentLink: '',
    }
  },
  methods: {
    ...mapActions('Quotations', [
      'searchQuotations',
      'addPaymentLinkQuotation',
    ]),
    async markPayment() {
      const resp = await this.addPaymentLinkQuotation({
        data: {
          link_pago: this.paymentLink,
        },
        id: this.quotation.id,
      })

      if (resp && resp.status === 200) {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
        this.searchQuotations({
          estado: 'APROBADO_INTERNO',
        })
        this.modal = false
      } else {
        this.$bvToast.toast(`Error al adjuntar link de pago: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
