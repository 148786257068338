<template lang="pug">
div
  div.d-flex.justify-content-between
    div.d-flex.align-items-center
      b-icon.mr-2(icon="tools" variant="primary" font-scale="1.5")
      h4.mb-0.title Solicitud de Partes
    //- b-icon.mr-2(icon="pencil-square"
    //- variant="primary" font-scale="1.5" style="cursor: pointer;" @click="editParts" v-if="ot.solicitud_partes.length > 0")
  hr
  PartsTable(:ot="ot" :showStateActionColumn="false")
</template>

<script>
import { mapGetters } from 'vuex'
import PartsTable from './PartsTable.vue'

export default {
  name: 'SolicitudPartes',
  components: { PartsTable },
  computed: {
    ...mapGetters('OTStore', ['ot']),
    ...mapGetters('Diagnosticos', ['diagnostic']),
  },
  methods: {
    editParts() {
      const parts = []
      // eslint-disable-next-line
      this.ot.solicitud_partes?.map((parte) => {
        parts.push({
          ot: this.ot.ot,
          solicitudId: parte.id,
          parte: {
            partNumber: parte.parte.part_number,
            description: parte.parte.description,
            realLocation: parte.parte.real_location,
          },
          allocated: parte.allocated,
        })
      })
      this.$router.push({
        name: 'PedirPartes',
        params: { order_id: this.ot.ot, data: parts },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}
.ask-parts {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 135px;
  height: 35px;
  border-radius: 5px;
  font-weight: 600;
}
</style>
