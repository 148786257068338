<template lang="pug">
div
    button.change-button(
      :disabled="disabled"
      @click="show = true"
      v-if="showButton"
      @hide="hideModal()"
    ) Rechazar
    b-modal.hide-foooter(v-model="show" title="Rechazar OT" size="lg")
      b-form-group
        label(class="client-form-inputs") Motivo de rechazo:
        b-form-textarea(
          id="comment"
          v-model="form.comentario_rechazo "
          placeholder="Ingresar comentario..."
          rows="4"
          )
      hr
      OTUploads(:ot="ot" fileNamePattern="evidencia-rechazo" :attachmentType="ARCHIVO_ADJUNTO_TIPO.EVIDENCIA")
      hr
      b-form-group
        Label(class="form-inputs") OT {{ot.ot}} pasará a Rechazado ¿Está seguro?
      template.botones(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='save()') Si
        b-button(size='sm' variant='danger' @click='hideModal()') No
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTUploads from '../OTUploads.vue'

export default {
  components: {
    OTUploads,
  },
  name: 'ActionRechazar',
  props: ['ot', 'disabled'],
  data() {
    return {
      show: false,
      form: {
        id: this.ot.diagnostico_detail ? this.ot.diagnostico_detail.id : null,
        orden_trabajo: this.ot.ot,
        comentario_rechazo: this.comentario_rechazo
          ? this.comentario_rechazo
          : null,
      },
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('OTStore', ['statusRequest']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,
    showButton() {
      const partes = this.ot.solicitud_partes?.filter(
        (parte) => parte.estado !== 'Dropped' && parte.estado !== 'Usado'
      ) || []
      return partes.length === 0
    },
  },
  methods: {
    ...mapActions('OTStore', ['getOT']),
    ...mapActions('Warehouse', ['changeWarehouseState']),
    ...mapActions('Diagnosticos', ['updateDiagnoseOT']),
    hideModal() {
      this.show = false
    },
    async save() {
      await this.changeWarehouseState({
        state: 'Rechazado',
        orden: this.ot.ot,
        user: this.user.user.id,
      })
      const response = await this.updateDiagnoseOT(this.form)
      if (response.status === 200) {
        this.$bvToast.toast('OT rechazada correctamente.', {
          variant: 'success',
        })
        this.$emit('changed')
        this.hideModal()
      } else {
        this.$bvToast.toast(`Error al rechazar OT: ${response.data}`, {
          variant: 'danger',
        })
      }
      this.hideModal()
    },
  },
}
</script>
