<template lang="pug">
#ActionSolicitar
  //- ACCION SOLICITAR DIAGNOSTICO
  div
    //- Botón acción
    button.change-button(
      @hide="hideModal()"
      @click="modal = true"
      ) {{ quotation.estado_code === 'ESPECIFICA_PIEZAS' ? 'Solicitar' : 'Corregir' }}
    //-Modal de revisión
    b-modal(v-model="modal" size="lg")
      template(#modal-title v-if="ot")
        span.header-modal.text-center Solicitar Presupuesto OT {{ ot.ot }} - RMA {{ ot.rma }}
      slot
      div.d-block
        p.font-select.text-center
        b-form.short-line-height
         b-row
            b-col
              b-form-group(label= "Presupuesto Adjunto:" label-for="input-document")
                  b-col.d-flex.flex-column
                    b-form-file(
                      id="file"
                      ref="file"
                      placeholder="Ningún archivo elegido"
                      browse-text="Buscar"
                      v-model="file")
      template(#modal-footer='{ ok, cancel }')
        b-button(size='sm' variant='success' @click='confirmRequest()', :disabled="file===null") Confirmar
        b-button(size='sm' variant='danger' @click='modal = false;') Cancelar
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['ot', 'quotation'],
  data() {
    return {
      modal: false,
      file: null,
    }
  },
  methods: {
    ...mapActions('Quotations', ['requestPartsQuotation', 'searchQuotations']),
    async confirmRequest() {
      const formData = new FormData()
      formData.append('pdf', this.file)

      const resp = await this.requestPartsQuotation({
        data: formData,
        id: this.quotation.id,
      })
      if (resp && resp.status === 200) {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
        this.searchQuotations({
          estado: 'ESPECIFICA_PIEZAS',
        })
        this.modal = false
      } else {
        this.$bvToast.toast(`Error al solicitar cotización: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
