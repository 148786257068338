export const CLIENTE_PERSONA = 1
export const CLIENTE_EMPRESA = 2
export const BODEGA = {
  TIPO_MOVIMIENTO: {
    ENTRADA: 1,
    SALIDA: 2,
  },
  TIPO_ENTRADA: {
    NO_APLICA: 0,
    POR_ASIGNAR: 1,
    ESPERA_DE_PARTES: 2,
    REPARADO: 3,
    ACREDITADO: 4,
    ASISTENCIA_RECHAZADA: 5,
    SIN_FALLA_HARDWARE: 6,
    REPARADO_3X3: 7,
    ESPERA_DE_RETIRO: 8,
    EQUIPO_POR_ENVIAR: 9,
  },
  TIPO_SALIDA: {
    NO_APLICA: 0,
    EVALUACION: 1,
    EN_REPARACION: 2,
    RETIRO_CLIENTE: 3,
    DESPACHO_DOMICILIO: 4,
    EQUIPO_PARA_PRUEBA: 5,
    ENTREGA_TECNICO: 6,
  },
}
