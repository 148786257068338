<template lang="pug">
#login-view
  #login-view-container
    .login
      img.logo(src="@/assets/logo_blaco_sill.png")
      .card-login
        router-view
</template>

<script>
import Login from '../components/auth/Login.vue'

export default {
  components: {
    Login,
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@/style/colors.scss';

#login-view {
  padding: 1rem;
  height: 100%;
  background-color: $sill-red;
  &-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.login {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin: -20px auto 40px;
    width: 110px;
  }
  .card-login {
    background: #f1f5f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 35px 45px;
    width: 430px;
    height: 480px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (width: 768px) {
      width: 430px;
      height: 480px;
      margin-left: -100px;
    }
    @media (width: 1024px) {
      width: 430px;
      height: 480px;
      margin-left: -55px;
    }
  }
}
</style>

<style lang="scss">
@import '@/style/colors.scss';

#login-view {
  .subtitle {
    color: #7f828f;
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;
    h4 {
      color: $sill-blue;
      font-size: 18px;
      font-weight: bold;
      margin: 15px 0;
    }
    p {
      margin: 5px 0;
      line-height: 120%;
    }
  }
  .icon-container {
    position: absolute;
    margin-left: 15px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  input {
    padding-left: 35px;
    height: 50px;
  }
  .boton {
    margin: 20px 0;
    font-size: 15px;
    span {
      margin-left: 5px;
    }
  }
}
</style>
