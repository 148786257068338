<template lang="pug">
  .select-technician
    div
      //- BOTON REPUESTO O ESPERA DE PARTES
      //- Hace ingreso a bodega de un equipo que debe esperar a que lleguen las partes solicitadas
      button.change-button(
        :disabled="disabled || loading"
        title="Ingresar a bodega"
        @click="crearMovimientoBodega(BODEGA.TIPO_MOVIMIENTO.ENTRADA, BODEGA.TIPO_ENTRADA.ESPERA_DE_PARTES, 0, ot.tecnico_asignado.id, user.id, 'Bodega')")
        | Ingresar a bodega por espera de partes
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { ACTION_NAMES } from '@/utils/enums/actions'
import { BODEGA } from '../constants'

export default {
  name: 'ActionIngresarABodegaPorEsperaDePartes',
  props: ['ot', 'disabled'],

  data() {
    return {
      show: false,
      user: null,
      loading: false,
      usuarios: [],
      BODEGA,
    }
  },

  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    const groupsJustAdmin = user.groups.filter((u) => u.name === 'Bodega')
    if (groupsJustAdmin.length !== 0) {
      this.isBodega = true
    }
    this.user = user
  },

  methods: {
    ...mapActions('OTStore', ['editOT', 'getOT']),
    ...mapActions('Warehouse', ['saveMovement']),
    async crearMovimientoBodega(
      t_movimiento,
      t_entrada,
      t_salida,
      entregado,
      recibido,
      ubicacion_ot
    ) {
      this.loading = true
      const data = {
        ot: this.ot.ot,
        tipo_movimiento: t_movimiento,
        tipo_entrada: t_entrada,
        tipo_salida: t_salida,
        entregado_por: entregado,
        recibido_por: recibido,
      }
      const resp = await this.saveMovement(data)
      console.log(data)
      if (resp.status === 200) {
        await this.editOT({
          ot: this.ot.ot,
          ubicacion: ubicacion_ot,
          action: ACTION_NAMES.ingresarABodegaPorEsperaDePartes,
        })
        this.$bvToast.toast(
          `OT ingresada a las ${moment(resp.data.fecha_hora).format(
            'DD/MM/YYYY HH:mm'
          )}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
        await this.getOT(this.ot.ot)
      } else {
        this.$bvToast.toast(
          `Error al crear el movimiento de bodega: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
    hideModal() {
      this.state = null
      this.show = false
      this.showModalDespacho = false
      this.$emit('changed')
    },
  },
}
</script>
