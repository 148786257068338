const secondsToHoursMinutes = (seconds, shortTime = true) => {
  const days = Math.floor(seconds / (3600 * 24)) //eslint-disable-line
  let hours = Math.floor((seconds % (3600 * 24)) / 3600) //eslint-disable-line
  const minutes = Math.floor((seconds % 3600) / 60) //eslint-disable-line

  if (shortTime && minutes > 0) hours += 1

  const daysDisplay = days > 0 ? days + (days === 1 ? ' d, ' : ' d, ') : ''
  const hoursDisplay = hours > 0 ? hours + (shortTime ? ' h ' : ' h, ') : ''
  const minutesDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? ' m' : ' m') : ''

  return shortTime
    ? daysDisplay + hoursDisplay
    : daysDisplay + hoursDisplay + minutesDisplay
}

export default secondsToHoursMinutes
