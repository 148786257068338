var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"items":_vm.currentTableItems,"fields":_vm.visibleColumns,"head-row-variant":"light-primary","tbody-class":"tabla-tbody","thead-class":"text-black tabla-thead","tbody-tr-class":"bg-white","responsive":"","busy":_vm.loading,"show-empty":"","empty-text":"No hay Ordenes de Trabajo en este estado","no-local-sorting":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-icon-left":""},on:{"sort-changed":_vm.sortingChanged,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(ot)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"ot-cell"},[_c('router-link',{style:(_vm.getColorOT(value)),attrs:{"to":(_vm.isRetailUser ? 'cliente' : 'orden') + "/" + (value.ot) + (_vm.isRetailUser ? ("/rut/" + (_vm.getClientRut(value.cliente))) : '')}},[_vm._v(_vm._s(value.ot))]),_c('CircularIcon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.html",value:(_vm.getSecondEnterData(value.segundos_ingresos)),expression:"getSecondEnterData(value.segundos_ingresos)",modifiers:{"hover":true,"html":true}}],attrs:{"number":value.segundos_ingresos ? value.segundos_ingresos.length : 0,"classCustom":"circular-icon","title":"Otros ingresos"}}),(value.cambio_3x3)?_c('CircularIcon',{attrs:{"number":"3x3","classCustom":"circular-icon circular-icon-3x3"}}):_vm._e()],1)]}},{key:"cell(serie)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex flex-row align-items-start"},[(item.ot.alert_text)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":item.ot.alert_text}},[_c('b-icon',{staticClass:"mr-2 cursor-pointer",attrs:{"icon":"exclamation-triangle","variant":"warning","font-scale":"1.5"},on:{"click":function($event){return _vm.showSerialNumberAlertModal(item.ot)}}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(value))])])]}},{key:"cell(despacho)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"cell(estado)",fn:function(ref){
var value = ref.value;
return [_c('Status',{attrs:{"status":value}})]}},{key:"cell(all_time)",fn:function(value){return [_c('div',{staticClass:"d-flex flex-row"}),_c('b-icon',{staticClass:"mr-1",attrs:{"icon":value && _vm.totalTime(value).icon,"variant":value && _vm.totalTime(value).variant}}),_vm._v(_vm._s(_vm.totalTime(value).time))]}},{key:"cell(state_time)",fn:function(value){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":value && _vm.currentStatusTime(value).icon,"variant":value && _vm.currentStatusTime(value).variant}}),_vm._v(_vm._s(_vm.currentStatusTime(value).time))]}},{key:"cell(excepciones)",fn:function(value){return [(value.item.excepciones)?_c('router-link',{attrs:{"to":("orden/" + (value.item.ot.ot) + "?from=excepciones")}},[_vm._v(_vm._s(value.item.excepciones.count))]):_vm._e()]}},{key:"cell(acciones)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('ActionsWrapper',{ref:("actionsWrapper" + index),attrs:{"otId":item.ot.ot,"actions":item.ot.acciones,"is-not-closed":item.ot.estado && item.ot.estado.estado.nombre !== 'Cerrado',"parentLoading":_vm.loading},on:{"changed":function () { return _vm.$emit('changed'); }}})]}}])}),_c('SerialNumberAlertModal',{attrs:{"ot":_vm.serialNumberAlertOT},on:{"close":function($event){_vm.serialNumberAlertOT = null}},model:{value:(_vm.serialNumberAlertModal),callback:function ($$v) {_vm.serialNumberAlertModal=$$v},expression:"serialNumberAlertModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }