<template lang="pug">
div
    button.change-button(
      :disabled="disabled"
      @click="show = true"
      @hide="hideModal()"
    ) Diagnosticar
    b-modal.hide-foooter(v-model="show" size="lg" :title="ot.diagnostico === null ? 'Crear Diagnóstico' : 'Editar Diagnóstico'")
      b-form-group(
        label-for="input-falla"
        label="¿Se pudo replicar la falla?")
      b-form-radio(v-model="form.replicar_falla" value="true"
        :state="$v.form.replicar_falla.$dirty ? !$v.form.replicar_falla.$invalid : null"
      ) Sí
      b-form-radio(v-model="form.replicar_falla" value="false"
        :state="$v.form.replicar_falla.$dirty ? !$v.form.replicar_falla.$invalid : null"
      ) No
      .errors(v-if="$v.form.replicar_falla.$dirty")
        b-form-invalid-feedback(:state="$v.form.replicar_falla.required")
          | Campo requerido
      b-form-group(
        label-for="input-informe"
        label="Diagnóstico:"
      )
        b-form-textarea(
          id="input-informe"
          rows="5"
          v-model="form.informe"
          placeholder="Registrar el diagnóstico (obligatorio)"
          :state="$v.form.informe.$dirty ? !$v.form.informe.$invalid : null")
        .errors(v-if="$v.form.informe.$dirty")
          b-form-invalid-feedback(:state="$v.form.informe.required")
            | Campo requerido

        OTUploads(:ot="ot", fileNamePattern="evidencia-diagnostico" :attachmentType="ARCHIVO_ADJUNTO_TIPO.EVIDENCIA")

      template.botones(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='saveDiagnostic()', :disabled="loading") Guardar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTUploads from '../OTUploads.vue'

export default {
  components: {
    OTUploads,
  },
  name: 'ActionDiagnosticar',
  props: ['ot', 'disabled'],

  validations: {
    form: {
      replicar_falla: { required },
      informe: { required },
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      form: {
        replicar_falla: this.ot.diagnostico_detail
          ? this.ot.diagnostico_detail.replicar_falla
          : null,
        informe: this.ot.diagnostico_detail
          ? this.ot.diagnostico_detail.informe
          : null,
        id: this.ot.diagnostico_detail ? this.ot.diagnostico_detail.id : null,
        orden_trabajo: this.ot.ot,
      },
    }
  },
  methods: {
    ...mapActions('Diagnosticos', ['createDiagnoseOT', 'updateDiagnoseOT']),
    ...mapActions('OTStore', ['getOT']),
    hideModal() {
      this.show = false
    },
    async saveDiagnostic() {
      this.loading = true
      if (this.$v.$invalid) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        const action = this.ot.diagnostico_detail
          ? this.updateDiagnoseOT
          : this.createDiagnoseOT
        const response = await action(this.form)
        if (response.status === 200) {
          this.$bvToast.toast('Diagnóstico guardado.', {
            variant: 'success',
          })
          await this.getOT(this.ot.ot)
          this.$emit('changed')
        } else {
          this.$bvToast.toast(
            `Error al guardar el diagnóstico: ${response.data}`,
            {
              variant: 'danger',
            }
          )
        }
        this.show = false
      }
      this.loading = false
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('OTStore', ['statusRequest']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,
  },
}
</script>

<style lang="scss" scoped>
.preview,
.capture {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
img {
  width: 100%;
}
.webcam {
  display: inline-flex;
  justify-content: space-between;
  margin: -8pxl;
}
</style>
