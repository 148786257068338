<template lang="pug">
div
    //- ACCION REVISAR DIAGNÓSTICO
    div
      //- Botón acción
      button.change-button(
        :disabled="disabled"
        v-b-modal.modal-multi-1
        @hide="hideModal()"
        ) Revisar Informe
      //-Modal de revisión
      b-modal.hide-foooter.adm-actions-modal(v-model="show" id="modal-multi-1" :title="`Revisión Informe OT: ${ot.ot}`" size="xl")
        b-row.short-line-height
          b-col
            b-form-group
              label R.M.A
              p {{ ot.rma ? ot.rma : '-'}}
          b-col
            b-form-group
              label Fecha de compra
              p {{ formatFechaCompra }}
          b-col
            b-form-group
              label Fecha de envío correo
              p {{ formatFechaEnvioCorreo }}
          b-col
            b-form-group
              label N° de serie
              p {{ ot.equipo.serial_number }}
        b-row.short-line-height
          b-col
            b-form-group(v-if="ot.cliente.tipo_cliente === 'Empresa'")
              label Cliente
              p {{ ot.cliente.empresa.razon_social }}
            b-form-group(v-else)
              label Cliente
              p {{ ot.cliente.persona.nombre+' '+ot.cliente.persona.apellido}}
          b-col
            b-form-group
              label Sucursal
              p {{ ot.sucursal ? ot.sucursal.nombre : ot.tienda_empresa ? ot.tienda_empresa.razon_social : '-' }}
          b-col
            b-button(variant="link" style="margin-left: -12px;" @click="downloadVoucher()") Descargar boleta
          b-col
        hr
        b-row.short-line-height
          b-col
            b-form-group
              label(for="informe-text-area") Diagnóstico
              b-form-textarea(
                id="informe-text-area"
                v-model="informeForm.informe"
                rows=7
              )
        b-row
          b-col
            b-form-group
              label(for="solucion-text-area") Solución
              b-form-textarea(
                id="solucion-text-area"
                v-model="informeForm.solucion"
                rows=7
              )
        b-alert(:show="ot.exige_cambio_3x3" variant="warning") Cliente solicitó 3x3
        template.botones(#modal-footer='{ ok, cancel, hide }')
          //- b-button.mr-auto(v-if="canSendEmail" size='sm' variant='success' @click='saveDiagnostic({ sendEmail: true, hide: false })') Guardar y enviar
          //- b-button(size='sm' variant='success' @click='saveDiagnostic({ sendEmail: false, hide: false })') Guardar sin enviar
          b-button(v-if="canSendEmail" size='sm' variant='success' @click='saveDiagnostic({ hide: true })' :disabled="loading") Guardar, enviar y cerrar
          ActionCambio3x3(
            v-if="ot.tipo_servicio.id !== budgetType"
            :clase="''"
            :ot="ot"
            :disabled="disabled"
            @changed="$emit('changed')"
          )
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import ActionCambio3x3 from './ActionCambio3x3.vue'

export default {
  name: 'ActionRevisarInforme',
  props: ['ot', 'disabled'],
  components: {
    ActionCambio3x3,
  },
  async created() {
    await this.getOT(this.ot.ot)
    this.saved = false
  },
  data() {
    return {
      show: false,
      saved: false,
      loading: false,
      informeForm: {
        informe: this.ot.diagnostico_detail
          ? this.ot.diagnostico_detail?.informe
          : '',
        solucion: this.ot.diagnostico_detail?.solucion
          || this.ot.diagnostico_detail?.comentario_rechazo
          || '',
      },
      canSendEmail: true,
      budgetType: 4 // tipo de servicio
    }
  },
  watch: {
    ot() {
      if (this.ot.diagnostico_detail) {
        this.informeForm.informe = this.ot.diagnostico_detail?.informe || ''
        this.informeForm.solucion = this.ot.diagnostico_detail?.solucion || this.ot.diagnostico_detail?.comentario_rechazo || ''
      }
    },
  },
  computed: {
    ...mapGetters('OTStore', ['files']),
    ...mapGetters('AuthenticationStore', ['user']),
    formatFechaCompra() {
      let date = null
      if (this.ot.fecha_de_compra) {
        date = moment(this.ot.fecha_de_compra).format('DD-MM-YYYY')
      } else {
        date = '-'
      }
      return date
    },
    formatFechaEnvioCorreo() {
      let date = null
      if (this.ot.diagnostico_detail?.fecha_envio_correo_cliente) {
        date = moment(
          this.ot.diagnostico_detail?.fecha_envio_correo_cliente
        ).format('DD-MM-YYYY')
      } else {
        date = '-'
      }
      return date
    },
    getBoleta() {
      let boleta = this.files.filter((b) => b.name.includes('boleta'))
      if (boleta.length > 0) {
        boleta = boleta[0] // eslint-disable-line
      } else {
        boleta = null
      }
      return boleta
    },
  },
  methods: {
    ...mapActions('Diagnosticos', ['updateDiagnoseOT', 'generatePdf']),
    ...mapActions('OTStore', [
      'getOT',
      'getFiles',
      'downloadFile',
      'sendChangeClientEmail',
      'createStore',
      'downloadPdfCambio',
    ]),
    ...mapActions('Warehouse', ['changeWarehouseState']),
    ...mapActions('Exceptions', ['createExcepcion']),
    hideModal() {
      this.state = null
      this.show = false
    },
    async downloadVoucher() {
      await this.getFiles(this.ot.ot)
      if (this.getBoleta) {
        const payload = {
          fileName: this.getBoleta.name,
          fileType: this.getBoleta.file_type,
          fileId: this.getBoleta.file_id,
        }
        await this.downloadFile(payload).catch((error) =>
          this.$bvToast.error(error)
        )
      } else {
        this.$bvToast.toast('La OT no tiene boleta almacenada', {
          variant: 'danger',
        })
      }
    },
    async saveDiagnostic({ hide }) {
      this.loading = true
      const data = this.ot.diagnostico_detail
      data.revisado = true
      data.solucion = this.informeForm.solucion
      data.informe = this.informeForm.informe
      data.usuario_revisor = this.user.user.id
      const resp = await this.updateDiagnoseOT({
        ...data,
        id: this.ot.diagnostico_detail?.id,
        email: true,
      })
      if (resp.status === 200) {
        this.$bvToast.toast(resp.data.mensaje, {
          variant: 'success',
        })
        await this.generatePdfOT()
        this.saved = true
        // if (sendEmail) {
        //   await this.sendClientEmail();
        // }
        // this.show = false;
        if (hide) {
          this.show = false
          this.$emit('changed')
        }
        await this.getOT(this.ot.ot)
        this.show = false
      } else {
        this.$bvToast.toast(
          `Hubo un error al guardar la revisión del informe: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
    async generatePdfOT() {
      this.disabled = true
      const resp = await this.generatePdf(this.ot.diagnostico_detail?.id)
      if (resp.status === 200) {
        this.$bvToast.toast('PDF Generado correctamente', {
          variant: 'success',
        })
      } else {
        this.$bvToast.toast(
          `Hubo un error al generar el archivo pdf: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
    },
    // async sendClientEmail() {
    //   const res = await this.sendEmailClient(this.ot.ot);
    //   if (res.status === 200) {
    //     this.$bvToast.toast(res.data, { variant: 'success' });
    //   } else {
    //     this.$bvToast.toast(res.data, { variant: 'danger' });
    //   }
    // },
    async changeStatusOT() {
      const payload = {
        state: 'Informe Revisado',
        orden: this.ot.ot,
        user: this.user.user.id,
      }
      await this.changeWarehouseState(payload) // ¿Por qué esto se llama warehouse si cambia el estado de la OT?
    },
  },
}
</script>
