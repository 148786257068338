import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const Quotations = {
  namespaced: true,
  state: {
    quotations: {},
    currentQuotations: {},
    currentStates: [],
    page: 1,
    isSearched: false,
    isCreated: null,
    status: null,
  },
  actions,
  getters,
  mutations,
}

export default Quotations
