import axios from '../../plugins/axios'

export const listParts = (_, { page, search }) => {
  //eslint-disable-line
  const apiAddress = `api/bodega/partes?page=${page}&search=${search}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response)
    })
}

export const retrievePart = ({ commit }, { pk }) => {
  //eslint-disable-line
  const apiAddress = `api/bodega/partes/${pk}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_PART', { part: response.data })
  })
}

export const removePart = (_, { pk }) => {
  //eslint-disable-line
  const apiAddress = `api/bodega/partes/${pk}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .delete(apiAddress, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const createPart = (_, data) => {
  const apiAddress = 'api/bodega/partes'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const patchPart = (_, data) => {
  console.log(data)
  const apiAddress = `api/bodega/partes/${data.id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .patch(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const savePartsExcel = (_, data) => {
  const apiAddress = 'api/bodega/upload_parts'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getPartsExcel = () => {
  const apiAddress = '/api/bodega/generar_excel_partes';
  const { key } = JSON.parse(localStorage.getItem('user'));
  const config = {
    headers: { Authorization: `Token ${key}` },
    responseType: 'blob',
  };
  return axios.get(apiAddress, config).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data], {
      type: 'application/ms-excel',
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ordenes-por-fecha.xls');
    document.body.appendChild(link);
    link.click();
  });
};

export const listPartsModels = (_, { idModelo }) => {
  const apiAddress = `api/bodega/list_partes_modelos/${idModelo}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error.response)
    })
}

export const changePart = (_, data) => {
  const apiAddress = 'api/bodega/cambiar_pieza_solicitada'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
};
