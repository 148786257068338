<template lang="pug">
div
  b-row
    b-col( cols="12" md="6" )
      b-form-group(label-for="input-nombre" )
        template( #label ) Nombre Cliente
        b-form-input(
          id="input-nombre"
          v-model.trim="$v.form.nombre.$model"
          :state="validateState('nombre')"
          :disabled="disabled"
          @blur="setTitleCase('nombre')"
        )
        b-form-invalid-feedback Campo requerido

    b-col( cols="12" md="6" )
      b-form-group( label-for="input-apellido" )
        template( #label ) Apellido Cliente
        b-form-input(
          id="input-apellido"
          v-model.trim="$v.form.apellido.$model"
          :state="validateState('apellido')"
          :disabled="disabled"
          @blur="setTitleCase('apellido')"
        )
        b-form-invalid-feedback Campo requerido

  b-row
    b-col( cols="12" md="4" )
      b-form-group( label-for="input-region" )
        template( #label ) Region
        b-form-select(
          id="input-region"
          v-model="$v.form.region.$model"
          :options="regionOptions"
          :state="validateState('region')"
          :disabled="disabled || loadingRegions" 
        )
        b-form-invalid-feedback Campo requerido

    b-col( cols="12" md="4" )
      b-form-group( label-for="input-comuna" )
        template( #label ) Comuna
        b-form-select(
          id="input-comuna"
          v-model="$v.form.comuna.$model"
          :options="comunaOptions || []"
          :state="validateState('comuna')"
          :disabled="disabled || !form.region"
        )
        b-form-invalid-feedback Campo requerido

    b-col( cols="12" md="4" )
      b-form-group( label-for="input-direccion" )
        template( #label ) Dirección
        b-form-input(
          id="input-direccion"
          v-model="$v.form.direccion.$model"
          :state="validateState('direccion')"
          :disabled="disabled"
        )
        b-form-invalid-feedback Campo requerido

  b-row
    b-col( cols="12" md="6" )
      b-form-group( label-for="input-telefono" )
        template( #label ) Teléfono
        b-form-input(
          id="input-telefono"
          v-model="$v.form.telefono.$model"
          :state="validateState('telefono')"
          :disabled="disabled"
          maxLength="9"
        )
        b-form-invalid-feedback( v-if="!$v.form.telefono.required" )
          | Campo requerido
        b-form-invalid-feedback( v-if="!$v.form.telefono.numeric" )
          | Solo puedes ingresar numeros
        b-form-invalid-feedback( v-if="!$v.form.telefono.minLength" )
          | Debes ingresar 9 digitos

    b-col( cols="12" md="6" )
      b-form-group( label-for="input-email" )
        template( #label ) Email
        b-form-input(
          id="input-email"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
          :disabled="disabled"
        )
        b-form-invalid-feedback( v-if="!$v.form.email.required" )
          | Campo requerido
        b-form-invalid-feedback( v-if="!$v.form.email.email" )
          | No es una dirección de correo válida

  b-row
    b-col( cols="12" md="6" )
      b-form-group( label-for="input-contacto-telefono" )
        template( #label ) Contacto Teléfono
        b-form-input(
          id="input-contacto-telefono"
          v-model="$v.form.contacto_telefono.$model"
          :disabled="disabled"
          maxLength="9"
        )

    b-col( cols="12" md="6" )
      b-form-group( label-for="input-contacto-nombre" )
        template( #label ) Contacto Nombre
        b-form-input(
          id="input-contacto-nombre"
          v-model="$v.form.contacto_nombre.$model"
          :disabled="disabled"
        )
</template>

<script>
import { required, numeric, minLength, email } from 'vuelidate/lib/validators'
import titleCase from '@/utils/titleCase'
import { mapGetters } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: Object,
  },
  data() {
    return {
      regionOptions: [],
      loadingRegions: true,
      // form: {
      //   nombre: null,
      //   apellido: null,
      //   region: null,
      //   comuna: null,
      //   direccion: null,
      //   telefono: null,
      //   email: null,
      //   contacto_telefono: null,
      //   contacto_nombre: null,
      // },
    }
  },
  validations: {
    form: {
      nombre: { required },
      apellido: { required },
      region: { required },
      comuna: { required },
      direccion: { required },
      telefono: {
        required,
        numeric,
        minLength: minLength(8),
      },
      email: { required, email },
      contacto_telefono: {},
      contacto_nombre: {},
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },

    comunaOptions() {
      if (this.form.region) {
        const region = this.regiones.find(
          (c) => c.region === this.form.region
        )
        if (region && region.comunas) {
          return region.comunas.map((c) => ({
            value: c,
            text: c,
          }))
        }
      }
      return [{ value: null, text: 'Selecciona una comuna' }]
    },
    ...mapGetters('ClientStore', ['regiones']),
  },
  watch: {
  'form.region': function (newVal, oldVal) {
    if (newVal !== oldVal) {
      this.form.comuna = null;
    }
  }
},

  methods: {
    validateState(field) {
      const { $dirty, $invalid } = this.$v.form[field]
      return $dirty ? !$invalid : null
    },
    resetForm() {
      this.form = {}
      this.$nextTick(() => this.$v.$reset())
    },
    validate() {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    setTitleCase(field) {
      this.$v.form[field].$model = titleCase(this.$v.form[field].$model)
    },
  },
  created() {
    try {
      this.regionOptions = this.regiones.map((r) => ({
        value: r.region,
        text: r.region,
      }))
    } catch (error) {
      console.error('Error al cargar las regiones', error)
    } finally {
      this.loadingRegions = false 
    }
  },
}
</script>

<style lang="scss" scoped>
.form-group::v-deep label {
  color: #74788d;
}
</style>
