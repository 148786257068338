<template lang="pug">
form(@submit="onSubmit")
  .subtitle
    h4 Bienvenido a Sill Laboratorio
    p Por favor inicia sesión para continuar
  small.text-danger(
    v-if="errors.invalidCredentials"
    ) Correo o contraseña inválido
  .form-group
    .icon-container
      b-icon(icon="envelope")
    input.form-control(
      type="email"
      :value="form.email"
      @input="(e)=>{form.email=e.target.value}"
      autocomplete
      required
      placeholder="Correo")
  .form-group
    .icon-container
      b-icon(icon="lock")
    input.form-control(
      type="password"
      :value="form.password"
      @input="(e)=>{form.password=e.target.value}"
      autocomplete required placeholder="Password"
      )
  center
    //- p
    //-   router-link(:to="{ name: 'Recover password' }") ¿Olvidaste tu contraseña?
  .w-100.d-inline-flex.flex-column.align-items-center
    b-button.boton(type="submit")
      | Iniciar sesión
      b-spinner(v-if="loading" small)

    router-link.mt-4(:to="{ name: 'Registro Retail' }")  Registro usuarios retail
</template>

<script>
import { mapActions } from 'vuex'
import initializeFirebase from '../../shared/firebase'

export default {
  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
      },
      errors: {
        invalidCredentials: false,
      },
    }
  },
  created() {
    // eslint-disable-next-line
    if (location.host.toString() === 'clientes.sill.cl') {
      this.$router.push('/cliente')
    }
  },
  methods: {
    ...mapActions('AuthenticationStore', ['registerFCMDevice']),
    ...mapActions('AuthenticationStore', ['login']),
    async initFirebase () {
      const token = await initializeFirebase()
      if (token) {
        this.registerFCMDevice({ device_token: token })
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      this.loading = true
      const response = await this.login(this.form)
      this.loading = false
      if (!response) {
        this.errors.invalidCredentials = true
      }
      this.initFirebase()
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';
</style>
