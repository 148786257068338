<template lang="pug">
Fragment
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Movimientos Manuales
  ManualMovementList
</template>

<script>
import ManualMovementList from '../../components/Inventory/ManualMovementList.vue'
import BackArrow from '../../components/UI/BackArrow.vue'

export default {
  name: 'Inventario',
  components: {
    ManualMovementList,
    BackArrow,
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
