import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const OTStore = {
  namespaced: true,
  state: {
    ot: null,
    ots: [],
    isSearchedOT: [],
    itSearchedOT: false,
    statusRequest: null,
    excelResponse: null,
    files: [],
    uploadingFile: '',
    printNoDisplay: false,
    couriers: [],
    distribuidores: [],
    storeList: [],
  },
  getters,
  mutations,
  actions,
}

export default OTStore
