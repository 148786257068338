var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"pills":"","card":"","lazy":""}},[_vm._l((_vm.tabs),function(tab,index){return _c('b-tab',{attrs:{"title":("" + (tab.title || tab.states[0])),"title-item-class":"title-item"},on:{"click":function($event){return _vm.handleTabChange(tab)}}},[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.tableData,"head-row-variant":"light-primary","thead-class":"text-black tabla-thead","tbody-class":"tabla-tbody","tbody-tr-class":"bg-white","responsive":"","busy":_vm.loading,"show-empty":"","empty-text":"No hay cotizaciones para mostrar"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('b-button',{staticClass:"p-0 m-0",attrs:{"variant":"text","size":"sm"},on:{"click":function($event){return _vm.handleView(item)}}},[_vm._v(_vm._s(value))])]}},{key:"cell(created_at)",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatedDate")(value)))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.state !== 'ANSWERED')?_c('b-button',{attrs:{"size":"sm","variant":"button"},on:{"click":function($event){return _vm.handleAnswer(item)}}},[_vm._v("Responder")]):_vm._e()]}}],null,true)})],1)}),_c('UploadQuoteModal',{attrs:{"quote":_vm.selectedQuote},on:{"updated":_vm.getExternalQuotes},model:{value:(_vm.showUploadQuoteModal),callback:function ($$v) {_vm.showUploadQuoteModal=$$v},expression:"showUploadQuoteModal"}}),_c('b-modal',{attrs:{"size":"xl","ok-only":""},model:{value:(_vm.showQuoteDetailModal),callback:function ($$v) {_vm.showQuoteDetailModal=$$v},expression:"showQuoteDetailModal"}},[_c('QuoteSummary',{staticClass:"mx-2",attrs:{"quote":_vm.selectedQuote}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }