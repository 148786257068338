import Vue from 'vue'

const mutations = {
  SET_OTS(state, { OTS }) {
    state.OTS = OTS
  },
  SET_CURRENT_OTS(state, { ots, states }) {
    state.currentStates = states
    state.currentOts = ots
  },
  SET_NOTIFICATIONS_OTS(state, { ots }) {
    state.notificationsOTS = ots
  },

  /** Actualiza una OT de la página actual del listado */
  UPDATE_CURRENT_OT(state, { index, ot }) {
    Vue.set(state.currentOts.results, index, ot)
  },

  /** Agrega una OT al comienzo de la página actual del listado */
  ADD_CURRENT_OT(state, { ot }) {
    state.currentOts.results.unshift(ot)
  },

  /** Elimina una OT de la página actual del listado */
  REMOVE_CURRENT_OT(state, { ot }) {
    const { results } = state.currentOts
    const index = results.findIndex((o) => o.ot === ot.ot)
    if (index === -1) return

    Vue.delete(results, index)
  },

  CHANGE_ESTADO_COUNT(state, { estado, diff }) {
    const { estados } = state.currentOts
    const count = estados[estado] + diff
    Vue.set(estados, estado, count)
  },

  SET_ENTERED_OTS(state, { enteredOTs }) {
    state.enteredOTs = enteredOTs
  },
  SET_ASSIGNED_OTS(state, { assignedOTs }) {
    state.assignedOTs = assignedOTs
  },
  SET_IN_REVISION_OTS(state, { inRevisionOTs }) {
    console.log('Revision', inRevisionOTs)
    state.inRevisionOTs = inRevisionOTs
  },
  SET_DIAGNOSTICATED_OTS(state, { diagnosticatedOTs }) {
    console.log('diagnostico', diagnosticatedOTs)
    state.diagnosticatedOTs = diagnosticatedOTs
  },
  SET_WAITING_PARTS_OTS(state, { waitingPartsOTs }) {
    state.waitingPartsOTs = waitingPartsOTs
  },
  SET_IN_REPAIRING_OTS(state, { inRepairingOTs }) {
    state.inRepairingOTs = inRepairingOTs
  },
  SET_WAITING_BUDGET_APPROVED(state, { waitingBudgetApprovedOTs }) {
    state.waitingBudgetApprovedOTs = waitingBudgetApprovedOTs
  },
  SET_OT(state, { OT }) {
    state.OT = OT
  },
  SELECT(state) {
    state.isSelected = !state.isSelected
  },
  SET_OTFORM(state, { OTFORM }) {
    state.OTFORM.rma = { ...OTFORM.rma } || null
    state.OTFORM.codigo_retail = { ...OTFORM.codigo_retail } || null
    state.OTFORM.observaciones = { ...OTFORM.observaciones } || null
    state.OTFORM.reporte_falla_cliente =
      { ...OTFORM.reporte_falla_cliente } || null
    state.OTFORM.otros_accesorios = { ...OTFORM.otros_accesorios } || null
  },
  SET_IS_SEARCHED(state, isSearched) {
    state.isSearched = isSearched
  },
  SET_STATES(state, userStates) {
    state.userStates = userStates
  },
  SET_IS_SEARCHING(state, isSearching) {
    state.isSearching = isSearching
  },
  SET_DEFAULT_STATES(state, states) {
    state.defaultStates = states
  },
}
export default mutations
