import axios from '../../plugins/axios'

export const createDiagnoseOT = ({}, data) => {
  // eslint-disable-line
  const apiAddress = '/api/diagnostico/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const updateDiagnoseOT = ({ commit }, data) => {
  // eslint-disable-line
  let apiAddress = `/api/diagnostico/${data.id}`
  console.log(data.email)
  if (data.email) {
    apiAddress += '?email=true'
  }
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      const diagnostic = response.data
      commit('SET_DIAGNOSTIC', { diagnostic })
      return response
    })
    .catch((error) => error.response)
}

// eslint-disable-next-line
export const generatePdf = ({ commit }, id) => {
  const url = `/api/diagnostico/cliente_pdf/${id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(url, '', config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const getLastDiagnostic = ({ commit }, id) => {
  //eslint-disable-line
  if (!id) {
    commit('SET_DIAGNOSTIC', { diagnostic: null })
    return
  }
  const url = `/api/diagnostico/${id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(url, config)
    .then((response) => {
      const diagnostic = response.data
      commit('SET_DIAGNOSTIC', { diagnostic })
    })
    .catch((error) => error.response)
}

export const showDiagnosticForm = ({ commit }, _showDiagnosticForm) => {
  //eslint-disable-line
  commit('SHOW_DIAGNOSTIC_FORM', { _showDiagnosticForm })
}

export const askParts = ({}, { otId, parts }) => {
  //   const apiAddress = '/api/bodega/partes/solicitud';
  const apiAddress = `/api/orden/${otId}/transiciones/solicitar_partes`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, parts, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const cancelPart = ({}, { otId, parte_solicitada_id }) => {
  const apiAddress = `/api/orden/${otId}/transiciones/cancelar_solicitud_parte`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const data = {
    parte_solicitada_id
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const updateRequests = ({}, data) => {
  const apiAddress = '/api/bodega/partes/solicitud'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const searchByPartNumber = ({}, { partNumber, shortQuery = false }) => {
  let apiAddress = `api/bodega/partes?search=${partNumber}`
  if (shortQuery) {
    apiAddress += '&short_query=true'
  }
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => response)
}

export const searchPartsByState = ({ commit }, { state }) => {
  const apiAddress = `api/bodega/partes/list?state=${state}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    const filteredParts = response.data
    commit('SET_FILTERED_PARTS', { filteredParts })
  })
}
