<template lang="pug">
div.dropdown
    span(id="bell-dropdown-top-right").icon-dropdown.mr-3
      b-icon.h4.mt-1.bell-icon(
        id="popover-1-bottomleft", @click="getNotifications" :class="{'shake' : animated}", href="#", style="cursor: pointer;", tabindex="0", variant="info" icon="bell-fill")
    div(
      v-if="newOT > 0"
    ).popover-notifications.d-flex.align-items-center.justify-content-center {{newOT}}
    b-popover.popover(target="popover-1-bottomleft" triggers="focus" placement="auto")
      ul(@scroll="handleScroll").dropdown-content.scroll
        li.title-notifications.d-flex.align-items-center.dropdown-item
          .d-flex
            span.mr-4.font-notification Notificaciones
        hr.dropdown-divider
        li(v-if="notificationsOTS" v-for="otNotification in OTList").dropdown-item
          .notification-item.separator-notification-item
            .d-flex.row.ml-1
              b-tooltip(target="button-1"  title="Marcar como no leido" placement="topright" )
              span.bold OT {{ otNotification.ot.ot }}:
              router-link(:to="`/orden/${otNotification.ot.ot}`" target="_blank")
                span.orden.ml-1 {{ otNotification.action[1] }}
            span.fecha {{ formatedDate(otNotification.ot.estado.fecha_hora) }}
        li(v-if="loading").dropdown-item
          b-skeleton(type="text" animation="fade" width="100%")
          b-skeleton(type="text" animation="fade" width="100%")
          b-skeleton(type="text" animation="fade" width="20%")
          hr
</template>
<script>
import { io } from 'socket.io-client'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { showButton } from '@/utils/actionsConditions'
import settings from '@/../settings'

export default {
  data() {
    return {
      animated: true,
      newOT: 0,
      actions: [
        ['Diagnosticar', 'Diagnosticar'],
        ['RevisarInforme', 'Revisar Informe'],
        ['EnviarCorreoCambio', 'Enviar correo de cambio'],
        ['EsperaDeRetiro', 'Espera de retiro'],
        ['EditRMA', 'Editar RMA'],
        ['SelectTechnician', 'Selección técnico'],
        ['PrimerIngresoABodega', 'Primer ingreso a bodega'],
        ['EntregarTecnico', 'Entregar a tecnico'],
        ['IngresarABodegaRechazado', 'Ingresar a bodega rechazado'],
        [
          'IngresarABodegaPorEsperaDePartes',
          'Ingresar A BodegaPorEsperaDePartes',
        ],
        [
          'EntregarEquipoRepuestoLocalizado',
          'Entregar equipo repuesto localizado',
        ],
        ['IngresarABodegaReparado', 'Ingresar a bodega reparado'],
        ['DarSalida', 'Dar salida'],
        ['Rechazar', 'Rechazar'],
        ['IngresarReparacion', 'Ingresar reparacion'],
        ['SolicitarPartes', 'Solicitar partes'],
        ['LlegadaDePartes', 'Llegada de partes'],
        ['CompletPreIngreso', 'Completar preingreso'],
        ['InternalQuotation', 'Cotización interna'],
        ['Reingreso', 'Reingreso'],
        ['EntregarRecepcion', 'Entregar a recepcion'],
        ['AddBoleta', 'Añadir boleta'],
        ['Cambio3x3', 'Cambio 3x3'],
      ],
      OTList: [],
      loading: false, 
      currentPage: 1,
    }
  },
  async created() {
    this.initWebsockets()
  },
  methods: {
    ...mapActions('OT', ['listNotificationsPagination', 'searchOT']),
    showButton: (actionName, ot, user) => showButton(actionName, ot, user),
    async handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
        this.loading = true
        this.currentPage += 1
        await this.loadMoreNotifications(this.currentPage)
        this.loading = false
      }
    },
    async loadMoreNotifications(page) {
      this.loading = true
      const res = await this.listNotificationsPagination(page) 
      if (res.status < 200 || res.status >= 300) {
        return
      }
      this.loading = false
      res.data.results.forEach((ot) => {
        if (ot.estado == null) return
        ot.acciones.visible.forEach((action) => {
          const actionObject = this.actions.find((a) => a[0] === action)
          if (!actionObject) return
          this.OTList.push({ ot, action: actionObject })
        })
      })
    },
    formatedDate(date) {
      return moment(date).format('DD/MM/YYYY H:mm')
    },
    async shakeBell() {
      const self = this
      self.animated = true
      setTimeout(() => {
        self.animated = false
      }, 1000)
    },
    async getNotifications() {
      this.newOT = 0
      // Si es la primera vez que entra a las notificaciones
      if(this.currentPage === 1){
      const res = await this.listNotificationsPagination(this.currentPage) 
      if (res.status < 200 || res.status >= 300) {
        return
      }

      this.OTList = []
      res.data.results.forEach((ot) => {
        if (ot.estado == null) return
        ot.acciones.visible.forEach((action) => {
          const actionObject = this.actions.find((a) => a[0] === action)
          if (!actionObject) return
          this.OTList.push({ ot, action: actionObject })
        })
      })
      }
    },
    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)
      this.socket.on('order_added', () => {
        setTimeout(() => {
          this.newOT += 1
          this.shakeBell()
        }, 500)
      })
    },
  },
  computed: {
    ...mapGetters('OT', ['notificationsOTS']),
    ...mapGetters('AuthenticationStore', ['user']),
  },
}
</script>
<style>
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  display: none !important;
}
.shake {
  animation: shake 0.62s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
<style lang="scss" scoped>
.popover,
.b-popover {
  display: none;
  background: transparent !important;
  border: transparent !important;
}
.scroll {
  overflow-y: scroll;
  scroll-margin-top: 10px;
  scroll-margin-bottom: 10px;
}
.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  width: 350px;
  box-shadow: 0px 8px 16px 0px rgba(128, 124, 124, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  top: 2rem;
  z-index: 1;
  left: -160px;
  height: 300px;
  overflow-x: hidden;
  list-style-type: none;
}
.font-notification {
  font-weight: 800 !important;
  color: #74788d;
  font-size: 1.1rem;
}
.all-read {
  font-size: 0.5rem;
}
.title-notifications {
  word-wrap: break-word;
  background-color: #fff;
  width: auto;
  height: 50px;
}
ul {
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.5rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d9e1;
  border-radius: 0.5rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
a {
  color: #5b73e8;
  cursor: pointer;
}
a:hover {
  color: #5b73e8;
}
::v-deep .dropdown-item:active {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.title-notifications:hover {
  background-color: transparent;
}
.bold {
  font-weight: bold !important;
  font-size: 0.8rem;
  cursor: default;
}
.notification-item {
  word-wrap: break-word;
}
.separator-notification-item {
  border-bottom: 1px solid #e9ecef;
}
.orden {
  font-size: 0.78rem;
}
.fecha {
  font-size: 0.6rem;
  position: relative;
  top: -6px;
  right: -4px;
  color: #cbcbcb;
}
.radio_control {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid #efefef;
  outline: none;
  &:checked + .radio__control {
    background: radial-gradient(#efefef 50%, rgba(255, 0, 0, 0) 51%);
  }
  ::v-deep button:after {
    outline: none;
    box-shadow: none;
  }
  .bullet {
    position: relative;
    right: 0.37em;
    display: block;
    width: 0.78em;
    height: 0.78em;
    background-color: #5b73e8;
    border-radius: 50%;
  }
}
.popover-notifications {
  display: block;
  position: absolute;
  width: 25px;
  border: 1px solid #5b73e8;
  border-radius: 40%;
  height: 25px;
  top: -10px;
  z-index: 1;
  left: 20px;
  font-size: 0.7rem;
  background-color: #5b73e8;
  color: white;
}
</style>
