import { defineStore } from 'pinia'
import { useActionLogStore } from '../api/useActionLogStore'
import { useUserStore } from '../api/useUserStore'

// eslint-disable-next-line
const fileDownload = require('js-file-download')

export const useUserReportStore = defineStore('userReport', {
  state: () => ({
    filters: {
      dateStart: null,
      dateEnd: null,
      user: null,
      page: 1,
      ot: null,
    },
    currentTab: null,
    loading: true,
    userRoles: [],
    users: [],
    results: [],
    totalOrders: 0,
    totalActions: 0,
    colorCount: {},
  }),

  getters: {
    hasMultipleRoles: (state) => {
      if (state.userRoles.length === 2) return false
      const totalCount = state.userRoles.find((r) => r.name === 'Todos')?.count || 0
      return state.userRoles.some((r) => r.count !== totalCount)
    },

    queryParams: (state) => {
      const f = state.filters
      const dateStartParam = f.dateStart ? `${f.dateStart}T00:00:00` : null
      const dateEndParam = f.dateEnd ? `${f.dateEnd}T23:59:59` : null
      const role = state.currentTab === 'Todos' ? null : state.currentTab
      return {
        page: f.page,
        created_at__gte: dateStartParam,
        created_at__lte: dateEndParam,
        usuario: f.user,
        usuario__groups__name: role,
        orden_trabajo: f.ot,
      }
    },
  },

  actions: {
    async getUserRoles() {
      const actionLog = useActionLogStore()
      const res = await actionLog.getActionsByRole()

      if (res.status !== 200) return

      this.userRoles = res.data
      this.currentTab = 'Todos'
    },

    async getActionLogs() {
      this.loading = true
      const actionLog = useActionLogStore()
      const res = await actionLog.listActionLogs(this.queryParams)

      if (res.status !== 200) return

      this.results = res.data.results
      this.totalOrders = res.data.ot_count
      this.totalActions = res.data.count
      this.colorCount = res.data.action_count_by_color
      this.loading = false
    },

    async getActionLogsExcel() {
      const actionLog = useActionLogStore()
      const res = await actionLog.exportActionLogs(this.queryParams)

      if (res.status !== 200) return
      fileDownload(res.data, 'reporte-usuarios.xls')
      return true
    },

    async getUsers() {
      const user = useUserStore()
      const res = await user.listUsers()

      if (res.status !== 200) return

      this.users = res.data.map((u) => ({
        value: u.id,
        text: `${u.first_name} ${u.last_name}`,
      }))
    },
  },
})
