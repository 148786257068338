<template lang="pug">
b-container(fluid)
  b-row
    b-col
      .d-flex.flex-row.justify-content-between.w-100
        .flex-row.gap-2
          BackArrow.m-0.mb-n4
          h4.title Reportes - Resumen OT
        b-breadcrumb(:items="breadcrumbs")
  b-row.mt-4
    b-col
      FilterBar
  b-row.mt-4
    b-col.mb-4(cols="6" v-for="(report, index) in getReports" :key="index")
      ReportList(
        :headerTitle="report.title"
        :keyTable="report.key"
        :fields="report.fields"
        :items="report.items"
      )
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mapActions as mapActionsVuex } from 'vuex'
import BackArrow from '@/components/UI/BackArrow.vue'
import FilterBar from '@/components/Reports/SummaryReport/FilterBar.vue'
import ReportList from '@/components/Reports/SummaryReport/ReportList.vue'
import { useSummaryReportStore } from '@/pinia/views/useSummaryReportStore'

export default {
  components: {
    BackArrow,
    FilterBar,
    ReportList
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(useSummaryReportStore, [
      'getReports',
      'techniciansParams',
    ]),
    breadcrumbs: () => [
      {
        text: 'Inicio',
        to: { path: '/' },
      },
      {
        text: 'Resumen OT',
        to: { path: '/' },
      }
    ],
  },
  methods: {
    ...mapActions(useSummaryReportStore, [
      'getSummaryReport',
      'initializeDateFilter',
    ]),
    ...mapActionsVuex('TechniciansStore', ['listTechnicians']),
  },
  async created() {
    this.initializeDateFilter()
    await this.listTechnicians({ params: this.techniciansParams })
    await this.getSummaryReport()
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
  margin: 0;
}
</style>
