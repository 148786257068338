<template lang="pug">
div
  Navbar
  .container
    h4.main-header Pre ingreso Clientes Retiro
    b-link( @click="cancelar" ).cancelar Cancelar
    GarantiaFalla(
      :garantiaReturn = "formClient.formGarantia"
      :setDatosGarantia = "setDatosEquipo"
      v-if="step===2"
      :guardarBoleta = "setBoleta"
      :boleta = "formClient.boleta"
      @goBack= "regresar"
    )
    DatosCliente(
      :setDatosProvisorios = "setDatosCliente"
      v-if="step===1"
      :datosReturn = "formClient.datosProvisorios"
    )
    Resumen(
      :datosCliente= "formClient.datosProvisorios"
      :garantiaFalla= "formClient.formGarantia"
      :boleta = "boleta"
      v-show="step===3"
      @finish = "cancelar"
      @goBack= "regresar"
    )
</template>

<script>
import Navbar from '@/components/OT/ClientOT/Navbar/Navbar.vue'
import DatosCliente from './Form/DatosCliente.vue'
import GarantiaFalla from './Form/GarantiaFalla.vue'
import Resumen from './Form/Resumen.vue'

export default {
  name: 'PreIngreso',
  components: {
    DatosCliente,
    GarantiaFalla,
    Resumen,
    Navbar,
  },
  data() {
    return {
      formClient: {
        datosProvisorios: {
          rut: null,
          nombre: null,
          apellido: null,
          email: null,
          telefono: null,
          direccion: null,
          comuna: null,
          region: null,
        },
        formGarantia: {
          tipo_servicio: null,
          reporte_falla_cliente: null,
          fecha_de_compra: null,
        },
      },
      boleta: '',
      step: 1,
    }
  },
  computed: {},
  methods: {
    setDatosCliente(datosProvisorios) {
      this.formClient.datosProvisorios = datosProvisorios
      this.step += 1
    },
    setDatosEquipo(formGarantia) {
      this.formClient.formGarantia = formGarantia
      this.step += 1
    },
    regresar() {
      this.step -= 1
    },
    cancelar() {
      this.formClient = {
        datosProvisorios: {
          rut: null,
          nombre: null,
          apellido: null,
          email: null,
          telefono: null,
          direccion: null,
          comuna: null,
          region: null,
        },
        formGarantia: {
          tipo_servicio: null,
          reporte_falla_cliente: null,
          fecha_de_compra: null,
        },
        boleta: '',
      }
      this.step = 1
    },
    setBoleta(boleta) {
      this.boleta = boleta
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/cliente.scss';

::v-deep.navbar {
  margin-bottom: 30px;
}
::v-deep.navbar-expand {
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep.sesion-item {
  color: #74788d;
  font-size: 15px;
  font-weight: bold;
}
::v-deep.color-icon {
  font-weight: bold;
  color: #5b73e8;
  font-size: 1.2em;
  margin-left: 6px;
}
</style>
