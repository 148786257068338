const mutations = {
  SET_EXCEPTION(state, { exception }) {
    state.exception = exception
  },
  SET_EXCEPTIONS(state, { exceptions }) {
    state.exception = exceptions
  },
  SET_CURRENT_EXCEPTIONS(state, { exceptions, states }) {
    state.currentStates = states
    state.currentExceptions = exceptions
  },
  SET_SHOW_MODAL(state, showModal) {
    state.showModal = showModal
  },
  SET_IS_CREATED(state, isCreated) {
    state.isCreated = isCreated
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_IS_SEARCHED(state, isSearched) {
    state.isSearched = isSearched
  },
}
export default mutations
