<template lang="pug">
div
  b-card.main-table-card
    .filters
      ButtonsFilters
      Filters(@filterData='fillTable($event)', @reload='fetch', :otsFiltered="otsFiltered")

    b-table.main-table(
      :fields='fields'
      :items='filteredItems'
      :busy='loading'
      bordered
      responsive
      foot-clone
    )
      template(#table-busy)
        .text-center.text-danger.my-2
          b-spinner.align-middle

      template(#cell(estado)='{ item }')
        span(:class='`header-fase-${item.fase}`') {{ item.estado }}

      template(
        v-for='field in timeFields',
        v-slot:[`cell(${field.key})`]='{ item, value, index }'
      )
        .flex-col.position-relative
          .d-flex.justify-content-center
            div(tooltip-left="OTs con acciones para realizar" tooltip-left-width="400px")
              b-icon(
                v-if='value.hasActions',
                icon='exclamation',
                variant='secondary',
                font-scale='2'
              )
            CircularIcon.mr-1.cursor-pointer(
              v-for='(color, colorName) in value.colors'
              :key='index + colorName'
              :number='color.length'
              :classCustom='colorName'
              @click.native='showOts(color, item.estado)'
            )
          b-button.view-all-button(
            v-if='showViewAllButton(value)',
            @click='showOts([...value.colors.green, ...value.colors.yellow, ...value.colors.red], item.estado)'
          )
            | Ver todas

      template(#cell(total)='{ item, value, index }')
        .total(@click='showOts(getOtsOfItem(item), item.estado)') {{ value }}

      //- Footer Totales
      template(#foot(estado)="{}")
        span TOTAL
      template(
        v-for="field in timeFields"
        v-slot:[`foot(${field.key})`]="{ column }"
      )
        .total(@click="showOts(getOtsOfRange(column))")
          | {{ columnTotals[column] || 0 }}
      template(#foot(total)="{}")
        span
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { io } from 'socket.io-client'
import Filters from '@/components/Dashboard/Filters.vue'
import ButtonsFilters from '@/components/Dashboard/ButtonsFilters.vue'
import CircularIcon from '@/components/UI/CircularIcon.vue'
import settings from '@/../settings'
import { mapState } from 'pinia'
import { useDashboardStore } from '../../pinia/views/useDashboardStore'

const TIME_RANGES = [
  {
    key: '1_day',
    label: '1 Día',
    condition: (days) => days <= 1,
  },
  {
    key: '2_days',
    label: '2 Días',
    condition: (days) => days === 2,
  },
  {
    key: '3_days',
    label: '3 Días',
    condition: (days) => days === 3,
  },
  {
    key: '4_days',
    label: '4 Días',
    condition: (days) => days === 4,
  },
  {
    key: '5_days',
    label: '5 Días',
    condition: (days) => days === 5,
  },
  {
    key: '6_days',
    label: '6 Días',
    condition: (days) => days === 6,
  },
  {
    key: '7_days',
    label: '7 Días',
    condition: (days) => days === 7,
  },
  {
    key: '8_to_10',
    label: '8 a 10 Días',
    condition: (days) => days >= 8 && days <= 10,
  },
  {
    key: '11_to_15',
    label: '11 a 15 Días',
    condition: (days) => days >= 11 && days <= 15,
  },
  {
    key: '15_plus',
    label: '> 15 Días',
    condition: (days) => days >= 15,
  },
]

export default {
  name: 'MainTable',
  components: { Filters, ButtonsFilters, CircularIcon },

  data() {
    return {
      loading: false,
      items: [],
      filteredItems: [],
      otsFiltered: [],
      columnTotals: {},
    }
  },

  computed: {
    ...mapGetters('OT', ['userStates']),
    ...mapState(useDashboardStore, ['filters', 'queryParams']),
    ...mapState(useDashboardStore, { backgroundLoading: 'loading' }),

    fields: () => [
      {
        key: 'estado',
        label: 'Estado',
        stickyColumn: true,
        thClass: 'col-estado',
      },
      ...TIME_RANGES,
      { key: 'total', label: 'TOTAL', class: 'col-total' },
    ],

    timeFields: () => TIME_RANGES,
  },

  watch: {
    'filters.fase': function (fase) {
      if (!fase) {
        this.filteredItems = this.items
        return
      }
      this.filteredItems = this.items.filter((i) => i.fase === fase)
    },
    filteredItems() {
      this.otsFiltered = []
      this.filteredItems.forEach((fi) => {
        this.getOtsOfItem(fi)
          .map((ot) => ot.ot)
          .forEach((ot) => {
            if (!this.otsFiltered.includes(ot)) {
              this.otsFiltered.push(ot)
            }
          })
      })
    },
  },

  methods: {
    ...mapActions('OT', ['getSillStates', 'getDashboard']),

    showViewAllButton(value) {
      const counts = [value.colors.green.length, value.colors.yellow.length, value.colors.red.length]
      return counts.filter((c) => c > 0).length > 1
    },

    async fetch({ inBackground } = {}) {
      if (inBackground) this.backgroundLoading = true

      if (!inBackground) this.loading = true
      const response = await this.getDashboard(this.queryParams)
      this.fillTable(response.data)
      if (!inBackground) this.loading = false
    },

    getOtsOfItem(item) {
      const ots = []
      const ignoreKeys = ['estado', 'fase', 'total']
      Object.entries(item).forEach(([key, value]) => {
        if (!ignoreKeys.includes(key)) {
          const colors = value?.colors
          // eslint-disable-next-line
          Object.entries(colors).forEach(([_, color]) => {
            color.forEach((col) => {
              if (col && !ots.includes(col)) {
                ots.push(col)
              }
            })
          })
        }
      })
      return ots
    },

    getOtsOfRange(column) {
      const ots = []
      this.items.forEach((item) => {
        const colors = item[column]?.colors
        // eslint-disable-next-line
        Object.entries(colors).forEach(([_, color]) => {
          color.forEach((col) => {
            if (col && !ots.includes(col)) {
              ots.push(col)
            }
          })
        })
      })
      return ots
    },

    /**
     * Distribuye las OTs en estados, rangos y colores correspondientes.
     */
    async fillTable(data) {
      const items = this.getEmptyTable()
      this.columnTotals = TIME_RANGES.reduce((acc, range) => {
        acc[range.key] = 0
        return acc
      }, {})

      data.forEach((ot) => {
        const estado = this.getOtName(ot)
        const days = this.getOtEstadoDays(ot)
        if (days === -1) return

        const indexEstado = items.findIndex((e) => e.estado === estado)
        const range = TIME_RANGES.find((r) => r.condition(days)).key
        const color = this.getOtColor(ot, days)

        if (indexEstado === -1) return

        items[indexEstado][range].colors[color].push(ot)
        items[indexEstado].total += 1
        this.columnTotals[range] += 1

        if (ot.acciones) {
          const { visible, disabled, secondary } = ot.acciones
          if (
            visible.length > 0 &&
            visible.some((a) => !disabled.includes(a) && !secondary.includes(a))
          ) {
            items[indexEstado][range].hasActions = true
          }
        }
      })
      this.items = items.filter((i) => i.total > 0)
      this.filteredItems = this.items
    },

    /**
     * Crea la estructura de la tabla vacía
     */
    getEmptyTable() {
      const estados3x3 = this.userStates
        .filter((e) => !e.nombre.includes('3x3'))
        .map((e) => ({ ...e, nombre: `${e.nombre} 3x3` }))
      const estados = this.userStates.concat(estados3x3)

      return estados.map((e) => {
        const ranges = TIME_RANGES.reduce((acc, range) => {
          acc[range.key] = {
            hasActions: false,
            colors: {
              green: [],
              yellow: [],
              red: [],
            },
          }
          return acc
        }, {})

        return {
          estado: e.nombre,
          fase: e.fase,
          total: 0,
          ...ranges,
        }
      })
    },

    getOtName(ot) {
      return ot.cambio_3x3
        ? `${ot.estado?.estado?.nombre} 3x3`
        : ot.estado?.estado?.nombre
    },

    getOtEstadoDays(ot) {
      if (ot.tiempo_estados?.hora_total == null) return -1
      return Math.ceil(ot.tiempo_estados.hora_total / 86400)
    },

    getOtColor(ot) {
      const { hora_estado_actual, hora_compuesta_actual } = ot.tiempo_estados
      const { hora_alerta_1, hora_alerta_2 } =
        ot.estado_compuesto || ot.estado.estado

      // La hora compuesta se usa en estado En Evaluación y En Reparación
      // y es la suma de todos los estados correspondientes
      const hora_estado = hora_compuesta_actual || hora_estado_actual
      const hours = Math.round(hora_estado / 3600)

      if (hours < hora_alerta_1) return 'green'
      if (hours > hora_alerta_2) return 'red'
      return 'yellow'
    },

    showOts(ots, estado) {
      const ids = ots.map((o) => o.ot)
      const estadoSin3x3 = estado?.includes(' 3x3')
        ? estado.split(' 3x3')[0]
        : estado
      const params = { title: estado, uniqueStates: [estadoSin3x3], ots: ids }
      // En caso de ser sólo una OTS, se enviará directo al detalle de la misma, en caso contrario irá a la lista
      const routePush = params.ots.length === 1 ? `/orden/${params.ots}` : { name: 'ResultadosFiltrosDashboard', query: params };
      this.$router.push(routePush);
    },

    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)

      this.socket.on('orden_trabajo:created', () => {
        this.fetch({ inBackground: true })
      })

      this.socket.on('orden_trabajo:updated', () => {
        this.fetch({ inBackground: true })
      })

      this.socket.on('orden_trabajo:deleted', () => {
        this.fetch({ inBackground: true })
      })
    },
  },

  async created() {
    await this.getSillStates()
    await this.fetch()
    this.initWebsockets()
  },

  destroyed() {
    if (!this.socket) return
    this.socket.disconnect()
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.main-table-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  border-radius: 5px;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: start;
  @include media-breakpoint-down(md) {
    display: block;
  }
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.title {
  color: #74788d;
  text-align: left;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  font-weight: bold;
  opacity: 1;
}

.green {
  background-color: #34c38f;
  color: white;
}

.yellow {
  background-color: #f1b44c;
  color: white;
}

.red {
  background-color: #f46a6a;
  color: white;
}

.header-fase-2 {
  color: #f1b44c;
  font-weight: bold;
}

.header-fase-3 {
  color: #34c38f;
  font-weight: bold;
}

.header-fase-5 {
  color: #0096db;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.total {
  font-weight: bold;
  color: #495057;
  font-size: 20px;
  cursor: pointer;
}

::v-deep .col-estado {
  width: 200px;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    min-width: 120px;
    width: auto;
  }
}

::v-deep .col-total {
  background: var(--light-primary);
}

::v-deep .cicular-icon {
  display: flex;
  justify-content: center;
}

.view-all-button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(calc(100% + 4px));
  z-index: 10;
  white-space: nowrap;
  font-size: 12px;
  padding: 8px 12px;
  line-height: 1;
  opacity: 0.8;
  display: none;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    border: solid transparent;
    border-bottom-color: #5b73e8;
    border-width: 5px;
    margin-left: -5px;
  }
}

.main-table td:hover .view-all-button {
  display: block;
  &:hover::after {
    border-bottom-color: #1148b5;
  }
}
</style>
