export const ACTION_NAMES = {
  diagnosticar: 'Diagnosticar',
  revisarInforme: 'RevisarInforme',
  enviarCorreoCambio: 'EnviarCorreoCambio',
  cambioEsperaDeParte: 'CambioEsperaDeParte',
  esperaDeRetiro: 'EsperaDeRetiro',
  editRMA: 'EditRMA',
  selectTechnician: 'SelectTechnician',
  primerIngresoABodega: 'PrimerIngresoABodega',
  entregarTecnico: 'EntregarTecnico',
  ingresarABodegaRechazado: 'IngresarABodegaRechazado',
  ingresarABodegaPorEsperaDePartes: 'IngresarABodegaPorEsperaDePartes',
  entregarEquipoRepuestoLocalizado: 'EntregarEquipoRepuestoLocalizado',
  ingresarABodegaReparado: 'IngresarABodegaReparado',
  darSalida: 'DarSalida',
  marcarPagoCompleto: 'MarcarPagoCompleto',
  desarmarEquipo: 'DesarmarEquipo',
  rechazar: 'Rechazar',
  ingresarReparacion: 'IngresarReparacion',
  solicitarPartes: 'SolicitarPartes',
  llegadaDePartes: 'LlegadaDePartes',
  completPreIngreso: 'CompletPreIngreso',
  rechazarPreIngreso: 'RechazarPreIngreso',
  recibirEquipo: 'RecibirEquipo',
  internalQuotation: 'InternalQuotation',
  reingreso: 'Reingreso',
  entregarRecepcion: 'EntregarRecepcion',
  addBoleta: 'AddBoleta',
  cambio3x3: 'Cambio3x3',
  devolverBodega: 'DevolverBodega',
  entregarTecnicoSinPartes: 'EntregarTecnicoSinPartes',
}
