<template lang="pug">
#ActionsQuotations
  div(
    :class="squareLayout ? 'square-layout' : 'default-layout'"
    v-if="quotation && quotation.estado_code"
  )
    component.mt-2(
      v-for="act in actions"
      :is="act.component"
      v-if="!loading && showButton(act.name, quotation, user) && ot"
      :quotation="quotation"
      :ot="ot"
      @changed="isChanged()"
    )
      QuotationInfo(:ot="ot", :quotation="quotation")
    b-spinner(v-if="loading")

</template>

<script>
/** @module OTActionsIndex
 * Despliega distintas acciones disponibles para una Presupuesto
 * cada acción es responsable de mostrarse de acuerdo al contexto de los Presupuestos
 */
import { mapGetters, mapActions } from 'vuex'
import { showButton } from '@/utils/actionsQuotationsConditions'
import hasPermission from '@/utils/permissions'
import ActionSolicitar from './Actions/ActionSolicitar.vue'
import ActionMarkPayment from './Actions/ActionMarkPayment.vue'
import ActionRevisar from './Actions/ActionRevisar.vue'
import ActionAddPaymentLink from './Actions/ActionAddPaymentLink.vue'
import QuotationInfo from './QuotationInfo.vue'

export default {
  name: 'OTActionsIndex',
  props: {
    quotation: Object,
    otId: Number,
    squareLayout: Boolean,
    loading: Boolean,
  },
  components: {
    QuotationInfo,
  },
  data() {
    return {
      show: false,
      user_id: null,
      ot: null,
      actions: [
        {
          name: 'Solicitar',
          component: ActionSolicitar,
        },
        {
          name: 'MarcarPago',
          component: ActionMarkPayment,
        },
        {
          name: 'AdjuntarLinkPago',
          component: ActionAddPaymentLink,
        },
        {
          name: 'Revisar',
          component: ActionRevisar,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  async created() {
    this.getOtInfo()
  },
  methods: {
    ...mapActions('Warehouse', ['saveMovement']),
    ...mapActions('OTStore', ['getOT']),
    showButton: (actionName, quotation, user) =>
      showButton(actionName, quotation, user),
    isChanged() {
      console.log('action index emit changed!')
      this.$emit('changed')
    },
    hasRole(roles) {
      return hasPermission({ roles, user: this.user })
    },
    async getOtInfo() {
      const response = await this.getOT(this.otId)
      this.ot = response
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.default-layout {
  max-width: 100%;
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  flex-direction: row;
  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }
}

.square-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &::v-deep .change-button {
    margin-right: 10px !important;
  }
}
</style>

<style lang="scss">
/* ------------------ Estilo no scoped que aplica a todos los botones de acción ----------------- */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
#ActionsQuotations {
  .change-button {
    width: 150px;
    height: 30px;
    background-color: #5b73e8;
    border-radius: 4px;
    border: none;

    margin-right: 15px;
    margin-bottom: 0;
    @include media-breakpoint-up(xl) {
      margin-right: 0;
      margin-bottom: 15px;
    }

    line-height: 1.2;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    &:disabled {
      background-color: #7d93ff;
      cursor: not-allowed;
    }

    &:hover {
      background-color: #253590;
      transition: all 0.15s ease-out;
    }

    &:disabled {
      background-color: #e6e6e6;
    }
  }
}
</style>
