const mutations = {
  SET_QUOTATIONS(state, { quotations }) {
    state.quotations = quotations
  },
  SET_IS_SEARCHED(state, isSearched) {
    state.isSearched = isSearched
  },
  SET_CURRENT_QUOTATIONS(state, { quotations, states }) {
    state.currentQuotations = quotations
    state.currentStates = states
  },
  SET_IS_CREATED(state, isCreated) {
    state.isCreated = isCreated
  },
  SET_STATUS(state, status) {
    state.status = status
  },
}
export default mutations
