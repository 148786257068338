<template lang="pug">
  div
    .d-flex.justify-content-between
      div
        b-icon.h3(icon="arrow-left" @click="$router.go(-1)" variant="secondary" style="margin: 2px 25px 7px 0px; cursor:pointer;")
        h4.title Bodega
      b-breadcrumb.breadcrumb(:items="breadcrumbs")
    RequestedSpareParts
</template>

<script>
import { mapActions } from 'vuex'
import RequestedSpareParts from '@/components/AskParts/RequestedSpareParts.vue'

export default {
  name: 'RequestedPartsView',
  components: { RequestedSpareParts },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { path: '/' },
        },
        {
          text: 'Bodega',
          to: { path: `/orden/${this.$route.params.order_id}` },
        },
        {
          text: `OT ${this.$route.params.order_id}`,
          active: true,
        },
      ],
    }
  },
  async created() {
    await this.getOT(this.$route.params.order_id)
  },
  methods: {
    ...mapActions('OTStore', ['getOT']),
  },
}
</script>

<style lang="scss" scoped>
.title {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
  margin-bottom: 20px;
  font-weight: 600;
}
</style>
