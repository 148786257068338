<template lang="pug">
div
  .ActionCompletPreIngreso
    div
      //- BOTON COMPLETAR PRE-INGRESO
      //-Boton para completar preingreso del cliente, como administrador
    router-link(:to="`/crear-orden-preingreso/${ot.ot}`")
      button.change-button(
        :disabled="disabled"
        )
        | Completar Pre-Ingreso
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ActionCompletPreIngreso',
  props: ['ot', 'disabled'],
  data() {
    return {
      modal: false,
    }
  },
  methods: {
    ...mapActions('OTStore', ['getOT']),
    showModal() {
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
  },
  computed: {},
}
</script>

<style lang="sass" scoped></style>
