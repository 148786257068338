<template lang="pug">
  .sucursales
    b-form-group
      label(for="input-city") {{ label }}

      //- Campo sucursal
      .fila-sucursal
        .w-100
          b-form-select(
              id="input-city"
              v-model="sucursal_id"
              :options="sucursalesOptions"
              :state="state"
              @change="handleSucursalChange"
          )
          b-form-invalid-feedback Campo requerido
        b-button.btn-editar.px-4(
          v-if="sucursal_id && sucursal_id !== -1"
          @click="editModal()" size="sm"
        )
          | Editar
      //- Lista de correos
      .correos.p-2( v-if="sucursal_id && sucursal_id !== -1" )
        b-tag(
          v-for="correo in correos_sucursal"
          no-remove
          variant="primary"
          :title="correo"
        ).my-1.mx-1
        .flex-spacer
      .suc-alert(v-if="ot.ot") La Sucursal seleccionada no se guardará hasta terminar el formulario

    //- Modal crear
    b-modal(
      v-model="showCreateModal"
      title="Crear Sucursal"
      :ok-title="this.editando ? 'Editar' : 'Crear'"
      cancel-title="Cancelar"
      @show="resetModal"
      @hidden="resetModal"
      @hide="handleHide"
      @ok="handleOk"
    )
      b-form( @submit.prevent.stop="onSubmit" ).form-crear
        b-row
          b-col
            b-form-group
              label(class="client-form-inputs" for="nombre") Nombre
              b-form-input(
                id="nombre"
                v-model="createForm.nombre"
                :state="$v.createForm.nombre.$dirty? !$v.createForm.nombre.$invalid : null"
                @input="$v.createForm.nombre.$touch()"
              )
              .errors(v-if="$v.createForm.nombre.$dirty")
                b-form-invalid-feedback(:state="$v.createForm.nombre.required")
                  | Campo requerido
        b-row
          b-col
            b-form-group
              label(class="client-form-inputs" for="direccion") Dirección
              b-form-input(
                id="direccion"
                v-model="createForm.direccion"
                :state="$v.createForm.direccion.$dirty? !$v.createForm.direccion.$invalid : null"
                @input="$v.createForm.direccion.$touch()"
              )
              .errors(v-if="$v.createForm.direccion.$dirty")
                b-form-invalid-feedback(:state="$v.createForm.direccion.required")
                  | Campo requerido
        b-row
          b-col
            b-form-group
              label(for="region").client-form-inputs Región
              b-form-select(
                id="region"
                v-model="createForm.region"
                :options="regionOptions"
                :state="$v.createForm.region.$dirty? !$v.createForm.region.$invalid : null"
              )
              .errors(v-if="$v.createForm.region.$dirty")
                .error(v-if="!$v.createForm.region.required")
                  | Campo requerido
        b-row
          b-col
              b-form-group
                label(class="client-form-inputs" for="input-comuna") Comuna
                b-form-select(
                  id="input-comuna"
                  v-model="createForm.comuna"
                  :options="comunaOptions"
                  :state="$v.createForm.comuna.$dirty? !$v.createForm.comuna.$invalid : null"
                )
                .errors(v-if="$v.createForm.comuna.$dirty")
                  .error(v-if="!$v.createForm.comuna.required")
                    | Campo requerido
        b-row
          b-col
            b-form-group
              label(class="client-form-inputs" for="telefono") Teléfono
              b-form-input(
                id="telefono"
                v-model="$v.createForm.telefono.$model"
                :state="$v.createForm.telefono.$dirty ? !$v.createForm.telefono.$invalid : null"
              )
              b-form-invalid-feedback(v-if="!$v.createForm.telefono.required")
                | Campo requerido
              b-form-invalid-feedback(v-if="!$v.createForm.telefono.numeric")
                | Solo puedes ingresar números
              b-form-invalid-feedback(v-if="!$v.createForm.telefono.minLength || !$v.createForm.telefono.maxLength")
                | Debe ingresar un número de 9 dígitos
          b-col
            b-form-group
              label(class="client-form-inputs" for="input-correo") Correos Electrónicos
              b-form-tags(
                input-id="input-correo"
                v-model="$v.correos_sucursal.$model"
                placeholder="Agregar correos separados por espacio..."
                invalid-tag-text="Dirección de correo inválida: "
                separator=" "
                :tag-validator="validateEmail"
                :state="correosTagState"
              )
              template(#invalid-feedback)
                | Debe ingresar al menos una dirección de correo
        b-row
          b-col
            b-form-group
              label(class="client-form-inputs" for="select-despacho") Despacho
              b-form-select(id="select-despacho", v-model="createForm.despacho", :options="options")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  required,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators/'
import validateEmail from '../../../../utils/validateEmail'


export default {
  name: 'Sucursales',
  props: {
    value: {
      type: Number,
      default: null,
    },
    state: Boolean,
    clientIdOverride: Number,
    label: {
      type: String,
      default: 'Sucursal',
    },
    otProp: {
      type: Object,
      default: () => {},
    },
  },
  validations: {
    correos_sucursal: { required },
    createForm: {
      nombre: { required },
      direccion: { required },
      telefono: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      region: { required },
      comuna: { required },
    }
  },
  data() {
    return {
      showCreateModal: false,
      createForm: this.getEmptyForm(),
      options: [
        { value: 'ENVIO', text: 'Envío' },
        { value: 'RETIRO', text: 'Retiro por el cliente' },
        { value: 'COORD_CLIENTE', text: 'Coordinado por cliente' },
      ],
      correos_sucursal: [],
      emailInvalid: true,
      successfulSubmit: false,
      editando: false,
    }
  },
  computed: {
    ...mapGetters('ClientStore', [
      'client',
      'sucursales',
      'sucursal',
      'errorSucursal',
      'regiones'
    ]),
    ...mapGetters('OTStore', {otStore: 'ot'}),
    clientId() {
      return this.clientIdOverride || this.client?.id
    },
    sucursal_id: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    correosTagState() {
      const { $dirty } = this.$v.correos_sucursal
      return $dirty ? this.correos_sucursal.length > 0 : null
    },
    sucursalesOptions() {
      return this.sucursales
        .map((s) => { // eslint-disable-line
          return {
            value: s.id,
            text: s.nombre,
          }
        })
        .concat([
          {
            value: null,
            text: 'Seleccione...',
          },
        ])
        .concat([
          {
            value: -1,
            text: 'Crear...',
          },
        ])
    },
    regionOptions() {
      return this.regiones.map((region) => ({
        value: region.region,
        text: region.region,
      }))
    },
    comunaOptions() {
      if (this.createForm.region) {
        const region = this.regiones.find(
          (c) => c.region === this.createForm.region
        )
        if (region && region.comunas) {
          return region.comunas.map((c) => ({
            value: c,
            text: c,
          }))
        }
      }
      return [
        {
          value: null,
          text: 'Seleciona una region',
        },
      ]
    },
    ot() {
      return this.otStore || this.otProp
    },
  },
  methods: {
    validateEmail,
    ...mapActions('ClientStore', [
      'getSucursales',
      'createSucursal',
      'editSucursal',
      'createSucursalNoStore',
    ]),
    ...mapMutations('ClientStore', ['SET_SUCURSAL']),
    async fetchSucursales() {
      const { clientId } = this
      if (clientId) {
        await this.getSucursales({ clientId })
        if (this.ot) {
          this.handleSucursalChange(this.ot.sucursal?.id || this.sucursal)
        }
      }
    },
    handleSucursalChange(value) {
      if (value === -1) {
        this.showCreateModal = true
        this.createForm = this.getEmptyForm()
        this.createForm.despacho = 'ENVIO'
        this.createForm.cliente_empresa = this.clientId
        this.correos_sucursal = []
      } else {
        const found = this.sucursales.find((s) => s.id === value)
        if (found) this.SET_SUCURSAL({ sucursal: found })
      }
    },
    editModal() {
      this.editando = true
      this.fillForm()
      this.showCreateModal = true
    },
    openSucursalProvisory(datosProvisorios) {
      this.showCreateModal = true
      this.createForm.nombre = datosProvisorios.nombre_sucursal;
      this.createForm.telefono = datosProvisorios.telefono;
      this.createForm.direccion = datosProvisorios.direccion;
      this.createForm.region = datosProvisorios.region;
      this.createForm.comuna = datosProvisorios.comuna;
      this.correos_sucursal = [datosProvisorios.email]
      this.createForm.cliente_empresa = this.clientId
    },
    handleOk(event) {
      event.preventDefault()
      this.onSubmit()
    },
    async onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        return
      }

      this.createForm.email = JSON.stringify(this.correos_sucursal)
      this.loading = true

      if (this.sucursal_id !== -1 && this.sucursal_id !== null) {
        const data = {
          cliente_empresa: this.createForm.cliente_empresa,
          sucursal_id: this.sucursal_id,
          data: this.createForm,
        }
        await this.editSucursal(data)
        if (this.errorSucursal) {
          return this.$bvToast.toast('Error al guardar', {
            variant: 'danger',
          })
        }
        this.$bvToast.toast('Sucursal editada exitosamente', {
          variant: 'success',
        })
        this.successfulSubmit = true
        this.showCreateModal = false

        await this.getSucursales({ clientId: this.clientId })
        const found = this.sucursales.find((s) => s.id === this.sucursal.id)
        if (found) this.SET_SUCURSAL({ sucursal: found })
        return
      }
      try {
        const action = this.isModalMode ? this.createSucursalNoStore : this.createSucursal
        await action(this.createForm)
        this.$bvToast.toast('Sucursal guardada exitosamente', {
          variant: 'success',
        })
        await this.getSucursales({ clientId: this.clientId })
        const found = this.sucursales.find((s) => s.id === this.sucursal.id)
        if (found) this.SET_SUCURSAL({ sucursal: found })
        this.successfulSubmit = true
        this.showCreateModal = false
        return
      } catch (e) {
        this.$bvToast.toast(`Error al guardar: ${e}`, {
          variant: 'danger',
        })
      }
    },
    handleHide(event) {
      if (event.trigger === 'ok') {
        event.preventDefault()
        return
      }
      if (!this.successfulSubmit) {
        this.editando = false
        this.$emit('modal-closed')
      }
      this.resetModal()
    },
    resetModal() {
      this.$v.$reset()
      this.successfulSubmit = false
    },
    getEmptyForm() {
      return {
        cliente_empresa: this.clientId || null,
        nombre: null,
        email: null,
        telefono: null,
        direccion: null,
        comuna: null,
        region: null,
        despacho: 'ENVIO',
      }
    },
    fillForm() {
      if (this.sucursal) {
        this.sucursal_id = this.sucursal.id
        this.correos_sucursal = JSON.parse(this.sucursal?.email || '[]')
        this.createForm = this.sucursal
      } else {
        this.sucursal_id = null
        this.createForm = {}
      }
    },
  },
  async created() {
    await this.fetchSucursales()
  },
  watch: {
    client() {
      // this.fetchSucursales()
      if (this.ot && this.ot.sucursal) {
        const found = this.sucursales.find((s) => s.id === this.ot.sucursal.id)
        if (found) this.SET_SUCURSAL({ sucursal: found })
      }
    },
    async sucursal() {
      this.fillForm()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.fila-sucursal {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.btn-editar {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.correos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 0.25rem;
}

.flex-spacer {
  flex-grow: 1;
}

.form-crear::v-deep {
  label {
    color: var(--primary);
  }
}
.suc-alert {
  font-size: 0.85rem;
  color: #575757;
  font-style: italic;
}
</style>
