module.exports = {
  baseApi: process.env.VUE_APP_BASE_API || 'http://localhost:8000',
  backUrl: process.env.VUE_APP_BACK_URL || 'https://admcenter.sill.cl/',
  webSocketsUrl: process.env.VUE_APP_WEBSOCKETS_URL || 'ws://localhost:3000',
  frontUrl: process.env.VUE_APP_FRONT_URL || 'http://localhost:8080',
  firebasePublicVapidKey: process.env.VUE_APP_FCM_VAPID_ID,
  firebaseConfig: {
    apiKey: process.env.VUE_APP_FCM_APIKEY,
    authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FCM_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FCM_APP_ID,
  },
}
