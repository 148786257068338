import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faAngleUp as fasAngleUp,
  faAngleDown as fasAngleDown,
  faSignOutAlt,
  faFileDownload,
  faCheckCircle,
  faBarcode,
  faPortrait,
  faLaptop,
  faWrench,
  faCloudUploadAlt,
  faPlusSquare,
  faEye,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fasAngleUp,
  fasAngleDown,
  faSignOutAlt,
  faFileDownload,
  faCheckCircle,
  faEye,
  faBarcode,
  faPortrait,
  faLaptop,
  faWrench,
  faCloudUploadAlt,
  faPlusSquare,
  faFileExcel 
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
