<template lang="pug">
b-card.sill-card.graph-card
  .outer-wrapper
    .chart-header
      | Gráfico
      br
      | de Acciones
    .chart-container
      canvas(
        ref="chart"
        width="100%"
        height="100%"
      )
</template>

<script>
import { mapState } from 'pinia'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'
// eslint-disable-next-line
import Chart from 'chart.js/auto'

const legendSpacingPlugin = {
  beforeInit(chart) {
    // Get a reference to the original fit function
    const originalFit = chart.legend.fit;

    // eslint-disable-next-line
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.width += 50;
    }
  }
}

export default {
  computed: {
    ...mapState(useUserReportStore, ['colorCount']),

    chartData() {
      const success = this.colorCount.success || 0
      const warning = this.colorCount.warning || 0
      const danger = this.colorCount.danger || 0
      const data = [success, warning, danger]
      const backgroundColor = ['#34C38F', '#F1B44C', '#F46A6A']
      return {
        labels: ['Sin alerta', 'Alerta 1', 'Alerta 2'],
        datasets: [{ data, label: '', backgroundColor }],
      }
    },
  },
  methods: {
    renderChart() {
      if (this.chart) this.chart.destroy()
      this.chart = new Chart(this.$refs.chart, {
        type: 'doughnut',
        data: this.chartData,
        plugins: [legendSpacingPlugin],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '70%',
          layout: {
            padding: {
              top: 6,
              bottom: 6,
            },
          },
          plugins: {
            legend: {
              position: 'left',
              align: 'end',
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 8,
                boxHeight: 8,
              },
            },
          },
        },
      })
    },
  },
  watch: {
    colorCount: {
      handler() {
        this.$nextTick(() => {
          this.renderChart()
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.graph-card {
  width: 335px;
  height: 200px;
  .card-body {
    padding: 16px;
  }
}

.outer-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-header {
  position: absolute;
  color: #707070;
  top: 6px;
  left: 6px;
  font-size: 16px;
  font-weight: bold;
}
</style>
