import slugify from 'slugify'

export default (
  query = null,
  estados = [],
  fecha_ingreso = null,
  tecnico_asignado__id = null,
  aprobadas_3x3 = null
) => {
  let url = 'api/orden'
  let concatenator = '?'

  if (query) {
    url += `${concatenator}query=${query}`
    concatenator = '&'
  }

  if (estados && estados.length > 0) {
    const slugified = estados.map((e) => slugify(e, { lower: true })).join(',')
    url += `${concatenator}estados=${slugified}`
    concatenator = '&'
  }

  if (fecha_ingreso) {
    url += `${concatenator}fecha_ingreso=${fecha_ingreso}`
    concatenator = '&'
  }

  if (tecnico_asignado__id) {
    url += `${concatenator}tecnico_asignado_id=${tecnico_asignado__id}`
    concatenator = '&'
  }

  if (aprobadas_3x3) {
    url += `${concatenator}aprobadas_3x3=${aprobadas_3x3}`
    concatenator = '&'
  }
  return { url, concatenator }
}
