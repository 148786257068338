const formatElapsedTime = (seconds) => {
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  // const s = Math.floor(seconds % 60);

  const dText = `${d}d`
  const hText = `${h}`.padStart(2, '0')
  const mText = `${m}`.padStart(2, '0')
  // const sText = `${s}`.padStart(2, '0')

  return `${dText} ${hText}:${mText}`
  // return `${dText} ${hText}:${mText}:${sText}`
}

export default formatElapsedTime

export const formatElapsedTimeInHours = (seconds) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)

  const hText = `${h}`.padStart(2, '0')
  const mText = `${m}`.padStart(2, '0')

  return `${hText}:${mText}`
}

export const formatElapsedTimeAsHHMMSS = (seconds) => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const hText = `${h}`.padStart(2, '0')
  const mText = `${m}`.padStart(2, '0')
  const sText = `${s}`.padStart(2, '0')

  return `${hText}:${mText}:${sText}`
}

export const formatElapsedTimeAsDayHour = (seconds) => {
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)

  const dText = `${d}d`
  const hText = `${h}h`

  return `${dText} ${hText}`
}
