import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const Warehouse = {
  namespaced: true,
  state: {
    movements: [],
    showEntryForm: null,
    showDepartureForm: null,
    allUsers: null,
    movementSelected: null,
    stateIsChanged: false,
  },
  getters,
  mutations,
  actions,
}
export default Warehouse
