<template lang="pug">
  .select-technician
    div
      button.change-button(
        :disabled="disabled"
        @click="modal = true, listTechnicians()"
        @hide="hideModal()"
        v-b-modal.selectTechnician
      ) Asignar técnico
    //- Modal de confirmación al cambiar técnico
    b-modal.hide-footer(title="Técnico asignado" v-model="modal" id="selectTechnician")
      div.d-block.text-center
        b-form-select.custom-select#main-select(
          v-model="tecnico" :options="options")
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='saveTechnician()') Si
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/** Asigna a técnico que diagnosticará la OT
 * Se encarga de guardar al técnico y pasarla a estado asignado
 */

export default {
  name: 'ActionSelectTechician',
  props: ['ot', 'disabled', 'updateOnly'],
  data() {
    return {
      tecnico: this.ot.tecnico_asignado ? this.ot.tecnico_asignado.id : null,
      modal: false,
    }
  },
  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    options() {
      return this.technicians
        .filter((t) => t.tecnico.groups.length === 1)
        .map((t) => ({
          value: t.tecnico.id,
          text: `${t.tecnico.first_name}  ${t.tecnico.last_name} (${t.cantidad_ot}/${t.cantidad_maxima}) `,
        }))
        .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase()) ? -1 : (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : 0)
        .concat([
          {
            value: null,
            text: 'Seleccione...',
          },
        ])
    },
  },
  methods: {
    ...mapActions('OTStore', ['asignNewTechnitian', 'updateTechnitian']),
    ...mapActions('TechniciansStore', ['listTechnicians']),
    showModal() {
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
    async saveTechnician() {
      try {
        await this.asignNewTechnitian({
          otId: this.ot.ot,
          technitianId: this.tecnico,
        })
        this.$bvToast.toast('Técnico asignado correctamente', {
          variant: 'success',
        })
        setTimeout(() => {
          this.$emit('changed')
          this.modal = false
        }, 500)
      } catch (err) {
        this.$bvToast.toast(err.response.data || 'Error al asignar', {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
