<template lang="pug">
  ViewOTIndex
</template>

<script>
import ViewOTIndex from '../../components/OT/ViewOT/ViewOTIndex.vue'

export default {
  name: 'ViewOTView',
  components: { ViewOTIndex },
}
</script>
