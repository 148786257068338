<template>
  <div class="icon-container">
    <span @click="viewDetails" class="eye-icon">
      <font-awesome-icon :icon="['fas', 'eye']" />
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.$emit('view-details');
    }
  }
}
</script>

<style scoped>
.icon-container {
  display: flex;
  align-items: center;  
  justify-content: center; 
  height: 100%;
}

.eye-icon {
  cursor: pointer;
  color: black;
  transition: color 0.3s;
}

.eye-icon:hover {
  color: #007bff;
}
</style>
