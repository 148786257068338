<template lang="pug">
  div
    button(
      :class="clase == '' ? 'btn btn-sm btn-primary' : 'change-button'"
      :disabled="disabled"
      v-b-modal.modal-cambio3x3
      title="Pedir Aprobación de 3x3"
      @hide="hideModal()")
      | Pedir Aprobación de 3x3
    b-modal.hide-foooter.adm-actions-modal(v-model="show" id="modal-cambio3x3" :title="`Aprobación 3x3 OT: ${ot.ot}`")
      b-form-group(
        label-for="input-comentario-solicitud"
        label="Comentario:"
      )
        b-form-textarea(
          id="input-comentario-solicitud"
          v-model="comentario"
          rows=7
          placeholder="Ingrese su comentario"
        )
      label ¿Está seguro?
      template.botones(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='createExcepcion3x3()' :disabled="loading") Aceptar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ActionCambio3x3',
  props: ['ot', 'disabled', 'size', 'variante', 'clase'],
  data() {
    return {
      show: false,
      saved: false,
      comentario: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('Exceptions', ['createExcepcion']),
    hideModal() {
      this.show = false
    },
    async createExcepcion3x3() {
      this.loading = true
      const data = {
        orden_trabajo: this.ot.ot,
        tipo: 'Aprobación de 3x3',
        comentario_solicitud: this.comentario,
        solicitado_por: this.user.user.id,
      }
      const res = await this.createExcepcion(data)
      this.loading = false
      console.log(res.status)
      if (res.status === 200) {
        this.$bvToast.toast('Solicitud creada correctamente', {
          variant: 'success',
        })
        this.$emit('changed')
        this.hideModal()
      } else {
        this.$bvToast.toast(`Error al crear la solicitud: ${res.data}`, {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
