<template lang="pug">
div
    //- Empresa
    //- button.change-button(
    //-   v-if="isAdmin && ot.cliente.tipo_cliente === 'Empresa' && (ot.pdf_cambio || pdfCambioGenerado)"
    //-   :disabled="disabled"
    //-   @click="showModalConfirmSendEmail = true"
    //-   @hide="hideModal()"
    //- ) Enviar correo de cambio
    //- Persona
    button.change-button(
      @click="showModalCambio = true"
      @hide="hideModal()"
      v-if="!ot.pdf_cambio && !pdfCambioGenerado"
      :disabled="disabled"
    ) Completar y enviar correo de cambio
    //- button.change-button(
    //-   @click="showModalConfirmSendEmail = true"
    //-   @hide="hideModal()"
    //-   v-if="isAdmin && ot.cliente.tipo_cliente === 'Persona' && (ot.pdf_cambio || pdfCambioGenerado)"
    //-   :disabled="disabled"
    //- )  Correo de cambio persona
    //- MODAL CORREO DE CAMBIO
    b-modal.hide-foooter(v-model="showModalCambio" :title="`Ingresar información de cambio OT ${ot.ot}`")
      p Cliente: {{ ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.nombre : ot.cliente.empresa.razon_social }}
      p R.M.A.: {{ ot.rma }}
      b-col
        b-form-group
          label Sucursal:
          p {{ot.sucursal ? ot.sucursal.nombre : ot.tienda_empresa ? ot.tienda_empresa.razon_social : '-'}}
        Sucursales(
          ref="sucursales"
          v-model="$v.formChange.cambio_sucursal.$model"
          :state="$v.formChange.cambio_sucursal.$dirty ? !$v.formChange.cambio_sucursal.$invalid : null"
          :clientIdOverride="ot.cliente.tipo_cliente === 'Empresa' ? ot.cliente.empresa.id : ot.tienda_empresa.id"
          :disabled="disabled"
          :otProp="ot"
          label="Sucursal de cambio"
          @modal-closed="formChange.cambio_sucursal = null"
        )
        //- b-form-group(
        //-   label-for="input-change-numero-boleta"
        //-   label="Número de Boleta:")
        //-   b-form-input(
        //-     id="input-change-numero-boleta"
        //-     v-model="formChange.numero_boleta"
        //-     placeholder="Ingresar número de boleta"
        //-     type="number"
        //-     :state="$v.formChange.numero_boleta.$dirty ? !$v.formChange.numero_boleta.$invalid : null"
        //-     )
        //-   .errors(v-if="$v.formChange.numero_boleta.$dirty")
        //-     b-form-invalid-feedback(:state="$v.formChange.numero_boleta.numeric")
        //-         | Solo puedes ingresar numeros
        //-     b-form-invalid-feedback(:state="$v.formChange.numero_boleta.required")
        //-         | Campo requerido
        //- b-form-group(
        //-   label-for="input-change-boleta"
        //-   label="Boleta:")
        //-   b-form-file(
        //-     style="z-index:0"
        //-     id="input-change-boleta"
        //-     v-model="formChange.boleta"
        //-     placeholder="Ingresar boleta"
        //-     drop-placeholder="Arrastre boleta aquí"
        //-     browse-text="Buscar"
        //-     :state="$v.formChange.boleta.$dirty ? !$v.formChange.boleta.$invalid : null"
        //-     )
        //-   .errors(v-if="$v.formChange.boleta.$dirty")
        //-     b-form-invalid-feedback(:state="$v.formChange.boleta.required")
                | Campo requerido
      template.botones(#modal-footer='{ ok, cancel, hide }')
          b-button(size='sm' variant='success' @click='saveChangeData()') Guardar
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
    //- MODAL CONFIRMACION ENVIO CORREO
    b-modal.hide-footer(v-model="showModalConfirmSendEmail" title="¿Está seguro?")
      div Se enviará correo de cambio a {{ ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.nombre : ot.cliente.empresa.razon_social }}
      template.botones(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='sendChangeEmail()') Aceptar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import Sucursales from '../NewOT/ClientForm/Sucursales.vue';
import hasPermission from '../../../utils/permissions'

export default {
  name: 'ActionCorreosDeCambio',
  props: ['ot', 'disabled'],
  components: {
    Sucursales
  },
  data() {
    return {
      showModalConfirmSendEmail: false,
      showModalCambio: false,
      pdfCambioGenerado: false,
      tiendaList: [],
      formChange: {
        cambio_sucursal: null,
        // numero_boleta: null,
        boleta: null,
      },
    }
  },
  validations: {
    formChange: {
      // numero_boleta: { required, numeric },
      // boleta: { required },
      cambio_sucursal: { required },
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('ClientStore', ['sucursales']),
    isAdmin() {
      return hasPermission({ roles: ['Administrativo'], user: this.user })
    },
  },
  async created() {
    console.log(this.ot.tienda_empresa)
    this.getSucursales({ clientId: this.ot.tienda_empresa.id })
  },
  methods: {
    ...mapActions('OTStore', [
      'getOT',
      'saveChangeClient',
      'sendChangeClientEmail',
      'getStoreList',
    ]),
    ...mapActions('ClientStore', ['getSucursales']),
    hideModal() {
      this.showModalCambio = false
    },
    async saveChangeData() {
      if (
        // this.$v.formChange.numero_boleta.$invalid ||
        (this.ot.cliente.tipo_cliente === 'Persona' &&
          this.$v.formChange.cambio_sucursal.$invalid)
      ) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        const sucursal = this.ot.cliente.tipo_cliente === 'Empresa' ? this.ot.sucursal.id : this.formChange.cambio_sucursal
        const formData = new FormData()
        // formData.append('numero_boleta', this.formChange.numero_boleta)
        formData.append('sucursal', sucursal)
        // cambiar sucursal por id

        if (this.formChange.boleta)
          formData.append('boleta', this.formChange.boleta)
        const status = await this.saveChangeClient({
          ot: this.ot.ot,
          form: formData,
        })
        if (status === 200) {
          this.$bvToast.toast('Información ingresada', {
            variant: 'success',
          })
          this.pdfCambioGenerado = true
          this.hideModal()
        } else {
          this.$bvToast.toast('Error en el ingreso de información', {
            variant: 'danger',
          })
        }
      }
    },
    async sendChangeEmail() {
      const status = await this.sendChangeClientEmail(this.ot.ot)
      if (status === 200) {
        this.$bvToast.toast('Correo de cambio enviado.', {
          variant: 'success',
        })
      } else {
        this.$bvToast.toast('Error al enviar el correo.', {
          variant: 'danger',
        })
      }
      this.pdfCambioGenerado = false
      this.$emit('changed')
      this.getOT(this.ot.ot)
    },
  },
}
</script>
