<template lang="pug">
div
  //- INGRESO
  .d-flex.justify-content-between
    h4.title.mb-3 Datos de Ingreso
    b-icon(
      v-if="!showFooter"
      icon="x"
      font-scale="2.5"
      @click="hideForm"
      style="cursor: pointer;"
    )
  h6.title Tipo de Ingreso
  b-form-select.mb-3(:disabled="disabledField" v-model="form.tipoIngreso" :options="tipoIngresoOptions")
  h6.title Entregado a bodega por
  b-form-select.mb-3(:disabled="disabledField" v-model="form.encargadoIngresa" :options="getUsers")
  h6.title Recibido en bodega por
  b-form-select.mb-3(:disabled="disabledField" v-model="form.encargadoRecibe" :options="getUsers")
  h6.title Comentarios
  b-form-textarea(:disabled="disabledField" rows="5" v-model="form.comentarios" placeholder="Comentarios...")

  div.footer(v-if="showFooter")
    b-button.mr-2(variant="outline-danger" @click="hideForm") Cancelar
    b-button(@click="saveData()" :disabled="disabled")
      b-spinner.mb-1.mr-1(small v-if="disabled")
      span.sr.only Guardar

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EntryForm',
  data() {
    return {
      showFooter: true,
      disabledField: false,
      disabled: false,
      form: {
        tipoIngreso: null,
        encargadoIngresa: null,
        encargadoRecibe: null,
        comentarios: null,
      },
      tipoIngresoOptions: [
        { value: null, text: 'Seleccione', disabled: true },
        { value: 1, text: 'Por asignar' },
        { value: 2, text: 'Espera de partes' },
        { value: 3, text: 'Reparado' },
        { value: 4, text: 'Acreditado' },
        { value: 5, text: 'Asistencia rechazada' },
        { value: 6, text: 'Sin falla de hardware' },
        { value: 7, text: 'Reparado 3x3' },
        { value: 8, text: 'Espera de retiro' },
        { value: 9, text: 'Equipo por enviar' },
      ],
    }
  },
  created() {
    if (this.movementSelected) {
      if (this.movementSelected.tipo === 'show') {
        this.showFooter = false
        this.disabledField = true
      } else {
        this.disabledField = false
      }
      this.form.tipoIngreso = this.getTipoIngreso()
      this.form.encargadoIngresa = this.movementSelected.entregado_por.id
      this.form.encargadoRecibe = this.movementSelected.recibido_por.id
      this.form.comentarios = this.movementSelected.comentarios
    }
  },
  methods: {
    ...mapActions('Warehouse', ['showEntryForm']),
    ...mapActions('Warehouse', ['saveMovement', 'editMovement']),
    ...mapActions('Warehouse', ['getMovements', 'setMovementData']),
    ...mapActions('OTStore', ['getOT']),
    hideForm() {
      this.showEntryForm(false)
      this.setMovementData(null)
    },
    async saveData() {
      this.disabled = true
      const data = {
        ot: this.ot.ot,
        tipo_movimiento: 1,
        tipo_entrada: this.form.tipoIngreso,
        entregado_por: this.form.encargadoIngresa,
        recibido_por: this.form.encargadoRecibe,
        comentarios: this.form.comentarios,
      }

      let resp = null
      let accion = ''

      if (this.movementSelected) {
        data.id = this.movementSelected.id
        resp = await this.editMovement(data)
        accion = 'Actualizado'
      } else {
        resp = await this.saveMovement(data)
        accion = 'Creado'
      }
      if (resp.status === 200) {
        this.disabled = false
        this.$bvToast.toast(`${accion} correctamente`, {
          variant: 'success',
        })
        setTimeout(() => {
          this.getMovements(this.ot.ot)
          this.getOT(this.ot.ot)
          this.showEntryForm(false)
        }, 2000)
        this.disabled = false
      } else {
        this.disabled = false
        this.$bvToast.toast(`Hubo un error: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
    getTipoIngreso() {
      return this.tipoIngresoOptions.find(
        (t) => t.text === this.movementSelected.tipo_entrada
      ).value
    },
  },
  computed: {
    ...mapGetters('Warehouse', ['allUsers']),
    ...mapGetters('Warehouse', ['movementSelected']),
    ...mapGetters('OTStore', ['ot']),
    getUsers() {
      const users = []
      // eslint-disable-next-line
      this.allUsers.map((u) => {
        users.push({
          value: u.id,
          text: u.email,
        })
      })
      users.unshift({
        value: null,
        text: 'Seleccione',
        disabled: true,
      })
      return users
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
}
.footer {
  margin-bottom: 15px;
  width: 100%;
  display: block;
  text-align: right;
  position: absolute;
  bottom: 0px;
  left: 0;
  padding-right: 20px;
}
</style>
