<template lang="pug">
b-container
  b-collapse(v-model="collapse" id="collapse-client-section" visible)
    b-form( novalidate @submit.prevent.stop )

      b-row
        b-col( cols="12" md="6" )
          b-form-group.relative( id="input-group-rut" )
            label( for="input-rut" ).color-indigo RUT
            FieldRutAutocomplete(
              v-if="!rutLoaded"
              v-model="query"
              :data="customers"
              @rut-typed="onRutTyped"
              @rut-selected="onRutSelected"
              @create-customer="setNewCustomer"
              :showCreateBtn="showCreateBtn"
            )
            FieldRutDisplay(
              v-else
              :disabled="!editingForm && !newCustomer"
              :rut="form.rut"
              @clear-customer="clearCustomer"
            )

        b-col( v-if="rutLoaded" cols="12" md="6" )
          FieldTipoCliente(
            v-model="form.tipo_cliente"
            :disabled="!editingForm && !newCustomer"
          )
      template( v-if="rutLoaded" )
        HeaderClienteNuevo(
          v-if="!form.id && form.tipo_cliente"
          :tipo="form.tipo_cliente"
        )
        FormPersona(
          ref="formPersona"
          v-if="form.tipo_cliente === CLIENTE_PERSONA"
          v-model="formPersona"
          :disabled="!editingForm && !newCustomer"
        )
        FormEmpresa(
          ref="formEmpresa"
          v-if="form.tipo_cliente === CLIENTE_EMPRESA"
          v-model="formEmpresa"
          :disabled="!editingForm && !newCustomer"
          :idCliente="form.id"
          :hasEmpresa="hasEmpresa"
        )
        Botones(
          :editing="editingForm"
          :newCustomer="newCustomer"
          :loading="loading"
          v-if="form.tipo_cliente"
          :confirm="onSubmit"
          @cancel="editingForm = false, getClient(client.id)"
          @edit="editingForm = true"
        )
  CollapsedForm(
    v-if="!collapse"
    :cliente="client"
  )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
// import validateRut from '@/utils/validateRut';
import * as cts from '../constants'
import FieldRutAutocomplete from './ClientForm/FieldRutAutocomplete.vue'
import FieldRutDisplay from './ClientForm/FieldRutDisplay.vue'
import FieldTipoCliente from './ClientForm/FieldTipoCliente.vue'
import HeaderClienteNuevo from './ClientForm/HeaderClienteNuevo.vue'
import Botones from './ClientForm/Botones.vue'
import CollapsedForm from './ClientForm/CollapsedForm.vue'
import FormPersona from './ClientForm/FormPersona.vue'
import FormEmpresa from './ClientForm/FormEmpresa.vue'

export default {
  components: {
    FieldRutAutocomplete,
    FieldRutDisplay,
    FieldTipoCliente,
    HeaderClienteNuevo,
    Botones,
    CollapsedForm,
    FormPersona,
    FormEmpresa,
  },
  props: ['provisoryRut'],
  data() {
    return {
      collapse: true,
      searching: false,
      query: '',
      loading: false,
      customers: [],
      rutLoaded: false,
      editingForm: false,
      personaTemp: null,
      empresaTemp: null,
      form: {
        id: null,
        rut: null,
        tipo_cliente: null,
      },
      formPersona: {
        nombre: null,
        apellido: null,
        region: null,
        comuna: null,
        direccion: null,
        telefono: null,
        email: null,
        contacto_telefono: null,
        contacto_nombre: null,
      },
      formEmpresa: {
        razon_social: null,
        correos_empresa: [],
        sucursal: null,
      },
    }
  },
  computed: {
    ...mapGetters('ClientStore', ['client', 'completeClient']),
    ...mapGetters('OTStore', ['ot']),
    newCustomer() {
      return !(this.client && this.client.id)
    },
    showCreateBtn() {
      return !!(
        this.query &&
        this.query.length > 3 &&
        !this.searching &&
        this.customers.length === 0
      )
    },
    hasEmpresa() {
      console.log(this.completeClient)
      if (this.ot?.cliente?.empresa || this.client.empresa || this.completeClient.hasEmpresa) {
        return true
      }
      return false
    },
  },
  watch: {
    ot() {
      this.fetchCustomer()
    },
    provisoryRut() {
      if (this.provisoryRut) {
        this.setProvisoryClient(this.provisoryRut);
      }
    },
    client() {
      const persona = this.completeClient?.persona || null
      const empresa = this.completeClient?.empresa || null
      const c = this.client || {}
      if (this.completeClient && this.client) {
        this.form = {
          id: this.client.id,
          rut: this.client.rut,
          tipo_cliente: this.client.tipo_cliente,
        }
        if (persona) {
          this.formPersona = {
            nombre: persona.nombre,
            apellido: persona.apellido,
            region: persona.region,
            comuna: persona.comuna,
            direccion: persona.direccion,
            telefono: persona.telefono,
            email: persona.email,
            contacto_telefono: persona.contacto_telefono,
            contacto_nombre: persona.contacto_nombre,
          };
        } else {
          this.formPersona = {
            nombre: c.nombre,
            apellido: c.apellido,
            region: c.region,
            comuna: c.comuna,
            direccion: c.direccion,
            telefono: c.telefono,
            email: c.email,
            contacto_telefono: c.contacto_telefono,
            contacto_nombre: c.contacto_nombre,
          };
        }
        let email = JSON.parse(empresa ? empresa.correo_empresas || '[]' : c.correo_empresas || '[]');
        if (this.ot.datos_provisorios) {
          email = [this.ot.datos_provisorios.email];
        }
        if (this.client?.correo_empresas) {
          this.correosEmpresa = JSON.parse(this.client.correo_empresas)
        }
        if (empresa) {
          this.formEmpresa = {
            razon_social: empresa.razon_social,
            sucursal: empresa.sucursal,
            correos_empresa: email, // todo: fix inconsistency
            hasEmpresa: true,
          };

        } else {
          this.formEmpresa = {
            razon_social: c.razon_social,
            sucursal: c.sucursal,
            correos_empresa: email,
          };
        }
        if (this.form?.rut) {
          this.rutLoaded = true
        }
      }
    },
  },
  methods: {
    ...mapActions('ClientStore', [
      'createClient',
      'editClient',
      'searchByRut',
      'getClient',
    ]),
    ...mapMutations('ClientStore', ['SET_CLIENT', 'SET_SUCURSAL']),

    onRutTyped: debounce(async function fn() {
      this.searching = true
      const res = await this.searchByRut({ rut: this.query })
      this.customers = res
      this.searching = false
    }, 200),

    onRutSelected(item) {
      let hasEmpresa = false
      let selectedCustomer = null
      if (item.tipo_cliente === 'Empresa' && item.empresa) {
        selectedCustomer = item.empresa
        hasEmpresa = true
      } else if (item.tipo_cliente === 'Persona' && item.persona) {
        selectedCustomer = item.persona
        if (item.empresa) {
          hasEmpresa = true
        }
      }
      console.log('selectedCustomer', selectedCustomer)
      this.SET_CLIENT({ client: {...selectedCustomer, hasEmpresa } })
    },

    clearCustomer() {
      this.rutLoaded = false
      this.query = null
      this.SET_CLIENT({ client: null })
    },

    setNewCustomer() {
      this.rutLoaded = true
      // this.form.rut = this.query.replace(/-[\dkK]$/, ''); // Quitar guión y verificador
      this.form.rut = this.query // Cliente pide mostrar guion y digito
      const client = { ...this.form, ...this.formPersona, ...this.formEmpresa }
      this.SET_CLIENT({ client })
    },

    setProvisoryClient(rut) {
      this.form.rut = rut;
      const client = { ...this.form, ...this.formPersona, ...this.formEmpresa };
      this.SET_CLIENT({ client });
    },

    async onSubmit(actionButton) {
      this.loading = true
      if (!this.form.tipo_cliente) return
      const formRef =
        this.form.tipo_cliente === cts.CLIENTE_PERSONA
          ? this.$refs.formPersona
          : this.$refs.formEmpresa

      if (!formRef.validate()) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        return
      }

      /* ----------------------------- Cliente persona ---------------------------- */ // eslint-disable-line
      if (this.form.tipo_cliente === cts.CLIENTE_PERSONA) {
        const data = { ...this.form, ...this.formPersona }

        if (!('contacto_nombre' in data) || !('contacto_telefono' in data)) {
          data.contacto_nombre = null
          data.contacto_telefono = null
        }

        const action = !this.form.id ? this.createClient : this.editClient
        try {
          const res = await action({ data })
          if (res.status === 200) {
            this.$bvToast.toast('Cliente guardado exitosamente', {
              variant: 'success',
            })
            this.collapse = false
            this.$emit('openSection', true)
          } else {
            this.$bvToast.toast(`Error al guardar el cliente: ${res.data}`, {
              variant: 'danger',
            })
          }
        } catch (err) {
          this.$bvToast.toast(`Error al guardar: ${err}`, { variant: 'danger' })
        }

        /* ----------------------------- Cliente empresa ---------------------------- */ // eslint-disable-line
      } else {
        const datosProvisorios = this.ot.datos_provisorios;
        const data = {
          razon_social: this.formEmpresa.razon_social,
          correo_empresas: JSON.stringify(this.formEmpresa.correos_empresa),
          tipo_cliente: cts.CLIENTE_EMPRESA,
          rut: this.form.rut,
          ...(this.form.id ? { id: this.form.id } : {}),
        }

        const action = !this.form.id ? this.createClient : this.editClient
        const sucursal = this.formEmpresa.sucursal // eslint-disable-line
        try {
          const res = await action({
            data,
            sucursal: this.formEmpresa.sucursal,
          })
          if (res.status === 200) {
            this.$bvToast.toast('Cliente guardado exitosamente', {
              variant: 'success',
            })
            this.$nextTick(() => formRef.unTouch())

            if (action === this.editClient && !this.editingForm) {
              this.collapse = false
              this.$emit('openSection', true)
            } else {
              this.editingForm = false
            }
            if (datosProvisorios && actionButton === 'create') {
              this.$refs.formEmpresa.openSucursalProvisory(datosProvisorios)
            }
          } else {
            this.$bvToast.toast('Error al guardar el cliente', {
              variant: 'danger',
            })
          }
        } catch (error) {
          this.$bvToast.toast(`Error al guardar: ${error}`, {
            variant: 'danger',
          })
        }
        this.SET_SUCURSAL({ sucursal, action: 'actddd' })
      }
      this.loading = false
    },
    fetchPreIngreso() {
      if (this.ot?.estado?.estado && ['Pre ingreso', 'Pre ingreso regiones'].includes(this.ot.estado.estado.nombre)) {
        const d = this.ot.datos_provisorios;
        this.form.tipo_cliente = d.tipo_cliente;
        if (d.tipo_cliente === cts.CLIENTE_PERSONA) {
          this.formPersona.nombre = d.nombre;
          this.formPersona.apellido = d.apellido;
          this.formPersona.email = d.email;
          this.formPersona.telefono = d.telefono;
          this.formPersona.direccion = d.direccion;
          this.formPersona.comuna = d.comuna;
          this.formPersona.region = d.region;
        } else {
          this.formEmpresa.razon_social = d.razon_social;
          this.formEmpresa.correos_empresa = [d.email];
        }
      }
    },
    async fetchCustomer() {
      let cliente = this.ot?.cliente?.persona || this.ot?.cliente?.empresa;
      let clienteId = cliente?.id;
      let datosProvisorios = null;

      if (this.ot?.datos_provisorios) {
        // Si tiene datos provisorios, es probable que el cliente ya existe, buscaremos por rut
        const d = this.ot.datos_provisorios;
        datosProvisorios = d;
        const res = await this.searchByRut({ rut: d.rut });
        if (res && res.length > 0) {
          cliente = res[0].persona || res[0].empresa;
          clienteId = cliente?.id;
        }
      }

      if (!clienteId) {
        this.SET_CLIENT({ client: null })
        return
      }
      this.query = cliente.rut
      this.onRutTyped()
      this.onRutSelected(cliente)
      await this.getClient(clienteId)

      if (this.client.tipo_cliente === cts.CLIENTE_PERSONA && this.$route.name === 'Completar Preingreso') {
        const d = this.ot.datos_provisorios
        this.editingForm = true
        this.formPersona.nombre = d.nombre
        this.formPersona.apellido = d.apellido
        this.formPersona.email = d.email
        this.formPersona.telefono = d.telefono
        this.formPersona.direccion = d.direccion
        this.formPersona.comuna = d.comuna
        this.formPersona.region = d.region
      }
      if (this.client.tipo_cliente === 2 && datosProvisorios && this.$route.name === 'Completar Preingreso') {
        const d = this.ot.datos_provisorios
        this.editingForm = true
        this.formEmpresa.razon_social = d.razon_social
        this.$refs.formEmpresa.openSucursalProvisory(datosProvisorios)
      }
      this.formEmpresa.sucursal = this.ot.sucursal?.id
    },
  },
  created() {
    this.CLIENTE_PERSONA = cts.CLIENTE_PERSONA
    this.CLIENTE_EMPRESA = cts.CLIENTE_EMPRESA
    this.fetchPreIngreso()
    this.fetchCustomer()
  },
}
</script>

<style lang="scss" scoped>
.color-indigo {
  color: #253590;
}

.container::v-deep {
  .btn {
    font-weight: bold;
    font-size: 13px;
    max-width: 100%;

    .btn-secondary {
      color: #fff;
      background-color: #5b73e8;
      border-color: #5b73e8;
    }
  }

  .invalid-feedback {
    color: #dc3545;
  }
}
</style>
