<template lang="pug">
  div
    button.change-button(
      :disabled="disabled"
      @click="showModalParte = true"
      @hide="hideModal()"
    ) Pedir cambio por espera de partes
    //- MODAL EXCEPCION ESPERA DE PARTES
    b-modal.hide-foooter(v-model="showModalParte" title="Ingresar información  de parte")
      b-col
        b-form-group(
          label-for= "input-comentario_solicitud"
          label="Comentario:")
          b-form-textarea(
            v-model="comentario_solicitud"
            placeholder="Ingrese su comentario"
            rows="3"
          )
      template.botones(#modal-footer='{ ok, cancel, hide }')
          b-button(size='sm' variant='success' @click='createExcepcionWaitingParts()' :disabled="loading") Guardar
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ActionCambioEsperaDeParte',
  props: ['ot', 'disabled'],
  components: {},
  validations: {
    comentario_solicitud: { required },
  },
  data() {
    return {
      showModalParte: false,
      comentario_solicitud: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('Exceptions', ['createExcepcion']),
    ...mapActions('OTStore', ['getOT']),
    hideModal() {
      this.showModalParte = false
    },
    async createExcepcionWaitingParts() {
      this.loading = true
      if (this.$v.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
      } else {
        const form = {
          estado: 1,
          orden_trabajo: this.ot.ot,
          comentario_solicitud: this.comentario_solicitud
            ? this.comentario_solicitud
            : null,
          solicitado_por: this.user.id,
          tipo: 'Cambio en espera de parte',
        }
        const res = await this.createExcepcion(form)
        if (res.status === 200) {
          this.$bvToast.toast('Solicitud creada correctamente', {
            variant: 'success',
          })
          this.$emit('changed')
          await this.getOT(this.ot.ot)
          this.hideModal()
        } else {
          this.$bvToast.toast(`Error al crear excepción: ${res.data}`, {
            variant: 'danger',
          })
          this.hideModal()
        }
      }
      this.loading = false
    },
  },
}
</script>
