<template lang="pug">
  .desarme-equipo
    div
      //- BOTON DESARMAR EQUIPO
      //-Ingresa datos para y asigna un técnico para desarme
    button.change-button(
      :disabled="disabled"
      @click="showModalDesarme = true, getUsers()") Desarmar equipo
    b-modal.hide-foooter(v-model="showModalDesarme" :title="`Ingresar datos de desarme`")
      b-col
        b-form-group(label="RMA de desarme" label-for="input-desarme-rma")
            b-input(
              v-model="formDesarme.rma"
              id="input-desarme-rma"
              :state="$v.$dirty ? !$v.formDesarme.rma.$invalid : null")
            .errors(v-if="$v.formDesarme.rma.$dirty")
              b-form-invalid-feedback(:state="$v.formDesarme.rma.required") Campo requerido
        b-form-group.input-label(label="Encargado de desarme" label-for="input-desarme-encargado")
          b-form-select(
            id="input-desarme-encargado"
            v-model="formDesarme.encargado"
            :options="techniciansOptions"
            :state="$v.$dirty ? !$v.formDesarme.encargado.$invalid : null")
          .errors(v-if="$v.formDesarme.encargado.$dirty")
              b-form-invalid-feedback(:state="$v.formDesarme.encargado.required") Campo requerido
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='desarmarEquipo()') Guardar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ActionDesarmarEquipo',
  props: ['ot', 'disabled'],

  validations: {
    formDesarme: {
      encargado: { required },
      rma: { required },
    },
  },
  data() {
    return {
      user: null,
      showModalDesarme: false,
      formDesarme: {
        rma: null,
        encargado: null,
      },
      usuarios: [],
    }
  },
  methods: {
    ...mapActions('Warehouse', ['changeWarehouseState']),
    ...mapActions('OTStore', ['desarme', 'getOT']),
    ...mapActions('AuthenticationStore', ['getAllUsers']),

    hideModal() {
      this.state = null
      this.show = false
      this.showModalDesarme = false
    },

    async desarmarEquipo() {
      if (
        this.$v.formDesarme.rma.$invalid ||
        this.$v.formDesarme.encargado.$invalid
      ) {
        this.$v.$touch()
      } else {
        await this.desarme({
          otId: this.ot.ot,
          rmaDesarme: this.formDesarme.rma,
          asignadoDesarme: this.formDesarme.encargado,
        })
        this.$emit('changed')
        this.hideModal()
      }
    },
    async getUsers() {
      const response = await this.getAllUsers()
      const users = []
      response.forEach((user) => {
        const groups = user.groups.filter(
          (g) => g.name === 'Supervisor' || g.name === 'Técnico'
        )
        if (groups.length > 0) {
          users.push(user)
        }
      })
      this.usuarios = users
    },
  },
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
  },

  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    techniciansOptions() {
      return this.usuarios
        .map((u) => ({
          value: u.id,
          text: `${u.first_name}  ${u.last_name}`,
        }))
        .concat([
          {
            value: null,
            text: 'Seleccione técnico',
          },
        ])
    },
  },
}
</script>
