<template lang="pug">
div
    button.change-button(
      :disabled="disabled"
      @click="show = true"
      v-if="showButton"
      @hide="hideModal()"
    ) Ingresar Reparación
    b-modal.hide-foooter(v-model="show"  size="lg" title="Ingresar Reparación")
      h6.label Diagnóstico
      p {{ ot.diagnostico_detail && ot.diagnostico_detail.informe }}
      b-form-group(
        label-for="input-informe"
        label="Solución"
      )
        b-form-textarea(
          id="input-informe"
          rows="5"
          v-model="form.solucion"
          placeholder="Registrar la solución (obligatorio)"
          :state="$v.form.solucion.$dirty ? !$v.form.solucion.$invalid : null")
        .errors(v-if="$v.form.solucion.$dirty")
          b-form-invalid-feedback(:state="$v.form.solucion.required")
            | Campo requerido
      hr
      b-col
        OTUploads(:ot="ot" fileNamePattern="evidencia-reparacion" :attachmentType="ARCHIVO_ADJUNTO_TIPO.EVIDENCIA")
      hr
      template.botones(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='showMessageModal()') Aceptar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
    //- Modal confiracion
    b-modal(v-model="showConfirm" title="Información" @ok="handleConfirm")
      p {{ message }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTUploads from '../OTUploads.vue'

export default {
  components: {
    OTUploads,
  },
  name: 'ActionIngresarReaparacion',
  props: ['ot', 'disabled'],

  validations: {
    form: {
      solucion: { required },
    },
  },
  data() {
    return {
      show: false,
      form: {
        solucion: null,
        id: null,
        ot: this.ot ? this.ot.ot : null,
      },
      message: null,
      showConfirm: false,
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('OTStore', ['statusRequest']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,
    showButton() {
      const estadosParteOk = ['Dropped', 'Usado', 'Part failed'].map((s) =>
        s.toLowerCase()
      )
      const partesPendientes = this.ot.solicitud_partes?.filter(
        (parte) => estadosParteOk.indexOf(parte.estado.toLowerCase()) === -1
      ) || []
      return partesPendientes.length === 0
    },
  },
  watch: {
    ot() {
      if (this.ot.diagnostico_detail) {
        this.form.informe = this.ot.diagnostico_detail?.informe
        this.form.id = this.ot.diagnostico_detail.id
        this.form.ot = this.ot.ot
      }
    },
  },
  methods: {
    ...mapActions('Diagnosticos', ['updateDiagnoseOT']),
    ...mapActions('OTStore', ['getOT']),
    ...mapActions('Warehouse', ['changeWarehouseState']),
    hideModal() {
      this.show = false
    },
    async showMessageModal() {
      if (this.$v.$invalid) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.message = null
        const repuestos_usados = this.ot.solicitud_partes?.filter(
          (parte) => parte.estado === 'Usado'
        ) || []

        if (repuestos_usados.length > 0) {
          this.message = 'Se utilizaron repuestos. Al aceptar el equipo quedará reparado'

        } else if (this.ot.diagnostico_detail.replicar_falla === true) {
          this.message = 'No se utilizaron repuestos y falla fue replicada, el equipo quedará como "Equipo restaurado de fábrica"'

        } else if (this.ot.diagnostico_detail.replicar_falla === false) {
          this.message = 'No se utilizaron repuestos y falla no fue replicada, el equipo quedará como "Equipo sin falla"'
        }

        this.showConfirm = true
      }
    },
    async handleConfirm() {
      this.show = false
      await this.changeWarehouseState({
        state: 'Reparado',
        orden: this.ot.ot,
        user: this.user.user.id,
      })
      this.saveDiagnostic()
      this.message = null
      this.showConfirm = false
    },
    async saveDiagnostic() {
      this.form.id = this.ot.diagnostico_detail
        ? this.ot.diagnostico_detail.id
        : this.ot.diagnostico
      console.log('update diagnostico con', this.form)
      const response = await this.updateDiagnoseOT(this.form)
      if (response.status === 200) {
        this.$bvToast.toast('Solución guardada.', {
          variant: 'success',
        })
        this.$emit('changed')
      } else {
        this.$bvToast.toast(`Error al guardar reparación: ${response.data}`, {
          variant: 'danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.preview,
.capture {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
img {
  width: 100%;
}
.webcam {
  display: inline-flex;
  justify-content: space-between;
  margin: -8pxl;
}

.label {
  color: #74788d;
  font-weight: bold;
}
</style>
