<template lang="pug">
Fragment
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Historial Pieza {{ $route.params.partNumber }}
  HistoryDetail
</template>

<script>
import HistoryDetail from '../components/Inventory/HistoryDetail.vue'
import BackArrow from '../components/UI/BackArrow.vue'

export default {
  name: 'Inventario',
  components: {
    HistoryDetail,
    BackArrow,
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
