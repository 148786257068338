<template lang="pug">
div
  b-form-group
    b-row
      b-col.center
        UploadFile(
          :otId="ot.ot"
          :fileNamePattern="fileNamePattern"
          :attachmentType="attachmentType"
          :limitToOne="limitToOne"
          @done="$emit('done')"
          :galleryMode="galleryMode"
        )

  b-form-group.mt-2( label-for="input-evidencia" label="Cargar con Cámara")
    b-button(@click="openCamera = !openCamera" v-if="!openCamera") Abrir cámara
    b-button(v-else @click="closeCamera") Cerrar cámara

  b-form-group
    div(v-model="openCamera" v-if="openCamera")
      label(class="client-form-inputs") Seleccionar cámara:
      b-form-group
        b-select(v-model="camera")
          option(
            v-for="device in devices"
            :key="device.deviceId"
            :value="device.deviceId") {{ device.label }}
        b-row.webcam
          b-col.capture
            vue-web-cam.m-2(
              ref="webcam"
              :device-id="deviceId"
              @cameras="onCameras"
              @camera-change="onCameraChange"
              height="auto"
              width="100%")
          b-col.preview
            div(v-for="pic in evidenciaCamara")
              img(:src="pic")

    b-row( v-if="device" )
      b-col
        b-button(@click="onCapture") Capturar
    b-row
      b-col
        br
        b-button(v-if="evidenciaCamara.length > 0" @click="uploadEvidenciaCamara" variant="primary") Subir capturas

</template>

<script>
import { WebCam } from 'vue-web-cam'
import { mapActions } from 'vuex'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import UploadFile from './NewOT/UploadFile.vue'

export default {
  components: {
    'vue-web-cam': WebCam,
    UploadFile,
  },
  name: 'OTUploads',
  props: {
    ot: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    fileNamePattern: {
      type: String,
    },
    limitToOne: {
      type: Boolean,
    },
    attachmentType: {
      type: String,
      default: ARCHIVO_ADJUNTO_TIPO.OTROS,
    },
    galleryMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deviceId: null,
      devices: [],
      openCamera: false,
      show: false,
      camera: null,
      evidenciaCamara: [],
      fileCount: 1,
    }
  },
  methods: {
    ...mapActions('OTStore', ['uploadFile']),
    async uploadEvidenciaCamara() {
      await this.evidenciaCamara.forEach(async (photo) => {
        fetch(photo)
          .then((res) => res.blob())
          .then(async (blob) => {
            const fileName = `ot-${this.ot.ot}-${this.fileNamePattern}-cam-${this.fileCount}.jpg`
            const file = new File([blob], fileName, { type: 'image/jpeg' })
            const formData = new FormData()
            formData.append('archivo', file)
            formData.append('orden_trabajo', this.ot.ot)
            formData.append('tipo', this.attachmentType)
            await this.uploadFile(formData)
            this.$bvToast.toast(
              `Captura ${this.fileCount} guardada correctamente`,
              {
                variant: 'success',
              }
            )
            this.fileCount += 1
          })
      })
      this.closeCamera()
      this.$emit('done')
    },
    onCapture() {
      const capture = this.$refs.webcam.capture()
      if (this.limitToOne) {
        this.evidenciaCamara = [capture]
      } else {
        this.evidenciaCamara.push(capture)
      }
    },
    onCameras(cameras) {
      this.devices = cameras
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
    },
    closeCamera() {
      this.openCamera = false
      this.$refs.webcam.stop()
      this.camera = null
    },
  },
  computed: {
    device() {
      return this.devices.find((n) => n.deviceId === this.deviceId)
    },
  },
  watch: {
    camera(id) {
      this.deviceId = id
    },
    devices() {
      const [first, ...tail] = this.devices //eslint-disable-line
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview,
.capture {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
img {
  width: 100%;
}
.webcam {
  display: inline-flex;
  justify-content: space-between;
  margin: -8pxl;
}
</style>
