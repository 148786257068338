<template lang="pug">
div
  div(
    :class="squareLayout ? 'square-layout' : 'default-layout'"
    v-if="ot && ot.estado && ot.estado.estado.nombre !== 'Cerrado'"
  )
    component.mt-2(
      v-for="action in actionsToShow"
      v-show="!loading"
      :is="getComponent(action)"
      :disabled="ot.acciones.disabled.includes(action)"
      :ot="ot"
      @changed="isChanged()"
      :toast="toast"
    )

    b-spinner(v-if="loading")

  //- Automáticamente abrir modal de alerta junto con este componente
  SerialNumberAlertModal(
    v-model="serialNumberAlertModal"
    :ot="ot"
    auto-open-on-mount
  )
</template>

<script>
/** @module OTActionsIndex
 * Despliega distintas acciones disponibles para una OT
 * cada acción es responsable de mostrarse de acuerdo al contexto del ot entregado
 */
import { mapGetters, mapActions } from 'vuex'
import { ACTION_NAMES } from '@/utils/enums/actions'
import SerialNumberAlertModal from '@/components/OT/SerialNumberAlertModal.vue'
import ActionRevisarInforme from './ActionRevisarInforme.vue'
import ActionCorreosDeCambio from './ActionCorreosDeCambio.vue'
import ActionCambioEsperaDeParte from './ActionCambioEsperaDeParte.vue'
import ActionEsperaDeRetiro from './ActionEsperaDeRetiro.vue'
import ActionEditRMA from './ActionEditRMA.vue'
import ActionSelectTechnician from './ActionSelectTechnician.vue'
import ActionPrimerIngresoABodega from './ActionPrimerIngresoABodega.vue'
import ActionEntregarTecnico from './ActionEntregarTecnico.vue'
import ActionEntregarTecnicoSinPartes from './ActionEntregarTecnicoSinPartes.vue'
import ActionIngresarABodegaRechazado from './ActionIngresarABodegaRechazado.vue'
import ActionIngresarABodegaPorEsperaDePartes from './ActionIngresarABodegaPorEsperaDePartes.vue'
import ActionEntregarEquipoRepuestoLocalizado from './ActionEntregarEquipoRepuestoLocalizado.vue'
import ActionIngresarABodegaReparado from './ActionIngresarABodegaReparado.vue'
import ActionDarSalida from './ActionDarSalida.vue'
import ActionMarkFullPayment from './ActionMarkFullPayment.vue'
import ActionDesarmarEquipo from './ActionDesarmarEquipo.vue'
import ActionDiagnosticar from './ActionDiagnosticar.vue'
import ActionRechazar from './ActionRechazar.vue'
import ActionIngresarReparacion from './ActionIngresarReparacion.vue'
import ActionSolicitarPartes from './ActionSolicitarPartes.vue'
import ActionLlegadaDePartes from './ActionLlegadaDePartes.vue'
import ActionCompletPreIngreso from './ActionCompletPreIngreso.vue'
import ActionRechazarPreIngreso from './ActionRechazarPreIngreso.vue'
import ActionRecibirEquipo from './ActionRecibirEquipo.vue'
import ActionInternalQuotation from './ActionInternalQuotation.vue'
import ActionReingreso from './ActionReingreso.vue'
import ActionEntregarRecepcion from './ActionEntregarRecepcion.vue'
import ActionDevolverBodega from './ActionDevolverBodega.vue'
import ActionAddBoleta from './ActionAddBoleta.vue'
import ActionCambio3x3 from './ActionCambio3x3.vue'

export default {
  name: 'OTActionsIndex',
  props: {
    ot: Object,
    squareLayout: Boolean,
    loading: Boolean,
  },
  components: {
    SerialNumberAlertModal,
  },
  data() {
    return {
      show: false,
      user_id: null,
      serialNumberAlertModal: false,
      actions: [
        {
          name: ACTION_NAMES.diagnosticar,
          component: ActionDiagnosticar,
        },
        {
          name: ACTION_NAMES.revisarInforme,
          component: ActionRevisarInforme,
        },
        {
          name: ACTION_NAMES.enviarCorreoCambio,
          component: ActionCorreosDeCambio,
        },
        {
          name: ACTION_NAMES.cambioEsperaDeParte,
          component: ActionCambioEsperaDeParte,
        },
        {
          name: ACTION_NAMES.esperaDeRetiro,
          component: ActionEsperaDeRetiro,
        },
        {
          name: ACTION_NAMES.editRMA,
          component: ActionEditRMA,
        },
        {
          name: ACTION_NAMES.selectTechnician,
          component: ActionSelectTechnician,
        },
        {
          name: ACTION_NAMES.primerIngresoABodega,
          component: ActionPrimerIngresoABodega,
        },
        {
          name: ACTION_NAMES.entregarTecnico,
          component: ActionEntregarTecnico,
        },
        {
          name: ACTION_NAMES.entregarTecnicoSinPartes,
          component: ActionEntregarTecnicoSinPartes,
        },
        {
          name: ACTION_NAMES.ingresarABodegaRechazado,
          component: ActionIngresarABodegaRechazado,
        },
        {
          name: ACTION_NAMES.ingresarABodegaPorEsperaDePartes,
          component: ActionIngresarABodegaPorEsperaDePartes,
        },
        {
          name: ACTION_NAMES.entregarEquipoRepuestoLocalizado,
          component: ActionEntregarEquipoRepuestoLocalizado,
        },
        {
          name: ACTION_NAMES.ingresarABodegaReparado,
          component: ActionIngresarABodegaReparado,
        },
        {
          name: ACTION_NAMES.darSalida,
          component: ActionDarSalida,
        },
        {
          name: ACTION_NAMES.marcarPagoCompleto,
          component: ActionMarkFullPayment,
        },
        {
          name: ACTION_NAMES.desarmarEquipo,
          component: ActionDesarmarEquipo,
        },
        {
          name: ACTION_NAMES.rechazar,
          component: ActionRechazar,
        },
        {
          name: ACTION_NAMES.ingresarReparacion,
          component: ActionIngresarReparacion,
        },
        {
          name: ACTION_NAMES.solicitarPartes,
          component: ActionSolicitarPartes,
        },
        {
          name: ACTION_NAMES.llegadaDePartes,
          component: ActionLlegadaDePartes,
        },
        {
          name: ACTION_NAMES.completPreIngreso,
          component: ActionCompletPreIngreso,
        },
        {
          name: ACTION_NAMES.rechazarPreIngreso,
          component: ActionRechazarPreIngreso,
        },
        {
          name: ACTION_NAMES.recibirEquipo,
          component: ActionRecibirEquipo,
        },
        {
          name: ACTION_NAMES.internalQuotation,
          component: ActionInternalQuotation,
        },
        {
          name: ACTION_NAMES.reingreso,
          component: ActionReingreso,
        },
        {
          name: ACTION_NAMES.entregarRecepcion,
          component: ActionEntregarRecepcion,
        },
        {
          name: ACTION_NAMES.addBoleta,
          component: ActionAddBoleta,
        },
        {
          name: ACTION_NAMES.cambio3x3,
          component: ActionCambio3x3,
        },
        {
          name: ACTION_NAMES.devolverBodega,
          component: ActionDevolverBodega,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('AuthenticationStore', ['user']),

    actionsToShow() {
      return [
        ...this.ot.acciones.visible,
        ...this.ot.acciones.disabled,
      ]
    },
  },

  methods: {
    ...mapActions('OTStore', ['getOT']),

    isChanged() {
      console.log('action index emit changed!')
      this.$emit('changed')
    },

    toast(message, options) {
      this.$bvToast.toast(message, options)
    },

    getComponent(actionName) {
      console.log('getComponent', actionName);
      return this.actions.find((action) => action.name === actionName).component
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.default-layout {
  max-width: 100%;
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  flex-direction: row;
  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }
}

.square-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &::v-deep .change-button {
    margin-right: 10px !important;
  }
}
</style>

<style lang="scss">
/* ------------------ Estilo no scoped que aplica a todos los botones de acción ----------------- */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.change-button {
  width: 170px;
  height: 60px;
  background-color: #5b73e8;
  border-radius: 4px;
  border: none;

  margin-right: 15px;
  margin-bottom: 0;
  @include media-breakpoint-up(xl) {
    margin-right: 0;
    margin-bottom: 15px;
  }

  line-height: 1.2;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  &:disabled {
    background-color: #7d93ff;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #253590;
    transition: all 0.15s ease-out;
  }

  &:disabled {
    background-color: #e6e6e6;
  }
}
</style>
