<template lang="pug">
  .login
    img.logo(src="../../assets/logo_blaco_sill.png")
    .card-login
      .subtitle
        h4 Bienvenido al
        .under-h4 Servicio Técnico de Sill
        br
        | Por favor ingresa tu número de orden para consultar el estado de tu equipo.
      .no-ot(v-show="errors.noFoundOT")
        .text-no-ot.ml-1 No tenemos resultados para el numero ingresado
      .form-group
        label.label-ot Rut
        b-form-input(
          v-model="formatedRut"
        )
      .form-group
        label.label-ot Numero de Orden de Trabajo
        b-form-input(
          v-model="OTNumber"
          type="number"
        )
      .d-flex.justify-content-center
        b-button( @click="onSubmit") Consultar
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OTLogin',
  data() {
    return {
      OTNumber: null,
      rut: null,
      errors: {
        noFoundOT: false,
      },
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'statusRequest']),
    formatedRut: {
      get() {
        return this.rut?.replace(/\./g, '')
      },
      set(v) {
        // this.rut = v?.replace(/\./g, '')
        const rutLimpio = v.replace(/[^0-9kK]/g, '');

        // asilar el cuerpo del dígito verificador
        const cuerpo = rutLimpio.slice(0, -1);
        const dv = rutLimpio.slice(-1).toUpperCase();

        if (rutLimpio.length < 2) return rutLimpio;

        // colocar los separadores de miles al cuerpo
        let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');

        cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, ''); // eslint-disable-line

        this.rut = `${cuerpoFormatoMiles.replace(/[^0-9kK]/g, '')}-${dv}`;
      },
    },
  },
  methods: {
    ...mapActions('OTStore', ['getOTPublic']),
    ...mapMutations('OTStore', ['SET_STATUS']),
    async onSubmit() {
      const OT = parseInt(this.OTNumber, 10)
      await this.getOTPublic({ otId: OT, rut: this.rut })
      if (this.statusRequest || this.OTNumber === null || this.OTNumber === '') {
        this.SET_STATUS(null)
        this.errors.noFoundOT = true
        return
      }
      this.$router.push(`./cliente/${OT}/rut/${this.rut}`)
      this.errors.noFoundOT = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/colors.scss';

.login {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin: -20px auto 40px;
    width: 110px;
  }
  .card-login {
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 35px 45px;
    width: 470px;
    //height: 20em;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (width: 768px) {
      width: 430px;
      //height: 480px;
      margin-left: -100px;
    }
    @media (width: 1024px) {
      width: 430px;
      //height: 361px;
      margin-left: -55px;
    }
  }
  .subtitle {
    color: #7f828f;
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;
    h4 {
      color: $sill-blue;
      font-size: 18px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    .under-h4 {
      color: $sill-blue;
      font-size: 18px;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
      line-height: 120%;
    }
  }
  .icon-container {
    position: absolute;
    margin-left: 15px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  input {
    height: 50px;
  }
  .boton {
    margin: 20px 0;
    background-color: $sill-red;
    border-color: $sill-red;
    font-size: 15px;
    span {
      margin-left: 5px;
    }
  }
}
.no-ot {
  background: #fee9e9 0% 0% no-repeat padding-box;
  border: 1px solid #f46a6a;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #f46a6a;
  opacity: 1;
  padding: 0.5em;
  position: relative;
  top: -1.5em;
}
.text-no-ot {
  font-size: 1em;
}
::v-deep .alert-danger {
  color: #f46a6a;
  background-color: #fee9e9;
  border-color: #f46a6a;
}
::v-deep .form-group label {
  color: #253590;
  font-weight: bold;
}
::v-deep .btn-secondary {
  background-color: #5b73e8;
  border-color: #5b73e8;
}
::v-deep .login input {
  padding-left: 0px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
