<template lang="pug">
div
  hr
  .form-card
    QuoteSummary(:quote="payload")

    .d-flex.justify-content-between.mt-4
      b-button.form-btn(@click="goBack()" small variant="outline-primary")
        | Anterior
      b-button.form-btn(@click="submit" small)
        | Enviar solicitud
    .d-flex.justify-content-center

    b-modal.hide-footer(
      v-model="showModal"
      no-close-on-backdrop
      title="Cotización Enviada"
      header-text-variant="darker-info"
    )
      .text-center.flex-col.gap-4
        font-awesome-icon(:icon="['fa','check-circle']" size="7x" color="#2ecc71")
        p.mb-0
          | Su cotización se ha enviado correctamente.
          br
          | Le enviaremos la respuesta al
          br
          | Email que ha Ingresado
      template(#modal-footer='{ ok, cancel, hide }')
        .d-flex.justify-content-center.w-100
          b-button.form-btn(variant='button' @click="finish()") Aceptar
</template>

<script>
import QuoteSummary from '@/components/Quotations/ExternalQuotes/QuoteSummary.vue'
import { useExternalQuoteStore } from '@/pinia/api/useExternalQuoteStore'
import moment from 'moment'
import { mapActions } from 'pinia'

export default {
  name: 'ResumeQuotations',
  props: ['dataClient', 'form'],
  components: {
    QuoteSummary,
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    payload() {
      return {
        quote_client: {
          rut: this.dataClient.rut,
          first_name: this.dataClient.nombre,
          last_name: this.dataClient.apellido,
          region: this.dataClient.region,
          commune: this.dataClient.comuna,
          address: this.dataClient.direccion,
          phone: this.dataClient.telefono,
          email: this.dataClient.email,
        },
        parts: this.form.parts.map((part) => ({
          category_id: part.category,
          comments: part.comments,
        })),
        device_serial_number: this.form.device.serial_number,
        device_picture: this.form.deviceImage,
      }
    }
  },
  methods: {
    ...mapActions(useExternalQuoteStore, ['createExternalQuote']),
    goBack() {
      this.$emit('goBack')
    },
    async submit() {
      const data = new FormData()
      Object.entries(this.payload.quote_client).forEach(([key, value]) => {
        data.append(`quote_client.${key}`, value)
      })
      data.append('parts_data', JSON.stringify(this.payload.parts))
      data.append('device_serial_number', this.payload.device_serial_number)
      data.append('device_picture', this.payload.device_picture)

      const res = await this.createExternalQuote(data)

      if (res.status < 200 || res.status >= 300) {
        return this.$bvToast.toast('Error al enviar el presupuesto', {
          variant: 'danger',
        })
      }

      this.showModal = true
    },
    getFecha(fecha) {
      const date = moment(fecha).format('DD/MM/YYYY')
      return date
    },
    async finish() {
      this.$emit('created')
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
  .title-data {
    color: #253590;
    font-weight: 700;
    font-size: 1.2em;
  }
  .details {
    color: var(--info);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .input-label {
    color: #74788d;
    font-weight: bold;
    font-size: 1em;
  }
  .display-data {
    font-size: 1em;
    margin-bottom: 20px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
</style>
