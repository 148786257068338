var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ot.estado.estado.nombre !== 'Acreditación')?_c('div',[_c('button',{staticClass:"change-button",attrs:{"disabled":_vm.disabled},on:{"click":_vm.openModal,"hide":function($event){return _vm.hideModal()}}},[_vm._v(_vm._s(_vm.changeNameButton()))]),_c('b-modal',{staticClass:"hide-foooter",attrs:{"title":"Información de partes solicitadas","size":"xl","scrollable":""},on:{"hide":function($event){return _vm.hideModal()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.solicitarPartes()}}},[_vm._v("Guardar"),(_vm.loading)?_c('b-spinner'):_vm._e()],1),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Cancelar")])]}}],null,false,2764299280),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.ot.solicitud_partes && _vm.ot.solicitud_partes.length < 1)?_c('div',[(_vm.parts.length < 1)?_c('span',[_vm._v("No existe solicitud de partes")]):_vm._e()]):_c('div',[_c('PartsTable',{attrs:{"ot":_vm.ot,"showStateActionColumn":true},on:{"changed":function($event){return _vm.$emit('changed')}}})],1),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.solicitarPartes()}}},[_c('b-table',{attrs:{"fields":_vm.tableFields,"items":_vm.parts,"responsive":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_c('span',{staticClass:"number circle bg-button"},[_vm._v(_vm._s(index + 1))])]}},{key:"cell(partNumber)",fn:function(ref){
var index = ref.index;
var part = ref.item;
return [_c('b-form-group',[_c('SILLAutocomplete',{key:index,class:_vm.getAutoCompleteClass(index),attrs:{"search":_vm.searchParts,"displayAttribute":"part_number","placeholder":"Ej: AF3FC3F","onSubmit":_vm.onPartSelected,"searching":_vm.searching},nativeOn:{"click":function($event){return _vm.onclick(index)},"keyup":function($event){return _vm.setPartNumber(index)}}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].parte.partNumber"))}},[_vm._v("Campo requerido")])],1)]}},{key:"cell(codigo)",fn:function(ref){
var part = ref.item;
return [_c('VueBarcode',{attrs:{"width":"1","height":"30","textAlign":"center","fontSize":"13"},model:{value:(part.parte.partNumber),callback:function ($$v) {_vm.$set(part.parte, "partNumber", $$v)},expression:"part.parte.partNumber"}})]}},{key:"cell(description)",fn:function(ref){
var index = ref.index;
var part = ref.item;
return [_c('b-form-group',[(_vm.$v.parts.$each.$iter[index])?_c('b-form-input',{staticStyle:{"text-transform":"uppercase"},attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].parte.description")),"formatter":_vm.formatter},model:{value:(_vm.$v.parts.$each.$iter[index].parte.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.parts.$each.$iter[index].parte.description, "$model", $$v)},expression:"$v.parts.$each.$iter[index].parte.description.$model"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v("Campo requerido")])],1)]}},{key:"cell(allocated)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[(_vm.$v.parts.$each.$iter[index])?_c('b-radio-group',{staticClass:"d-flex",attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].allocated")),"options":[{ text: 'Sí', value: true }, { text: 'No', value: false }]},model:{value:(_vm.$v.parts.$each.$iter[index].allocated.$model),callback:function ($$v) {_vm.$set(_vm.$v.parts.$each.$iter[index].allocated, "$model", $$v)},expression:"$v.parts.$each.$iter[index].allocated.$model"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].allocated"))}},[_vm._v("Campo requerido")])],1)]}},{key:"cell(realLocation)",fn:function(ref){
var part = ref.item;
return [_c('b-form-input',{model:{value:(part.parte.realLocation),callback:function ($$v) {_vm.$set(part.parte, "realLocation", $$v)},expression:"part.parte.realLocation"}})]}},{key:"cell(closeButton)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"circle",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.quitarParte(index)}}},[_vm._v("x")])]}}],null,false,3499358738)})],1),(_vm.parts.length < 50)?_c('button',{staticClass:"ask-parts mt-2",on:{"click":_vm.agregarParte}},[_vm._v("+ Agregar repuesto")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }