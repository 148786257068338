const mutations = {
  SET_NOTIFICATIONS(state, { notificacions }) {
    state.notificacions = notificacions
  },
  ADD_NOTIFICATIONS(state, { notificacions }) {
    state.notificacions.push(notificacions)
  },
  SET_NOTIFICATIONS_COUNT(state, notificacions_count) {
    state.notificacions_count = notificacions_count
  },
  SET_NOTIFICATIONS_NEWS(state, notificacions_news) {
    state.notificacions_news = notificacions_news
  },
  SET_NOTIFICATION(state, notification) {
    state.notification = notification
  },
}
export default mutations
