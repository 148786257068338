<template lang="pug">
#HistoryDetail
  .container-list
    b-card(border-variant="light")
      .d-inline-flex.align-items-center
        b-icon.mr-3(icon="info-circle-fill", variant="primary" font-scale="1.5")
        strong Descripción:
        div.mx-2 {{part.description}}
        strong.ml-2 Ubicación:
        div.ml-2.mr-4 {{part.real_location}}
        strong.ml-4 Buenos
        div.ml-4 {{part.cantidad_buenos}}
        strong.ml-4 Allocated
        div.ml-4 {{part.cantidad_allocated}}
        strong.ml-4 Drop
        div.ml-4 {{part.cantidad_drop}}
        strong.ml-4 Malos
        div.ml-4 {{part.cantidad_malos}}
        strong.ml-4 Salida
        div.ml-4 {{part.cantidad_salida}}
    b-table(
      :items="historyItems",
      :fields="fields",
      head-row-variant="light-primary",
      thead-class="text-black tabla-thead",
      tbody-class="tabla-tbody",
      tbody-tr-class="bg-white",
      responsive,
      :busy="loading",
      show-empty,
      empty-text="Sin datos en historial"
    )
      template(#cell(created_at)='value')
        div {{value.item.created_at | moment('DD-MM-YYYY HH:mm')}} Hrs.
      template(#cell(rma)='value')
        div(style="font-weight: bold; color:#253590") {{value.item.rma}}
      template(#cell(ot)='value')
        div(style="font-weight: bold; color:#253590") {{value.item.ot}}
  .d-flex.flex-row-reverse
    b-pagination(
      v-model="currentPage",
      :total-rows="count",
      prev-text="Ant.",
      next-text="Sig.",
      @change="handlePageChange"
    )
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'HistoryDetail',
  data() {
    return {
      historyItems: [],
      currentPage: 1,
      count: 0,
      part: {},
      loading: false,
      fields: [
        { key: 'created_at', label: 'Fecha del Movimiento', sortable: true },
        { key: 'tipo', label: 'Tipo de Movimiento', sortable: true },
        { key: 'cantidad', label: 'Cantidad', sortable: true },
        { key: 'rma', label: 'R.M.A Asociado' },
        { key: 'ot', label: 'O.T Asociada' },
        { key: 'user', label: 'Usuario' },
      ],
    }
  },
  methods: {
    ...mapActions('Warehouse', ['getHistoryPart']),
    async fetchData(page = 1) {
      this.loading = true
      const resp = await this.getHistoryPart({
        pk: this.$route.params.id,
        page,
      })
      if (resp.status === 200) {
        this.historyItems = resp.data.results
        this.count = resp.data.count
        this.part = resp.data.parte
      }
      this.loading = false
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
