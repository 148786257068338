/** Verifica que en los grupos que tiene el usuario se satisface alguno de los roles requeridos */
export default ({ roles, user }) => {
  if (!roles || roles.length === 0) return true
  if (!user || !user.user || !user.user.groups) return false
  let hasPermission = false
  const groups = user.user ? user.user.groups : user.groups
  groups.forEach((group) => {
    if (roles.includes(group.name)) {
      hasPermission = true
    }
  })
  return hasPermission
}
