<template lang="pug">
div
  .d-flex.flex-row(v-if="ot.diagnostico")
    b-button.mb-2(variant="success" @click="generatePdfOT" :disabled="ot.estado.estado.nombre === 'En Solicitud de Excepción' || disabled")
      span.sr.only Generar PDF
    //- b-button.mb-2(v-if="ot.informe_cliente_pdf && ot.diagnostico_detail.revisado"
    //-   :disabled="ot.estado.estado.nombre === 'En Solicitud de Excepción'"
    //-   style="margin-left: 5px;" variant="primary" @click="showEmailModal" size="small")
    //-   span.sr.only Reenviar correo a cliente
    b-form-checkbox.ml-auto(
      v-if="ot.informe_cliente_pdf"
      v-model="checked"
      name="check-button"
      switch
      :disabled="checked || ot.estado.estado.nombre !== 'Revisión informe'"
      @change="Review()"
    )
      | Informe Revisado: <b>{{ checked ? 'Revisado': 'No Revisado' }}</b>
      p.aviso(
          v-if="!ot.diagnostico_detail.revisado && ot.estado.estado.nombre !== 'Revisión informe'"
        ) Orden debe estar ingresada a bodega para aprobar informe
    b-icon.h3.ml-2(
      v-if="ot.informe_cliente_pdf && ot.diagnostico_detail.revisado"
      :title="ot.diagnostico_detail.revisado ? 'Informe Revisado' : 'Informe sin revisar'"
      :variant="ot.diagnostico_detail.revisado ? 'success' : ''"
    )
  .d-flex.justify-content-end.mb-2(v-if="ot.email_informe_status || (ot.diagnostico_detail && ot.diagnostico_detail.revisado)")
    .email-box(
      v-bind:class="bindClass"
    )
      .text-content(v-if="ot.email_informe_status")
        span {{ ot.email_informe_status.email_successful ? 'Email enviado correctamente ' : 'Email no se ha enviado correctamente ' }}
        span con fecha {{ formatDate(ot.email_informe_status.sending_datetime) }}
      .d-flex.justify-content-around.mt-2(v-if="ot.email_informe_status && ot.email_informe_status.email_successful === false")
        b-button(
          v-if="ot.email_informe_status.api_response && ot.email_informe_status.api_response !== ''"
          variant="outline-danger",
          v-b-tooltip.hover.left
          :title="'Respuesta de la API: ' + ot.email_informe_status.api_response"
        ) Ver Detalle
        b-button.mt-3(variant="outline-danger", @click="reSendEmail(ot.ot)" :disabled="loading") Reenviar E-Mail
      center.text-content(v-else-if="(ot.diagnostico_detail && ot.diagnostico_detail.revisado) || (ot.email_informe_status && ot.email_informe_status.email_successful === false)")
        span No se registra envío de correo
        b-button.mt-3(variant="outline-warning", @click="reSendEmail(ot.ot)" :disabled="loading") Reenviar E-Mail
  .container(v-if="ot.informe_cliente_pdf")
    b-spinner.mb-1.mr-1(small v-if="disabled")
    iframe(:src='informeUrl' :title="ot.codigo_retail" height="650px" width="100%")
  h4.text-center(v-else) No se ha generado el informe

  //- Modal confirmar reenvio email
  // b-modal(id="send-email-modal" @ok="sendClientEmail")
  //   p ¿Seguro reenviar correo a cliente?

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { backUrl } from '../../../settings'

export default {
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
    const rol = this.user.groups.filter((f) => f.id === 2)
    this.rol = rol
    if (this.ot && this.ot.diagnostico_detail) {
      this.checked = this.ot.diagnostico_detail.revisado
    }
  },
  data() {
    return {
      user: null,
      checked: false,
      disabled: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('Diagnosticos', [
      'getLastDiagnostic',
      'updateDiagnoseOT',
      'showDiagnosticForm',
    ]),
    ...mapActions('OTStore', ['getOT', 'sendEmailClient']),
    ...mapActions('Diagnosticos', ['generatePdf']),
    ...mapActions('OT', ['resendFailedEmail']),
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY, h:mm A');
    },
    async reSendEmail(ot) {
      this.loading = true
      const resp = await this.resendFailedEmail(ot);
      this.$bvToast.toast(`${resp.data}`, {
        variant: resp.status === 200 ? 'success' : 'danger',
      })
      this.loading = false
      await this.getOT(ot)
    },
    async generatePdfOT() {
      this.disabled = true
      const resp = await this.generatePdf(this.ot.diagnostico)
      if (resp.status === 200) {
        this.$bvToast.toast('PDF Generado correctamente', {
          variant: 'success',
        })
        await this.getOT(this.ot.ot)
      } else {
        this.$bvToast.toast(resp.data, {
          variant: 'danger',
        })
      }
      this.disabled = false
    },
    async Review() {
      if (!this.ot.diagnostico_detail.revisado) {
        let data = {}
        data = this.ot.diagnostico_detail
        data.revisado = true
        data.usuario_revisor = this.user.id
        await this.updateDiagnoseOT({
          ...data,
          id: this.ot.diagnostico_detail.id,
        })
        await this.getOT(this.ot.ot)
      }
      if (this.rol.length > 0) {
        this.showDiagnosticForm(true)
      }
    },
    // async sendClientEmail() {
    //   const res = await this.sendEmailClient(this.ot.ot);
    //   if (res.status === 200) {
    //     this.$bvToast.toast(res.data, { variant: 'success' });
    //   } else {
    //     this.$bvToast.toast(res.data, { variant: 'danger' });
    //   }
    // },
    // showEmailModal() {
    //   this.$bvModal.show('send-email-modal');
    // },
  },
  computed: {
    ...mapGetters('Diagnosticos', ['diagnostic']),
    ...mapGetters('OTStore', ['ot']),
    informeUrl() {
      if (this.ot && this.ot.informe_cliente_pdf) {
        return `${backUrl}${this.ot.informe_cliente_pdf}`
      }
      return null
    },
    bindClass() {
      if (this.ot.email_informe_status) {
        return {
          error: !this.ot.email_informe_status.email_successful,
          success: this.ot.email_informe_status.email_successful,
        }
      }
      if (this.ot.diagnostico_detail) {
        return {
          warning: this.ot.diagnostico_detail.revisado,
        }
      }
      return { success: true }
    },
  },
}
</script>

<style lang="scss" scoped>
.aviso {
  font-size: 0.9em;
}
.email-box {
  width: 300px;
  border-radius: 16px;
  padding: 24px;
  background: #50c72f;
  color: white;
  margin-right: 32px;
  &.error {
    background: #d8334f;
  }
  &.warning {
    background: #ffc107;
  }
  .btn-outline-danger, .btn-outline-danger:hover{
    background: white;
    color: red;
  }
  .btn-outline-warning, .btn-outline-warning:hover{
    background: white;
    color: #ffc107;
  }
}
</style>
