<template lang="pug">
.select-technician
  div
    //- BOTON MARCAR PAGO COMPLETO (CUANDO HAY UN PRESUPUESTO ABONADO)
    button.change-button(
      :disabled="disabled"
      title="Marcar Pago Completo"
      @click="showModal()")
      | Marcar Pago Completo
    b-modal.hide-footer(v-model="showModalConfirm" no-close-on-backdrop)
        div.d-block.text-center
          h5 ¿Estás seguro de confirmar el pago completo del presupuesto?
        template(#modal-footer='{ ok, cancel, hide }')
          b-button(size='sm' variant='success' @click='markPayment()') Si
          b-button(size='sm' variant='danger' @click='hideModal()') No
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'ActionDarSalida',
  props: ['ot', 'disabled', 'toast'],
  data() {
    return {
      showModalConfirm: false,
    }
  },
  methods: {
    ...mapActions('Quotations', ['markPaymentQuotation']),
    showModal() {
      this.showModalConfirm = true
    },
    hideModal() {
      this.showModalConfirm = false
    },
    async markPayment() {
      const resp = await this.markPaymentQuotation({
        data: {
          estado_pago: 'COMPLETO',
        },
        id: this.ot.presupuesto_id,
      })

      this.hideModal()

      if (resp && resp.status === 200) {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
        this.$emit('changed')
      } else {
        this.$bvToast.toast(`Error al marcar pago cotización: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
  }
}
</script>
