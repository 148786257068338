// Valida el rut com formato "XXXXXXXX-X"
export default (fullRut) => {
  fullRut = fullRut.replaceAll('.', '') // eslint-disable-line
  if (fullRut.length < 8) {
    return false
  }
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(fullRut)) {
    return false
  }
  const tmp = fullRut.split('-') // eslint-disable-line
  let digv = tmp[1] // eslint-disable-line
  let rut = tmp[0] // eslint-disable-line
  if (digv == 'K') digv = 'k' // eslint-disable-line

  let M = 0
  let S = 1
  for (; rut; rut = Math.floor(rut / 10)) {
    S = (S + (rut % 10) * (9 - (M++ % 6))) % 11 // eslint-disable-line
  }

  return (S ? S - 1 : 'k') == digv // eslint-disable-line
}
