<template lang="pug">
b-card.sill-card.graph-card
  .flex-col
    b-avatar(size="48").laptop-icon.mb-2
      b-icon(icon="laptop", font-scale="1.4")
    span Cantidad Total OTs
    span.order-count {{ totalOrders }}
</template>

<script>
import { mapState } from 'pinia'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'

export default {
  computed: {
    ...mapState(useUserReportStore, ['totalOrders']),
  },
}
</script>

<style lang="scss" scoped>
.graph-card {
  background-image: url('~@/assets/img/graph-img.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20px 72px;
  background-size: calc(100% - 40px);
  width: 335px;
  height: 200px;
  color: #707070;
  font-size: 16px;
  font-weight: bold;
}

.laptop-icon {
  background-color: #92a1ff;
}

.order-count {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.1;
  color: #5B73E8;
}
</style>
