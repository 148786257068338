<template lang="pug">
  #WarrantyFailsRegions
    b-card.form-card
      b-row
        span#icon 02
        h5.title.mt-2 Tipo de garantia y falla
      hr
      b-row
        b-col(cols="12" md='3')
          b-form-group(class="servicio" label-for="input-servicio")
            template( #label ) Tipo de servicio:
            b-form-select(
              id="input-falla"
              v-model="formWarranty.tipo_servicio"
              :options="serviceOptions"
              :state="$v.formWarranty.tipo_servicio.$dirty? !$v.formWarranty.tipo_servicio.$invalid : null"
            )
            .errors(v-if="$v.formWarranty.tipo_servicio.$dirty")
              b-form-invalid-feedback(:state="$v.formWarranty.tipo_servicio.required")
                | Campo requerido
        b-col(cols="12")
          b-form-group(class="falla" label-for="input-falla" :label-class="activeField === 'falla' ? 'active-label' : ''")
            template( #label ) Reporte de falla:
            b-form-input(
              id="input-falla"
              v-model="formWarranty.reporte_falla_cliente"
              :state="$v.formWarranty.reporte_falla_cliente.$dirty? !$v.formWarranty.reporte_falla_cliente.$invalid : null"
              @focus="activeField = 'falla'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.formWarranty.reporte_falla_cliente.$dirty")
              b-form-invalid-feedback(:state="$v.formWarranty.reporte_falla_cliente.required")
                | Campo requerido
        b-col(cols="12")
          b-form-group(v-show="formWarranty.tipo_servicio == 1" class="fecha" label-for="input-fecha")
            template( #label ) Fecha de compra:
            b-form-datepicker(
              v-model='formWarranty.fecha_de_compra'
              :state="$v.formWarranty.fecha_de_compra.$dirty? !$v.formWarranty.fecha_de_compra.$invalid : null"
              placeholder = "Seleccione una fecha"
              select-variant="primary")
            .errors(v-if="$v.formWarranty.fecha_de_compra.$dirty")
              b-form-invalid-feedback(:state="$v.formWarranty.fecha_de_compra.required")
                | Campo requerido
      b-form-group(v-show="formWarranty.tipo_servicio == 1")
        label(class="client-form-inputs" for="input-adjuntar") Adjuntar datos de Boleta:
        b-form-group(class="UploadFile")
          UploadFilePreIngreso(:setBoleta="setBoleta")
        .errors(v-if="$v.boleta.$dirty")
          b-form-invalid-feedback(:state="$v.boleta.required")
            | Campo requerido
      .d-flex.justify-content-between
        .botones(class="btn")
          b-button.btn-create(@click="goBack()" small)
            | Anterior
        .botones(class="btn")
          b-button.btn-create(@click="onSubmit()" small)
            | Siguente
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import UploadFilePreIngreso from '../Form/UploadFilePreIngreso.vue'

export default {
  name: 'WarrantyFailsRegions',
  props: ['setDataWarranty', 'safeBoleta', 'warrantyReturn'],
  components: {
    UploadFilePreIngreso,
  },
  data() {
    return {
      required: false,
      activeField: '',
      formWarranty: {
        tipo_servicio: this.warrantyReturn.tipo_servicio
          ? this.warrantyReturn.tipo_servicio
          : null,
        reporte_falla_cliente: this.warrantyReturn.reporte_falla_cliente
          ? this.warrantyReturn.reporte_falla_cliente
          : '',
        fecha_de_compra: this.warrantyReturn.fecha_de_compra
          ? this.warrantyReturn.fecha_de_compra
          : null,
      },
      boleta: null,
      serviceOptions: [
        { value: null, text: 'Seleccione' },
        { value: 1, text: 'Garantía' },
        { value: 4, text: 'Presupuesto' },
      ],
    }
  },
  validations: {
    formWarranty: {
      tipo_servicio: { required },
      reporte_falla_cliente: {
        required: requiredIf(function () {
          return this.formWarranty.tipo_servicio === 1 || this.formWarranty.tipo_servicio === 4;
        }),
      },
      fecha_de_compra: {
        required: requiredIf(function () {
          return this.formWarranty.tipo_servicio === 1;
        }),
      },
    },
    boleta: {
      required: requiredIf(function () {
        return this.formWarranty.tipo_servicio === 1;
      }),
    },
  },
  methods: {
    async onSubmit() {
      if (this.$v.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.setDataWarranty(this.formWarranty)
        this.safeBoleta(this.boleta)
        this.$bvToast.toast('Datos guardado exitosamente', {
          variant: 'success',
        })
      }
    },
    setBoleta(boleta) {
      this.boleta = boleta
    },
    goBack() {
      this.$emit('goBack')
    },
  },
  watch: {
    warrantyReturn: {
      handler() {
        this.formWarranty = {
          tipo_servicio: this.warrantyReturn.tipo_servicio
            ? this.warrantyReturn.tipo_servicio
            : null,
          reporte_falla_cliente: this.warrantyReturn.reporte_falla_cliente
            ? this.warrantyReturn.reporte_falla_cliente
            : '',
          fecha_de_compra: this.warrantyReturn.fecha_de_compra
            ? this.warrantyReturn.fecha_de_compra
            : null,
        }
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;
  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
</style>
