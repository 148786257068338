import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ClientStore = {
  namespaced: true,
  state: {
    client: null,
    completeClient: null,
    empresas: [],
    sucursales: [],
    sucursal: null,
    errorSucursal: null,
    regiones: [],
  },
  getters,
  mutations,
  actions,
}

export default ClientStore
