<template lang="pug">
#list
  QuotationSearch.mb-3(:estados="estados")
  b-tabs(pills card lazy)
    b-tab(
      v-for="(tab, index) in tabs"
      :title="`${tab.title || tab.states[0]} (${tab.states.length > 0 ? estados_count(tab.states[0]) : estados_count('')}) `"
      :title-item-class="isSearched?'d-none' : 'text-black'"
      @click ="handleTabChange(index)"
    )
      b-table(
        :fields="fields"
        :items="currentTableItems"
        head-row-variant="light-primary",
        thead-class="text-black tabla-thead"
        tbody-class="tabla-tbody"
        tbody-tr-class="bg-white"
        responsive
        :busy="loading"
        show-empty
        empty-text="No hay Ordenes de Trabajo en este estado"
      )
        template(#table-busy)
          div(class="text-center text-danger my-2")
            b-spinner(class="align-middle")
        template(#cell(pdf)='value')
          a(v-if="value.item.pdf" :href="value.item.pdf" target="_blank") Descargar
          p(v-else) -
        template(#cell(acciones)='value')
          ActionsQuotations(:otId="value.item.ot", :quotation="value.item" :squareLayout="true" :loading="false")
          b-button(@click="openQuotationDetail(value.item)") Ver
        template(#cell(ot)='value')
          .d-flex.justify-content-start
            router-link(:to="`orden/${value.item.ot}`" :style="getColorOT(value.item.estado)") {{value.item.ot}}
      div.d-flex.flex-row-reverse
        b-pagination(
          v-model="currentPage"
          :per-page="pageSize"
          prev-text="Ant."
          next-text="Sig."
          @change="handlePageChange"
        )
  QuotationDetail(ref="quotationDetail")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import QuotationSearch from './QuotationsSearch.vue'
import QuotationDetail from './QuotationDetail.vue'
// import ActionsQuotations from './ActionQuotations.vue';
import ActionsQuotations from './ActionsQuotations.vue'

export default {
  name: 'QuotationsList',
  props: ['ot'],
  components: {
    ActionsQuotations,
    QuotationDetail,
    QuotationSearch,
  },
  data() {
    return {
      tabs: [
        { title: 'Especifica piezas', states: ['ESPECIFICA_PIEZAS'] },
        { title: 'Presupuestado', states: ['PRESUPUESTADO'] },
        { title: 'Aprobado cliente', states: ['APROBADO_CLIENTE'] },
        { title: 'Rechazado cliente', states: ['RECHAZADO_CLIENTE'] },
        { title: 'Todos', states: ['ALL'] },
      ],
      currentPage: 1,
      pageSize: 5,
      selectedTab: 0,
      loading: false,
      user: null,
    }
  },
  async created() {
    await this.searchQuotations({
      estado: 'ESPECIFICA_PIEZAS',
    })
    this.$emit('stop-loading')
  },
  methods: {
    ...mapActions('Quotations', ['searchQuotations']),
    ...mapMutations('Quotations', [
      'SET_QUOTATIONS',
      'SET_IS_SEARCHED',
      'SET_IS_CREATED',
    ]),
    estados_count(value) {
      if (this.quotations.estados_count) {
        if (value === 'ESPECIFICA_PIEZAS')
          return this.quotations.estados_count.count_especifica_piezas
        if (value === 'PRESUPUESTADO')
          return this.quotations.estados_count.count_presupuestado
        if (value === 'APROBADO_CLIENTE')
          return this.quotations.estados_count.count_aprobado_cliente
        if (value === 'RECHAZADO_CLIENTE')
          return this.quotations.estados_count.count_rechazado_cliente
        if (value === 'ALL') return this.quotations.estados_count.count_all
      }
    },
    getColorOT(estado) {
      let style = 'color:#41509B; font-weight: bold'
      if (estado) {
        const state = estado
        if (state === 'Solicitado') {
          style = 'color:#7F828F; font-weight: bold'
        }
      }
      return style
    },
    getDate(date) {
      if (date) {
        const formatedDate = moment(date).format('DD/MM/YYYY')
        return formatedDate
      }
      return '-'
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
    handleTabChange(index) {
      this.selectedTab = index
      this.fetchData()
    },
    async fetchData(page = 1) {
      this.loading = true
      await this.searchQuotations({
        estado: this.tabs[this.selectedTab].states[0],
        search: this.$route.params.otId,
        page,
      })
      this.loading = false
    },
    openQuotationDetail(quotation) {
      this.$refs.quotationDetail.open(quotation)
    },
  },
  computed: {
    ...mapGetters('Quotations', [
      'quotations',
      'isSearched',
      'currentQuotations',
      'currentStates',
    ]),
    fields() {
      const fields = [
        { key: 'ot', label: 'O.T', stickyColumn: true },
        { key: 'fecha_hora_creacion', label: 'Fecha' },
        { key: 'tipo' },
        { key: 'estado' },
        { key: 'rma', label: 'RMA' },
        { key: 'numero_cotizacion', label: 'Número\xa0de presupuesto' },
        { key: 'pdf', label: 'Archivo' },
        { key: 'acciones', label: 'Acciones' },
      ]
      return fields
    },
    currentTableItems() {
      if (
        this.quotations &&
        this.quotations.cotizaciones_internas &&
        this.quotations.cotizaciones_internas.results.length > 0
      ) {
        return this.quotations.cotizaciones_internas.results.map((c) => { //eslint-disable-line
          return {
            ...c,
            rma: c.rma ? c.rma : '-',
            fecha_hora_creacion: this.getDate(c.fecha_hora_solicitud),
            all_time: c.tiempo_estados ? c.tiempo_estados.hora_total : '-',
            state_time: c.tiempo_estados
              ? c.tiempo_estados.hora_estado_actual
              : '-',
            tipo: c.modelo ? c.modelc.dispositive_type : '-',
            numero_cotizacion: c.numero_cotizacion ? c.numero_cotizacion : '-',
          }
        })
      }
      return []
    },
    /** Reduce las tabs a arreglo de estados para pasárselos a los filtros de búsqueda */
    estados() {
      let states = String
      if (this.tabs[this.selectedTab].states.length > 0) {
        states = this.tabs[this.selectedTab].states.map((t) => t)
      }
      return states
    },
  },
  watch: {
    isCreated(value) {
      if (value) {
        this.SET_IS_CREATED(null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.status {
  font-weight: bold;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
  margin: auto;
}
.ot {
  color: #41509b;
  font-weight: bold;
}
::v-deep .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8;
  font-weight: bold;
}
::v-deep .card-header {
  background-color: #ffffff;
  overflow-x: auto;

  ul {
    overflow-x: visible;
  }

  a {
    color: #495057;
    font-weight: bold;
    &:hover {
      color: #495057;
    }
  }
}
::v-deep table th {
  color: #495057;
}
a.router-link-active {
  color: #5b73e8;
}
.text-danger {
  color: #5b73e8 !important;
}
::v-deep .card-body {
  padding: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5b73e8 !important;
  font-weight: bold;
}

::v-deep .tabla-tbody td {
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

::v-deep .tabla-thead th {
  @include media-breakpoint-down(sm) {
    padding: 4px 10px 4px;
  }
}
</style>
