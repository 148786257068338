import axios from '../../plugins/axios'

export const listException = ({ commit }, page = 1) => {
  if (page === 1) {
    const apiAddress = 'api/excepcion/?page=1'
    const { key } = JSON.parse(localStorage.getItem('user'))
    const config = {
      headers: { Authorization: `Token ${key}` },
    }
    return axios.get(apiAddress, config).then((response) => {
      commit('SET_EXCEPTIONS', { exceptions: response.data })
    })
  }
  const apiAddress = `api/excepcion/?page=${page}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_EXCEPTIONS', { exceptions: response.data })
  })
}

export const searchException = ({ commit, state }, { estado, ot, page, search }) => {
  const url = 'api/excepcion/'
  let estadoId = 1
  if (estado && estado.length > 0) {
    if (estado[0] === 'solicitado') {
      estadoId = 1
    }
    if (estado[0] === 'aprobado') {
      estadoId = 2
    }
    if (estado[0] === 'rechazado') {
      estadoId = 3
    }
  } else {
    estadoId = null
  }
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  const params = {
    ot,
    estado: estadoId,
    page,
    search,
  }
  return axios.get(url, { params, ...config }).then((response) => {
    commit('SET_CURRENT_EXCEPTIONS', {
      exceptions: response.data,
      states: estado,
      lastQuery: JSON.stringify(url),
      page: response.data.next ? state.page + 1 : null,
    })
  })
}

export const createExcepcion = async ({ commit }, data) => {
  // eslint-disable-line
  const apiAddress = '/api/excepcion/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  const dataToSend = {
    ...data,
    estado: 1,
  }

  return axios
    .post(apiAddress, dataToSend, config)
    .then((response) => {
      commit('SET_STATUS', response)
      commit('SET_IS_CREATED', true)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
      return error
    })
}

export const editExcepcion = ({ commit }, data) => {
  // eslint-disable-line
  const apiAddress = `/api/excepcion/${data.id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', response)
      commit('SET_IS_CREATED', true)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
    })
}

export const getExcepcion = ({ commit }, excepcionId) => {
  // eslint-disable-line
  const apiAddress = `/api/excepcion/${excepcionId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_EXCEPTION', { exception: response.data })
  })
}

export const deleteException = ({}, excepcionId) => {
  const apiAddress = `api/excepcion/${excepcionId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.delete(apiAddress, config)
}
