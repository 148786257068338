<template lang="pug">
b-modal(
  v-model="model"
  title="Nuevo Cliente Empresa"
)
  b-form-group(label-for="input-rut")
    template( #label ) RUT
    b-form-input(
      ref="rutField"
      v-if="!idCliente"
      v-model="formEmpresa.rut"
      id="input-rut"
    )
    b-form-invalid-feedback Campo requerido
    span(
      v-if="idCliente"
      class="text-muted"
    ) {{ formEmpresa.rut }}

  FormEmpresa(
    ref="form"
    v-model="formEmpresa"
    :idCliente="idCliente"
  )

  b-alert.mt-3(:show="idCliente && sucursales.length === 0" variant="warning")
    b-icon.mr-3(icon="exclamation-triangle")
    | Debe crear al menos una sucursal

  template(#modal-footer)
    b-button(
      variant="secondary"
      @click="handleClose"
    ) Cancelar
    b-button(
      v-if="!idCliente"
      variant="primary"
      :disabled="loading"
      @click="handleCreate"
    ) Crear
    b-button(
      v-else
      variant="primary"
      :disabled="loading || sucursales.length === 0"
      @click="handleOkay"
    ) Aceptar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormEmpresa from './ClientForm/FormEmpresa.vue'
import * as cts from '../constants'

export default {
  props: { value: Boolean },
  components: { FormEmpresa },
  data() {
    return {
      loading: false,
      idCliente: null,
      formEmpresa: {
        rut: null,
        razon_social: null,
        correos_empresa: [],
        sucursal: null,
      },
    }
  },
  computed: {
    ...mapGetters('ClientStore', ['sucursales']),
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    ...mapActions('ClientStore', ['createClientNoStore']),

    async handleCreate() {
      this.loading = true

      if (!this.$refs.form.validate() || !this.formEmpresa.rut) {
        return this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
      }

      const data = {
        razon_social: this.formEmpresa.razon_social,
        correo_empresas: JSON.stringify(this.formEmpresa.correos_empresa),
        tipo_cliente: cts.CLIENTE_EMPRESA,
        rut: this.formEmpresa.rut,
      }
      const res = await this.createClientNoStore({ data })
      if (res.status === 200) {
        this.$bvToast.toast('Cliente creado exitosamente', { variant: 'success' })
        this.$nextTick(() => this.$refs.form.unTouch())
        this.idCliente = res.data.clienteEmpresa.id
      } else {
        this.$bvToast.toast('Error al guardar el cliente', { variant: 'danger' })
      }

      this.loading = false
    },

    async handleClose() {
      this.loading = true
      this.$nextTick(() => {
        this.$refs.form.resetForm()
        this.formEmpresa = {
          rut: null,
          razon_social: null,
          correos_empresa: [],
          sucursal: null,
        }
        this.idCliente = null
      })
      this.model = false
      this.loading = false
    },

    async handleOkay() {
      this.loading = true
      this.$emit('created', this.idCliente)
      this.handleClose()
    },
  },
}
</script>

<style lang="scss" scoped></style>
