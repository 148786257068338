<template lang="pug">
Fragment
  div.d-flex.justify-content-center( v-if="isEditMode && !ot" )
    b-spinner( variant="secondary" )
  .px-0.px-md-4( v-else )
    .d-flex.justify-content-between.align-items-center
      BackArrow
      b-breadcrumb( :items="breadcrumbs" variant="primary")
    OTSection(
      step="01"
      title="Datos del Cliente"
      :open="true"
      :section="'client'"
    )
      div.d-flex
        ClientForm.ot-box(@openSection="setOpenEquipmentSection", :provisoryRut="provisoryRut")
        b-card(class="cardProvisorio" v-if="ot && ot.datos_provisorios" header="Datos provisorios del cliente")
          b-card-text
            | Nombre: {{ ot.datos_provisorios.nombre ? ot.datos_provisorios.nombre : ''}}
            br
            | Rut: {{ ot.datos_provisorios.rut ? ot.datos_provisorios.rut :''}}
          b-button(@click="preComplete(ot.datos_provisorios.rut)" v-if="client === null" size="sm").w-100 Pre-Completar
    OTSection(
      step="02"
      v-if="openEquipment === true"
      title="Datos del Equipo"
      :open="true"
      :section="'equipment'"
    )
      EquipmentForm.ot-box(@openSection="setOpenOTSection")
    OTForm.ot-box(v-if="equipment && openOT === true")
</template>

<script>
/** @module NewOTIndex
 * Permite crear o editar una orden de trabajo con una secuencia de pasos
 */
import { mapActions, mapGetters, mapMutations } from "vuex";
import OTSection from "./OTSection.vue";
import OTForm from "./OTForm.vue";
import ClientForm from "./ClientForm.vue";
import EquipmentForm from "./EquipmentForm.vue";
import BackArrow from "../../UI/BackArrow.vue";

export default {
  name: "NewOTIndex",
  components: {
    OTSection,
    OTForm,
    ClientForm,
    EquipmentForm,
    BackArrow,
  },
  created() {
    const { otId, order_id } = this.$route.params;
    this.isEditMode = !!otId || !!order_id;
    this.fetchOT(otId || order_id);
    this.breadcrumbs = [
      {
        text: "Inicio",
        to: { path: "/" },
      },
      {
        text: "Ordenes de Trabajo",
        to: { path: "/" },
      },
      {
        text: this.isEditMode ? "Editar Orden de Trabajo" : "Crear Orden de Trabajo",
        active: true,
      },
    ];
  },
  data() {
    return {
      isEditMode: false,
      openEquipment: false,
      openOT: false,
      provisoryRut: null,
    };
  },
  computed: {
    ...mapGetters("OTStore", ["ot"]),
    ...mapGetters("ClientStore", ["client", "sucursal"]),
    ...mapGetters("EquipmentStore", ["equipment"]),
  },
  watch: {
    $route() {
      // Refrescar la página al cambiar entre Editar/Crear OT
      this.$router.go();
    },
    ot(value) {
      if (value.ot) {
        this.openOT = true;
        this.openEquipment = true;
      }
    },
  },
  methods: {
    ...mapActions("OTStore", ["getOT", "editOT"]),
    ...mapMutations("OTStore", ["SET_OT", "SET_EQUIPO"]),
    ...mapMutations("ClientStore", ["SET_CLIENT", "SET_SUCURSAL"]),
    ...mapMutations("EquipmentStore", ["SET_EQUIPMENT"]),
    preComplete(rut) {
      this.provisoryRut = rut;
    },
    setOpenEquipmentSection(value) {
      if (value === true) {
        this.openEquipment = true;
      }
    },
    setOpenOTSection(value) {
      if (value === true) {
        this.openOT = true;
      }
    },
    async fetchOT(otId) {
      if (otId) {
        await this.getOT(otId);
      } else {
        this.SET_OT({ ot: null });
        this.SET_CLIENT({ client: null });
        this.SET_EQUIPMENT({ equipment: null });
        this.SET_EQUIPO({ equipment: null });
        this.SET_SUCURSAL({ sucursal: null });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cardProvisorio {
  margin-top: -2%;
  margin-right: 3%;
  width: 20%;
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .cardProvisorio {
    font-size: small;
  }
}
.card-body {
  padding: 8px 25px;
}
</style>
