<template lang="pug">
  .ot-section
    .header
      .step {{ step }}
      .title {{ title }}
      div.ml-auto
        b-icon(
          :icon="isOpen ? 'chevron-up' : 'chevron-down'"
          v-b-toggle="`collapse-${section}-section`"
          font-scale="1.5"
          variant="primary"
          @click="openAndClose"
        )
    .content
      slot
</template>

<script>
export default {
  name: 'OTSection',
  props: ['step', 'title', 'open', 'section', 'openSection'],
  created() {
    if (this.open === true) {
      this.isOpen = this.open
    }
  },
  data() {
    return {
      isOpen: true,
    }
  },
  methods: {
    openAndClose() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ot-section {
  margin: 10px 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 15px;
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .step {
      background-color: #5c5fc1;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      flex-shrink: 0;
    }
    .title {
      color: #74788d;
      font-weight: bold;
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      .icon-end {
        position: relative;
        left: 55vw;
      }
    }
  }
  .content {
    padding: 20px 15px 0;
    @include media-breakpoint-down(sm) {
      padding: 20px 0 0;
    }
  }
}
</style>
