const mutations = {
  SET_CLIENT(state, { cliente_object_id }) {
    if (!state.ot) {
      state.ot = {}
    }
    state.ot.cliente = cliente_object_id
    state.ot.tipo_cliente = 1 // TODO: cambiar implementación cuando back actualice modelo a herencia
  },
  SET_EQUIPO(state, { equipment }) {
    if (!state.ot) {
      state.ot = {}
    }
    state.ot.equipo = equipment
  },
  SET_OT(state, { ot }) {
    state.ot = ot
  },
  SET_STORE_LIST(state, { storeList }) {
    state.storeList = storeList
  },
  SET_OTS(state, { ots }) {
    state.ots = ots
  },
  SET_IS_SEARCHED_OT(state, { isSearchedOT }) {
    state.isSearchedOT = isSearchedOT
  },
  SET_IT_SEARCHED_OT(state, itSearchedOT) {
    state.itSearchedOT = itSearchedOT
  },
  SET_STATUS(state, statusRequest) {
    state.statusRequest = statusRequest
  },
  SET_EXCEL_RESPONSE(state, excelResponse) {
    state.excelResponse = excelResponse
  },
  SET_FILES(state, files) {
    state.files = files
  },
  SET_FILE(state, uploadingFile) {
    state.uploadingFile = uploadingFile
  },
  SET_PRINT_NO_DISPLAY(state, printNoDisplay) {
    state.printNoDisplay = printNoDisplay
  },
  SET_COURIER(state, { couriers }) {
    state.couriers = couriers
  },
  SET_DISTRIBUIDORES(state, { distribuidores }) {
    state.distribuidores = distribuidores
  },
}
export default mutations
