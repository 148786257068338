<template lang="pug">
#ActionMarkPayment
  //- ACCION MARCAR TIPO DE PAGO
  div
    //- Botón acción
    button.change-button(
      @hide="hideModal()"
      @click="modal = true"
      ) Marcar pago
    //-Modal de revisión
    b-modal(v-model="modal" size="lg")
      template(#modal-title v-if="ot")
        span.header-modal.text-center Marcar tipo de pago presupuesto {{ ot.ot }}
      slot
      hr
      label(for="selectedType") Seleccione tipo de pago
      b-form-select(v-model="selectedType" :options="options" id="selectedType")
      template(#modal-footer='{ ok, cancel }')
        b-button(size='sm' variant='success' @click="markPayment" :disabled="selectedType === null") Confirmar
        b-button(size='sm' variant='danger' @click='modal = false;') Cancelar
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['ot', 'quotation'],
  data() {
    return {
      modal: false,
      selectedType: null,
      options: [
        { value: null, text: 'Seleccione...' },
        { value: 'COMPLETO', text: 'Completo' },
        { value: 'ABONO', text: 'Abono' },
        { value: 'DIAGNOSTICO', text: 'Diagnóstico' },
        { value: 'SIN_PAGO', text: 'Sin pago' },
      ],
    }
  },
  methods: {
    ...mapActions('Quotations', ['searchQuotations', 'markPaymentQuotation']),
    async markPayment() {
      const resp = await this.markPaymentQuotation({
        data: {
          estado_pago: this.selectedType,
        },
        id: this.quotation.id,
      })

      if (resp && resp.status === 200) {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
        this.searchQuotations({
          estado: 'APROBADO_INTERNO',
        })
        this.modal = false
      } else {
        this.$bvToast.toast(`Error al marcar pago cotización: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
