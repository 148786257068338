import { render, staticRenderFns } from "./AskParts.vue?vue&type=template&id=a3434232&scoped=true&lang=pug"
import script from "./AskParts.vue?vue&type=script&lang=js"
export * from "./AskParts.vue?vue&type=script&lang=js"
import style0 from "./AskParts.vue?vue&type=style&index=0&id=a3434232&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3434232",
  null
  
)

export default component.exports