import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { getHeaders } from './utils'

const listExternalQuotes = (params) => {
  const url = '/api/quotes/external-quote/'
  const config = { params, ...getHeaders() }
  return axios.get(url, config).catch((error) => error.response)
}

const createExternalQuote = (data) => {
  const url = '/api/quotes/external-quote/'
  const config = { ...getHeaders() }
  return axios.post(url, data, config).catch((error) => error.response)
}

const listPartCategories = (params) => {
  const url = '/api/quotes/part-category/'
  const config = { params, ...getHeaders() }
  return axios.get(url, config).catch((error) => error.response)
}

const uploadQuote = ({ id, data }) => {
  const url = `/api/quotes/upload-quote/${id}/`
  const config = { ...getHeaders() }
  return axios.post(url, data, config).catch((error) => error.response)
}

export const useExternalQuoteStore = defineStore('externalQuote', {
  state: () => ({}),

  actions: {
    listExternalQuotes,
    createExternalQuote,
    listPartCategories,
    uploadQuote,
  },
})
