<template lang="pug">
  div
    //- Boton para crear presupuesto interna
    button.change-button(
      :disabled="disabled"
      @click="show = true"
      @hide="hideModal()"
    ) Solicitud de cotizacion
    b-modal.hide-foooter(v-model="show" title="Solicitar presupuesto de equipo" size="xl" scrollable)
      b-container()
        b-row
          h5 Repuestos
        b-row(v-for="(part, index) of parts" :key="index" responsive)
          b-col(cols="1" class="d-flex justify-content-center align-items-center")
            .circle
              span.number {{index + 1}}
          b-col(cols="9" class="d-flex")
            div(class="flex-grow-1")
              label.part-number Part Number
              SILLAutocomplete(
                @click.native="onclick(index)"
                :search="searchParts"
                displayAttribute="part_number"
                :placeholder="part ? part.parte.partNumber: 'Ej: AF3FC3F'"
                :onSubmit="onPartSelected"
                :searching="searching"
                v-bind:key="index"
              )
            div(class="ml-2")
              label.label(class="mb-0") Código
              VueBarcode(v-model="part.parte.partNumber" width=1 height=30 textAlign="center" fontSize="13")
          b-col(cols="1" class="d-flex justify-content-center align-items-center")
            b-button.deleteButtonPart(@click="removeParts(index)" variant="danger") x
        button.addPartButton(@click="addParts" v-if="parts.length < 50").ask-parts + Agregar repuesto
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='onSubmit()' :disabled="parts.length === 0 || validateParts") Guardar
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueBarcode from 'vue-barcode'
import SILLAutocomplete from '@/components/UI/SILLAutocomplete.vue'
import hasPermissions from '../../../utils/permissions'

export default {
  name: 'ActionInternalQuotation',
  props: ['ot', 'disabled'],
  components: {
    SILLAutocomplete,
    VueBarcode,
  },
  data() {
    return {
      show: false,
      parts: [],
      partesModelo: [],
      inputText: null,
      searching: false,
      selectedPart: null,
    }
  },
  methods: {
    ...mapActions('Diagnosticos', ['searchByPartNumber']),
    ...mapActions('Quotations', ['specifyPartsQuotation']),
    ...mapActions('OTStore', ['getOT']),
    ...mapActions('PartsStore', ['listPartsModels']),
    hideModal() {
      this.show = false
      this.parts = []
    },
    async onSubmit() {
      const partes = this.parts.map((parte) => {
        //eslint-disable-line
        return {
          partNumber: parte.parte.partNumber,
        }
      })
      const data = {
        partes_solicitadas: partes,
      }
      const resp = await this.specifyPartsQuotation({ data, ot: this.ot.ot })
      this.$emit('changed')
      this.hideModal()
      if (resp && resp.status < 300) {
        this.$bvToast.toast(resp.data, {
          variant: 'success',
        })
      } else {
        this.$bvToast.toast(`Error al crear cotización: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
    onclick(index) {
      if (this.selectedPart) {
        this.parts[index].parte.id = this.selectedPart.id
        this.parts[index].parte.partNumber = this.selectedPart.part_number
      }
      this.selectedPart = null
    },
    async searchParts(partNumber) {
      this.searching = true
      this.inputText = partNumber
      if (partNumber.length <= 2) {
        return []
      }
      const resp = await this.searchByPartNumber({ partNumber })
      this.searching = false
      return this.getPartesOrdered(resp.data.results)
    },
    onPartSelected(partSelected) {
      this.selectedPart = partSelected
    },
    removeParts(index) {
      this.parts.splice(index, 1)
    },
    setPartNumber(index) {
      this.parts[index].parte.partNumber = this.inputText
    },
    addParts() {
      this.parts.push({
        ot: this.ot.ot,
        parte: {
          id: null,
          partNumber: 'AF3FC3F',
        },
      })
    },
    getPartesOrdered(parts) {
      const partes = parts.filter((part) => this.partesModelo.every((p) => p.parte.id !== part.id))
      const partesModelo = this.partesModelo.map((parte) => {
        return  {
          ...parte.parte,
          recomended: true
        }
      })
      return [...partesModelo, ...partes]
    },
    async fetchData() {
      this.partesModelo = await this.listPartsModels({ idModelo: this.ot.equipo.modelo})
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('OTStore', ['statusRequest']),
    showButton() {
      return (
        this.ot.diagnostico &&
        this.ot.estado &&
        this.ot.estado.estado.orden < 10 &&
        hasPermissions({ user: this.user, roles: ['Administrativo'] })
      )
    },
    validateParts() {
      return this.parts.some((p) => p.parte.id === null)
    }
  },
  watch: {
    show() {
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.size-small {
  width: 200px;
}
.big-size {
  font-size: 10rem;
}
#file {
  font-weight: 600;
  font-size: 15px;
}
.form-control {
  border: 1px solid #cbcbcb;
  opacity: 1;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #5c5fc1;
  border-radius: 50%;
  .number {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}
.deleteButtonPart {
  border-radius: 50%;
}
.addPartButton {
  margin-top: 20px;
}
.ask-parts {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 175px;
  height: 45px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.part-number {
  color: #253590;
}
</style>
