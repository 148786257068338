const mutations = {
  SET_DIAGNOSTIC(state, { diagnostic }) {
    state.diagnostic = diagnostic
  },
  SHOW_DIAGNOSTIC_FORM(state, { showDiagnosticForm }) {
    state.showDiagnosticForm = showDiagnosticForm
  },
  SET_FILTERED_PARTS(state, { filteredParts }) {
    state.filteredParts = filteredParts
  },
}
export default mutations
