<template lang="pug">
div
  b-card.form-card
    b-row
      span#icon 04
      h5.title.mt-2 Resumen
    hr
    div.mt-2
      .d-flex.flex-row
        b-icon.mr-2(icon="clipboard-data" variant="primary" font-scale="1.5")
        h3.title-data Datos Cliente
      hr
      div.d-flex.row
        b-col(cols='12' md='4')
          h6.input-label R.U.T:
          p.display-data {{ dataClient.rut }}
        b-col(cols='12' md='4')
          h6.input-label Tipo de cliente:
          p.display-data {{ dataClient.tipo_cliente == 2 ? 'Cliente Empresa' : 'Cliente Persona' }}
        b-col(v-if="dataClient.tipo_cliente == 1" cols='12' md='4')
          h6.input-label Nombre:
          p.display-data {{ dataClient.nombre }}
        b-col(v-if="dataClient.tipo_cliente == 2" cols='12' md='4')
          h6.input-label Razón Social:
          p.display-data {{ dataClient.razon_social }}
        b-col(v-if="dataClient.tipo_cliente == 1" cols='12' md='4')
          h6.input-label Apellido:
          p.display-data {{ dataClient.apellido }}
        b-col(cols='12' md='4')
          h6.input-label Email:
          p.display-data {{ dataClient.email }}
        b-col(cols='12' md='4')
          h6.input-label Teléfono:
          p.display-data {{ dataClient.telefono }}
        b-col(cols='12' md='4')
          h6.input-label Dirección:
          p.display-data {{ dataClient.direccion }}
        b-col(cols='12' md='4')
          h6.input-label Región:
          p.display-data {{ dataClient.region }}
        b-col(cols='12' md='4')
          h6.input-label Comuna:
          p.display-data {{ dataClient.comuna }}
        b-col(v-if="dataClient.tipo_cliente == 2" cols='12' md='4')
          h6.input-label Nombre de sucursal:
          p.display-data {{ dataClient.nombre_sucursal }}
      .d-flex.flex-row
        b-icon.no-print.mr-2(icon="clipboard" variant="primary" font-scale="1.5")
        h3.title-data Datos de Garantia y falla
      hr
      div.d-flex.row
        b-col(cols='12' md='4')
          h6.input-label Reporte de falla:
          p.display-data {{ warrantyFail.reporte_falla_cliente ? warrantyFail.reporte_falla_cliente : '--' }}
        b-col(cols='12' md='4')
          h6.input-label Tipo de servicio:
          p.display-data {{ warrantyFail.tipo_servicio == 1 ? 'Garantía' : 'Presupuesto' }}
      .d-flex.flex-row
        b-icon.mr-2(icon="laptop" variant="primary" font-scale="1.5")
        h3.title-data Datos del Equipo
      hr
      div.d-flex.row
        b-col(cols='12' md='4')
          h6.input-label Número de Serie:
          p.display-data {{ dataEquipment.serial_number ? dataEquipment.serial_number : '--' }}
        b-col(cols='12' md='4')
          h6.input-label Modelo:
          p.display-data {{ dataEquipment.model_name }}
        b-col(cols='12' md='4')
          h6.input-label Password:
          p.display-data {{ dataEquipment.password ? dataEquipment.password : '--'}}
        b-col(cols='12' md='4')
          h6.input-label Incluye Cargador:
          p.display-data {{ dataEquipment.power_source == true ? 'Si' : 'No'}}
        b-col(cols='12' md='4')
          h6.input-label Disco Duro:
          p.display-data {{ dataEquipment.size_hard_drive ? dataEquipment.size_hard_drive : '--'}}
        b-col(cols='12' md='4')
          h6.input-label Ram:
          p.display-data {{ dataEquipment.total_ram_memorie ? dataEquipment.total_ram_memorie : '--'}}
      .d-flex.justify-content-end
        label(for="acceptTerms" style="font-weight: bold;")
          | Acepto
          a(href="https://www.orimi.com/pdf-test.pdf" style="margin-left: 3px; font-weight: bold;" target="_blank") términos y condiciones
        b-form-checkbox(id="acceptTerms" v-model="acceptTerms" name="checkbox-1" style="margin-left: 8px;")
      .d-flex.justify-content-end
        label(for="acceptDiskDelete" style="font-weight: bold;")
          | Acepto que la información del equipo será borrada
        b-form-checkbox(id="acceptDiskDelete" v-model="acceptDiskDelete" name="checkbox-2" style="margin-left: 8px;")
      .d-flex.justify-content-between
        b-button.btn-create(@click="goBack()" small)
          | Anterior
        b-button.btn-create(@click="save()" small)
          | Guardar
      .d-flex.justify-content-center
        b-modal.hide-footer(v-model="showModal" no-close-on-backdrop)
          div.d-block.text-center
            font-awesome-icon(:icon="['fa','check-circle']" size="7x" color="#2ecc71")
            h4.title {{ responseMsj }}
            p Recuerde que el ingreso deberá ser completado en mesón
          template(#modal-footer='{ ok, cancel, hide }')
            b-button(variant='success' @click="finish()" :disabled="loading") Aceptar
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DataClientRegions from './DataClientRegions.vue'
import WarrantyFailsRegions from './WarrantyFailsRegions.vue'

export default {
  name: 'ResumeRegiones',
  props: ['dataClient', 'warrantyFail', 'boleta', 'dataEquipment'],
  components: {
    WarrantyFailsRegions,
    DataClientRegions,
  },
  data() {
    return {
      showModal: false,
      responseMsj: '',
      responseOk: true,
      loading: false,
      acceptTerms: false,
      acceptDiskDelete: false,
    };
  },
  methods: {
    ...mapActions('PreIngreso', ['sendPreIngressRegions']),
    goBack() {
      this.$emit('goBack')
    },
    getFecha(fecha) {
      const date = moment(fecha).format('DD/MM/YYYY')
      return date
    },
    async save() {
      this.loading = true;
      if (this.acceptTerms && this.acceptDiskDelete) {
        try {
          const otData = {
            datos_provisorios: this.dataClient,
            ...this.warrantyFail,
            equip: this.dataEquipment,
          };
          const formData = new FormData();
          formData.append('ot_data', JSON.stringify(otData));
          if (this.boleta) {
            formData.append('boleta', this.boleta);
          }
          const response = await this.sendPreIngressRegions(formData);
          this.showModal = true;
          this.responseMsj = response.data;
          this.responseOk = response.status === 201;
        } catch (error) {
          this.$bvToast.toast(`Error en el ingreso de información: ${error}`, {
            variant: 'danger',
          });
        }
      } else {
        this.$bvToast.toast('Debes aceptar los términos y condiciones', {
          variant: 'danger',
        })
      }
      this.loading = false
    },
    finish() {
      this.$emit('finish')
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;
  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
  .title-data {
    color: #253590;
    font-weight: 700;
    font-size: 1.2em;
  }
  .details {
    color: var(--info);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .input-label {
    color: #74788d;
    font-weight: bold;
    font-size: 1em;
  }
  .display-data {
    font-size: 1em;
    margin-bottom: 20px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
</style>
