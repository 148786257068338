<template lang="pug">
  div(v-if="value.estado && value.estado !== 'Solicitado'")
    b-form-group
      b-form-radio(v-model="stateRequest" value=5 @change="onSwitch()") Drop
      b-form-radio(v-model="stateRequest" value=6 @change="onSwitch()") Bad
      b-form-radio(v-model="stateRequest" value=7 @change="onSwitch()") Part fail
    //- Modal de confirmación al cambiar técnico
    b-modal.hide-footer(v-model="showModal" no-close-on-backdrop)
      div.d-block.text-center(v-if="stateRequest != 7")
        h4 ¿Estás seguro de cambiar el estado a {{stateRequest == 5 ? 'Drop' : 'Bad'}}?
      div(v-if="stateRequest == 7")
        h4 ¿Solicitar nuevo repuesto a partir de part fail?
        b-form-group
          b {{value.part_number}}
            b-form-group
              b-form-radio(
                v-model="selected"
                :value= "true"
              ) Allocated
              b-form-radio(
                v-model="selected"
                :value= "false"
              ) No allocated
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='setDropped(true)') {{ stateRequest != 7 ? 'Si' : 'Solicitar Nuevo' }}
        b-button(size='sm' variant='danger' @click='hideModal()' v-if="stateRequest != 7") No
        b-button(size='sm' variant='danger' @click='setDropped(false)' v-if="stateRequest == 7") No solicitar nuevo
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import print from '../../utils/print'

export default {
  props: ['value', 'ot'],
  data() {
    return {
      stateRequest: false,
      showModal: false,
      selected: '',
    }
  },
  created() {
    const { estado } = this.value
    if (estado === 'Dropped') {
      this.stateRequest = 5
    } else if (estado === 'Bad') {
      this.stateRequest = 6
    } else if (estado === 'Part failed') {
      this.stateRequest = 7
    }
  },
  methods: {
    ...mapActions('Diagnosticos', ['updateRequests']),
    ...mapActions('OTStore', ['getOT']),
    ...mapActions('Notifications', ['sendNotification']),
    hideModal() {
      this.stateRequest = false
      this.showModal = false
    },
    onSwitch() {
      this.showModal = true
    },
    async setDropped(requestPart) {
      this.requestPart = requestPart
      this.showModal = false
      const parts = []
      parts.push({
        ot: this.ot.ot,
        solicitudId: this.value.id,
        parte: {
          partNumber: this.value.part_number,
          description: this.value.descripcion,
          realLocation: this.value.real_location,
        },
        allocated:
          this.selected === '' ? this.value.allocated !== 'No' : this.selected, //eslint-disable-line
        solicitarPart: this.requestPart, // Variable true o false, para solicitar respuesto si es part fail.
        estado: this.stateRequest,
      })
      if (requestPart && parseInt(this.stateRequest, 10) === 7) {
        const now = moment().format('DD/MM/YYYY H:mm:ss')
        console.log(this.ot.ot, this.parts, this.ot, now)
        print(this.ot.ot, parts, this.ot, now)
      }
      const resp = await this.updateRequests(parts)
      if (resp && resp.status === 200) {
        this.$bvToast.toast('Solicitud editada correctamente', {
          variant: 'success',
        })
        this.getOT(this.ot.ot)
        this.sendDroppedNotification()
        this.$emit('changed')
      } else if (resp && resp.data) {
        this.$bvToast.toast(resp.data, {
          variant: 'danger',
        })
      }
    },
    getEstado(estado) {
      if (estado === 'Solicitado') return 1
      if (estado === 'En espera') return 2
      if (estado === 'Localizado') return 3
      if (estado === 'Entregado') return 4
    },
    async sendDroppedNotification() {
      const users = this.allUsers.filter((u) => { //eslint-disable-line
        return u.groups.find((g) => g.name === 'Bodega')
      })
      const userIds = users?.map((u) => u.id)
      const notificationData = {
        recipients: userIds,
        ot: this.ot.ot,
        message: this.getMessage(),
        send_by_email: false,
        type_message: 2,
      }
      await this.sendNotification(notificationData)
    },
    getMessage() {
      return this.stateRequest
        ? 'Se ha anulado una de las piezas solicitadas'
        : 'Se ha restaurado una de las piezas solicitadas'
    },
  },
  computed: {
    // ...mapGetters('OTStore', ['ot']),
    ...mapGetters('Warehouse', ['allUsers']),
  },
}
</script>

<style lang="scss" scoped></style>
