<template lang="pug">
div
  b-card.form-card
    b-row
      span#icon 03
      h5.title.mt-2 Resumen
    hr
    .d-flex.flex-row
      b-icon.mr-2(icon="clipboard-data" variant="primary" font-scale="1.5")
      h3.title-data Datos Cliente
    hr
    div.details
      b-col(cols='12' md='4')
        h6.input-label R.U.T:
        p.display-data {{ datosCliente.rut }}
      b-col(cols='12' md='4')
        h6.input-label Nombre:
        p.display-data {{ datosCliente.nombre }}
      b-col(cols='12' md='4')
        h6.input-label Apellido:
        p.display-data {{ datosCliente.apellido }}
      b-col(cols='12' md='4')
        h6.input-label Email:
        p.display-data {{ datosCliente.email }}
      b-col(cols='12' md='4')
        h6.input-label Teléfono:
        p.display-data {{ datosCliente.telefono }}
      b-col(cols='12' md='4')
        h6.input-label Dirección:
        p.display-data {{ datosCliente.direccion }}
      b-col(cols='12' md='4')
        h6.input-label Región:
        p.display-data {{ datosCliente.region }}
      b-col(cols='12' md='4')
        h6.input-label Comuna:
        p.display-data {{ datosCliente.comuna }}

    .d-flex.flex-row
      b-icon.mr-2(icon="laptop" variant="primary" font-scale="1.5")
      h3.title-data Datos de Garantia y falla
    hr
    div.d-flex.row.details
      b-col(cols='12' md='4')
        h6.input-label Reporte de falla:
        p.display-data {{ garantiaFalla.reporte_falla_cliente ? garantiaFalla.reporte_falla_cliente : '--' }}
      b-col(cols='12' md='4')
        h6.input-label Tipo de servicio:
        p.display-data {{ garantiaFalla.tipo_servicio === 1 ? 'Garantía' : 'Presupuesto' }}
      b-col(cols='12' md='4' v-if="garantiaFalla.tipo_servicio === 1 && boleta")
        h6.input-label Boleta:
        img.device-image(v-if="imageUrl" :src="imageUrl" alt="Imagen Boleta")
        .flex-col(v-else-if="boleta.type === 'application/pdf'")
          b-icon.device-icon.h3(icon="file-pdf" variant="black")
          p {{ boleta.name }}

    .d-flex.justify-content-between
      b-button.btn-create(@click="goBack()" small)
        | Anterior
      b-button.btn-create(@click="guardar()" small :disabled="loading")
        | Guardar
    .d-flex.justify-content-center
      b-modal.hide-footer(v-model="showModal" no-close-on-backdrop)
        div.d-block.text-center
          font-awesome-icon(:icon="['fa','check-circle']" size="7x" color="#2ecc71")
          h4.title {{ responseMsj }}
          p Recuerde que el ingreso deberá ser completado en mesón
        template(#modal-footer='{ ok, cancel, hide }')
          b-button(variant='success' @click="finish()") Aceptar
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import GarantiaFalla from './GarantiaFalla.vue'
import DatosCliente from './DatosCliente.vue'

export default {
  name: 'Resumen',
  props: ['datosCliente', 'garantiaFalla', 'boleta'],
  components: {
    GarantiaFalla,
    DatosCliente,
  },
  data() {
    return {
      showModal: false,
      responseMsj: '',
      responseOk: true,
      loading: false,
    }
  },
  computed: {
    imageUrl() {
      const validTypes = ['image/jpeg', 'image/png', 'image/tiff', 'image/gif']
      if (!this.boleta || !validTypes.includes(this.boleta.type)) return null
      return URL.createObjectURL(this.boleta)
    },
  },
  methods: {
    ...mapActions('PreIngreso', ['sendPreIngreso']),
    goBack() {
      this.$emit('goBack')
    },
    getFecha(fecha) {
      const date = moment(fecha).format('DD/MM/YYYY')
      return date
    },
    async guardar() {
      this.loading = true
      const otData = {
        datos_provisorios: this.datosCliente,
        ...this.garantiaFalla,
      }
      const formData = new FormData()
      formData.append('ot_data', JSON.stringify(otData))
      formData.append('boleta', this.boleta)
      const response = await this.sendPreIngreso(formData)
      this.showModal = true
      this.responseMsj = response.data
      this.responseOk = response.status === 201
      this.loading = false
    },
    finish() {
      this.$emit('finish')
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;
  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
  .title-data {
    color: #253590;
    font-weight: 700;
    font-size: 1.2em;
  }
  .details {
    color: var(--info);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .input-label {
    color: #74788d;
    font-weight: bold;
    font-size: 1em;
  }
  .display-data {
    font-size: 1em;
    margin-bottom: 20px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.device-image {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}

.device-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
</style>
