<template lang="pug">
#AddBoleta
    div
      //- BOTON AGREGAR BOLETA
      //-Agregar boleta solo cuando es garantia, y todos los usuarios.
      button.change-button(
        :disabled="disabled"
        title="Agregar boleta"
        @click="showModal()"
        )
        | Agregar Boleta
      b-modal.hide-foooter(v-model="showModalBoleta" title="Ingresar boleta" size="lg" )
        OTUploads(:ot="ot" fileNamePattern="boleta" :attachmentType="ARCHIVO_ADJUNTO_TIPO.BOLETA" :limitToOne="true" @done="handleDone")
        template(#modal-footer='{ ok, cancel, hide }')
          //- b-button(size='sm' variant='success' @click='uploadBoleta()') Guardar
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTUploads from '../OTUploads.vue'

export default {
  name: 'ActionAddBoleta',
  components: {
    OTUploads,
  },
  props: ['disabled'],
  data() {
    return {
      showModalBoleta: false,
    }
  },
  created() {
    this.getOT(this.$attrs.ot.ot)
  },
  methods: {
    ...mapActions('OTStore', ['uploadFile', 'getOT']),
    showModal() {
      this.showModalBoleta = true
    },
    hideModal() {
      this.showModalBoleta = false
    },
    async handleDone() {
      this.$bvToast.toast('Archivos subidos correctamente', {
        variant: 'success',
      })
      setTimeout(() => this.$emit('changed'), 500)
      this.hideModal()
    },
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'statusRequest']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,
  },
}
</script>
