<template lang="pug">
div(v-if="ot.estado.estado.nombre !== 'Acreditación'")
  button.change-button(
    :disabled="disabled"
    @click="openModal"
    @hide="hideModal()"
  ) {{changeNameButton()}}

  b-modal.hide-foooter(
    v-model="show"
    title="Información de partes solicitadas"
    size="xl" scrollable
    @hide="hideModal()"
  )
    div(v-if="ot.solicitud_partes && ot.solicitud_partes.length < 1")
      span(v-if="parts.length < 1") No existe solicitud de partes
    div(v-else)
      PartsTable(:ot="ot" :showStateActionColumn="true" @changed="$emit('changed')")

    b-form( @submit.stop.prevent="solicitarPartes()" )
      b-table(
        :fields="tableFields"
        :items="parts"
        responsive
      )
        template( #cell(index)="{ index }" )
          span.number.circle.bg-button {{ index + 1 }}

        //- TODO: cambiar por vue-typeahead-bootstrap y arreglar código
        template( #cell(partNumber)="{ index, item: part }" )
          b-form-group
            SILLAutocomplete(
              @click.native="onclick(index)"
              :search="searchParts"
              displayAttribute="part_number"
              placeholder="Ej: AF3FC3F"
              :onSubmit="onPartSelected"
              :searching="searching"
              @keyup.native="setPartNumber(index)"
              v-bind:key="index"
              :class="getAutoCompleteClass(index)"
            )
            b-form-invalid-feedback(
              :state="validateState(`$each.$iter[${index}].parte.partNumber`)"
            ) Campo requerido

        template( #cell(codigo)="{ item: part }" )
          VueBarcode(
            v-model="part.parte.partNumber"
            width=1 height=30 textAlign="center" fontSize="13")

        template( #cell(description)="{ index, item: part }" )
          b-form-group
            b-form-input(
              v-if="$v.parts.$each.$iter[index]"
              v-model="$v.parts.$each.$iter[index].parte.description.$model"
              :state="validateState(`$each.$iter[${index}].parte.description`)"
              :formatter="formatter"
              style="text-transform:uppercase;"
            )
            b-form-invalid-feedback Campo requerido

        template( #cell(allocated)="{ index }" )
          b-form-group
            b-radio-group.d-flex(
              v-if="$v.parts.$each.$iter[index]"
              v-model="$v.parts.$each.$iter[index].allocated.$model"
              :state="validateState(`$each.$iter[${index}].allocated`)"
              :options="[{ text: 'Sí', value: true }, { text: 'No', value: false }]"
            )
            b-form-invalid-feedback(
              :state="validateState(`$each.$iter[${index}].allocated`)"
            ) Campo requerido

        template( #cell(realLocation)="{ item: part }" )
          b-form-input(v-model="part.parte.realLocation")

        template( #cell(closeButton)="{ index }" )
          b-button(@click="quitarParte(index)" variant="danger").circle x

    button(@click="agregarParte" v-if="parts.length < 50").ask-parts.mt-2 + Agregar repuesto
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='success' @click='solicitarPartes()')
        | Guardar
        b-spinner(v-if="loading")
      //- b-button(size='sm' variant='secondary' @click='impresionPartes()')
      //-   | Volver a imprimir partes
      b-button(size='sm' variant='danger' @click='hideModal()') Cancelar

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { get as _get } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import VueBarcode from 'vue-barcode'
import SILLAutocomplete from '@/components/UI/SILLAutocomplete.vue'
import PartsTable from '../../AskParts/PartsTable.vue'
import AskPartsForm from '../../AskParts/AskPartsForm.vue'
import print from '../../../utils/print'

export default {
  name: 'ActionSolicitarPartes',
  props: ['ot', 'disabled'],
  components: {
    PartsTable,
    AskPartsForm,
    SILLAutocomplete,
    VueBarcode,
  },
  async created() {
    await this.getAllUsers()
  },
  data() {
    return {
      show: false,
      parts: [],
      searching: false,
      selectedPart: null,
      partNumberInputText: null,
      ot_data: null,
      loading: false,
      tableFields: [
        { key: 'index', label: '#' },
        { key: 'partNumber', label: 'Part Number' },
        { key: 'codigo', label: 'Código' },
        { key: 'description', label: 'Descripción' },
        { key: 'allocated', label: 'Allocated' },
        { key: 'realLocation', label: 'Real Location' },
        { key: 'closeButton', label: '' },
      ],
    }
  },
  validations: {
    parts: {
      $each: {
        allocated: { required },
        parte: {
          partNumber: { required },
          description: { required },
        },
      },
    },
  },
  computed: {
    ...mapGetters('Warehouse', ['allUsers']),
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('Diagnosticos', ['askParts', 'searchByPartNumber']),
    ...mapActions('Warehouse', ['getAllUsers']),
    ...mapActions('Notifications', ['sendNotification']),
    ...mapActions('OTStore', ['getOT']),
    ...mapActions('Warehouse', ['changeWarehouseState']),
    formatter(value) {
      if (!value || !value.length) return null
      return value.toUpperCase().substr(0, 10)
    },
    hideModal() {
      this.show = false
      this.$v.parts.$reset()
      this.parts = []
    },
    impresionPartes() {
      // método para probar la impresión
      const now = moment().format('DD/MM/YYYY H:mm:ss')
      print(this.ot.ot, this.parts.filter((part) => part.allocated), this.ot, now)
    },
    agregarParte() {
      this.parts.push({
        ot: this.ot.ot,
        parte: {
          id: null,
          partNumber: null,
          description: null,
          realLocation: null,
        },
        allocated: null,
        newPartToSearch: true
      })
    },
    openModal() {
      this.show = true
      this.cargarPartesAprobadas()
    },
    cargarPartesAprobadas () {
      this.ot.solicitud_cotizacion_interna.forEach((ci) => {
        ci.partes_solicitadas.forEach((ps) => {
          const parte = {
            id:  ps.id,
            description:  ps.description,
            partNumber:  ps.part_number,
            realLocation:  ps.real_location
          }
          const allocated = true
          this.parts.push({parte, allocated})
        })
      })
    },
    async solicitarPartes() {
      this.$v.parts.$touch()
      if (this.$v.parts.$anyError) {
        return
      }
      this.loading = true
      const parts = this.parts.filter((p) => p.newPartToSearch)
      const resp = await this.askParts({ otId: this.ot.ot, parts })
      this.loading = false
      if (resp && (resp.status === 201 || resp.status === 200)) {
        this.$bvToast.toast(`${resp.data}`, {
          variant: 'success',
        })
        // función para impresión de código de partes solicitadas
        if (this.parts.filter((part) => part.allocated).length > 0) {
          this.impresionPartes()
        }
        this.show = false
        await this.getOT(this.ot.ot)
        this.$emit('changed')
        this.hideModal()
      }
    },
    async searchParts(partNumber) {
      this.partNumberInputText = partNumber
      if (partNumber.length <= 2) {
        return []
      }
      this.searching = true
      const resp = await this.searchByPartNumber({
        partNumber,
        shortQuery: true,
      })
      this.searching = false
      let partes = resp.data.results
      if (resp.data.results.length === 0) {
        partes = [
          {
            description: null,
            id: null,
            part_number: partNumber,
            real_location: null,
          },
        ]
      }
      return partes
    },
    quitarParte(index) {
      this.parts.splice(index, 1)
    },
    onPartSelected(partSelected) {
      this.selectedPart = partSelected
    },
    setPartNumber(index) {
      this.parts[index].parte.partNumber = this.partNumberInputText
      this.$v.parts.$each.$iter[index].parte.partNumber.$model =
        this.partNumberInputText
    },
    onclick(index) {
      if (this.selectedPart) {
        this.parts[index].parte.id = this.selectedPart.id
        this.parts[index].parte.description = this.selectedPart.description
        this.parts[index].parte.partNumber = this.selectedPart.part_number
        this.parts[index].parte.realLocation = this.selectedPart.real_location
      }
      this.selectedPart = null
    },
    changeNameButton() {
      const partes = this.ot.solicitud_partes?.filter(
        (parte) =>
          parte.estado !== 'Dropped' &&
          parte.estado !== 'Usado' &&
          parte.estado !== 'Part failed'
      ) || []
      return partes.length > 0 ? 'Cerrar o pedir repuestos' : 'Pedir repuestos'
    },
    validateState(fieldPath) {
      const field = _get(this.$v.parts, fieldPath)
      if (!field) return null
      const { $dirty, $error } = field
      return $dirty ? !$error : null
    },
    getAutoCompleteClass(index) {
      const state = this.validateState(`$each.$iter[${index}].parte.partNumber`)
      return state
        ? 'autocomplete-valid'
        : state === false
        ? 'autocomplete-invalid'
        : ''
    },
  }
}
</script>
<style lang="scss" scoped>
.form-control {
  border: 1px solid #cbcbcb;
  opacity: 1;
}

.col-1 {
  flex: 0 0 5.333333%;
  max-width: 5.333333%;
}

.number {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  opacity: 1;
  border-radius: 50%;
}

.parts-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  height: 85vh;
}

.part-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

label {
  font-weight: bold;
}

.label {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
}

.part-number {
  color: #253590;
}

.title {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
  font-weight: 600;
}

.ask-parts {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 175px;
  height: 45px;
  border-radius: 5px;
  font-weight: 600;
}
</style>
