var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.ot.pdf_cambio && !_vm.pdfCambioGenerado)?_c('button',{staticClass:"change-button",attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.showModalCambio = true},"hide":function($event){return _vm.hideModal()}}},[_vm._v("Completar y enviar correo de cambio")]):_vm._e(),_c('b-modal',{staticClass:"hide-foooter",attrs:{"title":("Ingresar información de cambio OT " + (_vm.ot.ot))},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.saveChangeData()}}},[_vm._v("Guardar")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Cancelar")])]}}]),model:{value:(_vm.showModalCambio),callback:function ($$v) {_vm.showModalCambio=$$v},expression:"showModalCambio"}},[_c('p',[_vm._v("Cliente: "+_vm._s(_vm.ot.cliente.tipo_cliente === "Persona" ? _vm.ot.cliente.persona.nombre : _vm.ot.cliente.empresa.razon_social))]),_c('p',[_vm._v("R.M.A.: "+_vm._s(_vm.ot.rma))]),_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Sucursal:")]),_c('p',[_vm._v(_vm._s(_vm.ot.sucursal ? _vm.ot.sucursal.nombre : _vm.ot.tienda_empresa ? _vm.ot.tienda_empresa.razon_social : '-'))])]),_c('Sucursales',{ref:"sucursales",attrs:{"state":_vm.$v.formChange.cambio_sucursal.$dirty ? !_vm.$v.formChange.cambio_sucursal.$invalid : null,"clientIdOverride":_vm.ot.cliente.tipo_cliente === 'Empresa' ? _vm.ot.cliente.empresa.id : _vm.ot.tienda_empresa.id,"disabled":_vm.disabled,"otProp":_vm.ot,"label":"Sucursal de cambio"},on:{"modal-closed":function($event){_vm.formChange.cambio_sucursal = null}},model:{value:(_vm.$v.formChange.cambio_sucursal.$model),callback:function ($$v) {_vm.$set(_vm.$v.formChange.cambio_sucursal, "$model", $$v)},expression:"$v.formChange.cambio_sucursal.$model"}})],1)],1),_c('b-modal',{staticClass:"hide-footer",attrs:{"title":"¿Está seguro?"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.sendChangeEmail()}}},[_vm._v("Aceptar")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Cancelar")])]}}]),model:{value:(_vm.showModalConfirmSendEmail),callback:function ($$v) {_vm.showModalConfirmSendEmail=$$v},expression:"showModalConfirmSendEmail"}},[_c('div',[_vm._v("Se enviará correo de cambio a "+_vm._s(_vm.ot.cliente.tipo_cliente === "Persona" ? _vm.ot.cliente.persona.nombre : _vm.ot.cliente.empresa.razon_social))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }