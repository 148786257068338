<template lang="pug">
div( v-if="cliente" )
  .d-flex
    span.span-label Cliente:
    span( v-if="cliente.tipo_cliente === 1" )
      | {{ cliente.nombre }} {{ cliente.apellido }}
    span( v-else )
      | {{ cliente.razon_social }}
  .d-flex
    span.span-label RUT:
    span {{ cliente.rut }}
</template>

<script>
export default {
  props: {
    cliente: Object,
  },
}
</script>

<style lang="scss" scoped>
.span-label {
  width: 100px;
  font-weight: bold;
  color: #253590;
}
</style>
