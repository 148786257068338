<template lang="pug">
div
  b-container
    b-collapse(v-model="collapse" id="collapse-equipment-section" visible)
      b-form
        b-row
          b-col
            b-form-group
              label(
                for="input-serial"
                class="client-header"
              ) N° de Serie
              template(v-if="loadedSerial === false")
                b-row
                  b-col( cols="12" md="6" lg="3" )
                    vue-typeahead-bootstrap(
                      v-model="query"
                      :data="equipments"
                      :serializer="e => e.serial_number"
                      @hit="onSerialSelect"
                      :placeholder="formEquipo.serial_number ? formEquipo.serial_number : 'Ej: 76872AFG'"
                    )
                  b-col( cols="12" md="6" lg="2" ).mt-3.mt-md-0
                    b-button.w-100(
                      v-if="loadedSerial === false && query && query !== ''"
                      @click="setNewSerial"
                    ) Crear
              template(v-else-if="loadedSerial === true")
                .d-flex.align-items-center.justify-content-between.justify-content-md-start.pl-4
                  span.mr-5 {{ formEquipo.serial_number }}
                  b-button(
                    @click="loadingSerial"
                    size="sm"
                    :disabled="!creating && equipment && equipment.id !== null"
                  ) Cambiar Equipo
        .client-form(v-if="formEquipo && formEquipo.serial_number")
          b-row(v-if="!formEquipo.id")
            b-col
              h5 Crear equipo
          b-row
            b-col
              ModelForm(
                :modelo_id="formEquipo.modelo"
                @onModelSelect="setLocalModel"
                :disabled="disabledModel()"
              )
          b-row
            b-col( cols="12" md="4" )
              b-form-group
                label(class="client-form-inputs" for="input-power_source") Incluye Cargador
                b-form-select(
                  id="input-power_source"
                  v-model="formEquipo.power_source"
                  :options="chargerOptions"
                  :disabled="!creating && equipment && equipment.id !== null"
                )
            b-col( cols="12" md="4" )
              b-form-group
                label(class="client-form-inputs" for="input-hardDriveType") Tipos de Disco Duro
                b-form-select(
                  id="input-hardDriveType"
                  v-model="formEquipo.type_hard_drive"
                  :options="hardDriveTypeOptions"
                  @change="setHardDriveSize"
                  :disabled="!creating && equipment && equipment.id !== null"
                )
            b-col( cols="12" md="4" )
              b-form-group
                label(class="client-form-inputs" for="input-hardDriveSize") Disco Duro
                b-form-select(
                  id="input-hardDriveSize"
                  v-model="formEquipo.size_hard_drive"
                  :options="hardDriveOptions"
                  :disabled="!creating && equipment && equipment.id !== null"
                )
          b-row
            b-col( cols="12" md="6" )
              b-form-group
                label(class="client-form-inputs" for="input-ram") Ram
                b-form-select(
                  id="input-ram"
                  v-model="formEquipo.total_ram_memorie"
                  :options="ramOptions"
                  :disabled="!creating && equipment && equipment.id !== null"
                )
            b-col( cols="12" md="6" )
              b-form-group
                label(class="client-form-inputs" for="input-password") Password
                b-form-input(
                  id="input-password"
                  placeholder="Opcional..."
                  v-model="formEquipo.password"
                  :disabled="!creating && equipment && equipment.id !== null"
                )
      .botones(style="display:flex")
        .button-wrapper.mt-3
          b-button.boton(
            v-if="formEquipo.serial_number"
            @click="onSubmit"
            :disabled="disabledSubmit"
          ) {{ formEquipo.id ? 'Confirmar' : 'Crear equipo' }}
          b-button.boton(
            v-if="!creating && equipment && equipment.id"
            @click="creating = true" small
          ) Editar
          b-button.boton(
            v-if="creating && equipment && equipment.id"
            @click="creating = false, getEquipment(equipment.id)"
            variant="secondary"
          ) Cancelar
    div(v-if="!collapse && formEquipo.id")
      b-form-group
        div
          label(class ="client-form-inputs" for="input-name") Número de serie:
          slot {{ ' ' + equipment.serial_number }}
        div
          label(class ="client-form-inputs" for="input-name") Modelo:
          slot {{ ' ' + equipment.modelo_detail.model_name }}
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ModelForm from './EquipmentForm/ModelForm.vue'

import {
  chargerOptions,
  hardDriveOptions,
  ramOptions,
  equipTypeOptions,
  hardDriveTypeOptions,
} from './options'

export default {
  components: {
    ModelForm,
    VueTypeaheadBootstrap,
  },
  validations: {
    formEquipo: {
      serial_number: { required },
    },
  },
  data() {
    return {
      equipments: [],
      query: '',
      creating: false,
      disabledSubmit: false,
      collapse: true,
      searchingSerial: false,
      formEquipo: {
        id: null,
        cliente: this.client ? this.client.id : null,
        modelo: null,
        serial_number: null,
        password: null,
        type_hard_drive: null,
        size_hard_drive: null,
        power_source: null,
        total_ram_memorie: null,
        memorie_modules: null,
      },
      formMarca: {
        id: null,
      },
      tipo: null,
      chargerOptions,
      hardDriveOptions,
      ramOptions,
      equipTypeOptions,
      hardDriveTypeOptions,
      createMarca: false,
      createModel: false,
      loadedSerial: false,
    }
  },
  methods: {
    ...mapActions('EquipmentStore', [
      'createEquipment',
      'editEquipment',
      'searchBySerial',
      'getModels',
      'getBrands',
      'getEquipment',
    ]),
    ...mapMutations('EquipmentStore', ['SET_EQUIPMENT']),
    setNewSerial() {
      try {
        this.loadedSerial = true
        this.creating = true
        this.formEquipo.serial_number = this.query
        this.formEquipo.cliente = this.client.id
        this.SET_EQUIPMENT({ equipment: this.formEquipo })
      } catch {
        this.$bvToast.toast('Ingrese numero de serie.', {
          variant: 'danger',
        })
      }
    },
    async serialSearch(serial) {
      this.formEquipo = null
      if (serial && serial.length >= 1) {
        this.searchingSerial = true
        const res = await this.searchBySerial(serial)
        this.searchingSerial = false
        return res.data
          .concat([{ serial_number: serial, id: null }])
          .filter((c) => c.serial_number && c.serial_number.length >= 3)
      }
    },
    disabledModel() {
      if (!this.creating && this.formEquipo.id !== null) {
        return true
      }
    },
    async onSerialSelect(equipo) {
      this.creating = true
      this.formEquipo = equipo
      this.formEquipo.cliente = this.client.id
      if (equipo.id) {
        this.SET_EQUIPMENT({ equipment: equipo })
      }
      await this.getBrands()
      await this.getModels()
      if (equipo.modelo) {
        const modelo = this.models.find((mod) => mod.id === equipo.modelo)
        this.formMarca.id = modelo ? modelo.marca : null
        this.tipo = modelo.dispositive_type
      }
      this.creating = false
    },
    async onSubmit() {
      if (this.$v.$invalid || !this.formEquipo.modelo) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.creating = true
        if (!this.formEquipo.id) {
          // Creacion de Equipo
          this.disabledSubmit = true
          await this.createEquipment(this.formEquipo)
          this.creating = false
          if (this.statusRequest) {
            if (
              this.statusRequest.status === 200 ||
              this.statusRequest.status === 201
            ) {
              this.formEquipo = this.statusRequest.data
              this.SET_EQUIPMENT({ equipment: this.formEquipo })
              this.$emit('openSection', true)
              this.collapse = false
              this.$bvToast.toast('Equipo guardado exitosamente', {
                variant: 'success',
              })
              this.isCreatedOT = true
              return
            }
            if (this.statusRequest.status === 400) {
              this.$bvToast.toast(
                `Error al guardar: ${this.statusRequest.data}`,
                {
                  variant: 'danger',
                }
              )
            }
          }
          this.disabledSubmit = false
          // editar/confirmar equipo
        }
        if (this.formEquipo.id) {
          this.disabledSubmit = true
          await this.editEquipment(this.formEquipo)
          this.creating = false
          if (this.statusRequest) {
            if (
              this.statusRequest.status === 200 ||
              this.statusRequest.status === 201
            ) {
              this.formEquipo = this.statusRequest.data
              this.SET_EQUIPMENT({ equipment: this.formEquipo })
              this.$bvToast.toast('Equipo editado exitosamente', {
                variant: 'success',
              })
              this.$emit('openSection', true)
              this.collapse = false
              this.isCreatedOT = true
            }
            if (this.statusRequest.status === 400) {
              this.$bvToast.toast(
                `Error al guardar: ${this.statusRequest.data}`,
                {
                  variant: 'danger',
                }
              )
            }
          }
          this.disabledSubmit = false
        }
      }
    },
    async fetchEquipment() {
      const equipmentId = this.ot?.equipo?.id
      if (!equipmentId) {
        this.SET_EQUIPMENT({ equipment: null })
        return
      }
      await this.getEquipment(equipmentId)
    },
    setLocalModel(model_id) {
      this.formEquipo.modelo = model_id
    },
    setModelValidation() {
      this.$v.formEquipo.modelo.$touch()
    },
    loadingSerial() {
      this.loadedSerial = false
      this.creating = false
      this.query = null
      this.SET_EQUIPMENT({ equipment: null })
    },
    setHardDriveSize() {
      if (this.formEquipo.type_hard_drive === 'NOT_VERIFIED') {
        this.formEquipo.size_hard_drive = null
      }
    },
  },
  computed: {
    ...mapGetters('EquipmentStore', [
      'brands',
      'models',
      'equipment',
      'statusRequest',
    ]),
    ...mapGetters('OTStore', ['ot']),
    ...mapGetters('ClientStore', ['client']),
    brandOptions() {
      return this.brands
        ? this.brands
            .map((val) => ({ value: val.id, text: val.marca_name }))
            .push({ value: true, text: 'Otra Marca' })
        : []
    },
    modelOptions() {
      return this.models
        ? this.models
            .map((val) => ({ value: val.id, text: val.model_name }))
            .push({ value: true, text: 'Otro Modelo' })
        : []
    },
  },
  watch: {
    ot() {
      this.fetchEquipment()
    },
    equipment() {
      this.formEquipo = this.equipment || {}
      if (this.formEquipo && this.formEquipo.serial_number) {
        this.loadedSerial = true
      }
    },
    async query(serial) {
      const res = await this.searchBySerial({ serial })
      this.equipments = res
    },
  },
  created() {
    this.fetchEquipment()
  },
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.edit-button {
  position: relative;
  left: -15%;
}

.error {
  color: #dc3545;
}

.client-header {
  color: #253590;
  font-weight: bold;
}

.client-form-inputs {
  color: #74788d;
}

.button-wrapper {
  width: 100%;
  display: flex;
  gap: 1rem;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.boton {
  min-width: 120px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
</style>
