<template lang="pug">
Fragment
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Control de Inventario
  AddInventoryForm
</template>
<script>
import AddInventoryForm from '@/components/Inventory/AddInventoryForm.vue'
import BackArrow from '../../components/UI/BackArrow.vue'

export default {
  name: 'IngresoInventoryView',
  components: {
    AddInventoryForm,
    BackArrow,
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
