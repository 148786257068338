<template lang="pug">
  .select-technician
    div
      button.change-button(
        :disabled="disabled"
        @click="modal = true"
        @hide="hideModal()"
      ) Dar reingreso
    //- Modal para dar reingreso a una OT
    b-modal.hide-footer(:title="`Dar reingreso a OT ${ot.ot}`" v-model="modal" id="selectTechnician")
      div.d-block.text-center
        p Se cerrará la orden actual y creará una nueva con características similares
        b-form-input(v-model="informeDeFalla")
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click='darReingreso()') Confirmar reingreso
        b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions } from 'vuex'

/** Cierra OT actual y crea una nueva
 * con caraterísticas similares
 */

export default {
  name: 'ActionReingreso',
  props: ['ot', 'disabled'],
  data() {
    return {
      informeDeFalla: this.ot?.diagnostico_detail?.informe,
      modal: false,
    }
  },
  methods: {
    ...mapActions('OTStore', ['reingreso']),
    ...mapActions('TechniciansStore', ['listTechnicians']),
    showModal() {
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
    async darReingreso() {
      try {
        const res = await this.reingreso({
          otId: this.ot.ot,
          informeDeFalla: this.informeDeFalla,
        })
        const newOT = res.data
        this.$bvToast.toast(
          `Reingreso exitoso, se generó nueva OT ${newOT.ot}`,
          {
            variant: 'success',
            'auto-hide-delay': 6000,
          }
        )
        setTimeout(() => {
          this.$emit('changed')
          console.log('to', `/editar-orden/${newOT.ot}`, newOT)
          this.$router.push(`/editar-orden/${newOT.ot}`)
          this.modal = false
        }, 3000)
      } catch (err) {
        this.$bvToast.toast(err.response.data || 'Error al asignar', {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
