<template lang="pug">
.flex-row.justify-content-end
  b-pagination(
    v-model="filters.page"
    :total-rows="totalItems"
    :per-page="20"
    @input="getExternalQuotes"
  )
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useExternalQuoteListStore } from '@/pinia/views/useExternalQuoteListStore'

export default {
  computed: {
    ...mapWritableState(useExternalQuoteListStore, ['filters']),
    ...mapState(useExternalQuoteListStore, ['totalItems']),
  },
  methods: {
    ...mapActions(useExternalQuoteListStore, ['getExternalQuotes']),
  },
}
</script>

<style lang="scss" scoped></style>
