<template lang="pug">
#DataEquipQuotations(v-if="parts.length >= 0")
  .part-card(v-for="(part, index) in parts" :key="index")
    AccordionParts(
      :numberParts="index"
      :open="true"
    )
      b-collapse(:id="`accordion-${index}`" visible accordion="my-accordion" role="tabpanel"  )
        b-row
          b-col(cols="12" md="6")
            b-form-group(label-for="input-category")
              template(#label) Categoría del repuesto
              b-form-select(
                v-model="part.category"
                id="input-category"
                :options="categoryOptions"
              )
            b-form-group(label-for="input-comments")
              template(#label) Comentarios
              b-form-textarea.w-100(
                v-model="part.comments"
                id="input-comments"
                rows="3"
                placeholder="Ingresa información adicional que creas que pueda ser importante."
              )
              .em-text.flex-row.align-items-center

        .flex-row.justify-content-end.mt-n4(v-if="index > 0")
          b-button(@click="deletePart(index)" variant="text")
            .flex-row.align-items-center.text-danger
              font-awesome-icon(icon="plus-square" size="lg")
              span.ml-2 Eliminar repuesto

  b-button(@click="addPart()" variant="text").mt-n2
    .em-text.flex-row.align-items-center
      font-awesome-icon(icon="plus-square" size="lg")
      span.ml-2 Agregar repuesto

  .d-flex.justify-content-between.mt-4
    b-button.btn-create(@click="goBack()" small)
      | Anterior
    b-button.btn-create(@click="onSubmit()" small)
      | Siguiente
</template>

<script>
import { mapActions } from 'pinia'
import { useExternalQuoteStore } from '@/pinia/api/useExternalQuoteStore'
import AccordionParts from './AccordionParts.vue'

export default {
  name: 'DataPartsQuotations',
  props: ['setDataParts'],
  components: { AccordionParts },
  data() {
    return {
      states: false,
      parts: [],
      categoryOptions: [],
    }
  },
  async mounted() {
    this.addPart()
    const res = await this.listPartCategories()
    this.categoryOptions = res.data?.map((category) => ({
      value: category.id,
      text: category.name,
    }))
    this.categoryOptions.unshift({ value: null, text: 'Seleccione' })
  },
  methods: {
    ...mapActions(useExternalQuoteStore, ['listPartCategories']),
    onSubmit() {
      this.setDataParts(this.parts)
    },
    handleFileUpload(event, index) {
      if (event.target.files) {
        this.parts[index].file = event.target.files // eslint-disable-line
      }
    },
    addPart() {
      this.parts.push({
        partName: null,
        partDescriptions: null,
        file: null,
      })
    },
    deletePart(index) {
      this.parts.splice(index, 1)
    },
    goBack() {
      this.$emit('goBack')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  .title-data {
    letter-spacing: 0px;
    color: #253590;
    opacity: 1;
    font-weight: 600;
    font-size: 18px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
::v-deep textarea.form-control {
  width: auto;
}
::v-deep .card-body {
  padding: 0;
}
::v-deep.fileDataPart {
  display: flex;
  border: 3px dashed gray;
  border-radius: 1rem;
  width: 100%;
  position: relative;
  height: 20vh;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
::v-deep.fileDataPart span {
  background-color: #5b73e8;
  border-radius: 3px;
  color: white;
  padding: 1rem;
}
::v-deep.fileDataPart input[type='file'] {
  height: 20vh;
  opacity: 0;
  position: absolute;
  top: -60px;
  bottom: 0;
  left: 0;
  right: 0;
}
::v-deep.contenedorFileDataPart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.part-card::v-deep .form-card {
  border-radius: 10px;
}
.em-text {
  color: #253590;
}
</style>
