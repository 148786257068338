<template lang="pug">
  .login-view
    .login-view-container
      OTLogin
</template>

<script>
import OTLogin from '@/components/ClientOTAuth/OTLogin.vue'

export default {
  name: 'ClientOTView',
  components: {
    OTLogin,
  },
}
</script>

<style lang="scss" scoped>
@import '../style/global.scss';

.login-view {
  padding: 1rem;
  height: 100%;
  background-color: $sill-red;
  &-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
