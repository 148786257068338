<template lang="pug">
  div(v-if="ot")
    button.change-button(
      :disabled="disabled"
      @click="showModalRetiro = true"
      @hide="hideModal()"
    ) Pedir cambio espera de retiro
    //- MODAL ESPERA DE RETIRO
    b-modal.hide-foooter(v-model="showModalRetiro" title="Ingresar información retiro")
      b-col
        b-form-group(label="Nuevo RMA de cambio" label-for="input-retiro-rma")
          b-input(
            v-model="formRetiro.rma_cambio"
            id="input-retiro-rma"
            :state="$v.$dirty ? !$v.formRetiro.rma_cambio.$invalid : null")
          .errors(v-if="$v.formRetiro.rma_cambio.$dirty")
            b-form-invalid-feedback(:state="$v.formRetiro.rma_cambio.required") Campo requerido
        b-form-group(label="Comentario" label-for="input-retiro-comentario")
          b-textarea(
            id="input-retiro-rma"
            v-model="formRetiro.comentario_cambio")
      template.botones(#modal-footer='{ ok, cancel, hide }')
          b-button(size='sm' variant='success' @click='createExcepcionRetiro()' :disabled="loading") Guardar
          b-button(size='sm' variant='danger' @click='hideModal()') Cancelar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ActionEsperaDeRetiro',
  props: ['ot', 'disabled'],
  validations: {
    formRetiro: {
      rma_cambio: { required },
    },
  },
  data() {
    return {
      showModalRetiro: false,
      loading: false,
      formRetiro: {
        rma_cambio: null,
        comentario_cambio: null,
      },
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('Exceptions', ['createExcepcion']),
    ...mapActions('OTStore', ['getOT']),
    hideModal() {
      this.showModalRetiro = false
    },
    async createExcepcionRetiro() {
      this.loading = true
      const form = {
        estado: 1,
        orden_trabajo: this.ot.ot,
        comentario_solicitud: 'Solicita cambio espera de retiro',
        solicitado_por: this.user.id,
        tipo: 'Cambio en espera de retiro',
        rma_cambio: this.formRetiro.rma_cambio,
        comentario_cambio: this.formRetiro.comentario_cambio,
      }
      await this.createExcepcion(form)
      this.$emit('changed')
      await this.getOT(this.ot.ot)
      this.hideModal()
      this.loading = false
    },
  },
}
</script>
