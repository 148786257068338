<template lang="pug">
div
  b-form-group(:label="label", :label-for="inputId")
    b-form-tags(
      :id="inputId",
      v-model="internalValue",
      no-outer-focus,
      class="mb-2"
    )
      template(v-slot="{ tags, disabled, addTag, removeTag }")
        ul.list-inline.d-inline-block.mb-2(v-if="tags.length > 0")
          li.list-inline-item(v-for="tag in parsedTags", :key="tag[idKey]")
            b-form-tag(
              @remove="removeTag(JSON.stringify(tag))",
              :title="tag[labelKey]",
              :disabled="disabled",
              variant="info"
            ) {{ tag[labelKey] }}

        b-dropdown(size="sm", variant="outline-primary", block, menu-class="w-100 dropdown-menu")
          template(#button-content)
            b-icon(icon="tag-fill")
            | {{ selectorLabel }}
          b-dropdown-form(@submit.stop.prevent="() => {}")
            b-form-group(
              label="Buscar tag",
              :label-for="inputId + '-search'",
              label-cols-md="auto",
              class="mb-0",
              label-size="sm",
              :description="searchDesc",
              :disabled="disabled"
            )
              b-form-input(
                v-model="search",
                :id="inputId + '-search'",
                type="search",
                size="sm",
                autocomplete="off"
              )
          b-dropdown-divider
          b-dropdown-item-button(
            v-for="option in availableOptions",
            :key="option[idKey]",
            @click="onOptionClick(option)"
          ) {{ option[labelKey] }}
          b-dropdown-text(v-if="availableOptions.length === 0")
            | No hay opciones disponibles
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    labelKey: {
      type: String,
      required: true
    },
    idKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    inputId: {
      type: String,
      default: 'tags-with-dropdown'
    },
    label: {
      type: String,
      default: ''
    },
    selectorLabel: {
      type: String,
      default: 'Select tags'
    }
  },
  data() {
    return {
      search: '',
      internalValue: this.value.map(item => JSON.stringify(item))
    }
  },
  computed: {
    criteria() {
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const criteria = this.criteria // eslint-disable-line
      const selectedIds = this.parsedTags.map(tag => tag[this.idKey])
      const options = this.options.filter(opt => !selectedIds.includes(opt[this.idKey]))
      if (criteria) {
        return options.filter(opt => opt[this.labelKey].toLowerCase().includes(criteria))
      }
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'No se encontraron resultados para la búsqueda'
      }
      return ''
    },
    parsedTags() {
      return this.internalValue.map(tag => JSON.parse(tag))
    }
  },
  watch: {
    internalValue(newVal) {
      this.$emit('input', newVal.map(tag => JSON.parse(tag)))
    }
  },
  methods: {
    onOptionClick(option) {
      this.internalValue.push(JSON.stringify(option))
      this.search = ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  overflow-x: clip;
  overflow-y: auto;
  max-height: 60vh;
}
</style>
