<template lang="pug">
div.mb-4(v-if="files")
  div.head
    .d-flex
      b-icon.no-print.mr-2(icon="clipboard" variant="primary" font-scale="1.5")
      h4.mb-0.title Galería y Documentos
  hr(style="margin: 8px 0 16px;")
  OTUploads(:ot="ot" :hideTitles="true" :attachmentType="ARCHIVO_ADJUNTO_TIPO.OTROS" galleryMode)
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTUploads from '../OTUploads.vue'

export default {
  name: 'DocumentsTable',
  components: {
    OTUploads,
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Nombre del archivo' },
        { key: 'actions', label: 'Acciones', thStyle: 'width: 90px' },
      ],
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'files']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,
  },
  methods: {
    ...mapActions('OTStore', ['getFiles', 'downloadFile', 'downloadPdfCambio']),
    async download(data) {
      if (data.file_id) {
        const payload = {
          fileName: data.name,
          fileType: data.file_type,
          fileId: data.file_id,
        }
        await this.downloadFile(payload).catch((error) =>
          this.$bvToast.error(error)
        )
        await this.getFiles(this.ot.ot)
      } else {
        const payload = {
          ot: this.ot.ot,
          rma: this.ot.rma,
        }
        await this.downloadPdfCambio(payload).catch((error) =>
          this.$bvToast.error(error)
        )
      }
    },
  },
  async created() {
    await this.getFiles(this.ot.ot)
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}

.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
}

.input-label {
  color: #74788d;
  font-weight: bold;
  font-size: 1em;
}

.display-data {
  color: #495057;
  font-size: 1em;
}

.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}

p {
  font-size: 15px;
}

.title2 {
  color: #253590;
  font-weight: bold;
  font-style: 15px;
}
</style>
