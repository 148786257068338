<template lang="pug">
  div
    .d-flex.justify-content-between
      .d-flex.justify-content-start
        b-icon.mr-2(icon="tools" variant="primary" font-scale="1.5")
        span.title Historial Cambios Ubicación
    hr
    .movements
      b-table-simple.text-center(hover responsive)
        b-thead
          b-tr
            b-th Fecha y hora
            b-th Ubicación anterior
            b-th Ubicación nueva
            b-th Estado
            b-th Usuario
            b-th Detalles
        b-tbody
          b-tr(v-for="(m, index) in sortedCambiosUbicacion" :key="index")
            b-td {{getDate(m.created_at)}}
            b-td {{m.ubicacion_anterior}}
            b-td {{m.ubicacion_nueva}}
            b-td {{m.estado}}
            b-td {{m.usuario}}
            b-td
              b-icon(
                v-if="['Entregado a cliente', 'Enviado a domicilio', 'Domicilio'].includes(m.ubicacion_nueva)"
                icon="eye-fill"
                variant="primary"
                font-scale="1.5"
                style="cursor: pointer"
                @click="setData()"
              )
    h6.text-center(v-if="sortedCambiosUbicacion.length === 0") No hay registros

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Movements',
  created() {
    this.getAllUsers()
    this.getRole()
  },
  methods: {
    ...mapActions('Warehouse', ['showEntryForm']),
    ...mapActions('Warehouse', ['showDepartureForm']),
    ...mapActions('Warehouse', ['getAllUsers']),
    ...mapActions('Warehouse', ['setMovementData']),
    showForm() {
      if (this.movements.length === 0) {
        this.showEntryForm(true)
      } else if (
        this.movements[this.movements.length - 1].tipo_movimiento === 'Entrada'
      ) {
        this.showDepartureForm(true)
      } else {
        this.showEntryForm(true)
      }
    },
    getRole() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      const roles = user.groups.map((r) => r.name)
      if (roles.includes('Bodega')) return true
    },
    setData() {
      this.showDepartureForm(false)
      this.showEntryForm(false)
      this.showDepartureForm(true)
    },
    getDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
  },
  computed: {
    ...mapGetters('OTStore', ['ot']),
    sortedCambiosUbicacion() {
      // eslint-disable-next-line
      return this.ot.cambios_ubicacion_list.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    },
  },
  destroyed() {
    this.showDepartureForm(false)
    this.showEntryForm(false)
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
