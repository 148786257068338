import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { getHeaders } from './utils'

const listUserGroups = () => {
  const url = '/api/usuarios/groups/'
  const config = getHeaders()
  return axios.get(url, config).catch((error) => error.response)
}

const listUsers = (params) => {
  const url = '/api/usuarios/'
  const config = { params, ...getHeaders() }
  return axios.get(url, config).catch((error) => error.response)
}

export const useUserStore = defineStore('user', {
  state: () => ({}),

  actions: {
    listUserGroups,
    listUsers,
  },
})
