<template lang="pug">
div
  b-button#details-popover.question-button(href="#" size="sm")
    b-icon(icon="question" variant="ligth")
  b-popover(target="details-popover" triggers="click blur" custom-class="popover-root")
    template(slot="title")
      span Cálculo del tiempo
    p El tiempo para este estado se calcula de la siguiente forma:
    p Se suman los tiempos en evaluación y reparación:
    ul
      li(v-for="item in detail.estados") {{ item.estado }}: {{ item.hora | formatElapsedTimeInHours }}
    p (A estos tiempos se les restó 1 hora de almuerzo por día)
    p Y para obtener el color se compara con el siguiente tiempo:
    ul
      li Estado: {{ detail.estado_compuesto.estado }}
      li Hora Alerta Amarilla: {{ detail.estado_compuesto.hora_alerta_1  }} h
      li Hora Alerta Roja: {{ detail.estado_compuesto.hora_alerta_2  }} h
</template>

<script>
import { formatElapsedTimeInHours } from '@/utils/formatElapsedTime'

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  filters: {
    formatElapsedTimeInHours,
  },
}
</script>

<style lang="scss" scoped>
.question-button {
  border-radius: 100%;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-root {
  max-width: 300px;
}
</style>
