import axios from '../../plugins/axios'

export const getMovements = ({ commit }, otId) => {
  //eslint-disable-line
  const apiAddress = `api/bodega/${otId}/movimientos`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_MOVEMENTS', { movements: response.data })
  })
}

// eslint-disable-next-line
export const showEntryForm = ({ commit }, showEntryForm) => {
  commit('SHOW_ENTRY_FORM', { showEntryForm })
}

// eslint-disable-next-line
export const showDepartureForm = ({ commit }, showDepartureForm) => {
  commit('SHOW_DEPARTURE_FORM', { showDepartureForm })
}

export const getAllUsers = ({ commit }) => {
  //eslint-disable-line
  const apiAddress = 'api/usuarios/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_ALL_USERS', { allUsers: response.data })
  })
}

export const saveMovement = ({}, data) => {
  //eslint-disable-line
  const apiAddress = 'api/bodega/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const editMovement = ({}, data) => {
  //eslint-disable-line
  const apiAddress = `api/bodega/${data.id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const setMovementData = ({ commit }, movementSelected) => {
  //eslint-disable-line
  commit('SET_MOVEMENT_SELECTED', { movementSelected })
}

export const changeWarehouseState = ({ commit }, data) => {
  console.log('NO DEBERÍA CAMBIARSE ESTO DESDE EL FRONT!!!')
  // TODO: eliminar este servicio y reemplazar por transiciones
  const apiAddress = 'api/bodega/cambio_estado'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .put(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATE_IS_CHANGED', true)
      console.log(response.data)
    })
    .catch((error) => {
      console.log(error.response)
    })
}

// accion para listar historial de una parte
export const getHistoryPart = (_, { pk, page }) => {
  const apiAddress = `api/bodega/parte/historial/${pk}?page=${page}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response)
    .catch((error) => {
      console.log(error.response)
    })
}

// ingresar inventario
export const saveInventory = (_, data) => {
  const apiAddress = 'api/bodega/movimiento_inventario'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const listMovementsInventory = (_, { page, code, type }) => {
  const apiAddress = `api/bodega/movimiento_inventario?page=${page}&code=${code}&type=${type}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response)
    .catch((error) => error.response)
}

// ingresar inventario con excel
export const saveInventoryExcel = (_, data) => {
  const apiAddress = 'api/bodega/upload_inventory'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const getMovementsGroups = (_, { filters }) => {
  const apiAddress = `api/bodega/grupo_movimiento/${filters}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const getMovementGroupDetail = (_, { pk }) => {
  const apiAddress = `api/bodega/grupo_movimiento/${pk}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .get(apiAddress, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const manualMovementDetail = ({}, pk) => {
  const apiAddress = `/api/bodega/grupo_movimiento/${pk}/detalle_excel`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    responseType: 'blob',
  }
  return axios.get(apiAddress, config).then((response) => {
    console.log(response)
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/ms-excel',
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'detalle-mov-manual.xls')
    document.body.appendChild(link)
    link.click()
  })
}
