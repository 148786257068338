<template lang="pug">
.flex-col.gap-2
  .flex-row.justify-content-between.w-100
    .flex-row.gap-2
      BackArrow.m-0.mb-n4
      h4.title Reportes - Usuarios
    b-breadcrumb(:items="breadcrumbs")

  FilterBar
  .flex-row.gap-2
    OrderCounterCard
    ActionChartCard
  ActionLogList
  ActionLogPagination

</template>

<script>
import { mapActions } from 'pinia'
import BackArrow from '@/components/UI/BackArrow.vue'
import FilterBar from '@/components/Reports/UserReport/FilterBar.vue'
import OrderCounterCard from '@/components/Reports/UserReport/OrderCounterCard.vue'
import ActionChartCard from '@/components/Reports/UserReport/ActionChartCard.vue'
import ActionLogList from '@/components/Reports/UserReport/ActionLogList.vue'
import ActionLogPagination from '@/components/Reports/UserReport/ActionLogPagination.vue'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'

export default {
  components: {
    BackArrow,
    FilterBar,
    OrderCounterCard,
    ActionChartCard,
    ActionLogList,
    ActionLogPagination,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Inicio',
        to: { path: '/' },
      },
      {
        text: 'Usuarios',
        to: { path: '/reports/user' },
      }
    ],
  },
  methods: {
    ...mapActions(useUserReportStore, [
      'getUserRoles',
      'getActionLogs',
      'getUsers',
    ]),
  },
  async created() {
    await this.getUserRoles()
    await this.getUsers()
    await this.getActionLogs()
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
  margin: 0;
}
</style>
