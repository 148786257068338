import axios from '../../plugins/axios'

// eslint-disable-next-line
export const login = ({ commit }, { email, password }) => {
  if (email && password) {
    return axios
      .post('/api/auth/login/', { username: email, email, password })
      .then(
        (response) => {
          const user = response.data
          commit('SET_USER', { user })
          return response
        },
        (error) => {
          console.error(error)
        }
      )
  }
}

export const logout = ({ commit }) => {
  commit('SET_USER', { user: null })
  localStorage.removeItem('user')
}

export const registerFCMDevice = ({}, data) => {
  const url = '/api/mensajeria/register_device'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios.post(url, data, config).then(
    (response) => response,
    (error) => error
  )
}

export const getAllUsers = ({}) => {
  // eslint-disable-line
  const apiAddress = '/api/usuarios/'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => response.data)
}

export const registerRetailUser = ({}, { email }) => {
  return axios
    .post('/api/retail/register_retail_user/', { email })
}

export const resetRetailUserPassword = ({}, { user_id, password, token }) => {
  const headers = { Authorization: `Token ${token}` }
  return axios.post(
    '/api/retail/reset_retail_user_password/',
    { user_id, password },
    { headers }
  )
}
