<template lang="pug">
b-card(v-if="ot")
  h4.ot-data.title.mb-5 Pedidos de Repuestos Orden de Trabajo {{ot.ot}}
  div.d-flex
    b-icon.mr-2(icon="clipboard" variant="primary" font-scale="1.5")
    h6.mb-0.ot-data.sub-title Resumen OT
  hr
  .row.ot-data.mb-4
    .col-2
      label.label O.T
      p.data {{ot.ot}}
    .col-2
      label.label R.M.A
      p.data {{ot.rma}}
    .col-2
      label.label Código R.M.A
      VueBarcode(v-model="ot.rma" width=1 height=20 textAlign="center" fontSize="13")
    .col-2
      label.label Fecha y Hora
      p.data {{ot.fecha_ingreso | beautyDate}}
    .col-2
      label.label Técnico que solicita
      p.data {{ot.tecnico_asignado ? ot.tecnico_asignado.first_name + ' ' + ot.tecnico_asignado.last_name : '-'}}
    .col-4
  PartsTable(:ot="ot" :showStateActionColumn="false")
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import VueBarcode from 'vue-barcode'
import PartsTable from './PartsTable.vue'

export default {
  name: 'RequestedSpareParts',
  components: { PartsTable, VueBarcode },
  computed: {
    ...mapGetters('OTStore', ['ot']),
  },
  filters: {
    beautyDate(fullDate) {
      //eslint-disable-line
      if (fullDate) {
        const date = moment(fullDate).format('DD/MM/YYYY')
        const time = moment(fullDate).format('HH:mm')
        return `${date} a las ${time}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ot-data {
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
}
.data {
  color: #495057;
  font-weight: normal;
}
.label {
  color: #253590;
}
.title {
  color: #74788d;
}
.sub-title {
  color: #253590;
}
</style>
