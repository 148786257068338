import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { getHeaders } from './utils'

const getActionsByRole = () => {
  const url = '/api/accion/actions-by-role/'
  const config = getHeaders()
  return axios.get(url, config).catch((error) => error.response)
}

const listActionLogs = (params) => {
  const url = '/api/accion/registro-accion/'
  const config = { params, ...getHeaders() }
  return axios.get(url, config).catch((error) => error.response)
}

const exportActionLogs = (params) => {
  const url = '/api/accion/registro-accion/export-xls/'
  const config = { params, ...getHeaders(), responseType: 'blob' }
  return axios.get(url, config).catch((error) => error.response)
}

export const useActionLogStore = defineStore('actionLog', {
  state: () => ({}),

  actions: {
    getActionsByRole,
    listActionLogs,
    exportActionLogs,
  },
})
