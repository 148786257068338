import { defineStore } from 'pinia'



export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    filters: {
      fase: null,
      tipoServicio: null,
      tipoCliente: null,
      tecnico: null,
      alertColor: [],
    },
    loading: false,
  }),

  getters: {
    queryParams: (state) => {
      const { filters: f } = state
      return {
        servicio: f.tipoServicio || 0,
        tecnico: f.tecnico || 0,
        tipo_cliente: f.tipoCliente,
        alert_color: f.alertColor,
      }
    },
  },

  actions: {},
})
