<template lang="pug">
Fragment
  .loading-container( v-if="loading" )
    b-spinner( label="Loading..." variant="primary" )
  div( v-show="!loading" )
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Excepciones
  ListException( @stop-loading="loading = false" )
</template>

<script>
import ListException from '../components/Excepcion/List.vue'
import BackArrow from '../components/UI/BackArrow.vue'

export default {
  name: 'Excepciones',
  components: {
    ListException,
    BackArrow,
  },
  data() {
    return {
      loading: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
