<template lang="pug">
Fragment
  .loading-container( v-if="loading" )
    b-spinner( label="Loading..." variant="primary" )
  div( v-show="!loading" )
  b-row
    b-col(cols='1')
      BackArrow
    b-col(cols='11').d-flex.justify-content-flex-start.mb-2
      h4.title Cotizaciones externas
  ExternalQuotesList( @stop-loading="loading = false" )
  ExternalQuotesPagination
</template>

<script>
import BackArrow from '@/components/UI/BackArrow.vue'
import ExternalQuotesList from '@/components/Quotations/ExternalQuotes/ExternalQuotesList.vue'
import ExternalQuotesPagination from '@/components/Quotations/ExternalQuotes/ExternalQuotesPagination.vue'
import { mapActions } from 'pinia'
import { useExternalQuoteListStore } from '@/pinia/views/useExternalQuoteListStore'

export default {
  components: {
    BackArrow,
    ExternalQuotesList,
    ExternalQuotesPagination,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions(useExternalQuoteListStore, ['getExternalQuotes']),
  },
  async created() {
    await this.getExternalQuotes()
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
