<template lang="pug">
.flex-row.justify-content-end
  b-pagination(
    v-model="filters.page"
    :total-rows="totalActions"
    :per-page="20"
    @input="getActionLogs"
  )
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useUserReportStore } from '@/pinia/views/useUserReportStore';

export default {
  computed: {
    ...mapWritableState(useUserReportStore, ['filters']),
    ...mapState(useUserReportStore, ['totalActions'])
  },
  methods: {
    ...mapActions(useUserReportStore, ['getActionLogs']),
  },
}
</script>

<style lang="scss" scoped>

</style>
