const config = {
  BModal: {
    okTitle: 'Aceptar',
    okVariant: 'button',
    cancelTitle: 'Cancelar',
    cancelVariant: 'outline-danger',
  },

  BButton: {
    variant: 'button',
  },
  BFormDatepicker: {
    navButtonVariant: 'button',
  },
}

export default config
