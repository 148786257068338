<template lang="pug">
div
  //- Salida
  .d-flex.justify-content-end
    b-icon(
      icon="x"
      font-scale="2.5"
      @click="hideForm"
      style="cursor: pointer;"
    )
  h4.title Datos de Despacho
  h6.title Tipo de Retiro:
  .d-flex
    strong {{ getTipoRetiro(formDespacho.tipoRetiro) }}
  br
  //- b-form-select.mb-3(:disabled="disabledField" v-model="formDespacho.tipoRetiro" :options="tipoRetiroOptions")
  h6.title N° Guía de Despacho
  b-form-input.mb-3(:disabled="disabledField" v-model="formDespacho.nroGuia")
  div(v-if="formDespacho.tipoRetiro === 'ENVIO' || formDespacho.tipoRetiro === 'COORD_CLIENTE'")
    h6.title N° Guía de Courier
    b-form-input.mb-3(:disabled="disabledField" v-model="formDespacho.nroCourier")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DepartureForm',
  data() {
    return {
      disabledField: true,
      disabled: false,
      formDespacho: {
        tipoRetiro: null,
        nroGuia: null,
        nroCourier: null,
      },
      tipoSalidaOptions: [
        { value: null, text: 'Seleccione', disabled: true },
        { value: 1, text: 'Externo' },
        { value: 2, text: 'Interno' },
      ],
      // tipoRetiroOptions: [
      //   { value: null, text: 'Seleccione', disabled: true },
      //   { value: 'RETIRO', text: 'Retiro por cliente' },
      //   { value: 'ENVIO', text: 'Despacho a domicilio' },
      //   { value: 'COORD_CLIENTE', text: 'Coordinado por cliente' },
      //   { value: 'ON_SITE', text: 'On Site',  },
      // ],
    }
  },
  created() {
    if (this.ot) {
      this.formDespacho.tipoRetiro = this.ot.cliente.persona && this.ot.cliente.tipo_cliente === 'Persona' ? this.ot.despacho : this.ot.sucursal.despacho
      this.formDespacho.nroGuia = this.ot.numero_guia_despacho
      this.formDespacho.nroCourier = this.ot.cliente.persona && this.ot.cliente.tipo_cliente === 'Persona' ? this.ot.numero_courrier : this.ot.sucursal.numero_courrier
      this.formDespacho.comentarios = this.ot.cliente.persona && this.ot.cliente.tipo_cliente === 'Persona' ? this.ot.comentarios : this.ot.sucursal.comentarios
    }
  },
  methods: {
    ...mapActions('Warehouse', ['showDepartureForm']),
    ...mapActions('Warehouse', ['saveMovement']),
    ...mapActions('Warehouse', ['editMovement']),
    ...mapActions('Warehouse', ['getMovements', 'setMovementData']),
    ...mapActions('OTStore', ['getOT']),
    hideForm() {
      this.showDepartureForm(false)
    },
    // getTipoSalida() {
    //   return this.tipoRetiroOptions.find(
    //     (t) => t.text === this.ot.despacho
    //   ).value
    // },
    getTipoRetiro(retiro) {
      if (retiro === 'ENVIO') return 'Envío'
      if (retiro === 'RETIRO') return 'Retiro'
      if (retiro === 'COORD_CLIENTE') return 'Coordinado por cliente'
      if (retiro === 'ON_SITE') return 'On Site'
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot']),
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
}
.footer {
  margin-bottom: 15px;
  width: 100%;
  display: block;
  text-align: right;
  position: absolute;
  bottom: 0px;
  left: 0;
  padding-right: 20px;
}
</style>
