<template lang="pug">
div
  b-container
    b-button(v-if="hasActions() && isNotClosed" @click="showModal" size="sm")
      b-spinner(v-if="loading" small)
      span(v-else) Ver Acciones
    p(v-else) ---

    b-modal(v-model="modal" :title="modalTitle" @close="cleanOT" hide-footer)
      OTActionsIndex(
        v-if="ot"
        :ot="ot"
        :loading="parentLoading || loading"
        squareLayout
        @changed="() => $emit('changed')"
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OTActionsIndex from '../OTActions/OTActionsIndex.vue'

export default {
  name: 'ActionsWrapper',
  data() {
    return {
      loading: false,
      modal: false,
      ot: null,
    }
  },

  props: {
    otId: {
      type: Number,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
      default: () => ({
        visible: [],
        disabled: [],
      }),
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
    isNotClosed: {
      type: Boolean,
      default: true,
    }
  },

  components: { OTActionsIndex },

  computed: {
    ...mapGetters('AuthenticationStore', ['user']),

    modalTitle() {
      return this.ot ? `Acciones ${this.ot.ot}` : ''
    },
  },
  methods: {
    ...mapActions('OTStore', ['getOTForActions', 'cleanOT']),
    // hasActions: (ot, user) => hasActions(ot, user),
    hasActions() {
      return (
        this.actions.visible.length > 0 ||
        this.actions.disabled.length > 0
      )
    },
    async showModal() {
      this.modal = true
      this.fetchOT()
    },
    async fetchOT() {
      this.loading = true;
      this.ot = await this.getOTForActions(this.otId);
      this.loading = false;
    },
  },
  watch: {
    // otId() {
    //   this.fetchOT(this.otId);
    // },
  },
}
</script>
