<template lang="pug">
b-container#root-card.pb-4
    b-row
      b-col
        label( for="input-search" ).label-search Ingresar Búsqueda
    b-row
      b-col( cols="12" md="10" )
        b-input-group.with-icon
          b-input-group-prepend( is-text )
            b-icon( icon="search" )
          b-form-input(
            label-cols-lg="5"
            id="input-search"
            type="text"
            class="form-control"
            placeholder="Ingresar OT/Rut Cliente/Nombre Cliente/Razón social"
            v-model="searchedFilter"
            @keydown.enter="searchingFilter"
          )
      b-col( cols="12" md="2" ).mt-3.mt-md-0
        b-button.btn-buscar(
          @click="searchingFilter"
        ) Buscar
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'QuotationSearch',
  props: ['estados'],
  data() {
    return {
      searchedFilter: '',
      stateSearched: null,
      states: this.estados,
      ot: [],
    }
  },
  async created() {
    await this.searchQuotations({
      estado: 'ESPECIFICA_PIEZAS',
      ot: null,
    })
  },
  computed: {
    ...mapGetters('Quotations', [
      'quotations',
      'currentPage',
      'isSearched',
      'currentQuotations',
      'currentStates',
    ]),
  },
  methods: {
    ...mapActions('Quotations', ['searchQuotations']),
    ...mapMutations('Quotations', [
      'SET_QUOTATIONS',
      'SET_IS_SEARCHED',
      'SET_IS_CREATED',
    ]),
    searchingFilter() {
      try {
        this.searchQuotations({
          estado: this.states[0],
          search: this.searchedFilter,
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
  watch: {
    searchedFilter(newVal) {
      if (newVal === '') {
        this.SET_IS_SEARCHED()
        this.searchingFilter()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#root-card {
  max-width: 100% !important;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}

.with-icon::v-deep {
  .input-group-text {
    background-color: inherit;
    border-right: none;
  }
  .form-control {
    border-left: none;
  }
}

.label-search {
  color: #74788d;
  font-weight: 700;
  margin-left: 2%;
  margin-top: 0.8em;
}

.btn-buscar {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0 auto;
}
</style>
