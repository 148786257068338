<template lang="pug">
  div
    .d-flex.justify-content-between
      .d-flex.justify-content-start
        b-icon.mr-2(icon="tools" variant="primary" font-scale="1.5")
        span.title Historial Movimientos de Inventario
    hr
    b-table(:items="statesItems" :busy="loading" hover responsive)
      template(#table-busy)
            div(class="text-center text-danger my-2")
              b-spinner(class="align-middle")
    h4.text-center(v-if="statesItems.length === 0") No hay registros
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'InventoryMovementsHistory',
  props: ['otId'],
  data() {
    return {
      movements: [],
      loading: false,
    }
  },
  async created() {
    this.loading = true
    await this.getInventoryMovementsOt(this.otId).then((res) => {
      this.movements = res.data
    })
    this.loading = false
  },
  methods: {
    ...mapActions('OT', ['getInventoryMovementsOt']),
  },
  computed: {
    statesItems() {
      return this.movements.map((e) => ({
        'Fecha ': new Date(e.created_at).toLocaleString(),
        'Código ': e.parte.part_number,
        'Descripción': e.parte.description,
        'Ubicación real ': e.parte.real_location,
        'Tipo ': e.tipo,
        'Cantidad ': e.cantidad,
        'Usuario ': e.user ? e.user : '',
      }))
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
