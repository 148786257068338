<template lang="pug">
.wrapper
  b-button.boton(
    small
    @click="confirm(newCustomer ? 'create' : 'edit')"
  )
    | {{ newCustomer ? 'Crear cliente' : 'Confirmar' }}

  b-button.boton(
    v-if="editing && !newCustomer"
    small variant="secondary"
    @click="$emit('cancel')"
  )
    | Cancelar

  b-button.boton(
    :disabled="loading"
    v-if="!editing && !newCustomer"
    small
    @click="$emit('edit')"
  )
    | Editar
</template>

<script>
export default {
  props: {
    editing: Boolean,
    newCustomer: Boolean,
    rutLoaded: Boolean,
    loading: Boolean,
    confirm: Function,
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.wrapper {
  display: flex;
  gap: 1rem;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.boton {
  min-width: 120px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
</style>
