import { defineStore } from 'pinia'
import { useExternalQuoteStore } from '../api/useExternalQuoteStore'

export const useExternalQuoteListStore = defineStore('externalQuoteList', {
  state: () => ({
    filters: {
      states: ['REQUESTED'],
      page: 1,
    },
    currentTab: null,
    loading: true,
    results: [],
    totalItems: 0,
    summary: null,
  }),

  getters: {
    queryParams: (state) => {
      const f = state.filters
      return {
        page: f.page,
        state__in: f.states.join(','),
      }
    },
  },

  actions: {
    async getExternalQuotes() {
      this.loading = true
      const externalQuote = useExternalQuoteStore()
      const res = await externalQuote.listExternalQuotes(this.queryParams)

      if (res.status !== 200) return
      console.log('res', res)

      this.results = res.data.results
      this.totalItems = res.data.count
      this.loading = false
    },
  },
})
