<template lang="pug">
#PartsList
  div(style="display: flex; justify-content: end;")
    b-button.mb-3(variant="button" :href="fileUrl" download align-self="end")
      b-icon.mr-1(icon="cloud-download" variant="ligth")
      | Descargar Plantilla
  .container-list
    b-card(border-variant="light")
      strong Ingresar Búsqueda
      b-row
        b-col(cols="6")
          b-input-group.mt-2
            b-input-group-prepend
              b-button(@click="handleSearch" variant="light")
                b-icon(icon="search" variant="muted" size="md")
            b-form-input(
              v-model="search"
              placeholder="Ingresar el Código de la Pieza / Descripción / Ubicación Real"
              @keydown.enter="handleSearch"
              @keydown.esc="handleClear"
            )
            b-input-group-append
              b-button(@click="handleClear" variant="light")
                b-icon(icon="x" variant="muted" size="md")
        b-col.d-flex.justify-content-end(cols="6")
          //- Crear/Editar Parte
          b-button.mt-2(variant="estado-green" v-b-modal.create-edit-modal) + Crear Nueva Pieza
          b-modal#create-edit-modal(:title="form.id ? 'Editar pieza' : 'Crear Nueva Pieza'" hide-footer ref="createmodal" @hidden="triggerCloseCreateModal")
            .col-10
              label.label Código de la Pieza
              b-form-input(v-model="form.part_number", placeholder="Ingrese Código de la Pieza", :state="$v.$dirty ? !$v.form.part_number.$invalid : null")
              .errors(v-if="$v.$dirty")
                b-form-invalid-feedback(:state="$v.form.part_number.required") Campo requerido
            .col-10
              label.label Descripción
              b-form-input(v-model="form.description", placeholder="Ingrese Descripción", :state="$v.$dirty ? !$v.form.description.$invalid : null")
              .errors(v-if="$v.$dirty")
                b-form-invalid-feedback(:state="$v.form.description.required") Campo requerido
            .col-10
              label.label Ubicación Real
              b-form-input(v-model="form.real_location", placeholder="Ingrese Ubicación Real", :state="$v.$dirty ? !$v.form.real_location.$invalid : null")
              .errors(v-if="$v.$dirty")
                b-form-invalid-feedback(:state="$v.form.real_location.required") Campo requerido
            .col-12.d-flex.justify-content-around.mt-4
              b-button.modal-btn(variant="outline-danger" @click="$bvModal.hide('create-edit-modal')") Cancelar
              b-button.modal-btn(variant="button" @click="createOrEditPart()" :disabled="loading") {{ form.id ? 'Editar' : 'Crear' }}
          //- Carga Masiva
          b-button.ml-2.mt-2(variant="button" v-b-modal.file-modal)
            b-icon.mr-1(icon="file-earmark-arrow-up-fill" variant="ligth")
            |  Carga Masiva
          b-modal#file-modal(title="Seleccionar archivo" hide-footer)
            b-form-file(
              v-model="file"
              accept=".xlsx, .xls, .csv"
              placeholder="Seleccionar archivo"
              drop-placeholder="Arrastre el archivo aquí"
              browse-text="Buscar"
            )
            .d-flex.justify-content-end.mt-5
              b-button(variant="button" @click="handleFileUpload") Cargar
  b-table(
    :items="parts",
    :fields="fields",
    head-row-variant="light-primary",
    thead-class="text-black tabla-thead",
    tbody-class="tabla-tbody",
    tbody-tr-class="bg-white",
    responsive,
    :busy="loading",
    show-empty,
    empty-text="Sin datos en lista de partes"
  )
    template(#cell(actions)='value')
      b-button(variant="link" @click="getPartDetail(value.item.id)" v-b-modal.create-edit-modal)
        b-icon.mr-1(icon="pencil-square")
      b-button(variant="link" @click="handleDeleteRow(value.item.id, value.item.part_number)" v-b-modal.delete-modal)
        b-icon.mr-1(icon="trash-fill" variant="danger")
  b-pagination(
    v-model="currentPage",
    :total-rows="count",
    prev-text="Ant.",
    next-text="Sig.",
    @change="handlePageChange"
  )
  b-modal#delete-modal(title="Eliminar Pieza" ok-title="Eliminar" @ok="deletePart(form.id)" @hidden="triggerCloseCreateModal")
    label ¿Desea eliminar pieza {{ partSelected }}?
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

const basePath = process.env.VUE_APP_SERVER_BASE || 'https://center.sill.cl'

export default {
  name: 'PartsList',
  validations: {
    form: {
      part_number: { required },
      description: { required },
      real_location: { required },
    },
  },
  data() {
    return {
      parts: [],
      currentPage: 1,
      count: 0,
      idUpdating: null,
      loading: false,
      file: null,
      search: '',
      partSelected: '',
      fileUrl: `${basePath}/plantilla_creacion.xlsx`,
      form: {
        id: null,
        part_number: '',
        normalized_part_number: '',
        description: '',
        real_location: '',
      },
      fields: [
        { key: 'part_number', label: 'Código', sortable: true },
        { key: 'real_location', label: 'Ubicación real', sortable: true },
        { key: 'description', label: 'Descripción', sortable: true },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('PartsStore', ['part']),
  },
  methods: {
    ...mapActions('PartsStore', [
      'listParts',
      'savePartsExcel',
      'createPart',
      'patchPart',
      'removePart',
      'retrievePart',
    ]),
    async fetchData(page = 1) {
      this.loading = true
      const resp = await this.listParts({ page, search: this.search })
      this.parts = resp.results
      this.count = resp.count
      this.loading = false
    },
    async getPartDetail(pk) {
      await this.retrievePart({ pk })
      this.form.id = this.part.parte.id
      this.form.part_number = this.part.parte.part_number
      this.form.real_location = this.part.parte.real_location
      this.form.description = this.part.parte.description
    },
    handleDeleteRow(id, part) {
      this.partSelected = part
      this.form.id = id
    },
    handleSearch() {
      this.fetchData(this.currentPage)
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
    async deletePart(pk) {
      await this.removePart({ pk })
      this.$bvModal.hide('delete-modal')
      this.fetchData(this.currentPage)
      this.$bvToast.toast('Parte eliminada correctamente.', {
        variant: 'success',
      })
    },
    handleClear() {
      this.search = ''
      this.fetchData(this.currentPage)
    },
    async handleFileUpload() {
      const file_data = new FormData()
      file_data.append('file', this.file)
      const response = await this.savePartsExcel(file_data)
      if (response.status === 200) {
        this.$bvToast.toast('Partes ingresadas.', {
          variant: 'success',
        })
        this.$bvModal.hide('file-modal')
      } else {
        this.$bvToast.toast(response.data, {
          variant: 'danger',
        })
      }
    },
    async createOrEditPart() {
      this.loading = true
      const action = this.form.id ? this.patchPart : this.createPart
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.form.normalized_part_number = this.form.part_number
        const resp = await action(this.form)
        if (resp.status === 201 || resp.status === 200) {
          this.$bvToast.toast(
            this.form.id
              ? 'Se ha editado exitósamente'
              : 'Se ha creado exitósamente',
            {
              variant: 'success',
            }
          )
          this.$bvModal.hide('create-edit-modal')
          this.fetchData(this.currentPage)
        } else {
          Object.values(resp.data).forEach((errors) => {
            errors.forEach((error) => {
              this.$bvToast.toast(error, {
                variant: 'danger',
              })
            })
          })
        }
      }
      this.loading = false
    },
    triggerCloseCreateModal() {
      this.form.id = null
      this.form.part_number = ''
      this.form.description = ''
      this.form.real_location = ''
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
<style lang="scss">
#PartsList {
  .search-btn {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .btn-ingreso {
    width: 130px;
    color: black !important;
    font-weight: bold !important;
    font-size: 14px !important;
    align-items: center;
    opacity: 0.7;
  }
}
#create-edit-modal {
  .col-10 {
    margin-top: 16px;
  }
  label {
    font-weight: bold;
  }
  .label {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
  }
  .modal-btn {
    width: 120px;
    height: 50px;
  }
}
</style>
