import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const EquipmentStore = {
  namespaced: true,
  state: {
    equipment: null,
    models: [],
    brands: [],
    model: null,
    statusRequest: null,
  },
  getters,
  mutations,
  actions,
}

export default EquipmentStore
