<template lang="pug">
  .select-technician
    div
      //- BOTON RECHAZADO
      //-Hace ingreso a bodega un equipo en estado Rechazado
      button.change-button(
        :disabled="disabled || loading"
        title="Ingresar a bodega"
        @click="crearMovimientoBodega(1, 6, 0, ot.tecnico_asignado.id, user.id, 'Bodega')")
        | Ingresar a bodega rechazado
</template>

<script>
import { ACTION_NAMES } from '@/utils/enums/actions'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'ActionIngresarABodegaRechazado',
  props: ['ot', 'disabled'],

  data() {
    return {
      user: null,
      isBodega: false,
      loading: false,
    }
  },
  created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
  },
  methods: {
    ...mapActions('Warehouse', ['saveMovement']),
    ...mapActions('OTStore', ['editOT', 'getOT']),
    async crearMovimientoBodega(
      t_movimiento,
      t_entrada,
      t_salida,
      entregado,
      recibido,
      ubicacion_ot
    ) {
      this.loading = true
      const data = {
        ot: this.ot.ot,
        tipo_movimiento: t_movimiento,
        tipo_entrada: t_entrada,
        tipo_salida: t_salida,
        entregado_por: entregado,
        recibido_por: recibido,
      }
      const resp = await this.saveMovement(data)
      if (resp.status === 200) {
        await this.editOT({
          ot: this.ot.ot,
          ubicacion: ubicacion_ot,
          action: ACTION_NAMES.ingresarABodegaRechazado,
        })
        this.$bvToast.toast(
          `OT ingresada a las ${moment(resp.data.fecha_hora).format(
            'DD/MM/YYYY HH:mm'
          )}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
        await this.getOT(this.ot.ot)
      } else {
        this.$bvToast.toast(
          `Error al crear el movimiento de bodega: ${resp.data}`,
          {
            variant: 'danger',
          }
        )
      }
      this.loading = false
    },
  },
}
</script>
