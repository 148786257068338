export const ot = (state) => state.ot
export const ots = (state) => state.ots
export const isSearchedOT = (state) => state.isSearchedOT
export const itSearchedOT = (state) => state.itSearchedOT
export const statusRequest = (state) => state.statusRequest
export const excelResponse = (state) => state.excelResponse
export const files = (state) => state.files
export const uploadingFile = (state) => state.uploadingFile
export const printNoDisplay = (state) => state.printNoDisplay
export const couriers = (state) => state.couriers
export const distribuidores = (state) => state.distribuidores
export const storeList = (state) => state.storeList
