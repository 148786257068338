import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const Diagnosticos = {
  namespaced: true,
  state: {
    diagnostic: null,
    showDiagnosticForm: null,
    filteredParts: null,
  },
  getters,
  mutations,
  actions,
}

export default Diagnosticos
