<template lang="pug">
#app
  navbar(v-if="user && $route.name !== 'ClienteOT'  && $route.name !== 'ClienteOT-login' && !$route.meta.fullScreen")
  PushNotificationAlert(:notification="notification")
  router-view(v-if="$route.meta.fullScreen")
  .container(v-else)
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PushNotificationAlert from './components/UI/PushNotificationAlert.vue'
import Navbar from './components/UI/Navbar.vue'
import initializeFirebase from './shared/firebase'
import isAuthenticated from './utils/isAuthenticated'

export default {
  components: {
    Navbar,
    PushNotificationAlert
  },
  computed: {
    ...mapGetters('Notifications', ['notification']),
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('AuthenticationStore', ['registerFCMDevice']),
    ...mapActions('ClientStore', ['listRegiones']),
  },
  async created() {
    // Se ontiene la lista de regiones, al momento de cargar la app
    await this.listRegiones()
    if (!isAuthenticated()) return
    const token = await initializeFirebase()
    if (token) {
      this.registerFCMDevice({ device_token: token })
    }
  },
}
</script>

<style lang="scss">
@import '../src/style/global.scss';
@import '../src/style/utility.scss';
html {
  height: 100%;
}
body {
  font-family: 'Open Sans';
  height: 100%;
}
#app {
  height: 100%;
}
.container-xl,
.container-lg,
// .container-md,
.container-sm,
.container {
  max-width: 96% !important;
  @media only screen and (max-width: 1200px) {
    max-width: 98% !important;
  }
}
</style>
