<template lang="pug">
div
  .ActionRechazarPreIngreso
    div
      //- BOTON rechazar PRE-INGRESO
      //-Boton para rechazar preingreso del cliente, como administrador
    b-button.rechazar-button(
      :disabled="disabled"
      @click="showModal"
      variant='danger'
    ) 
      span Rechazar Pre-Ingreso
  b-modal.modal(v-model="modal" title="Rechazar Pre - Ingreso" hide-footer)
    p Para rechazar el Pre Ingreso, por favor escribe los motivos del rechazo.
    .form-group
      label Motivo del Rechazo
      b-form-textarea(
        id="textarea"
        v-model="comment"
        placeholder="Ingresa el motivo del rechazo"
        rows="3"
        max-rows="6"
        :state="$v.comment.$dirty ? !$v.comment.$invalid : null"
      )
      b-form-invalid-feedback(v-if="!$v.comment.required")
        | Debe ingresar un comentario de al menos 5 caracteres
      b-form-invalid-feedback(v-if="!$v.comment.minLength")
        | Debe ingresar un comentario de al menos 5 caracteres
      center
        button.change-button.handdle-btn(
          @click="handleOk"
        ) Rechazar
</template>

<script>
import { mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ActionRechazarPreIngreso',
  props: ['ot', 'disabled'],
  data() {
    return {
      modal: false,
      comment: ''
    }
  },
  validations: {
    comment: {
      required,
      minLength: minLength(5),
    },
  },
  methods: {
    ...mapActions('OTStore', ['rechazarPreIngreso']),
    showModal() {
      this.modal = true
    },
    hideModal() {
      this.modal = false
    },
    async handleOk() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        return
      }
      const resp = await this.rechazarPreIngreso({
        ot: this.ot.ot,
        comment: this.comment,
      });
      if (resp && resp.status === 200) {
        this.$bvToast.toast('La OT se ha rechazado correctamente', {
          variant: 'success',
        });
      } else {
        this.$bvToast.toast('Error en el rechazo de OT', {
          variant: 'danger',
        });
      }

      this.hideModal()
    },
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
p {
  color: #616161;
  text-align: center;
  font-size: 15px;
}
.form-group {
  label {
    color: #253590;
    font-size: 18px;
  }
}
.ActionRechazarPreIngreso {
  .rechazar-button {
    width: 170px;
    height: 60px;
    border-radius: 4px;
    border: none;
    margin-right: 15px;
    margin-bottom: 0;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
}
.handdle-btn {
  margin-top: 24px;
  height: 50px !important;
}
</style>
