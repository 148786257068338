import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const Exceptions = {
  namespaced: true,
  state: {
    exceptions: [],
    exception: {},
    currentExceptions: [],
    currentStates: [],
    page: 1,
    lastQuery: '',
    showModal: null,
    isCreated: null,
    status: null,
    isSearched: false,
  },
  getters,
  mutations,
  actions,
}

export default Exceptions
