<template lang="pug">
b-modal(
    v-if="ot && ot.alert_text"
    v-model="show"
    centered hide-footer
    :title="`Alerta para N° Serie: ${ot.equipo.serial_number}`"
  )
  .d-flex.flex-column.align-items-center
    b-icon(icon="exclamation-triangle-fill" variant="warning" class="mb-3" font-scale="4")
    p.text-center {{ ot.alert_text }}
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    ot: {
      type: Object,
      default: null,
    },
    autoOpenOnMount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  methods: {},

  mounted() {
    if (this.autoOpenOnMount) {
      this.show = true
    }
  },
}
</script>

<style lang="scss" scoped></style>
