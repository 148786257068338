<template lang="pug">
b-row
  b-col
    .header
      | Cliente {{ tipo === 1 ? 'persona' : 'empresa' }}
      b-badge.ml-3( variant="info" ) Nuevo
</template>

<script>
export default {
  props: {
    tipo: Number,
  },
}
</script>

<style lang="scss" scoped>
.header {
  color: #253590;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1em;
}
</style>
