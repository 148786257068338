<template lang="pug">
.states-history
  .d-flex
    b-icon.mr-2( icon="tools" variant="primary" font-scale="1.5" )
    span.title Historial Estados
  hr
  b-table(:items="statesItems" hover responsive)
</template>

<script>
import formatElapsedTime, { formatElapsedTimeInHours } from '@/utils/formatElapsedTime'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'StatesHistory',
  props: ['otId'],
  data() {
    return {
      estados: [],
      timeElapsed: 0,
      momentNow: null,
      interval: null,
    }
  },
  created() {
    this.getOtHistory(this.otId).then((res) => {
      this.estados = res.data
      this.momentNow = moment()
      this.interval = setInterval(() => {
        this.timeElapsed += 1
      }, 1000)
    })
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions('OT', ['getOtHistory']),
    calculateDuration(state, index) {
      const actualDate = moment(state.fecha_hora)
      if (!this.estados[index - 1]) {
        const duration = moment.duration(actualDate.diff(this.momentNow))
        return formatElapsedTime(-duration.asSeconds() + this.timeElapsed)
      }
      const prevState = this.estados[index - 1]
      const prevDate = moment(prevState.fecha_hora)
      const duration = moment.duration(prevDate.diff(actualDate))
      const hours = duration.asHours()
      return `${hours.toFixed(2)} h`
    },
  },
  computed: {
    statesItems() {
      return this.estados.map((s, index) => {
        // const workTime = index === 0 ? formatElapsedTimeAsHHMMSS(s.work_time_duration) : formatElapsedTimeInHours(s.work_time_duration)
        return {
          'Fecha y hora': moment(s.fecha_hora).format('DD/MM/YYYY HH:mm'),
          estado: s.estado.nombre,
          Usuario: s.user ? `${s.user.first_name} ${s.user.last_name}` : '-',
          Ubicación: s.ubicacion && s.ubicacion !== '' ? s.ubicacion : '-',
          Duración: this.calculateDuration(s, index),
          'Horas hábiles': formatElapsedTimeInHours(s.work_time_duration),
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #253590;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
