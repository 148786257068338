<template lang="pug">
#NavBar
  b-navbar(variant="light-primary").client-navbar
    b-navbar-brand
      img.ml-md-5.ml-2.mr-1(style="height: 30px;" src="@/assets/logo.png")
    b-navbar-brand
      img(style="height: 20px;" src="@/assets/logo_dell.png")
    b-navbar-brand
      img( style="height: 20px;" src="@/assets/logo_asus.png")
    b-navbar-nav.ml-auto
      router-link(:to="'/cliente'").d-flex.align-items-center
        span.sesion-item Salir
        span.sesion-item
          font-awesome-icon.color-icon(:icon="['fas','sign-out-alt']" size="lg").mr-md-5
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss" scoped>
@import '~@/style/cliente.scss';
@import '~@/style/colors.scss';

$sill-red-light: lighten($sill-red, 40%) !important;

.client-navbar {
  height: 55px;
}

#NavBar::v-deep {
  @media (min-width: 0px) {
    margin-bottom: 30px;
    #UserName {
      color: $sill-grey;
      font-weight: bold;
    }
    hr {
      margin-top: 2px;
    }
    #menu {
      z-index: 99;
      background: #fff;
      height: 100%;
      width: 250px;
      position: fixed;
      top: 0;
      left: -250px;
      padding: 20px;
      transition: 0.3s;
    }
    .btn-hide-menu {
      position: absolute;
      z-index: 100;
      top: 10px;
      right: 16px;
    }
    .boton {
      position: relative;
      background: transparent;
      border-color: transparent;
    }
    .boton:hover {
      background: transparent;
      border-color: transparent;
    }
    .boton:focus {
      outline: none;
    }
    .contenido {
      font-size: 14px;
      height: 32px;
      margin: 90px 0px;
    }

    .nav-item {
      padding-left: 10px;
      border-radius: 5px;
      margin: 5px 0;
      cursor: pointer;
      .nav-link {
        color: $sill-grey;
      }
      &.active {
        background: $sill-red-light;
        .nav-link {
          color: $sill-red;
          font-weight: bold;
        }
      }
      &:hover {
        .nav-link {
          color: $sill-red;
          font-weight: bold;
        }
      }
    }
    .navbar-expand {
      box-shadow: 0px 3px 6px #00000029;
    }
    .imagen {
      width: 21px;
      margin-right: 10px;
      margin-left: 17px;
    }
    .sesion-item {
      color: #74788d;
      font-size: 15px;
      font-weight: bold;
    }
    .color-icon {
      color: #5b73e8;
      margin-left: 10px;
    }
  }
  @media (max-width: 767px) {
    img {
      width: 50px !important;
    }
    .sesion-item {
      font-size: 12px;
    }
  }
}
</style>
