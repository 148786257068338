const mutations = {
  SET_CLIENT(state, { client }) {
    // Desde la api vienen como objetos distintos al trabajar con herencia
    // y desde otros lados del front viene en la raíz del objeto
    // en front los paso al mismo
    state.client = client
      ? client.clientePersona ||
        client.clienteEmpresa ||
        client.persona ||
        client.empresa ||
        client
      : null
    state.completeClient = client
  },
  SET_SUCURSALES(state, { sucursales }) {
    state.sucursales = Array.isArray(sucursales) ? sucursales : []
  },
  SET_EMPRESAS(state, { empresas }) {
    state.empresas = Array.isArray(empresas) ? empresas : []
  },
  SET_REGIONES(state, { regiones }) {
    state.regiones = regiones
  },
  SET_SUCURSAL(state, { sucursal }) {
    state.sucursal = sucursal
  },
  SET_ERROR_SUCURSAL(state, errorSucursal) {
    state.errorSucursal = errorSucursal
  },
}
export default mutations
