import axios from '../../plugins/axios'

export const searchQuotations = ({ commit, state }, { estado, search }) => {
  //eslint-disable-line
  let url = '/api/orden/cotizacion-interna'
  let noFilters = false
  if (estado) {
    url = `/api/orden/cotizacion-interna?estado=${estado}`
  }
  if (search) {
    url = `/api/orden/cotizacion-interna?estado=${estado}&search=${search}`
  }
  if (!estado && !search) {
    noFilters = true
  }
  // construye query con variables de consulta requeridas
  // verifica si es la misma query que se pidió anteriormente para avanzar de página o resetearla
  const isANewQuery = JSON.stringify(url) !== JSON.stringify(state.lastQuery)
  const page = isANewQuery ? 1 : state.page
  if (!page) return // no quedan más páginas
  if (noFilters) {
    url += `?page=${page}`
  }
  if (noFilters === false) {
    url += `&page=${page}`
  }
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(url, config).then((response) => {
    commit('SET_QUOTATIONS', {
      quotations: response.data,
      states: estado,
    })
  })
}

export const editQuotations = ({ commit }, { data, id }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/cotizacion/${id}/responder`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios
    .post(apiAddress, data, config)
    .then((response) => {
      commit('SET_STATUS', response)
      commit('SET_IS_CREATED', true)
      return response
    })
    .catch((error) => {
      commit('SET_STATUS', error.response)
    })
}

export const specifyPartsQuotation = ({}, { data, ot }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${ot}/transiciones/cotizacion-interna/especificar-piezas`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const requestPartsQuotation = ({}, { data, id }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${id}/transiciones/cotizacion-interna/solicitar`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const respondQuotation = ({}, { data, id }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${id}/transiciones/cotizacion-interna/responder`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const addPaymentLinkQuotation = ({}, { data, id }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${id}/transiciones/cotizacion-interna/adjuntar-link-pago`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}

export const markPaymentQuotation = ({}, { data, id }) => {
  // eslint-disable-line
  const apiAddress = `/api/orden/${id}/transiciones/cotizacion-interna/marcar-pago`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }

  return axios
    .post(apiAddress, data, config)
    .then((response) => response)
    .catch((error) => error.response)
}
