import axios from '../../plugins/axios'

export const getNotifications = ({ commit }, payload) => {
  const apiAddress = `/api/usuarios/${payload.id}/notificaciones?page=${payload.page}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    if (response) {
      commit('SET_NOTIFICATIONS_COUNT', response.data.count)
    }
    if (payload.page === 1) {
      if (response) {
        commit('SET_NOTIFICATIONS', { notificacions: response.data.results })
        return
      }
    }
    if (response) {
      commit('ADD_NOTIFICATIONS', { notificacions: response.data.results })
    }
  })
}

export const sendNotification = ({}, data) => {
  const apiAddress = '/api/usuarios/messaging'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config)
}

export const markAllRead = ({}, id) => {
  const apiAddress = `/api/usuarios/notificaciones/${id}/all_read`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config)
}

export const markReadState = ({}, payload) => {
  const apiAddress = `/api/usuarios/notificaciones/${payload.id}/mark_read?read_state=${payload.read_state}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config)
}

export const getNotificationsNew = ({ commit }, id) => {
  const apiAddress = `/api/usuarios/notificaciones/${id}/notificaciones_count`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_NOTIFICATIONS_NEWS', response.data)
  })
}

export const setNewNotification = ({ commit }, notification) => {
  commit('SET_NOTIFICATION', notification)
}
