const mutations = {
  SET_MOVEMENTS(state, { movements }) {
    state.movements = movements
  },
  SHOW_ENTRY_FORM(state, { showEntryForm }) {
    state.showEntryForm = showEntryForm
  },
  SHOW_DEPARTURE_FORM(state, { showDepartureForm }) {
    state.showDepartureForm = showDepartureForm
  },
  SET_MOVEMENT_SELECTED(state, { movementSelected }) {
    state.movementSelected = movementSelected
  },
  SET_ALL_USERS(state, { allUsers }) {
    state.allUsers = allUsers
  },
  SET_STATE_IS_CHANGED(state, stateIsChanged) {
    state.stateIsChanged = stateIsChanged
  },
}
export default mutations
