<template lang="pug">
div
  b-icon.ml-2(icon="pencil-square", variant="primary", @click="modal = true")
  b-modal.hide-footer(title="Editar diagnósico" v-model="modal" id="updateDiagnostic")
    div
      div.details.mt-2
        label.font-weight-bold.mr-2 Status
        span {{diagnostic.resultado}}
      div.details.mt-2
        label.font-weight-bold.mr-2 Fecha
        span {{diagnostic.fecha_creacion | formatedDate}}
      div.details.mt-2
        label.font-weight-bold.mr-2 Solución Macro
        span {{diagnostic.solucion_macro? diagnostic.solucion_macro : '-'}}
      div.details.mt-2
        label.font-weight-bold.mr-2 ¿Se pudo replicar la falla?
        b-form-select(v-model="diagnostic.replicar_falla" :options="options")
      div.details.mt-2
        label.font-weight-bold.mr-2 Informe
        b-form-textarea(id="textarea-auto-height", rows="3", max-rows="8", v-model="diagnosticTemp.informe")
      div.details.mt-2
        label.font-weight-bold.mr-2 Solución
        b-form-textarea(id="textarea-auto-height", rows="3", max-rows="8", v-model="diagnosticTemp.solucion")
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='success', @click="editDiagnostic") Editar
      b-button(size='sm' variant='danger', @click="modal = false") Cancelar
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['diagnostic'],
  data() {
    return {
      modal: false,
      diagnosticTemp: { ...this.diagnostic },
      options: [
        { value: true, text: 'Sí' },
        { value: false, text: 'No' },
      ],
    };
  },
  methods: {
    ...mapActions('Diagnosticos', ['updateDiagnoseOT']),
    async editDiagnostic() {
      const data = {
        informe: this.diagnosticTemp.informe,
        solucion: this.diagnosticTemp.solucion,
        replicar_falla: this.diagnosticTemp.replicar_falla,
      }
      await this.updateDiagnoseOT({ ...data, id: this.diagnostic.id })
      this.$bvToast.toast('Diagnóstico editado exitósamente', {
        variant: 'success',
      })
      this.modal = false
    },
  },
}
</script>
