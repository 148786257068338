<template lang="pug">
div
  b-table(
    :items="currentTableItems"
    :fields="visibleColumns"
    head-row-variant="light-primary"
    tbody-class="tabla-tbody"
    thead-class="text-black tabla-thead"
    tbody-tr-class="bg-white"
    responsive
    :busy="loading"
    show-empty
    empty-text="No hay Ordenes de Trabajo en este estado"
    no-local-sorting
    @sort-changed="sortingChanged"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    sort-icon-left
  )
    template(#table-busy)
      div(class="text-center text-danger my-2")
        b-spinner(class="align-middle")

    template(#cell(ot)='{value}')
      .ot-cell
        router-link(
          :to="(isRetailUser ? 'cliente' : 'orden') + `/${value.ot}` + (isRetailUser ? `/rut/${getClientRut(value.cliente)}` : '')"
          :style="getColorOT(value)"
        ) {{value.ot}}
        CircularIcon(
          :number="value.segundos_ingresos ? value.segundos_ingresos.length : 0"
          classCustom="circular-icon"
          v-b-popover.hover.html="getSecondEnterData(value.segundos_ingresos)" title="Otros ingresos")
        CircularIcon(
          v-if="value.cambio_3x3"
          number="3x3"
          classCustom="circular-icon circular-icon-3x3")

    template(#cell(serie)='{ item, value }')
      .d-flex.flex-row.align-items-start
        div(v-if="item.ot.alert_text" v-b-tooltip.hover :title="item.ot.alert_text")
          b-icon.mr-2.cursor-pointer(
            icon="exclamation-triangle" variant="warning" font-scale="1.5"
            @click="showSerialNumberAlertModal(item.ot)"
          )
        span {{ value }}

    template(#cell(despacho)='{value}')
      //- span {{ LABEL_TIPO_DESPACHO(value) }}
      span {{ value }}

    template(#cell(estado)='{value}')
      Status(:status="value")

    template(#cell(all_time)="value")
      .d-flex.flex-row
      b-icon.mr-1( :icon="value && totalTime(value).icon" :variant="value && totalTime(value).variant")
      | {{totalTime(value).time}}
      //- span {{ value.item.ot.detalle_tiempos.total }}

    template(#cell(state_time)="value")
      b-icon.mr-1( :icon="value && currentStatusTime(value).icon" :variant="value && currentStatusTime(value).variant")
      | {{currentStatusTime(value).time}}
      //- span {{ value.item.ot.detalle_tiempos.state }}

    template(#cell(excepciones)='value')
      router-link(v-if="value.item.excepciones" :to="`orden/${value.item.ot.ot}?from=excepciones`") {{value.item.excepciones.count}}

    template(#cell(acciones)="{ item, index }")
      ActionsWrapper(
        :ref="`actionsWrapper${index}`"
        :otId="item.ot.ot"
        :actions="item.ot.acciones"
        :is-not-closed="item.ot.estado && item.ot.estado.estado.nombre !== 'Cerrado'"
        @changed="() => $emit('changed')"
        :parentLoading="loading"
      )

  SerialNumberAlertModal(
    v-model="serialNumberAlertModal"
    @close="serialNumberAlertOT = null"
    :ot="serialNumberAlertOT"
  )
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import formatElapsedTime, { formatElapsedTimeInHours } from '@/utils/formatElapsedTime'
import secondsToHoursMinutes from '@/utils/secondsToHoursMinutes'
import SerialNumberAlertModal from '@/components/OT/SerialNumberAlertModal.vue'
import CircularIcon from '../../UI/CircularIcon.vue'
import Status from '../Status.vue'
import ActionsWrapper from './ActionsWrapper.vue'

/** Recibe un listado de OTs y la renderea en una tabla */
export default {
  name: 'OtTable',
  props: ['otItems', 'loading'],
  components: {
    CircularIcon,
    Status,
    ActionsWrapper,
    SerialNumberAlertModal,
  },
  data() {
    return {
      fields: [
        { key: 'ot', label: 'O.T', stickyColumn: true, sortable: true },
        { key: 'fecha_ingreso', label: 'Fecha', sortable: true },
        { key: 'tipo_servicio', label: 'Tipo', sortable: true },
        { key: 'rma', sortable: true },
        { key: 'cliente', sortable: true },
        { key: 'serie', sortable: true },
        { key: 'tecnico', sortable: true, condition: () => !this.isRetailUser },
        {
          key: 'ubicacion',
          sortable: true,
          condition: () => !this.isRetailUser,
        },
        {
          key: 'despacho',
          sortable: true,
          condition: () => !this.isRetailUser,
        },
        {
          key: 'estado',
          label: 'Estado',
          tdClass: 'text-center',
          sortable: true,
        },
        { key: 'state_time', label: 'Tiempo Estado' },
        { key: 'all_time', label: 'Tiempo Total' },
        {
          key: 'codigo_retail',
          label: 'Código Retail',
          sortable: true,
          condition: () => this.isRetailUser,
        },
        {
          key: 'guia_despacho_ingreso',
          label: 'Guía Despacho Ingreso',
          sortable: true,
          condition: () => this.isRetailUser,
        },
        {
          key: 'sucursal',
          label: 'Sucursal',
          sortable: true,
          condition: () => this.isRetailUser,
        },
        {
          key: 'acciones',
          label: 'Acciones',
          condition: () => !this.isRetailUser,
        },
      ],
      sortBy: null,
      sortDesc: null,
      serialNumberAlertOT: null,
      serialNumberAlertModal: false,
    }
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),

    isRetailUser() {
      return this.user?.user?.groups?.some((g) => g.name === 'Retail')
    },

    visibleColumns() {
      return this.fields.filter((f) => (f.condition ? f.condition() : true))
    },

    /** Transforma las ot en formato requerido para tabla bootstrap */
    currentTableItems() {
      if (!this.otItems || !this.otItems.results) return []
      return this.otItems.results.map((O) => ({
        ot: O,
        fecha_ingreso: O.fecha_ingreso
          ? moment(O.fecha_ingreso).format('DD/MM/YYYY')
          : '-',
        tipo_servicio: O.tipo_servicio ? O.tipo_servicio : '-',
        rma: O.rma ? O.rma : '-',
        serie:
          O.equipo && O.equipo.serial_number ? O.equipo.serial_number : '-',
        ubicacion: O.ubicacion ? O.ubicacion : '-',
        tecnico: O.tecnico_asignado
          ? `${O.tecnico_asignado.first_name} ${O.tecnico_asignado.last_name}`
          : '-',
        despacho: O.despacho,
        estado: O.estado ? O.estado : 'Sin estado',
        limite_total_rojo: O.limite_total_rojo,
        limite_total_amarillo: O.limite_total_amarillo,
        all_time: O.tiempo_estados?.hora_total,
        state_time: O.tiempo_estados?.hora_estado_actual,
        cliente: this.getClient(O.cliente),
        ingreso: this.getDate(O.fecha_ingreso),
        tipo: O.equipo ? O.equipo.modelo_detail.dispositive_type : '-',
        marca: O.equipo ? O.equipo.modelo_detail.marca.marca_name : '-',
        serial_number: O.equipo ? O.equipo.serial_number : '-',
        falla: O.reporte_falla_cliente,
        recepcionista: O.recepcionista_detail
          ? `${O.recepcionista_detail.first_name} ${O.recepcionista_detail.last_name}`
          : '-',
        selectTechnician: O,
        codigo_retail: O.codigo_retail,
        guia_despacho_ingreso: O.guia_despacho_ingreso,
        sucursal: O.sucursal,
        detalle_tiempo_compuesto: O.detalle_tiempo_compuesto,
        tiempo_estados: O.tiempo_estados,
      }))
    },
  },
  methods: {
    getClientRut (cliente) {
      if (cliente.persona && cliente.tipo_cliente === 'Persona') {
        return cliente.persona.rut
      }
      if (cliente.empresa && cliente.tipo_cliente === 'Empresa') {
        return cliente.empresa.rut
      }
      return ''
    },
    sortingChanged(context) {
      this.$emit('sortingChanged', context.sortBy, context.sortDesc)
    },
    getColorOT(value) {
      let style = 'color:#41509B; font-weight: bold'
      if (value.estado) {
        const state = value.estado.estado.nombre
        if (state === 'En Solicitud de Excepción') {
          style = 'color:#7F828F; font-weight: bold'
        }
      }
      return style
    },
    getClient(cliente) {
      if (!cliente) return '-'
      if (cliente.persona && cliente.tipo_cliente === 'Persona') {
        return `${cliente.persona.nombre} ${cliente.persona.apellido}`
      }
      return `${cliente.empresa ? cliente.empresa.razon_social : ''}`
    },
    getDate(date) {
      if (date) {
        const formatedDate = moment(date).format('DD/MM/YYYY')
        return formatedDate
      }
      return '-'
    },
    getSecondEnterData(ordenes) {
      const urls = []
      if (Array.isArray(ordenes)) {
        // eslint-disable-next-line
        ordenes.map((o) => {
          const rmaValue = o.rma ? ` | ${o.rma}` : ''
          urls.push(
            `<a href="/orden/${
              o.ot
            }" target="_blank" class="related-ot-link"> ${o.ot}</a> (${moment(
              o.fecha_ingreso
            ).format('DD-MM-YYYY')}${rmaValue})<br>`
          )
        })
        return urls.join('')
      }
      return `<a href="/orden/${ordenes}" target="_blank" class="related-ot-link"> ${ordenes} </a>`
    },
    getAllTime(value) {
      const OT = value.item
      return secondsToHoursMinutes(OT.all_time)
    },
    currentStatusTime(value) {
      const ot = value.item
      const time = ot?.detalle_tiempo_compuesto?.hora_compuesta || ot?.tiempo_estados?.hora_estado_actual
      if (!time) return { time: '00:00' }

      const hora_alerta_1 = ot?.detalle_tiempo_compuesto?.estado_compuesto?.hora_alerta_1 || ot.estado.estado?.hora_alerta_1
      const hora_alerta_2 = ot?.detalle_tiempo_compuesto?.estado_compuesto?.hora_alerta_2 || ot.estado.estado?.hora_alerta_2
      const alerta1 = hora_alerta_1 * 3600 || 0
      const alerta2 = hora_alerta_2 * 3600 || 100000

      let data
      if (time < alerta1) {
        data = { icon: 'check-circle', variant: 'estado-green' }
      } else if (time >= alerta1 && time < alerta2) {
        data = { icon: 'exclamation-circle-fill', variant: 'estado-yellow' }
      } else if (time >= alerta2) {
        data = { icon: 'exclamation-triangle-fill', variant: 'estado-red' }
      }

      return { ...data, time: formatElapsedTimeInHours(time, false) || '' }
    },
    totalTime(value) {
      const ot = value.item
      const time = ot.all_time
      if (!time) return { time: '---' }
      const hours = Math.round(time / 3600)
      const alerta1 = ot.limite_total_amarillo
      const alerta2 = ot.limite_total_rojo
      let data

      if (hours < alerta1) {
        data = { icon: 'check-circle', variant: 'estado-green' }
      } else if (hours >= alerta1 && hours < alerta2) {
        data = { icon: 'exclamation-circle-fill', variant: 'estado-yellow' }
      } else if (time >= alerta2) {
        data = { icon: 'exclamation-triangle-fill', variant: 'estado-red' }
      }
      return { ...data, time: formatElapsedTime(time, false) || '---' }
    },
    getActualStateIcon(value) {
      const OT = value.item
      const hora_alerta_1 = OT.estado.estado?.hora_alerta_1 * 3600
      const hora_alerta_2 = OT.estado.estado?.hora_alerta_2 * 3600
      if (hora_alerta_1 > OT.state_time) {
        return 'check-circle'
      }
      if (hora_alerta_1 <= OT.state_time && OT.state_time < hora_alerta_2) {
        return 'exclamation-circle-fill'
      }
      if (OT.state_time >= hora_alerta_2) {
        return 'exclamation-triangle-fill'
      }
      return ''
    },
    getActualStateVariant(value) {
      const OT = value.item
      const hora_alerta_1 = OT.estado.estado?.hora_alerta_1 * 3600
      const hora_alerta_2 = OT.estado.estado?.hora_alerta_2 * 3600
      if (hora_alerta_1 > OT.state_time) {
        return 'success'
      }
      if (hora_alerta_1 <= OT.state_time && OT.state_time < hora_alerta_2) {
        return 'warning'
      }
      if (OT.state_time >= hora_alerta_2) {
        return 'danger'
      }
    },
    showSerialNumberAlertModal(ot) {
      this.serialNumberAlertModal = true
      this.serialNumberAlertOT = ot
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.container-list {
  box-shadow: 0px 3px 6px #00000029;
}

.ver-pedido {
  color: #fff;
  background-color: var(--button);
  border-color: var(--button);
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-weight: 600;

  &:disabled {
    background-color: #7d93ff;
    border-color: #7d93ff;
    cursor: not-allowed;
  }
}

.ot {
  color: #41509b;
  font-weight: bold;
}

.header-tecnico {
  font-size: 1.2em;
  font-weight: 700;
}

::v-deep .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--button);
  font-weight: bold;
}

::v-deep .card-header {
  background-color: #ffffff;
  overflow-x: auto;

  ul {
    overflow-x: visible;
  }

  a {
    color: #495057;
    font-weight: bold;

    &:hover {
      color: #495057;
    }
  }
}

::v-deep table th {
  color: #495057;
}

a.router-link-active {
  color: var(--button);
}

.text-danger {
  color: var(--button) !important;
}

::v-deep .card-body {
  padding: 0;
}

.text-danger[data-v-76755bd8] {
  color: #d32b2b !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--button) !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.ot-cell {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

::v-deep .tabla-tbody td {
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

::v-deep .tabla-thead th {
  @include media-breakpoint-down(sm) {
    padding: 4px 10px 4px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
