<template lang="pug">
  div
    b-icon.mr-2(icon="file-text" font-scale="1.5" variant="primary")
    span.title Presupuestos
    hr
    b-table(:items="quotationsItems" :busy="loading" :fields="[{ key: 'Número' },{ key: 'Estado' },{ key: 'Repuestos' },{ key: 'archivo' }]")
      template(#table-busy)
        div(class="text-center text-danger my-2")
          b-spinner(class="align-middle")
      template(#cell(archivo)='value')
        a(v-if="value.item.archivo" :href="value.item.archivo" target="_blank") descargar
        p(v-else) -
    h4.text-center(v-if="quotationsItems.length === 0") No hay registros
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DetailInternalQuotations',
  data() {
    return {
      loading: false,
      foto: '',
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot']),
    quotationsItems() {
      console.log(this.ot)
      return this.ot.solicitud_cotizacion_interna?.map((q) => ({
        Número: this.ot.numero_cotizacion,
        Estado: q.estado,
        Repuestos: q.partes_solicitadas.reduce((parte, val) => {
          //eslint-disable-line
          const comma = parte.length ? ' , ' : ''
          return parte + comma + val.part_number
        }, ''),
        archivo: q.pdf,
      }))
    },
  },
  methods: {
    ...mapActions('OTStore', ['getOT']),
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #253590;
  font-weight: bold;
  font-size: 1.2em;
}
</style>
