<template lang="pug">
#HistoryDetail
  .container-list
    b-card(border-variant="light")
      strong Ingresar Búsqueda
      b-row
        b-col(cols="12")
          b-input-group.mt-2
            .mr-2
              label Fecha inicio
              b-form-datepicker.mb-2(size='sm' locale='es', v-model="dateStart", placeholder="Fecha inicio", style="width: 300px;")
            .mr-2
              label Fecha fin
              b-form-datepicker.mb-2(size='sm' locale='es', v-model="dateEnd", placeholder="Fecha fin", style="width: 300px;")
            .mr-2
              label Tipo de movimiento
              b-form-select.mb-3(size='sm', v-model='selectedType' :options='optionsType' value-field='item' text-field='name' disabled-field='notEnabled')
            .search-btn.mr-2
              b-button(@click="handleSearch") Buscar
    b-table(
      :items="parts",
      :fields="fields",
      head-row-variant="light-primary",
      thead-class="text-black tabla-thead",
      tbody-class="tabla-tbody",
      tbody-tr-class="bg-white",
      responsive,
      :busy="loading",
      show-empty,
      empty-text="Sin datos en historial"
    )
      template(#cell(created_at)='value')
        div {{value.item.created_at | moment('DD-MM-YYYY HH:mm')}} Hrs.
      template(#cell(actions)='value')
        div(style="font-weight: bold; color:#253590")
          b-button(@click="getMovementsDetails(value.item.id)") Ver Detalle
    b-modal#modal-center(centered, title='Detalle de movimiento manual', v-model="showModal", ok-only, ok-title="Cerrar", size="lg")
      b-button.d-flex.mb-4(@click="getManualMovementDetail(movementDetail.id)", v-if="movementDetail") Exportar detalle a Excel
      .d-flex.mb-4(v-if="movementDetail")
        b-col(cols="6")
          div
            strong Fecha:
            div {{ movementDetail.created_at | moment('DD-MM-YYYY HH:mm')}} Hrs.
          div
            strong Usuario:
            div {{ movementDetail.user }}
        b-col(cols="6")
          div
            strong Tipo:
            div {{ movementDetail.tipo }}
          div
            strong Cantidad total:
            div {{ movementDetail.cantidad_total }}
      b-table(
        :items="movementDetail ? movementDetail.movimientos_inventario : []",
        :fields="fieldsDetail",
        head-row-variant="light-primary",
        thead-class="text-black tabla-thead",
        tbody-class="tabla-tbody",
        tbody-tr-class="bg-white",
        responsive,
        :busy="loadingDetail",
        show-empty,
        empty-text="Sin datos de piezas"
      )
  .d-flex.flex-row-reverse
    b-pagination(
      v-model="currentPage",
      :total-rows="count",
      prev-text="Ant.",
      next-text="Sig.",
      @change="handlePageChange"
    )
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManualMovementList',
  data() {
    return {
      parts: [],
      currentPage: 1,
      dateStart: null,
      dateEnd: null,
      selectedType: null,
      movementDetail: null,
      showModal: false,
      optionsType: [
        { item: null, name: 'Todos' },
        { item: 1, name: 'Ingreso' },
        // { item: 2, name: 'Entrega' },
        // { item: 3, name: 'Drop' },
        // { item: 4, name: 'Part fail' },
        { item: 5, name: 'Rebaja Drop' },
        { item: 6, name: 'RTV' },
        { item: 7, name: 'SCRAP' },
        // { item: 8, name: 'BAD' },
        { item: 9, name: 'SLOW' },
      ],
      count: 0,
      search: '',
      loading: false,
      loadingDetail: false,
      fields: [
        { key: 'created_at', label: 'Fecha del Movimiento', sortable: true },
        { key: 'tipo', label: 'Tipo de Movimiento', sortable: true },
        { key: 'partes', label: 'Partes distintas', sortable: true },
        { key: 'cantidad_total', label: 'Cantidad', sortable: true },
        { key: 'user', label: 'Usuario' },
        { key: 'actions', label: 'Acciones' },
      ],
      fieldsDetail: [
        { key: 'parte.part_number', label: 'Parte', sortable: true },
        { key: 'cantidad', label: 'Cantidad', sortable: true },
        { key: 'parte.real_location', label: 'Ubicación real', sortable: true },
        { key: 'parte.description', label: 'Descripción', sortable: true },
      ],
    }
  },
  methods: {
    ...mapActions('Warehouse', [
      'getMovementsGroups',
      'getMovementGroupDetail',
      'manualMovementDetail',
    ]),
    getManualMovementDetail(pk) {
      this.manualMovementDetail(pk)
    },
    async fetchData(page = 1) {
      this.loading = true
      let filters = `?page=${page}`

      if (this.dateStart && this.dateEnd)
        filters += `&date_start=${this.dateStart}&date_end=${this.dateEnd}`

      if (this.selectedType) filters += `&type=${this.selectedType}`

      const resp = await this.getMovementsGroups({ filters })
      if (resp.status === 200) {
        this.parts = resp.data.results
        this.count = resp.data.count
      }
      this.loading = false
    },
    async getMovementsDetails(pk) {
      this.loadingDetail = true
      const response = await this.getMovementGroupDetail({ pk })
      this.movementDetail = response.data
      this.showModal = true
      this.loadingDetail = false
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
    handleSearch() {
      this.fetchData(this.currentPage)
    },
    handleClear() {
      this.search = ''
      this.fetchData(this.currentPage)
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
<style lang="scss">
#HistoryDetail {
  .search-btn {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
}
</style>
