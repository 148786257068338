<template lang="pug">
b-container
  .d-flex.justify-content-between.mb-3
    h4.title Resultados dashboard
    b-breadcrumb.breadcrumb.text-right(:items="breadcrumbs")
  GenericOTList(:tabs="tabs" :otList="otList")
</template>

<script>
import GenericOTList from '../components/OT/ListOT/GenericOTList.vue'

export default {
  name: 'FilteredResultsDashboard',
  components: { GenericOTList },

  data() {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { path: '/' },
        },
        {
          text: 'Resultados dashboard',
          active: true,
        },
      ],
      tabs: [
        {
          title: `${this.$route.query.title}`,
          states: Array.isArray(this.$route.query.uniqueStates)
            ? this.$route.query.uniqueStates
            : [this.$route.query.uniqueStates],
        },
      ],
    }
  },

  computed: {
    otList() {
      return this.$route.query.ots.map(ot => Number(ot)).join(',')
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
  opacity: 1;
}
</style>
