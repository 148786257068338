<template lang="pug">
#ConfirmChange
  Navbar
  h2 {{ message }}
  b-button.py-2.mt-2(v-if="response", :to="'/cliente/' + ot + '/rut/' + rut") Continuar a mi Orden de Trabajo
</template>
<script>
import { mapActions } from 'vuex';
import Navbar from './Navbar/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      token: this.$route.params.token,
      response: null,
      message: '',
      ot: null,
      rut: null,
    };
  },
  methods: {
    ...mapActions('ClientStore', ['confirmChangeDelivery']),
  },
  async created() {
    const resp = await this.confirmChangeDelivery(this.token);
    console.log(resp);
    this.message = resp.data.message;
    this.ot = resp.data.ot;
    this.rut = resp.data.rut;
    this.response = resp.status === 200;
  },
};
</script>
<style lang="scss" scoped>
#ConfirmChange {
  text-align: center;
}
h2 {
  font-style: italic;
}
</style>
