<template lang="pug">
  div.rounded.d-flex.justify-content-center.status(:style='getColor(status)') {{status.estado ? status.estado.nombre: 'Sin Estado'}}
</template>

<script>
export default {
  name: 'Status',
  props: ['status'],
  methods: {
    getColor(status) {
      if (status !== 'Sin estado') {
        return `font-weight: bold; background-color: ${status.estado.color_background}; color: ${status.estado.color_foreground}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  font-weight: bold;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
  margin: auto;
}
</style>
