<template lang="pug">
  b-form-select(v-model="stateSelected" :options="options" :disabled="disableSelect()")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StatesSelect',
  props: ['solicitud'],
  data() {
    return {
      stateSelected: null,
      options: [
        { value: 1, text: 'Solicitado', disabled: this.disableOption(1) },
        { value: 2, text: 'En espera', disabled: this.disableOption(2) },
        { value: 3, text: 'Localizado', disabled: this.disableOption(3) },
        { value: 4, text: 'Entregado', disabled: this.disableOption(4) },
      ],
    }
  },
  created() {
    const option = this.options.find(
      (o) => o.text === this.solicitud.estado_pieza
    )
    this.stateSelected = option.value
  },
  methods: {
    disableOption(value) {
      const { user } = JSON.parse(localStorage.getItem('user'))
      const administrativo = user.groups.find(
        (u) => u.name === 'Administrativo'
      )
      const bodega = user.groups.find((u) => u.name === 'Bodega')
      if (administrativo && bodega) {
        if (value === 1) return true
        if (value === 2) return false
        if (value === 3) return false
        if (value === 4) return false
      }
      if (administrativo) {
        if (value === 1) return true
        if (value === 2) return false
        if (value === 3) return false
        if (value === 4) return true
      }
      if (bodega) {
        if (value === 1) return true
        if (value === 2) return true
        if (value === 3) return true
        if (value === 4) return false
      }
    },
    disableSelect() {
      if (this.$route.name === 'AgregarDiagnostico') return true
      if (this.solicitud.dropped) return true
      if (this.solicitud) {
        const roles = this.getRole()
        if (
          roles.includes('Administrativo') &&
          this.solicitud.allocated === 'No'
        )
          return false
        if (roles.includes('Bodega') && this.solicitud.allocated === 'Si')
          return false
        if (
          roles.includes('Bodega') &&
          this.solicitud.allocated === 'No' &&
          this.solicitud.estado_pieza === 'Localizado'
        )
          return false
        return true
      }
    },
    getRole() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      const roles = user.groups.map((r) => r.name)
      return roles
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
  watch: {
    stateSelected(newVal) {
      //eslint-disable-line
      const option = this.options.find((o) => o.value === newVal)
      this.solicitud.estado_pieza = option.text
    },
  },
}
</script>

<style lang="scss"></style>
