<template lang="pug">
b-icon.back-arrow.h3.mb-4(
  icon="arrow-left-square-fill"
  variant="button"
  @click="$router.go(-1)"
)
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.back-arrow {
  margin: 2px 25px 7px 0px;
  cursor: pointer;
}
</style>
