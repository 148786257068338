import axios from '../../plugins/axios'

export const sendPreIngreso = ({}, data) => {
  const apiAdress = 'api/public/orden/'
  return axios.post(apiAdress, data)
}

export const sendPreIngressRegions = ({}, data) => {
  const apiAdress = 'api/public/orden/regions'
  return axios.post(apiAdress, data)
}
