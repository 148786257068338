<template lang="pug">
.wrapper
  b-button.font-weight-bold(
    variant="outline-button"
    @click="showModal = true"
  ) Cambiar Tipo de Servicio
  b-modal(
    v-model="showModal"
    title="Cambiar Tipo de Servicio"
    @hide="reset"
  )
    div(v-if="ot.tipo_servicio.id === PRESUPUESTO")
      b-form-group
        label.client-form-inputs(for="input-service-type") Nuevo tipo de Servicio
        b-input#input-service-type(value="Garantía" readonly)

      //- Fecha de compra
      b-form-group
        label(class="client-form-inputs" for="input-fecha_compra") Fecha de compra
        b-form-datepicker(
          id="input-purchaseDate"
          v-model="form.fecha_de_compra"
          locale="es-CL"
          :state="$v.form.fecha_de_compra.$dirty ? !$v.form.fecha_de_compra.$invalid : null"
          :max="new Date()"
          select-variant="primary"
          placeholder="Ingrese una fecha"
        )
        .errors(v-if="$v.form.fecha_de_compra.$dirty")
          b-form-invalid-feedback(:state="$v.form.fecha_de_compra.required")
            | Campo requerido

      //- Tienda
      b-form-group
        label(class="client-form-inputs" for="input-sucursal") Tienda
        b-input-group
          b-select(
            v-model="form.sucursal",
            id="input-sucursal",
            :state="$v.form.sucursal.$dirty ? !$v.form.sucursal.$invalid : null"
          )
            b-form-select-option(v-for="empresa in empresas", :value="empresa.id") {{ empresa.razon_social }}
          b-input-group-append
            b-button(
              variant="primary"
              @click="showClienteEmpresaModal = true"
            ) Nueva
            ClienteEmpresaModal(v-model="showClienteEmpresaModal" @created="handleNewTienda")
        .errors(v-if="$v.form.sucursal.$dirty")
          b-form-invalid-feedback(:state="$v.form.sucursal.required")
            | Campo requerido

      //- Comentario
      b-form-group
        label.client-form-inputs(for="input-comentario") Comentario
        b-form-textarea#input-comentario(v-model="form.comentario" rows="3")

    div(v-else-if="ot.tipo_servicio.id === GARANTIA")
      b-form-group
        label.client-form-inputs(for="input-service-type") Nuevo tipo de Servicio
        b-input#input-service-type(value="Presupuesto" readonly)

    template(#modal-footer='{ ok, cancel, hide }')
      b-button(@click="submit" :disabled="loading")
        span(v-if="ot.tipo_servicio.id === PRESUPUESTO") Generar excepción
        span(v-else-if="ot.tipo_servicio.id === GARANTIA") Cambiar a Presupuesto
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import ClienteEmpresaModal from '@/components/OT/NewOT/ClienteEmpresaModal.vue'

const GARANTIA = 1
const PRESUPUESTO = 4
const EXCEPCION_AMPLIACION_GARANTIA = 'Ampliación de garantía'
export default {
  props: {
    ot: Object,
  },
  components: {
    ClienteEmpresaModal,
  },
  validations: {
    form: {
      fecha_de_compra: {
        required: requiredIf(function () {
          return this.ot.tipo_servicio.id === PRESUPUESTO
        }),
      },
      sucursal: {
        required: requiredIf(function () {
          return this.ot.tipo_servicio.id === PRESUPUESTO
        }),
      },
    },
  },
  data() {
    return {
      showModal: false,
      showClienteEmpresaModal: false,
      loading: false,
      form: {
        fecha_de_compra: null,
        sucursal: null,
        comentario: null,
      },
    }
  },
  computed: {
    GARANTIA: () => GARANTIA,
    PRESUPUESTO: () => PRESUPUESTO,

    ...mapGetters('ClientStore', ['empresas']),
    ...mapGetters('AuthenticationStore', ['user']),
  },
  methods: {
    ...mapActions('ClientStore', ['listEmpresas']),
    ...mapActions('Exceptions', ['createExcepcion']),

    handleNewTienda(empresaId) {
      this.listEmpresas()
      this.$nextTick(() => {
        this.form.sucursal = empresaId
      })
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true
      try {
        // await this.$store.dispatch('OTStore/changeServiceType', {
        //   otId: this.ot.id,
        //   form: this.form,
        // })
        if (this.ot.tipo_servicio.id === PRESUPUESTO) {
          const data = {
            orden_trabajo: this.ot.ot,
            tipo: EXCEPCION_AMPLIACION_GARANTIA,
            comentario_solicitud: this.form.comentario,
            solicitado_por: this.user.user.id,
            additional_data: {
              fecha_compra: this.form.fecha_de_compra,
              tienda: this.form.sucursal,
            },
          }
          await this.createExcepcion(data)
          this.$bvToast.toast('Excepción creada', {
            variant: 'success',
          })
          //
        } else if (this.ot.tipo_servicio.id === GARANTIA) {
          await this.$store.dispatch('OTStore/changeServiceType', {
            otId: this.ot.ot,
            data: {},
          })
          this.$bvToast.toast('OT cambiada a Presupuesto', {
            variant: 'success',
          })
        }

        this.$emit('change')
        this.showModal = false
        this.reset()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    reset() {
      this.$v.$reset()
      this.form = {
        fecha_de_compra: null,
        sucursal: null,
      }
    },
  },
  created() {
    this.listEmpresas()
  },
}
</script>

<style lang="scss" scoped></style>
