<template lang="pug">
b-card.sill-card
  .flex-row.justify-content-between.mb-3
    h5.table-header Detalle de Registro
    b-button(
      variant="button"
      @click="exportToExcel"
    ).d-flex.align-items-center.py-2.px-4
      b-icon(icon="file-earmark-arrow-down-fill", font-scale="0.9")
      span.ml-3 Exportar

  b-table(
    :items="results"
    :fields="fields"
    head-row-variant="light-primary"
    tbody-class="tabla-tbody"
    thead-class="text-black tabla-thead"
    tbody-tr-class="bg-white"
    responsive
    show-empty
    no-local-sorting
    empty-text="No hay Ordenes de Trabajo en este estado"
    :busy="loading"
  )
    template(#table-busy)
      div(class="text-center text-danger my-2")
        b-spinner(class="align-middle")

    template(#table-colgroup="{ fields }")
      col
      col
      col
      col
      col(style="width: 1%; min-width: 160px;" v-if="!hideOTColumn")

    template(#cell(created_at)='{ value }')
      span {{ value | formatDateTime }}

    template(#cell(usuario)='{ value }')
      span {{ value.first_name }} {{ value.last_name }}

    //- template(#cell(accion_label)='{ value }')
    //- template(#cell(last_action_label)='{ value }')

    template(#cell(tiempo_desde_ultima)='{ item, value }')
      b-icon(
        :icon="timeIcons[item.color].icon"
        :variant="timeIcons[item.color].variant"
        font-scale="1.2"
      )
      span.ml-2 {{ value | formatElapsedTimeInHours }}

    template(#cell(orden_trabajo)='{ value }')
      b-link.order-link(:to="`/orden/${value}`") {{ value }}
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'pinia'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'
import { formatElapsedTimeInHours } from '@/utils/formatElapsedTime'

export default {
  props: {
    hideOTColumn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useUserReportStore, [
      'results',
      'currentTab',
      'filters',
      'loading',
    ]),

    fields() {
      return [
        { key: 'created_at', label: 'Fecha' },
        { key: 'usuario', label: 'Usuario' },
        { key: 'accion_label', label: 'Acción' },
        { key: 'last_action_label', label: 'Acción anterior' },
        { key: 'tiempo_desde_ultima', label: 'Tiempo Empleado' },
        ...(this.hideOTColumn ? [] : [{ key: 'orden_trabajo', label: 'Orden de Trabajo' }]),
      ]
    },

    timeIcons() {
      return {
        success: { icon: 'check-circle-fill', variant: 'estado-green' },
        warning: {
          icon: 'exclamation-triangle-fill',
          variant: 'estado-yellow',
        },
        danger: { icon: 'exclamation-triangle-fill', variant: 'estado-red' },
      }
    },
  },

  filters: {
    formatDateTime(value) {
      return moment(value).format('DD-MM-YYYY [a las] HH:mm')
    },
    formatElapsedTimeInHours,
  },

  methods: {
    ...mapActions(useUserReportStore, ['getActionLogsExcel']),

    async exportToExcel() {
      const success = await this.getActionLogsExcel()
      if (!success)
        this.$bvToast.toast('Error al exportar.', { variant: 'danger' })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {
  color: #707070;
  font-size: 16px;
  font-weight: bold;
}

.order-link {
  font-weight: bold;
}
</style>
