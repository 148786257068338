<template lang="pug">
#DataClientRegions
  b-card.form-card
    b-row
      span#icon 01
      h5.title.mt-2 Datos Cliente
    hr
    b-row
      b-col(cols='12' md='3')
        b-form-group(class="rut" label-for="input-rut" :label-class="activeField === 'rut' ? 'active-label' : ''")
          template( #label ) RUT:
          b-form-input(
            placeholder="Ej: 76872144-0"
            id="input-rut"
            v-model="rutFormatted"
            :state="$v.datosProvisorios.rut.$dirty? !$v.datosProvisorios.rut.$invalid : null"
            @input="$v.datosProvisorios.rut.$touch()"
            @focus="activeField = 'rut'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.datosProvisorios.rut.$dirty")
            b-form-invalid-feedback(:state="$v.datosProvisorios.rut.required")
              | Campo requerido
      b-col(cols='12' md='3')
        b-form-group(class="tipo" label-for="input-tipo")
          template( #label ) Tipo de Cliente:
          b-form-radio-group(
            button
            button-variant="info"
            id="input-tipo"
            v-model="datosProvisorios.tipo_cliente"
            :options="[{ text: 'Persona', value: cts.CLIENTE_PERSONA }, { text: 'Retail', value: cts.CLIENTE_EMPRESA }]"
          )
    //- Cliente persona
    b-row
      b-col(cols='12' md='6')
        b-form-group(v-if="datosProvisorios.tipo_cliente == 1" label-for="input-nombre" :label-class="activeField === 'nombre' ? 'active-label' : ''")
          template( #label ) Nombre:
          b-form-input(
            id="input-nombre"
            v-model="datosProvisorios.nombre"
            :state="$v.datosProvisorios.nombre.$dirty? !$v.datosProvisorios.nombre.$invalid : null"
            @input="$v.datosProvisorios.nombre.$touch()"
            @focus="activeField = 'nombre'"
            @blur="activeField = ''"
            )
          .errors(v-if="$v.datosProvisorios.apellido.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.nombre.required")
                | Campo requerido
      b-col(cols='12' md='6')
        b-form-group(v-if="datosProvisorios.tipo_cliente == 1" label-for="input-apellido" :label-class="activeField === 'apellido' ? 'active-label' : ''")
          template( #label ) Apellido:
          b-form-input(
            id="input-apellido"
            v-model="datosProvisorios.apellido"
            :state="$v.datosProvisorios.apellido.$dirty? !$v.datosProvisorios.apellido.$invalid : null"
            @input="$v.datosProvisorios.apellido.$touch()"
            @focus="activeField = 'apellido'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.datosProvisorios.apellido.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.apellido.required")
                  | Campo requerido
        //-Cliente Empresa
    b-row
      b-col(cols='12' md='6')
        b-form-group(v-if="datosProvisorios.tipo_cliente == 2" label-for="input-razon" :label-class="activeField === 'razon' ? 'active-label' : ''")
          template( #label )  Razón Social:
          b-form-input(
            id="input-razon"
            v-model="datosProvisorios.razon_social"
            :state="$v.datosProvisorios.razon_social.$dirty? !$v.datosProvisorios.razon_social.$invalid : null"
            @input="$v.datosProvisorios.razon_social.$touch()"
            @focus="activeField = 'razon'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.datosProvisorios.razon_social.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.razon_social.required")
                | Campo requerido
      b-col(cols='12' md='6')
        b-form-group(v-if="datosProvisorios.tipo_cliente == 2"  label-for="input-sucursal" :label-class="activeField === 'sucursal' ? 'active-label' : ''")
          template( #label ) Nombre de Sucursal:
          b-form-input(
            id="input-sucursal"
            v-model="datosProvisorios.nombre_sucursal"
            :state="$v.datosProvisorios.nombre_sucursal.$dirty? !$v.datosProvisorios.nombre_sucursal.$invalid : null"
            @input="$v.datosProvisorios.nombre_sucursal.$touch()"
            @focus="activeField = 'sucursal'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.datosProvisorios.razon_social.$dirty")
            b-form-invalid-feedback(:state="$v.datosProvisorios.razon_social.required")
              | Campo requerido
    b-row
      b-col(cols='12' md='4')
        b-form-group(class="region" label-for="input-region")
          template( #label ) Región:
          b-form-select(
            id="input-city"
            v-model="datosProvisorios.region"
            :options="regionOptions"
            :state="$v.datosProvisorios.region.$dirty? !$v.datosProvisorios.region.$invalid : null"
          )
          .errors(v-if="$v.datosProvisorios.region.$dirty")
            b-form-invalid-feedback(:state="$v.datosProvisorios.region.required")
              | Campo requerido
      b-col(cols='12' md='4')
        b-form-group(class="comuna" label-for="input-comuna")
          template( #label ) Comuna:
          b-form-select(
              id="input-comuna"
              v-model="datosProvisorios.comuna"
              :options="comunaOptions"
              :state="$v.datosProvisorios.region.$dirty? !$v.datosProvisorios.comuna.$invalid : null"
          )
          .errors(v-if="$v.datosProvisorios.comuna.$dirty")
            b-form-invalid-feedback(:state="$v.datosProvisorios.comuna.required")
              | Campo requerido
      b-col(cols='12' md='4')
        b-form-group(class="direccion" label-for="input-direccion" :label-class="activeField === 'direccion' ? 'active-label' : ''")
          template( #label ) Dirección:
          b-form-input(
              id="input-direccion"
              v-model="datosProvisorios.direccion"
              :state="$v.datosProvisorios.direccion.$dirty? !$v.datosProvisorios.direccion.$invalid : null"
              @input="$v.datosProvisorios.direccion.$touch()"
              @focus="activeField = 'direccion'"
              @blur="activeField = ''"
          )
          .errors(v-if="$v.datosProvisorios.direccion.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.direccion.required")
                | Campo requerido
    b-row
        b-col(cols='12' md='6')
          b-form-group(class="telefono" label-for="input-telefono" :label-class="activeField === 'telefono' ? 'active-label' : ''")
            template( #label ) Teléfono:
            b-form-input(
              id="input-telefono"
              v-model="datosProvisorios.telefono"
              :state="$v.datosProvisorios.telefono.$dirty? !$v.datosProvisorios.telefono.$invalid : null"
              @input="$v.datosProvisorios.telefono.$touch()"
              maxLength="9"
              @focus="activeField = 'telefono'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.telefono.$dirty")
                b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.required")
                    | Campo requerido
                b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.numeric")
                    | Solo puedes ingresar numeros
                b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.minLength")
                    | Tienes que ingresar 8 digitos
        b-col(cols='12' md='6')
          b-form-group(label-for="input-email" :label-class="activeField === 'email' ? 'active-label' : ''")
            template( #label ) Email:
            b-form-input(
              id="input-email"
              v-model="datosProvisorios.email"
              :state="$v.datosProvisorios.email.$dirty? !$v.datosProvisorios.email.$invalid : null"
              @input="$v.datosProvisorios.email.$touch()"
              @focus="activeField = 'email'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.email.$dirty")
                b-form-invalid-feedback(:state="$v.datosProvisorios.email.required")
                  | Campo requerido
                b-form-invalid-feedback(:state="$v.datosProvisorios.email.email")
                  | No es una direccion de correo valida
    .d-flex.justify-content-end
      .botones
        b-button(@click="onSubmit()" small)
          | Siguente
</template>

<script>
import { required, email, numeric, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';
import validateRut from '../../../../utils/validateRut'
import validateEmail from '../../../../utils/validateEmail'
import * as cts from '../../constants'
import sucursales from '../../NewOT/ClientForm/Sucursales.vue'

export default {
  props: ['setDatosProvisorios', 'returnClient'],
  components: {
    sucursales,
  },
  data() {
    return {
      activeField: false,
      query: '',
      required: false,
      collapse: true,
      emailInvalid: true,
      dirty: false,
      cts,
      datosProvisorios: {
        tipo_cliente: this.returnClient.tipo_cliente
          ? this.returnClient.tipo_cliente
          : cts.CLIENTE_PERSONA,
        nombre: this.returnClient.nombre,
        apellido: this.returnClient.apellido,
        rut: this.returnClient.rut,
        email: this.returnClient.email,
        comuna: this.returnClient.comuna,
        telefono: this.returnClient.telefono,
        direccion: this.returnClient.direccion,
        region: this.returnClient.region,
        razon_social: this.returnClient.razon_social,
        nombre_sucursal: this.returnClient.sucursal,
      },
    }
  },
  validations: {
    datosProvisorios: {
      rut: { required },
      nombre: { required },
      apellido: { required },
      email: { required, email },
      telefono: {
        required,
        numeric,
        minLength: minLength(8),
      },
      direccion: { required },
      comuna: { required },
      region: { required },
      razon_social: { required },
      nombre_sucursal: { required },
    },
  },
  methods: {
    setNewClient() {
      try {
        const splitted = this.query.split('-')
        const loweredDigit = splitted[1].toString().toLowerCase()
        const rut = `${splitted[0]}-${loweredDigit}`
        this.datosProvisorios.rut = rut
      } catch {
        this.$bvToast.toast('Ingrese un rut válido. Ej: 12345678-9', {
          variant: 'danger',
        })
      }
    },
    async onSubmit() {
      if (this.datosProvisorios.tipo_cliente === cts.CLIENTE_PERSONA) {
        if (
          this.$v.datosProvisorios.nombre.$invalid ||
          this.$v.datosProvisorios.apellido.$invalid ||
          this.$v.datosProvisorios.region.$invalid ||
          this.$v.datosProvisorios.comuna.$invalid ||
          this.$v.datosProvisorios.direccion.$invalid ||
          this.$v.datosProvisorios.telefono.$invalid ||
          this.$v.datosProvisorios.email.$invalid ||
          this.$v.datosProvisorios.rut.$invalid
        ) {
          this.$bvToast.toast('Rellene todos los campos requeridos', {
            variant: 'danger',
          })
          this.$v.$touch()
        } else {
          this.setDatosProvisorios(this.datosProvisorios)
        }
      } else if (this.datosProvisorios.tipo_cliente === cts.CLIENTE_EMPRESA) {
        if (
          this.$v.datosProvisorios.razon_social.$invalid ||
          this.$v.datosProvisorios.rut.$invalid ||
          this.$v.datosProvisorios.nombre_sucursal.$invalid
        ) {
          this.$bvToast.toast('Rellene todos los campos requeridos', {
            variant: 'danger',
          })
          this.$v.$touch()
        } else {
          this.setDatosProvisorios(this.datosProvisorios)
        }
      }
    },
    tagValidator(tag) {
      // Individual tag validator function
      const validate = validateEmail(tag)
      if (validate) {
        this.emailInvalid = true
        return validate
      }
      this.emailInvalid = false
      return validate
    },
  },
  computed: {
    rutIsValid() {
      return validateRut(this.datosProvisorios.rut || '')
    },
    rutFormatted: {
      get() {
        return this.datosProvisorios.rut?.replace(/\./g, '')
      },
      set(v) {
        // this.rut = v?.replace(/\./g, '')
        const rutLimpio = v.replace(/[^0-9kK]/g, '');

        // asilar el cuerpo del dígito verificador
        const cuerpo = rutLimpio.slice(0, -1);
        const dv = rutLimpio.slice(-1).toUpperCase();

        if (rutLimpio.length < 2) return rutLimpio;

        // colocar los separadores de miles al cuerpo
        let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');

        cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, ''); // eslint-disable-line

        this.datosProvisorios.rut = `${cuerpoFormatoMiles.replace(/[^0-9kK]/g, '')}-${dv}`
      },
    },
    regionOptions() {
      return this.regiones.map((region) => ({
        value: region.region,
        text: region.region,
      }))
    },
    comunaOptions() {
      if (this.datosProvisorios.region) {
        const region = this.regiones.find(
          (c) => c.region === this.datosProvisorios.region
        )
        if (region && region.comunas) {
          return region.comunas.map((c) => ({
            value: c,
            text: c,
          }))
        }
      }
      return [
        {
          value: null,
          text: 'Seleciona una region',
        },
      ]
    },
    state() {
      return this.dirty ? this.correos_empresas.length > 0 : null
    },
    ...mapGetters('ClientStore', ['regiones']),
  },
  watch: {
    returnClient: {
      handler() {
        this.datosProvisorios = {
          tipo_cliente: this.returnClient.tipo_cliente
            ? this.returnClient.tipo_cliente
            : cts.CLIENTE_PERSONA,
          nombre: this.returnClient.nombre,
          apellido: this.returnClient.apellido,
          rut: this.returnClient.rut,
          email: this.returnClient.email,
          comuna: this.returnClient.comuna,
          telefono: this.returnClient.telefono,
          direccion: this.returnClient.direccion,
          region: this.returnClient.region,
          razon_social: this.returnClient.razon_social,
          nombre_sucursal: this.returnClient.sucursal,
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;

  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
</style>
