<template lang="pug">
  div
    button.change-button(
      :disabled="disabled"
      @click="show = true"
      @hide="hideModal()"
    ) Avisar llegada de partes
    b-modal.hide-foooter(v-model="show" title="Avisar llegada de partes" size="xl" scrollable, ok-title="Avisar llegada de todas", @ok="handleArrivalAll")
      div(v-if="ot.solicitud_partes.length < 1") No existe solicitud de partes
      div(v-else)
        PartsTable(
          :ot="ot"
          :showStateActionColumn="false"
          :showArrivalColumn="true"
          :filterAllocated="false"
          :filterStates="filterStates"
          @arrived="handleArrival",
          :items="partesSolicitadas"
          )
</template>

<script>
/** Formulario para que administrativos puedan dar aviso de la llegada de partes no localizadas */
import { mapActions } from 'vuex'
import moment from 'moment'
import PartsTable from '../../AskParts/PartsTable.vue'
import print from '../../../utils/print'

export default {
  name: 'ActionLlegadaDePartes',
  props: ['ot', 'disabled'],
  components: {
    PartsTable,
  },
  data() {
    return {
      show: false,
      filterStates: ['Solicitado', 'En espera'],
    }
  },
  computed: {
    partesSolicitadas() {
      return this.ot.solicitud_partes
        .filter((parte) => {
          if (this.filterStates && this.filterStates.length > 0) {
            return this.filterStates.includes(parte.estado)
          }
          return true
        })
        .map((parte) => { //eslint-disable-line
          const estadosNuevosNombres = {
            Usado: 'Bad',
          }
          return {
            allocated: parte.allocated ? 'Sí' : 'No',
            part_number: parte.parte ? parte.parte.part_number : '-',
            fecha_hora_solicitud: parte.fecha_hora_solicitud ? moment(parte.fecha_hora_solicitud).format('DD-MM-YYYY') : '-',
            fecha_hora_entrega: parte.fecha_hora_entrega ? moment(parte.fecha_hora_entrega).format('DD-MM-YYYY') : '-',
            code: parte.parte ? parte.parte.part_number : '',
            descripcion: parte.parte ? parte.parte.description : '-',
            real_location: parte.parte ? parte.parte.real_location : '-',
            estado: estadosNuevosNombres[parte.estado]
              ? estadosNuevosNombres[parte.estado]
              : parte.estado,
            // _rowVariant: parte.dropped && this.$route.name !== 'AgregarDiagnostico' ? 'info' : '',
            id: parte.id,
          }
        })
    },
  },
  methods: {
    ...mapActions('OTStore', ['partArrival', 'partArrivalAll']),
    impresionPartes(part) {
      const parte = part
      parte.parte = part
      const now = moment().format('DD/MM/YYYY H:mm:ss')
      print(this.ot.ot, [parte], this.ot, now)
    },
    async handleArrival(part) {
      try {
        await this.partArrival({ otId: this.ot.ot, partId: part.id })
        this.$bvToast.toast('Llegada de parte notificada', {
          variant: 'success',
        })
        setTimeout(() => this.$emit('changed'), 500)
        this.modal = false
        this.$emit('changed')
        this.impresionPartes(part)
      } catch (err) {
        this.$bvToast.toast(err.response.data || 'Error al registrar llegada', {
          variant: 'danger',
        })
      }
    },
    async handleArrivalAll() {
      try {
        const partsIds = this.partesSolicitadas.map((part) => part.id)
        await this.partArrivalAll({ otId: this.ot.ot, partsId: partsIds })
        this.$bvToast.toast('Llegada de partes notificada', {
          variant: 'success',
        })
        setTimeout(() => this.$emit('changed'), 500)
        this.modal = false
        this.$emit('changed')
        this.partesSolicitadas.forEach((part) => this.impresionPartes(part))
        // this.impresionPartes(part)
      } catch (err) {
        this.$bvToast.toast(err.response.data || 'Error al registrar llegada', {
          variant: 'danger',
        })
      }
    },
  },
}
</script>
