import hasPermission from '@/utils/permissions'

const actions = [
  {
    name: 'Solicitar',
    roles: ['Administrativo', 'Ventas'],
    show: (quotation) =>
      quotation.estado_code === 'ESPECIFICA_PIEZAS' ||
      quotation.estado_code === 'RECHAZADO_INTERNO',
  },
  {
    name: 'AdjuntarLinkPago',
    roles: ['Administrativo', 'Ventas'],
    show: (quotation) => // eslint-disable-line
      // quotation.estado_code === 'APROBADO_INTERNO' &&
      // quotation.link_pago === null,
      false
  },
  {
    name: 'MarcarPago',
    roles: ['Administrativo', 'Ventas'],
    show: (quotation) =>
      (quotation.estado_code === 'APROBADO_INTERNO' ||
      quotation.estado_code === 'PRESUPUESTADO') &&
      quotation.estado_pago === null,
  },
  {
    name: 'Revisar',
    roles: ['Administrativo', 'Ventas'],
    show: (quotation) => quotation.link_pago && quotation.estado_pago,
  },
]

const getAction = (actionName) => actions.find((a) => a.name === actionName)
const hasRole = (user, roles) => hasPermission({ roles, user })

/* -------------------------------------- Métodos públicos -------------------------------------- */

const showButton = (actionName, quotation, user) => {
  const action = getAction(actionName)
  if (!hasRole(user, action.roles)) return false

  return action.show(quotation)
}

const hasActions = (quotation, user) => {
  const actiones = actions.some((action) =>
    showButton(action.name, quotation, user)
  )
  return actiones
}

export { showButton, hasActions }
