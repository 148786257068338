<template lang="pug">
#DataEquipmentRegions
  b-card.form-card
    b-row
      span#icon 03
      h5.title.mt-2 Datos del Equipo
    hr
    b-row
      b-col(cols='12' md='4')
        ModelForm(
          @dataModel="setDataModel"
          :modelo_id="formEquipment.model_name"
          @onModelSelect="setLocalModel"
          :state="$v.formEquipment.model_name.$dirty? !$v.formEquipment.model_name.$invalid : null"
        )
          template( #invalid-feedback )
            .errors(v-if="$v.formEquipment.model_name.$dirty")
              b-form-invalid-feedback(:state="$v.formEquipment.model_name.required")
                | Campo requerido
      b-col(cols='12' md='4')
        b-form-group(class="falla" label-for="input-serial" :label-class="activeField === 'serial' ? 'active-label' : ''")
          template( #label ) Número de serie:
          b-form-input(
            id="input-serial"
            placeholder="Ingrese número de serie..."
            v-model="formEquipment.serial_number"
            @focus="activeField = 'serial'"
            @blur="activeField = ''"
          )
      b-col(cols='12' md='4')
        b-form-group(class="falla" label-for="input-password" :label-class="activeField === 'password' ? 'active-label' : ''")
          template( #label ) Password:
          b-form-input(
            id="input-password"
            placeholder="Opcional..."
            v-model="formEquipment.password"
            @focus="activeField = 'password'"
            @blur="activeField = ''"
          )
    b-row
      b-col(cols='12' md='4')
        b-form-group(label-for="input-power")
          template( #label ) Incluye Cargador:
          b-form-select(
            id="input-power"
            v-model="formEquipment.power_source"
            :options="chargerOptions"
          )
      b-col(cols='12' md='4')
          b-form-group(label-for="input-hardDriveSize")
              template( #label ) Disco Duro:
              b-form-select(
                id="input-hardDriveSize"
                v-model="formEquipment.size_hard_drive"
                :options="hardDriveOptions"
              )
      b-col(cols='12' md='4')
          b-form-group( label-for="input-ram" )
              template( #label ) Ram:
              b-form-select(
                id="input-ram"
                v-model="formEquipment.total_ram_memorie"
                :options="ramOptions"
              )
    .d-flex.justify-content-between
        b-button.btn-create(@click="goBack()" small)
          | Anterior
        b-button.btn-create(@click="onSubmit()" small)
          | Siguiente
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  chargerOptions,
  hardDriveOptions,
  ramOptions,
  equipTypeOptions,
  hardDriveTypeOptions,
} from '../../NewOT/options'
import ModelForm from '../../NewOT/EquipmentForm/ModelForm.vue'

export default {
  props: ['setFormEquipment', 'returnEquipment'],
  components: {
    ModelForm,
  },
  validations: {
    formEquipment: {
      model_name: { required },
    },
  },
  data() {
    return {
      activeField: false,
      required: false,
      dirty: false,
      formEquipment: {
        serial_number: this.returnEquipment.serial_number,
        password: this.returnEquipment.password,
        size_hard_drive: this.returnEquipment.size_hard_drive,
        power_source: this.returnEquipment.power_source,
        total_ram_memorie: this.returnEquipment.total_ram_memorie,
        model_name: this.returnEquipment.model_name,
        modelo: null,
      },
      chargerOptions,
      hardDriveOptions,
      ramOptions,
      equipTypeOptions,
      hardDriveTypeOptions,
    }
  },
  methods: {
    ...mapActions('EquipmentStore', ['getModels']),
    async onSubmit() {
      if (this.$v.formEquipment.model_name.$invalid) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.setFormEquipment(this.formEquipment)
      }
    },
    goBack() {
      this.$emit('goBack')
    },
    setLocalModel(model_id) {
      this.formEquipment.modelo = model_id
    },
    setDataModel(model_data) {
      this.formEquipment.model_name = model_data.text
    },
  },
  computed: {
    ...mapGetters('EquipmentStore', ['models', 'equipment']),
  },
  watch: {
    returnEquipment: {
      handler () {
        this.formEquipment = {
          serial_number: this.returnEquipment.serial_number,
          password: this.returnEquipment.password,
          size_hard_drive: this.returnEquipment.size_hard_drive,
          power_source: this.returnEquipment.power_source,
          total_ram_memorie: this.returnEquipment.total_ram_memorie,
          model_name: this.returnEquipment.model_name,
          modelo: null,
        }
      },
      deep: true,
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;

  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: var(--primary);
  }
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
</style>
