<template lang="pug">
b-modal(
  v-model="model"
  title="Subir cotización"
  size="lg"
)
  b-form-file(
    v-model="file"
    accept=".pdf"
    placeholder="Seleccione un archivo"
  )
  template(#modal-footer="{ ok, cancel, hide }")
    b-button(v-if="loading" disabled)
      b-spinner(small)
    b-button(v-else size="sm" variant="success" :disabled="!file" @click="submit") Guardar
    b-button(size="sm" variant="danger" @click="closeModal") Cancelar
</template>

<script>
import { mapActions } from 'pinia'
import { useExternalQuoteStore } from '@/pinia/api/useExternalQuoteStore'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    ...mapActions(useExternalQuoteStore, ['uploadQuote']),

    closeModal() {
      this.$emit('input', false)
    },

    async submit() {
      this.loading = true
      const data = new FormData()
      data.append('file', this.file)
      const res =await this.uploadQuote({ id: this.quote.id, data })

      if (res.status < 200 || res.status >= 300) {
        this.$bvToast.toast('Error al subir la cotización', {
          title: 'Error',
          variant: 'danger',
        })
        this.loading = false
        return
      }

      this.closeModal()
      this.loading = false
      this.$emit('updated')
    },
  },
}
</script>

<style lang="scss" scoped></style>
